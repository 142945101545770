import { EMPLOYEE_ADVANCE_SALARY_APPROVE } from "constants";
import { EMPLOYEE_LEAVE_APPROVAL } from "constants";
import { EMPLOYEE_LEAVE } from "constants";
import { useEffect, useState } from "react";
import { prefillUrl } from "utils/StringUtils";
import { Avatar } from "antd";
import Title from "antd/es/typography/Title";
import { DASHBOARD_LEAVE } from "constants";

export const getEmployeeLeaveRequests = async ({ pageNo, pageSize, query, search, sorting }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = EMPLOYEE_LEAVE;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const getEmployeeLeaveApproval = async (pageNo, pageSize, query, search, sort) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  //employee/advance_salary/request/
  let url = EMPLOYEE_LEAVE_APPROVAL;

  if (pageNo) {
    url = prefillUrl(url);
    url = `${url}page_no=${pageNo}`;
  }

  if (pageSize) {
    url = prefillUrl(url);
    url = `${url}&page_size=${pageSize}`;
  }

  if (query) {

    if (query.leave_type) {
      url = prefillUrl(url);
      url = `${url}&leave_type=${query.leave_type}`;
    }

    if (query.from_date) {
      url = prefillUrl(url);
      url = `${url}&from_date=${query.from_date}`;
    }

    if (query.from_date_half) {
      url = prefillUrl(url);
      url = `${url}&from_date_half=${query.from_date_half}`;
    }

    if (query.to_date) {
      url = prefillUrl(url);
      url = `${url}&to_date=${query.to_date}`;
    }

    if (query.to_date_half) {
      url = prefillUrl(url);
      url = `${url}&to_date_half=${query.to_date_half}`;
    }
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  if (sort) {
    url = prefillUrl(url);
    url = `${url}&${sort}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeeAdvanceSalaryApprovalRequests = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  //employee/advance_salary/approve/
  let url = EMPLOYEE_ADVANCE_SALARY_APPROVE;

  if (id) {
    url = `${url}?id=${id}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const useGetEmployeeLeaveApproval = (pageNo, pageSize, filters, searchText, sort) => {
  const [data, setData] = useState(null);
  const [pageInfo, setPageInfo] = useState();
  const [sorting, setSorting] = useState()

  const [message, setMessage] = useState();

  function refetch(pageNo, pageSize, filters, searchText, sort) {
    setSorting(sort)
    fetchData(pageNo, pageSize, filters, searchText, sorting);
  }

  async function fetchData(pageNo, pageSize, filters, searchText, sorting) {
    const apiData = await getEmployeeLeaveApproval(pageNo, pageSize, filters, searchText, sorting);

    if (apiData) {
      if (apiData.status === "success") {
        setData(
          apiData.data.result.map((row) => ({
            key: row.id,
            employee_information: (
              <>
                <Avatar.Group>
                  <Avatar
                    className="shape-avatar"
                    shape="round"
                    size={50}
                    src={row.employee_id.profile}
                  ></Avatar>
                  <div className="avatar-info pl-3">
                    <Title level={5}>
                      {row.employee_id.employee_name}
                    </Title>
                    <p>
                      {row.employee_id.designation + " | " + row.employee_id.employee_id}{" "}
                    </p>
                  </div>
                </Avatar.Group>{" "}
              </>
            ),
            from_date: row.from_date,
            from_date_half: row.from_date_half,
            to_date: row.to_date,
            to_date_half: row.to_date_half,
            reason: row.reason,
            status: row.status,
          }))
        );
        setPageInfo({
          pageNo: apiData.data.page_no,
          pageSize: apiData.data.page_size,
          totalCount: apiData.data.total_count,
          noOfPages: apiData.data.no_of_pages,
        });
      } else {
        setMessage(apiData.message);
      }
    }
  }

  useEffect(() => {
    setSorting(sort ? sort : sorting)
    fetchData(pageNo, pageSize, filters, searchText, sorting);
  }, [pageNo, pageSize, filters, searchText, sorting]);

  return [data, pageInfo, message, refetch];
};

export const getEmployeeLeaveDashboard = async (date) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  //employee/advance_salary/request/
  let url = DASHBOARD_LEAVE;

  if (date) {
    url = prefillUrl(url);
    url = `${url}date=${date}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};