import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form, Steps, Tooltip } from 'antd';
import ResignationSubmission from './ResignationSubmission';
import AssetsHandover from './AssetsHandover';
import ResignationFinalStep from './ResigantionFinalStep';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImagePaths } from 'utils/ImagePath';
import { getResignationDetails } from 'hooks/api/employeeApi/getEmployeeResignation';
import { RESIGNATION_DETAILS } from 'constants';
import ToastMsg from 'components/common/ToastMsg';
import dayjs from 'dayjs';
import { extractImageName } from 'components/common/validation';
import { EmployeeResignation } from '../employeeOnboarding/apihook/CreateEmployeeApi';

const StepperIndex = () => {
    const [current, setCurrent] = useState(0);
    const [Approver, setApprover] = useState([]);
    const [loading, setLoading] = useState({
        isOnboarding: false
    });
    const [isCleared, setIsCleared] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const default_onboarding_document_details = [{
        letter_type: "",
        processed_by: "",
        approved_by: "",
        letter_provided_date: "",
        letter_status: "",
        is_send_mail: false,
        uploaded_documents: [],
        document_comments: ""
    }];
    const [formData, setFormData] = useState({
        //Resignation Submission
        resignationData: null,
        employeeDetails: null,
        approverDetails: null,
        finalApproverDetails: null,
        finalApproverData: null,
        //Assets Handover
        returnDate: undefined,
        designation: undefined,
        handoverTo: undefined,
        handoverComments: "",
        approvedDate: undefined,
        approvedBy: undefined,
        approverComments: "",
        assetDetailsList: [],
        selectedassetDetails: [],
        handoverDetails: [],
        //Final Step
        onboarding_process_details: {
            InterviewedBy: "",
            Exit_Interview_date: "",
            Exit_interview_status: "",
            Exit_interview_comments: "",
            Exit_verified_by: "",
            Exit_verified_date: "",
            verified_status: "",
            verified_comments: "",
            final_settlement_date: "",
            final_settlement_status: "",
            final_settlement_comments: ""
        },
        onboarding_document_details: default_onboarding_document_details.map(obj => ({ ...obj })),
        is_finish_process: false,
        send_mail: true,
    });
    const dateFormat = "YYYY-MM-DD";
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const location = useLocation();
    const { editDetails, stepkey } = location.state || {};
    const authtoken = sessionStorage.getItem("token")

    useEffect(() => {
        getResignationUserDetails();
        clearState();
    }, [current])

    useEffect(() => {
        setCurrent(stepkey)
    }, [stepkey]);


    const clearState = () => {
        setFormData({
            returnDate: undefined,
            designation: null,
            handoverTo: null,
            handoverComments: "",
            approvedDate: undefined,
            approvedBy: null,
            approverComments: "",
            assetDetailsList: [],
            selectedassetDetails: [],
            handoverDetails: [],
        })
        setIsCleared(true);
    };

    const getResignationUserDetails = async () => {
        setApprover([])
        try {
            const apiData = await getResignationDetails(`id=${editDetails?.id}`);
            if (apiData.data && apiData.status === "success") {
                let data = apiData.data.result[0]
                setFormData({
                    ...formData,
                    resignationData: data,
                    employeeDetails: data?.employee_details,
                    approverDetails: data?.approver,
                    finalApproverDetails: data?.interview_details,
                    finalApproverData: data?.final_approver,
                    assetDetailsList: data?.asset_details,
                    handoverDetails: data?.recent_asset_handover
                })
                setIsCleared(false);
            } else {
                setApprover([])
                ToastMsg("error", apiData?.message);
            }
        } catch (error) {
            setApprover([])
            ToastMsg("error", error?.message);
        }
    };

    const handleBackCancel = useCallback(() => {
        navigate('/employee/offboarding');
    }, [navigate, form])

    const handleSubmit = useCallback(() => {
        try {
            setLoading(true);
            const dataForm = new FormData();
            dataForm.append("stage", "2");
            dataForm.append("employee_id", formData.employeeDetails ? formData.employeeDetails?.employee_id : "");
            dataForm.append("resignation_id", formData.resignationData ? formData.resignationData?.id : "");
            dataForm.append("handover_to", formData.handoverTo ? formData.handoverTo : "");
            dataForm.append("return_date", dayjs(dayjs(), "DD-MM-YYYY").format(dateFormat));
            dataForm.append("approved_date", dayjs(formData?.approvedDate).format(dateFormat));
            dataForm.append("comments", formData.handoverComments ? formData.handoverComments : "");
            dataForm.append("approved_by", formData.approvedBy ? formData.approvedBy : "");
            dataForm.append("approved_comments", formData.approverComments ? formData.approverComments : "");
            dataForm.append("assets", formData.selectedassetDetails.length > 0 ? JSON.stringify(formData.selectedassetDetails) : null);
            fetch(RESIGNATION_DETAILS, {
                method: "PUT",
                headers: {
                    //'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: dataForm
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        setFormData({
                            ...formData,
                            selectedassetDetails: []
                        })
                        setCurrent(current + 1);
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData]);

    const handleOnboardingSave = (send_mail = false, move_to_employee = false, save_data = false) => {

        let employeeId = formData.employeeDetails?.employee_id
        let fromEdit = false
        const modifiedInfo = {
            ...formData,
            onboarding_process_details: { ...formData.onboarding_process_details },
            onboarding_document_details: formData.onboarding_document_details.map(obj => ({ ...obj })),
        };

        if (modifiedInfo.onboarding_process_details.letter_provided_date) {
            modifiedInfo.onboarding_process_details.letter_provided_date = dayjs(modifiedInfo?.onboarding_process_details?.letter_provided_date).format("YYYY-MM-DD")
        }

        if (modifiedInfo.onboarding_process_details.background_verification_date) {
            modifiedInfo.onboarding_process_details.background_verification_date = dayjs(modifiedInfo?.onboarding_process_details?.background_verification_date).format("YYYY-MM-DD")
        }

        modifiedInfo.onboarding_document_details = modifiedInfo.onboarding_document_details?.filter(row =>
            row.letter_provided_date || row.letter_type || row.processed_by || row.approved_by || row.letter_status ||
            ((row.uploaded_documents && typeof row.uploaded_documents === "string")
                || (row.uploaded_documents && Array.isArray(row.uploaded_documents) && row.uploaded_documents.length > 0))
            || row.document_comments
        );

        // creating keys for put and post
        if (modifiedInfo.onboarding_document_details?.length > 0) {

            modifiedInfo.onboarding_document_details?.forEach((item, index) => {

                if (item.uploaded_documents && item.uploaded_documents?.length > 0 && Object.keys(item.uploaded_documents[0]).length > 0) {
                    let imgObj = { ...item.uploaded_documents[0] };
                    if (imgObj.hasOwnProperty('name')) {
                        //let filename = removeFileExtension(imgObj.name);
                        item["uploaded_documents"] = "";
                        modifiedInfo[`document__${index}`] = imgObj.originFileObj;
                    }
                }
                else {
                    item["uploaded_documents"] = "";
                }
                if (item.uploaded_documents && typeof item.uploaded_documents === "string") {
                    item.uploaded_documents = extractImageName(item.uploaded_documents);
                }
                if (item.letter_provided_date) {
                    item.letter_provided_date = dayjs(item.letter_provided_date).format("YYYY-MM-DD")
                }

                item.is_send_mail = item.is_send_mail ? "True" : "False"
                item.employee = formData.employeeDetails?.employee_id;
            });

            modifiedInfo.is_finish_process = move_to_employee;
            modifiedInfo.send_mail = send_mail;
        }
        setLoading((prevState) => ({
            ...prevState,
            isOnboarding: true
        }));
        EmployeeResignation(
            modifiedInfo,
            authtoken,
            fromEdit,
            employeeId,
            async (data) => {
                ToastMsg("success", data.message);
                if (move_to_employee || save_data) {
                    navigate("/employee/offboarding");
                }
                setLoading((prevState) => ({
                    ...prevState,
                    isOnboarding: false
                }));
            },
            (error) => {
                setLoading((prevState) => ({
                    ...prevState,
                    isOnboarding: false
                }));
                ToastMsg("error", error);
            }
        )
    }

    const next = () => {
        if (current == 0 || (current == 1 && formData.selectedassetDetails.length <= 0)) {
            setCurrent(current + 1);
        } else if (current == 1 && formData.selectedassetDetails.length > 0) {
            handleSubmit()
        } else if (current == 2) {
            handleOnboardingSave(false, false, true)
        }
    };

    const prev = () => {
        if (current == 0) {
            handleBackCancel()
        } else {
            setCurrent(current - 1);
        }
    };

    const steps = [
        {
            title: 'Resigantion Submission',
            content: <ResignationSubmission form={form} formData={formData} setFormData={setFormData} triggerNext={next} triggerPrevious={prev} />,
        },
        {
            title: 'Assets Handover',
            content: <AssetsHandover form={form} formData={formData} setFormData={setFormData} setIsValid={setIsValid} triggerNext={next} triggerPrevious={prev} />,
        },
        {
            title: 'Final Step',
            content: <ResignationFinalStep form={form} formData={formData} setFormData={setFormData} triggerNext={next} triggerPrevious={prev} ResignationSave={handleOnboardingSave} isLoading={loading.isOnboarding} />,
        },
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const onChange = (value) => {
        setCurrent(value)
    };

    return (
        <Card className='h-full'>
            <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt'>{"Resignation"}</p>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Steps className='mt-5' size="small" style={{ width: 600, align: 'center' }} onChange={editDetails?.id ? onChange : null} current={current} items={items} />
            </div>
            <div>{steps[current].content}</div>
        </Card>
    );
};

export default StepperIndex;
