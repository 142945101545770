import { EMPLOYEE_WEEK_OFF } from 'constants';
import { prefillUrl } from 'utils/StringUtils';


export const getEmployeeWeekOff = async (query) => {
    const authtoken = sessionStorage.getItem("token")
    let url = EMPLOYEE_WEEK_OFF;

    if (query) {
        url = prefillUrl(url);
        url = `${url}drop_down=${query}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getEmployeeWeekOffByDepartment = async (query, dept) => {
    const authtoken = sessionStorage.getItem("token")
    let url = EMPLOYEE_WEEK_OFF;

    if (query) {
        url = prefillUrl(url);
        url = `${url}drop_down=${query}`;
    }
    if (dept) {
        url = prefillUrl(url);
        url = `${url}&department=${dept}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getEmployeeWeekOffPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = EMPLOYEE_WEEK_OFF;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getEmployeeWeekOffById = async (id) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },

    };

    return await fetch(EMPLOYEE_WEEK_OFF + `?id=${id}`, requestOptions).then((response) => response.json()).then(data => data);
}

export const addEmployeeWeekOff = async (input) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `token ${authtoken}` },
        body: JSON.stringify(input)
    };

    return await fetch(EMPLOYEE_WEEK_OFF, requestOptions).then((response) => response.json()).then(data => data);
}

export const updateEmployeeWeekOff = async (input) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'PUT',
        headers: { 'Authorization': `token ${authtoken}` },
        body: JSON.stringify(input)
    };

    return await fetch(EMPLOYEE_WEEK_OFF, requestOptions).then((response) => response.json()).then(data => data);
}

export const getCountOfDays = (daySet, weekDay) => {
    const count = daySet.filter(day => day.includes(weekDay)).length;
    if (count == 5) {
        return count;
    }
    else {
        return 0;
    }
};

export const removeSpecialCharacters = (inputString) => {
    // Define a regular expression pattern to match special characters at the end of the string
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\s]*$/;

    // Use the replace method with the regex pattern to remove special characters from the end
    const cleanedString = inputString.replace(regex, '');

    return cleanedString;
};

export const getweekOffRules = async (daySet) => {
    let offRules = "";

    const checkAllNumbersMatched = (numbersToMatch) => {
        return numbersToMatch.every(number => daySet.includes(number));
    };

    const mondayNumbers = [1, 8, 15, 22, 29];
    const tuesdayNumbers = [2, 9, 16, 23, 30];
    const wednesdayNumbers = [3, 10, 17, 24, 31];
    const thursdayNumbers = [4, 11, 18, 25, 32];
    const fridayNumbers = [5, 12, 19, 26, 33];
    const saturdayNumbers = [6, 13, 20, 27, 34];
    const sundayNumbers = [7, 14, 21, 28, 35];

    const isMonday = checkAllNumbersMatched(mondayNumbers);
    const isTueday = checkAllNumbersMatched(tuesdayNumbers);
    const isWednesday = checkAllNumbersMatched(wednesdayNumbers);
    const isThursday = checkAllNumbersMatched(thursdayNumbers);
    const isFriday = checkAllNumbersMatched(fridayNumbers);
    const isSaturday = checkAllNumbersMatched(saturdayNumbers);
    const isSunday = checkAllNumbersMatched(sundayNumbers);

    offRules = isMonday ? "All Monday," : ""
    offRules += isTueday ? "All Tueday," : ""
    offRules += isWednesday ? "All Wednesday," : ""
    offRules += isThursday ? "All Thursday," : ""
    offRules += isFriday ? "All Friday," : ""
    offRules += isSaturday ? "All Saturday," : ""
    offRules += isSunday ? "All Sunday," : ""

    const filterArrayByMatchedValues = (originalArray, filterValues) => {
        const isAllValuesPresent = filterValues.every(value => originalArray.includes(value));

        if (!isAllValuesPresent) {
            return originalArray;
        }

        const filteredArray = originalArray.filter(item => !filterValues.includes(item));
        return filteredArray;
    };

    let filteredArray = filterArrayByMatchedValues(daySet, mondayNumbers);
    filteredArray = filterArrayByMatchedValues(filteredArray, tuesdayNumbers);
    filteredArray = filterArrayByMatchedValues(filteredArray, wednesdayNumbers);
    filteredArray = filterArrayByMatchedValues(filteredArray, thursdayNumbers);
    filteredArray = filterArrayByMatchedValues(filteredArray, fridayNumbers);
    filteredArray = filterArrayByMatchedValues(filteredArray, saturdayNumbers);
    filteredArray = filterArrayByMatchedValues(filteredArray, sundayNumbers);

    const getDayDescription = day => {
        const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const weekNumber = Math.ceil(day / 7);
        const dayIndex = (day) % 7;
        let offs = ""
        if (weekNumber === 1) {
            offs = `${weekNumber}st ${dayOfWeek[dayIndex]}`
        }
        else if (weekNumber === 2) {
            offs = `${weekNumber}nd ${dayOfWeek[dayIndex]}`
        }
        else if (weekNumber === 3) {
            offs = `${weekNumber}rd ${dayOfWeek[dayIndex]}`
        }
        else if (weekNumber === 4 || weekNumber === 5) {
            offs = `${weekNumber}th ${dayOfWeek[dayIndex]}`
        }


        return offs;
    };

    const val = filteredArray.map(day => ({ value: day, description: getDayDescription(day) }));
    let offRule = ""

    val.map(day => (
        offRule = offRule ? offRule + " & " + day.description : day.description
    ))

    if (offRules !== "") {
        offRules = removeSpecialCharacters(offRules);
    }

    offRules = offRules ? offRules + " & " + offRule : offRule

    if (offRules !== "") {
        offRules = removeSpecialCharacters(offRules);
    }

    return offRules
}

export const generateOutput = (arr, off_type) => {
    const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const output = arr.map(item => {
        const weekNumber = Math.ceil(item / 7);
        const dayIndex = (item) % 7;
        const day = dayOfWeek[dayIndex];
        return { week: weekNumber, day: [day] };
    });

    let groupedOutput = output.reduce((acc, item) => {
        const existingWeek = acc.find(el => el.week === item.week);
        if (existingWeek) {
            existingWeek.day.push(...item.day);
        } else {
            acc.push(item);
        }
        return acc;
    }, []);

    if (off_type === "fullday") {
        groupedOutput = {
            "fullday": groupedOutput
        }
    }
    else if (off_type === "half_day") {
        groupedOutput = {
            "half_day": groupedOutput
        }
    }

    return groupedOutput;
};

export const convertOutputToArray = (outputArray, off_type) => {
    const result = [];
    const dayOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    if (off_type === "fullday") {
        outputArray.fullday && outputArray.fullday.forEach(item => {
            item.day.map((day, index) => {
                const dayIndex = dayOfWeek.indexOf(item.day[index]);
                const dayNumber = (item.week - 1) * 7 + (dayIndex + 1);
                result.push(dayNumber);
            })
        });
    }

    if (off_type === "halfday") {
        outputArray.half_day && outputArray.half_day.forEach(item => {
            item.day.map((day, index) => {
                const dayIndex = dayOfWeek.indexOf(item.day[index]);
                const dayNumber = (item.week - 1) * 7 + (dayIndex + 1);
                result.push(dayNumber);
            })
        });
    }


    return result;
};

// export const getWeekOffRules = async (date) => {
//     let sundayDetails = await getWeekDetails(date,0,"Sunday");
//     let mondayDetails = await getWeekDetails(date,1,"Monday");
//     let tuesdayDetails = await getWeekDetails(date,2,"Tuesday");
//     let weddayDetails = await getWeekDetails(date,3,"Wednesday");
//     let thudayDetails = await getWeekDetails(date,4,"Thursday");
//     let fridayDetails = await getWeekDetails(date,5,"Friday");
//     let satdayDetails = await getWeekDetails(date,6,"Saturday");
//     let weekOffRule = "";

//     weekOffRule = sundayDetails? sundayDetails : weekOffRule
//     weekOffRule = " & " + mondayDetails? weekOffRule + mondayDetails : weekOffRule
//     weekOffRule = " & " + tuesdayDetails? weekOffRule + tuesdayDetails : weekOffRule
//     weekOffRule = " & " + weddayDetails? weekOffRule + weddayDetails : weekOffRule
//     weekOffRule = " & " + thudayDetails? weekOffRule + thudayDetails : weekOffRule
//     weekOffRule = " & " + fridayDetails? weekOffRule + fridayDetails : weekOffRule
//     weekOffRule = " & " + satdayDetails? weekOffRule + satdayDetails : weekOffRule
//     return weekOffRule;
// }

// const getWeekDetails = async (date,weekday,weekname) => {
//     const noOfDaysInMonth = moment().daysInMonth();
//     // const monthStart = moment().date(1).format("YYYY-MM-DD");
//     let saturdays = [];
//     for(var i = 1; i <= noOfDaysInMonth; i++) {
//         if(moment(moment().date(i).format("YYYY-MM-DD")).day()===weekday)
//         {
//             saturdays.push(moment().date(i).format("YYYY-MM-DD"))
//         }
//     }
//     let sat=""
//     saturdays.forEach((satd,i) => {
//         date.forEach((sd) => {
//             if(satd === sd){
//                 sat=sat+(i+1)
//             }
//         })
//     })
//     let weekOffDetail = weekOffDetails(sat, weekname, saturdays.length)
//     return weekOffDetail;
// }

// const weekOffDetails = (off, weekDays, weekDaysLength) =>
// {
//     let weekOffDetails = "";
//     if(off === "13")
//     {
//         if(weekDaysLength===4)
//         {
//             weekOffDetails=`Alternative ${weekDays}'s`
//         }
//         else{
//             weekOffDetails=`1st & 3rd ${weekDays}'s`
//         }
//     }
//     else if(off === "135" && weekDaysLength===5)
//     {
//         weekOffDetails=`Alternative ${weekDays}'s`
//     }
//     else if(off === "12345" && weekDaysLength===5)
//     {
//         weekOffDetails=`All ${weekDays}'s`
//     }
//     else if(off === "1234")
//     {
//         if(weekDaysLength===4)
//         {
//             weekOffDetails=`All ${weekDays}'s`
//         }
//         else{
//             weekOffDetails=`1st,2nd,3rd,4th ${weekDays}'s`
//         }
//     }
//     else if(off === "123")
//     {
//         weekOffDetails=`1st,2nd,3rd ${weekDays}'s`
//     }
//     else if(off === "12")
//     {
//         weekOffDetails=`1st,2nd ${weekDays}'s`
//     }
//     else if(off === "1")
//     {
//         weekOffDetails=`1st ${weekDays}`
//     }
//     else if(off === "2")
//     {
//         weekOffDetails=`2nd ${weekDays}`
//     }
//     else if(off === "3")
//     {
//         weekOffDetails=`3rd ${weekDays}`
//     }
//     else if(off === "4")
//     {
//         weekOffDetails=`4th ${weekDays}`
//     }
//     else if(off === "5" && weekDaysLength===5)
//     {
//         weekOffDetails=`5th ${weekDays}`
//     }
//     else if(off === "14")
//     {
//         weekOffDetails=`1st,4th ${weekDays}'s`
//     }
//     else if(off === "15" && weekDaysLength===5)
//     {
//         weekOffDetails=`1st,5th ${weekDays}'s`
//     }
//     else if(off === "23")
//     {
//         weekOffDetails=`2nd,3rd ${weekDays}'s`
//     }
//     else if(off === "24")
//     {
//         if(weekDaysLength===4)
//         {
//             weekOffDetails=`Alternative ${weekDays}'s`
//         }
//         else{
//             weekOffDetails=`2nd,4th ${weekDays}'s`
//         }
//     }
//     else if(off === "35" && weekDaysLength===5)
//     {
//         weekOffDetails=`3rd,5th ${weekDays}'s`
//     }
//     return weekOffDetails;
// }