import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(...registerables);

const StackedBarChart = ({ list }) => {
    if (list?.length === 0) {
        return null;
    }
    const datasets = [];
    let startTimeForXAxis = new Date().getTime();
    const lateData = [];
    const productiveData = [];
    const breakData = [];
    const overtimeData = [];

    list?.forEach(item => {
        //getting the initialize stat point of the chart
        const startTime = new Date(item.from_time).getTime();
        if (startTime < startTimeForXAxis) {
            startTimeForXAxis = startTime;
        }
        if (item.status.includes('late')) {
            lateData.push({ x: [item.from_time, item.to_time], y: "" });
        } else if (item.status.includes('productive') || item.status.includes('ontime')) {
            productiveData.push({ x: [item.from_time, item.to_time], y: "" });
        } else if (item.status.includes('break')) {
            breakData.push({ x: [item.from_time, item.to_time], y: "" });
        } else if (item.status.includes('overtime')) {
            overtimeData.push({ x: [item.from_time, item.to_time], y: "" });
        }
    });

    if (lateData.length > 0) {
        datasets.push({
            label: 'Late',
            data: lateData,
            backgroundColor: '#D94853',
            barThickness: 30,
        });
    }
    if (productiveData.length > 0) {
        datasets.push({
            label: 'Productive',
            data: productiveData,
            backgroundColor: '#0E862A',
            barThickness: 30,
        });
    }
    if (breakData.length > 0) {
        datasets.push({
            label: 'Break',
            data: breakData,
            backgroundColor: '#CACACA',
            barThickness: 30,
        });
    }
    if (overtimeData.length > 0) {
        datasets.push({
            label: 'Overtime',
            data: overtimeData,
            backgroundColor: '#285BA7',
            barThickness: 30,
        });
    }

    const data = { labels: "", datasets };
    startTimeForXAxis = startTimeForXAxis !== Infinity ? startTimeForXAxis : new Date().getTime();

    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: (ctx) => {
                        const startDate = new Date(ctx.parsed._custom.barStart);
                        const endDate = new Date(ctx.parsed._custom.barEnd);
                        const formattedStartDate = startDate.toLocaleString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        });
                        const formattedEndDate = endDate.toLocaleString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        });
                        return `${formattedStartDate} - ${formattedEndDate}`
                    }
                },
                titleFont: {
                    family: 'Urbanist',
                },
                bodyFont: {
                    family: 'Urbanist',
                },
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                },
                ticks: {
                    color: '#183433',
                    font: {
                        family: 'Urbanist',
                        size: 15,
                        weight: 'bold',
                    },
                },
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'hh:mm a'
                    }
                },
                min: startTimeForXAxis
            },
            y: {
                beginAtZero: true,
                stacked: true,
                grid: {
                    color: 'rgba(0, 0, 0, 0)',
                    drawBorder: false,
                },
            }
        }
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            {list?.length > 0 ? <Bar data={Object.keys(data)?.length > 0 ? data : null} options={options} /> : null}
        </div>
    );
};

export default StackedBarChart;


