import { VERIFY_OTP } from "constants";

const VerifyOTPApi = (
  formData,
  authtoken,
  id,
  email,
  onSuccess,
  onError
) => {
  let url = VERIFY_OTP;

  formData = {
    user_id: parseInt(id),
    otp: parseInt(formData),
    email: email
  }

  console.log(formData,"formdata");

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${authtoken}`,
    },
    body: JSON.stringify(formData)
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};

export default VerifyOTPApi;
