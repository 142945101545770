import { EMPLOYEE_ISSUED_DOCUMENT } from "constants";

export const getIssuedEmployeeDocById = async (id) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },

    };

    return await fetch(EMPLOYEE_ISSUED_DOCUMENT + `?employee_id=${id}`, requestOptions).then((response) => response.json()).then(data => data);
}

export const CreateEmployeeIssuedDoc = (
    formData,
    authtoken,
    employeeId,
    onSuccess,
    onError
  ) => {
  
    const dataForm = new FormData();
    dataForm.append("employee_id", formData?.employee_id ? formData?.employee_id : null);
    dataForm.append("letter_type", formData?.letter_type ? formData?.letter_type : null);
    dataForm.append("processed_by", formData?.processed_by ? formData?.processed_by : null);
    dataForm.append('approved_by', formData?.approved_by ? formData?.approved_by : null);
    dataForm.append('letter_provided_date', formData?.letter_provided_date ? formData?.letter_provided_date : null);
    dataForm.append('letter_status', formData?.letter_status ? formData?.letter_status : null);
    dataForm.append("uploaded_documents", formData?.uploaded_documents[0]?.originFileObj)
    for (const key in formData) {
      if(key.includes("__")){
        dataForm.append(key, formData[key]);
      }
    }
    fetch(EMPLOYEE_ISSUED_DOCUMENT, {
      method: "POST",
      headers: {
        Authorization: `token ${authtoken}`,
      },
      body: dataForm
    })
      .then((response) => {
        if (response.status === 413) {
          onError("Request payload is too large");
        }
        return response.json()
      })
      .then(async (data) => {
        if (data.status === "success") {
          onSuccess(data);
        } else if (data.status === "fail") {
          onError(data.message);
        }
      })
      .catch(error => {
        onError(error.message);
    });
  };

  export const deleteDocumentById = async (id, letter_type) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        let url = `${EMPLOYEE_ISSUED_DOCUMENT}?document_id=${id}&letter_type=${letter_type}`;

        const requestOptions = {
            method: "DELETE",
            headers: {
                Authorization: `token ${authtoken}`,
            },
        };

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};
