import React, { useState, useEffect, useRef } from "react";
import { Input, Select, DatePicker, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { statusEnum, approvalTypeEnum, payrollStatusEnum, monthEnum } from "components/common/enum";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { disabledFutureDate } from "components/common/validation";

const PayrollFilter = ({
    pageName,
    filterDatas,
    closeFilter,
    filteredData,
    clearData,
}) => {
    const [filterData, setFilterData] = useState({
        from_date: filterDatas.from_date,
        to_date: filterDatas.to_date,
        leave_type: filterDatas.leave_type,
        employee: filterDatas.employee,
        leaveTypeList: filterDatas.leaveTypeList,
        payroll_name: filterDatas.payroll_name,
        payroll_month: filterDatas.payroll_month,
        employee_type: filterDatas.employee_type,
        empTypeList: filterDatas.empTypeList,
        designation_name: filterDatas.designation_name,
        department_name: filterDatas.department_name,
        designationList: filterDatas.designationList,
        departmentList: filterDatas.departmentList,
        max_amount: filterDatas.max_amount,
        min_amount: filterDatas.min_amount,
        approval_date: filterDatas.approval_date,
        approval_type: filterDatas.approval_type,
        approval_employee: filterDatas.approval_employee,
        requested_date: filterDatas.requested_date,
        payroll_status: filterDatas.payroll_status,
    });
    const [employeeList, setEmployeeList] = useState([]);
    const hideCursorRef = useRef({});

    const getEmployeeList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setEmployeeList([...apiData.data])
            } else {
                setEmployeeList([])
            }
        } catch (error) {
            setEmployeeList([])
        }
    }

    useEffect(() => {
        getEmployeeList();
    }, []);

    const handleChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            from_date: "",
            to_date: "",
            leave_type: "",
            employee: "",
            payroll_name: "",
            payroll_month: "",
            employee_type: "",
            designation_name: "",
            department_name: "",
            max_amount: "",
            min_amount: "",
            approval_date: "",
            approval_type: undefined,
            approval_employee: undefined,
            requested_date: "",
            payroll_status: "",
        }));
        clearData();
    };

    const renderInput = (label, statekey, maxLength = 150,) => {
        return (
            <div className="fltTextFieldDiv">
                <div className='commonInputTlt'>{label}</div>
                <Input
                autoComplete="off"
                className={statekey == 'min_amount' || statekey == 'max_amount'?  "fltDatepickertextField" : "fltTextField"}
                type={'text'}
                name={statekey}
                value={filterData[statekey] ? filterData[statekey] : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                maxLength={maxLength}
            />
            </div>
        );
    };

    const handleDatePickerChange = (date, name) => {
        setFilterData({
            ...filterData,
            [name]: dayjs(date).format("YYYY-MM-DD"),
        });
    };

    const renderDatePicker = (label, statekey) => {
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <DatePicker
                className="fltTextField"
                disabledDate={disabledFutureDate}
                name={statekey}
                value={filterData[statekey] ? dayjs(filterData[statekey], "YYYY-MM-DD") : ""}
                format={"DD-MM-YYYY"}
                onChange={(date) => handleDatePickerChange(date, statekey,)}
                allowClear={false}
                inputReadOnly={true}
                locale={locale}
                placeholder = ""
            />
            </div>
        )
    }

    const renderSelect = (label, statekey, list = statusEnum, showKey = "text", valueKey = "value") => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <Select
                className="fltTextField"
                value={filterData[statekey] ? filterData[statekey] : undefined}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                ref={hideCursorRef.current[statekey]}
                onSelect={() => {
                    hideCursorRef.current[statekey].current.blur();
                }}
            >
                {list.map((option) => (
                    (valueKey === "id" && option.is_active === true) || valueKey !== "id" ?
                    <Select.Option key={option[valueKey]} value={option[valueKey]}>
                       {statekey === "employee" || statekey === "approval_employee" ? option[showKey] + (option['employee_id'] ? " - " + option['employee_id'] : "") : option[showKey]}
                    </Select.Option> : null
                ))}
            </Select>
            </div>
        );
    };

    return (
        <div className="h-full w-full">
            <div className="flex justify-between items-center filterTltDiv">
                <p className="common_addTltTxt">{"Filter"}</p>
                <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter} /></Tooltip>
            </div>
            <div className="flex flex-col items-center fltContentDiv">
                {pageName === "LeaveApproval" ? (
                    <>
                        {renderDatePicker("Select From Date", "from_date",)}
                        {renderDatePicker("Select To Date", "to_date",)}
                        {renderSelect("Select Leave Type", "leave_type", filterData.leaveTypeList, "leave_name", "id")}
                        {renderSelect("Select Employee", "employee", employeeList, "first_name", "id")}
                    </>
                )
                    : pageName === "AdvanceSalary" ? (
                        <>
                            {renderDatePicker("Select From Date", "from_date",)}
                            {renderDatePicker("Select To Date", "to_date",)}
                            {renderSelect("Select Employee", "employee", employeeList, "first_name", "id")}
                        </>
                    ) : pageName === "Reimbursements" ? (
                        <>
                            {renderDatePicker("Select From Date", "from_date",)}
                            {renderDatePicker("Select To Date", "to_date",)}
                            {renderSelect("Select Employee", "employee", employeeList, "first_name", "id")}
                        </>
                    ) : pageName === "Payroll" ? (
                        <>
                            {renderSelect("Select Payroll Month", "payroll_month", monthEnum.filter(month => month.text !== 'All'))}
                            {renderSelect("Select Employee Type", "employee_type", filterData.empTypeList, "employee_type_name", "id")}
                            {renderSelect("Status", "payroll_status", payrollStatusEnum)}
                        </>
                    ) : pageName === "Payslip" ? (
                        <>
                            {renderSelect("Select Employee Name", "employee", employeeList, "first_name", "id")}
                            {renderSelect("Select Designation", "designation_name", filterData.designationList, "designation_name", "id")}
                            {renderSelect("Select Department", "department_name", filterData.departmentList, "department_name", "id")}
                            <div className="grid grid-cols-2 gap-3" >
                                {renderInput("Min Amount", 'min_amount', 7)}
                                {renderInput("Max Amount", 'max_amount', 7)}
                            </div>
                        </>
                    )
                        : pageName === "ApprovalInbox" ? (<>
                            {renderDatePicker("Select Date", "approval_date",)}
                            {renderSelect("Select Approval Type", "approval_type", approvalTypeEnum)}
                            {renderSelect("Select Employee", "approval_employee", employeeList, "first_name", "id")}
                        </>
                        )
                        : pageName === "ResignationApproval" ? (<>
                            {renderDatePicker("Request Date", "requested_date",)}
                            {renderSelect("Select Department", "department_name", filterData.departmentList, "department_name", "id")}
                            {renderSelect("Select Employee", "employee", employeeList, "first_name", "id")}
                        </>
                        )
                            : null}
            </div>

            <div className="flex justify-center items-end">
                <MyButton
                    label="Clear"
                    onClick={handleClear}
                    outlined = {true}
                    paddingX={"0 0.7vw"}
                    marginRight={"0.625vw"}
                />
                <MyButton
                    label={"Apply"}
                    onClick={() => filteredData(filterData)}
                    paddingX={"0 0.5vw"}
                />
            </div>
        </div>
    );
};

export default PayrollFilter;