import React, { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const EmployeeTypeChart = ({ chartData }) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(`chartdiv`);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    var chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(70)
    }));
    

    // Define data
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 4,
        fixedWidthGrid: false
      })
    }));
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      legendLabelText: "{category}",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{category} - {value}"
      })
    }));

    series.get("colors").set("colors", [
      am5.color(0xD94854),
      am5.color(0x087f8c),
      am5.color(0x5aaa95),
      am5.color(0x86a873),
      am5.color(0xbb9f06)
    ]);

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
      width: 15,
      height: 15,
    });

    // Create modal for a "no data" note
    var modal = am5.Modal.new(root, {
      content: "No data"
    });

    const transformedData = Object.entries(chartData).map(([category, value]) => ({
      category,
      value
    }));

    if(transformedData){
      series.data.setAll(transformedData)
    }
    else{
      series.data.setAll([]);
    }
    series.events.on("datavalidated", function(ev) {
      var series = ev.target;
      if (ev.target.data.length < 1) {  
        // Generate placeholder data
        var categoryField = series.get("categoryField");
        var valueField = series.get("valueField");
        var placeholder = [];
        for (let i = 0; i < 3; i++) {
          var item = {};
          item[categoryField] = "";
          item[valueField] = 1;
          placeholder.push(item)
        }
        series.data.setAll(placeholder);
        
        // Disable ticks/labels
        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("forceHidden", true);
        
        // Show modal
        modal.open();
      }
      else {
        // Re-enable ticks/labels
        // series.labels.template.set("forceHidden", false);
        // series.ticks.template.set("forceHidden", false);
        
        // Hide modal
        modal.close();
      }
    });
    
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    legend.data.setAll(series.dataItems);
    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300"
    });
    series.labels.template.set("forceHidden", true);
    series.ticks.template.set("forceHidden", true);
    legend.valueLabels.template.set("forceHidden", true);
    // series.appear();
    // chart.appear(1000, 100);

    return () => {
      root._logo?.dispose();
      root.dispose();
    };
  }, [chartData]);

  return (
    <div id={`chartdiv`} style={{ width: "100%", height: "240px" }} />
  );
}
export default EmployeeTypeChart;
