import { ADMIN_NOTICE_BOARD } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getNoticeBoardPagination = async ({
  pageNo,
  pageSize,
  query,
  search,
  sorting,
}) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = ADMIN_NOTICE_BOARD;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};



export const deleteNoticeBoardById = async (id, isDraft = false) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = ADMIN_NOTICE_BOARD;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("is_active", false);
    if (isDraft) {
      formData.append("draft_delete", true);
      formData.append("is_draft", true);
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `token ${authtoken}`,
      },
      body: formData,
    };
    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};