import { EMPLOYEE_TYPE } from "constants";
import { prefillUrl } from "utils/StringUtils";


export const getEmployeeType = async (query) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    let url = EMPLOYEE_TYPE;

    if (query) {
      url = prefillUrl(url);
      url = `${url}${query}`;
    }

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const getEmployeeTypePagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = EMPLOYEE_TYPE;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const getEmployeeTypeById = async (id) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYEE_TYPE + `?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};
