import { PAYROLL_PAY_SLIP } from "constants";
import { prefillUrl } from "utils/StringUtils";
import dayjs from "dayjs";

export const getPayslipPagination = async ({ pageNo, pageSize, query, search, sorting, date }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = PAYROLL_PAY_SLIP;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    if (date) {
      url = prefillUrl(url);
      url = `${url}&payroll_month=${dayjs(date).format('M')}&payroll_year=${dayjs(date).format('YYYY')}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};
