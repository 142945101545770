import React, { useState, useRef } from 'react';
import { Input, Select, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from 'components/ui/Button/MyButton';
import { statusEnum, salaryDeductEnum, salaryShareEnum, holidaytype, payrollPeriodEnum, approvalTypeEnum, salaryComponentType } from 'components/common/enum';

const ConfigurationFilter = ({ pageName, filterDatas, closeFilter, filteredData, clearData }) => {
    const [filterData, setFilterData] = useState({
        shift_code: filterDatas.shift_code,
        shift_name: filterDatas.shift_name,
        shift_is_active: filterDatas.shift_is_active,
        deduction_code: filterDatas.deduction_code,
        deduction_name: filterDatas.deduction_name,
        share: filterDatas.share,
        deduct_from: filterDatas.deduct_from,
        deduction_is_active: filterDatas.deduction_is_active,
        holiday_code: filterDatas.holiday_code,
        holiday_name: filterDatas.holiday_name,
        holiday_type: filterDatas.holiday_type,
        holiday_is_active: filterDatas.holiday_is_active,
        weekOff_code: filterDatas.weekOff_code,
        weekOff_name: filterDatas.weekOff_name,
        weekOff_is_active: filterDatas.weekOff_is_active,
        approval_type: filterDatas.approval_type,
        approval_department: filterDatas.approval_department,
        approval_is_active: filterDatas.approval_is_active,
        departmentList: filterDatas.departmentList,
        payroll_code: filterDatas.payroll_code,
        payroll_name: filterDatas.payroll_name,
        period: filterDatas.period,
        payroll_is_active: filterDatas.payroll_is_active,
        salary_template_code: filterDatas.salary_template_code,
        salary_template_is_active: filterDatas.salary_template_is_active,
        salary_component_code: filterDatas.salary_component_code,
        salary_component_type: filterDatas.salary_component_type,
        salary_component_status: filterDatas.salary_component_status,
    });

    const hideCursorRef = useRef({});

    const handleChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFilterData((prevState) => ({
            ...prevState,
            shift_code: "",
            shift_name: "",
            shift_is_active: "",
            deduction_code: "",
            deduction_name: "",
            share: "",
            deduct_from: "",
            deduction_is_active: "",
            holiday_code: "",
            holiday_name: "",
            holiday_type: "",
            holiday_is_active: "",
            weekOff_code: "",
            weekOff_name: "",
            weekOff_is_active: "",
            approval_type: "",
            approval_department: "",
            approval_is_active: "",
            payroll_code: "",
            payroll_name: "",
            period: "",
            payroll_is_active: "",
            salary_template_code: "",
            salary_template_is_active: "",
            salary_component_code: "",
            salary_component_type: "",
            salary_component_status: "",
        }))
        clearData();
    }

    const renderInput = (label, statekey, testID = '') => {
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <Input
                autoComplete='off'
                className="fltTextField"
                size="large"
                type="text"
                name={statekey}
                value={filterData[statekey] ? filterData[statekey] : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={testID}
            />
            </div>
        )
    }

    const renderSelect = (label, statekey, testID = '', list = statusEnum, showKey = "text", valueKey = "value") => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <Select
                className="fltTextField"
                value={filterData[statekey] ? filterData[statekey] : undefined}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                data-testid={testID}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                ref={hideCursorRef.current[statekey]}
                onSelect={() => {
                    hideCursorRef.current[statekey].current.blur();
                }}
            >
                {list.map((option) => (
                    (statekey === "approval_department" && option.is_active === true) || statekey !== "approval_department" ?
                    <Select.Option key={option[valueKey]} value={option[valueKey]}>{option[showKey]}</Select.Option>
                    : null
                ))}
            </Select>
            </div>
        )
    }

    return (
        <div className='h-full w-full'>
            <div className="flex justify-between items-center filterTltDiv">
                <p className="common_addTltTxt">{"Filter"}</p>
                <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter} /></Tooltip>
            </div>
            <div className="flex flex-col items-center fltContentDiv" >
                {pageName === "Shift" ?
                    <>
                        {renderInput("Shift Code", "shift_code", 'sft_flt_code')}
                        {renderInput("Shift Name", "shift_name", 'sft_flt_name')}
                        {renderSelect("Status", "shift_is_active", 'sft_flt_sts')}
                    </>
                    : pageName === "SalaryDeduction" ?
                        <>
                            {renderInput("Deduction Code", "deduction_code", 'sd_flt_dcode')}
                            {renderInput("Deduction name", "deduction_name", 'sd_flt_dname')}
                            {renderSelect("Share", "share", 'sd_flt_share', salaryShareEnum)}
                            {renderSelect("Deduct From", "deduct_from", 'sd_flt_dfrom', salaryDeductEnum)}
                            {renderSelect("Status", "deduction_is_active", 'sd_flt_sts')}
                        </>
                        : pageName === "HolidaySetup" ?
                            <>
                                {renderInput("Holiday Code", "holiday_code", 'hs_flt_code')}
                                {renderInput("Holiday name", "holiday_name", 'hs_flt_name')}
                                {renderSelect("Holiday Type", "holiday_type", 'hs_flt_type', holidaytype)}
                                {renderSelect("Status", "holiday_is_active", 'hs_flt_sts')}
                            </>
                            : pageName === "Weekoff" ?
                                <>
                                    {renderInput("Week-Off Code", "weekOff_code", 'woff_flt_code')}
                                    {renderInput("Week-Off name", "weekOff_name", 'woff_flt_name')}
                                    {renderSelect("Status", "weekOff_is_active", 'woff_flt_sts')}
                                </>
                                : pageName === "ApprovalHierarchy" ?
                                    <>
                                        {renderSelect("Approval Type", "approval_type", 'ah_flt_type', approvalTypeEnum)}
                                        {renderSelect("Department", "approval_department", 'ah_flt_dep', filterDatas.departmentList, "department_name", "id")}
                                        {renderSelect("Status", "approval_is_active", 'ah_flt_sts')}
                                    </>
                                    : pageName === "PayrollPeriod" ?
                                        <>
                                            {renderInput("Payroll Period Code", "payroll_code", 'pp_flt_code')}
                                            {renderInput("Payroll Period Name", "payroll_name", 'pp_flt_name')}
                                            {renderSelect("Period", "period", 'pp_flt_period', payrollPeriodEnum)}
                                            {renderSelect("Status", "payroll_is_active", 'pp_flt_sts')}
                                        </>
                                        : pageName === "SalaryTemplate" ?
                                            <>
                                                {renderInput("Salary Template Code", "salary_template_code")}
                                                {renderSelect("Status", "salary_template_is_active")}
                                            </>
                                            : pageName === "SalaryComponents" ?
                                                <>
                                                    {renderInput("Salary Component Code", "salary_component_code", '')}
                                                    {renderSelect("Component Type", "salary_component_type", '', salaryComponentType)}
                                                    {renderSelect("Status", "salary_component_status",)}
                                                </>
                                                : null}
            </div>
            <div className='flex justify-center items-end'>
                <MyButton label="Clear" onClick={handleClear} outlined = {true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} testID='config_flt_clear' />
                <MyButton label={"Apply"} onClick={() => filteredData(filterData)} paddingX={"0 0.5vw"} testID='config_flt_apply' />
            </div>
        </div>
    )
}

export default ConfigurationFilter;

