import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Select, Checkbox, Tooltip } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup, CommonDeletePopup } from "components/common/CommonComponent";
import { SALARY_COMPONENT } from "constants";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from "components/common/validation";
import { salaryComponentType } from 'components/common/enum.js';

export const AddEditSalaryComponent = () => {
  const [formData, setFormData] = useState({
    code: "",
    salary_component_name: "",
    component_type: "",
    is_active: true,
    employee_share: true,
    employer_share: false,
    amount: false,
  });
  const [loading, setLoading] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();
  //const [isalert, setIsAlert] = useState(false); //as discussed

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      const dataObj = {
        code: editDetails?.code ? editDetails?.code : "",
        salary_component_name: editDetails?.salary_component_name ? editDetails?.salary_component_name : "",
        component_type: editDetails?.component_type ? editDetails?.component_type : "",
        is_active: editDetails?.is_active,
        employee_share: editDetails?.employee_share,
        employer_share: editDetails?.employer_share,
        amount: editDetails?.amount,
        is_default: editDetails?.is_default
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails?.id,
        ...dataObj
      }));
      form.setFieldsValue({...dataObj});
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const handleChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value
    };
    setFormData(updatedFormData);
    form.setFieldsValue(updatedFormData);
  };

  const handleKeyDown = (e) => {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete', 'Shift'];
    if (allowedKeys.includes(e.key) || /^[a-zA-Z\s]$/.test(e.key)) {
      return;
    }
    e.preventDefault();
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <Input
          autoComplete='off'
          style={styles.textField}
          disabled={statekey === "code"}
          type={"text"}
          name={statekey}
          value={formData[statekey]}
          maxLength={maxLength}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          onKeyDown={statekey === "salary_component_name" ? handleKeyDown : null}
          data-testID={testID}
        />
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/configuration/salarycomponent');
  }, [navigate, form])

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    //setIsAlert(false); //as discussed
    handleBackCancel();
  }

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData = {
        ...updatedFormData,
         employee_share: updatedFormData?.component_type === "earning" ? false : updatedFormData?.employee_share,
         employer_share: updatedFormData?.component_type === "earning" ? false : updatedFormData?.employer_share,
         amount: updatedFormData?.component_type === "earning" ? false : updatedFormData?.amount,
         is_draft: isDraftClked
      }
      fetch(SALARY_COMPONENT, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
              code: "",
              salary_component_name: "",
              component_type: "",
              is_active: true,
              employee_share: true,
              employer_share: false,
              amount:false,
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  }, [formData, fromEdit, handleBackCancel]);

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    //if(!formData.is_active){
      //setIsAlert(true); //as discussed
    //}else{
      checkAllMandatory(false);
    //}
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const handleSaveInactive = useCallback(() => {
    checkAllMandatory(false);
    //setIsAlert(false); //as discussed
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active', 'employee_share', 'employer_share', 'amount',];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Salary Component" : "Add Salary Component"}</p>
        </div>
        <Checkbox
          disabled={formData?.is_default || (fromEdit ? !isActive : true)}
          checked={formData?.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
          data-testid={'hs_sts'}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          data-testid='sc_form'
        >
          <div className='w-full grid grid-rows-2 grid-cols-3 gap-5 m-1'>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Salary Component Code', "code", 'component_code', 10, true)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="salary_component_name"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Salary Component name', "salary_component_name", 'hs_name', 50, true)}
            </Form.Item>
            <Form.Item
              name="component_type"
              style={styles.formItem}
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Component Type<span className='requiredTxt'>*</span></div>
              <Select
                style={styles.textField}
                value={formData?.component_type}
                name={"component_type"}
                onChange={(value) => handleChange("component_type", value)}
                data-testid={'hs_type'}
              >
                {salaryComponentType.map((option) => (
                  <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {!fromEdit ? <Form.Item style={styles.emptyFormItem} /> : null}
            {formData?.component_type === "deduction" ?
              <Form.Item style={styles.formItem} name="employee_share">
                <Checkbox
                  checked={formData?.employee_share}
                  onChange={(e) => handleChange("employee_share", e.target.checked)}
                  data-testid={'sc_emp_share'}
                >
                  Employee Share
                </Checkbox>
              </Form.Item>: null}
            {formData?.component_type === "deduction" ?
              <Form.Item style={styles.formItem} name="employer_share">
                <Checkbox
                  checked={formData?.employer_share}
                  onChange={(e) => handleChange("employer_share", e.target.checked)}
                  data-testid={'sc_emp_share'}
                >
                Employer Share
              </Checkbox>
            </Form.Item> : null}
            {formData?.component_type === "deduction" ?
              <Form.Item style={styles.formItem} name="amount">
                <Checkbox
                  checked={formData?.amount}
                  onChange={(e) => handleChange("amount", e.target.checked)}
                  data-testid={'sc_amount'}
                >
                Amount
              </Checkbox>
            </Form.Item> : null}
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                {fromEdit ? null
                  : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_draft" />}
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="hs_save" disabled={formData?.is_default} />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID='hs' /> : null}
      {/* {isalert ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={handleSaveInactive} testID='hs' sentence="Inactivating this Salary component will impact the Salary Template and Salary Structure. Please ensure this change is intentional, as it may affect payroll calculations" okBtnLable="Update"  width={"30vw"}/> : null} */}
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default AddEditSalaryComponent;