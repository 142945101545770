import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip, Tag } from "antd";
import moment from "moment";
import PayrollFilter from 'pages/payroll/PayRollFilters';
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getPayrollPagination } from "hooks/api/payrollApi/payrollTableApi";
import { PAYROLL_RUN_GET } from "constants";
import { getEmployeeType } from "hooks/api/masterApi/employeeTypeApi";
import { toIndianCurrency } from "utils/StringUtils";
import PublishPopUp from "./runPayroll/PublishPopUp";
import { useNavigate } from "react-router-dom";
import {checkUserPermissionFunc} from "components/common/CommonFuntion";

const initialData = {
  payroll_month: "",
  employee_type: "",
  empTypeList: [],
  payroll_status: "",
}

const statusConfig = {
  "pending": { label: "Pending", bgColor: "#E28F12" },
  "yet_to_publish": { label: "Yet To Publish", bgColor: "#00A7C9" },
  "completed": { label: "Completed", bgColor: "#0E862A" },
};

const Payroll = () => {
  const query = "";
  const pageSize = 10;
  const authtoken = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const [isPublish, setIsPublish] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState("");
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";

  useEffect(() => {
    if (!authtoken) {
      window.location.href = '/';
    }
  }, [authtoken]);
  
  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&payroll_month=" + (filterDatas.payroll_month !== undefined ? filterDatas.payroll_month : "") +
    "&employee_type=" + (filterDatas.employee_type !== undefined ? filterDatas.employee_type : "") +
    "&approval_status=" + (filterDatas.payroll_status !== undefined ? filterDatas.payroll_status : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getPayrollPagination, {
    pageNo,
    pageSize,
    queryString,
    searchText,
    sorting
  });

  const getEmpTypeList = async () => {
    try {
      const apiData = await getEmployeeType(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          empTypeList: [...apiData.data]
        }))
      } else {
        setFilterDatas((prevFilterData) => ({
          ...prevFilterData,
          empTypeList: []
        }))
      }
    } catch (error) {
      setFilterDatas((prevFilterData) => ({
        ...prevFilterData,
        empTypeList: []
      }))
    }
  }

  useEffect(() => {
    getEmpTypeList();
  }, []);

  const userPermission = checkUserPermissionFunc("payroll", "Payroll", "SubMenu");

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const handlePublishPopCls = () => {
    setIsPublish(false);
    setSelectedDetails(null);
  }

  const handleNavigateTo = (status, paySlipId) => {
    if (status === 'pending') {
      navigate('/payroll/runpayroll/process', { state: { PaySlipId: paySlipId } })
    } else if (status === 'yet_to_publish') {
      setIsPublish(true);
      setSelectedDetails(paySlipId);
    }
  }

  const columns = [
    {
      title: "Payroll Month",
      dataIndex: "payroll_month",
      key: "payroll_month",
      sorter: true,
      width: "16%",
      fixed: "left",
      render: (_, record) => {
        let payrollMonth = record?.payroll_month ? moment().month(record?.payroll_month - 1).format('MMMM') : ""
        return (<p style={{ color: "black" }} >{payrollMonth ? `Payroll For ${payrollMonth}` : "-"}</p>)
      }
    },
    {
      title: "Payroll Period",
      dataIndex: "from_date",
      key: "from_date",
      width: "20%",
      render: (_, record) => {
        let payrollPeriod = (record.from_date && record.to_date) ?
          `${moment(record.from_date).format('DD MMM YYYY')} - ${moment(record.to_date).format('DD MMM YYYY')}` : "";
        return (
          <span style={{ color: "black" }}>{payrollPeriod ? payrollPeriod : "-"}</span>
        )
      },
      align: "center",
      fixed: "left",
    },
    {
      title: "Employee Type",
      dataIndex: "employee_type",
      key: "employee_type",
      width: "22%",
      fixed: "left",
      render: (_, record) => {
        let employeType = record?.employee_type?.length > 0 ? record?.employee_type?.map(type => type.employee_type_name).join(", ") : "";
        return (
          employeType?.length > 33 ?
            <Tooltip title={employeType}>{<span style={{ color: "black" }}>{employeType?.slice(0, 33) + "..."}</span>}</Tooltip>
            : <p style={{ color: "black" }}>{employeType ? employeType : "-"}</p>
        )
      },
    },
    {
      title: "No of days",
      dataIndex: "total_days",
      key: "total_days",
      width: "8%",
      align: "right",
      fixed: "left",
      render: (_, record) => {
        return (
           <p style={{ color: "black" }}>{record.total_days ? record.total_days : "-"}</p>
        )
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: "18%",
      align: 'center',
      fixed: "left",
      render: (_, record) => {
        const status = record?.status ? record?.status : '';
        const statusInfo = statusConfig[status];
        return (<Tag
          onClick={() => {userPermission?.edit && handleNavigateTo(status, record?.id)}}
          className="statusDiv"
          style={{cursor: userPermission?.edit ? "pointer" : "default" }}
          color={statusInfo.bgColor} key={record.status}>{statusInfo.label}</Tag>)
      }
    },
    {
      title: "Net Pay",
      dataIndex: "total_paid_salary",
      key: "total_paid_salary",
      sorter: true,
      align: 'right',
      width: "16%",
      fixed: "right",
      render: (_, record) => (
        <span style={{ color: "black" }}>
          {record.total_paid_salary ? toIndianCurrency(record.total_paid_salary) : "-"}
        </span>
      ),
      fixed: "left",
    },

  ];

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `payroll_month=${data.payroll_month ? data.payroll_month : ""}&employee_type=${data.employee_type ? data.employee_type : ""}&approval_status=${data.payroll_status ? data.payroll_status : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas((prevState) => ({
      ...prevState,
      payroll_month: "",
      employee_type: "",
      payroll_status: "",
    }));
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
  }
  //filter end
  const handleSearch = () => {
    setSelectedDetails(null);
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    refetch(pagination.current,
      pageSize,
      queryString,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const publishSuccess = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
  }

  const printData =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&payroll_month=" + (filterDatas.payroll_month !== undefined ? filterDatas.payroll_month : "") +
    "&employee_type=" + (filterDatas.employee_type !== undefined ? filterDatas.employee_type : "") +
    "&approval_status=" + (filterDatas.payroll_status !== undefined ? filterDatas.payroll_status : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");
  
  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"} >
        <CommonTblHeader pageName={"Payroll"}
          pageInfo={pageInfo} isAddRequired={false}
          handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          isDraftRequired={false}
          url={PAYROLL_RUN_GET} query={printData} addBtnLabel={"New Request"}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <PayrollFilter
              pageName={"Payroll"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table 
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </div>
      </div>
      {isPublish ?
        <PublishPopUp payroll_id={selectedDetails} handleCloseBack={handlePublishPopCls} handleSuccess = {publishSuccess}/>
        : null}
    </Card>
  );
}

export default Payroll;