import { ASSET_REGISTRY, ASSET_BLOCK, ASSET_CITY, ASSET_FLOOR, ASSET_ROOM } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getAssetRegistryPagination = async ({
    pageNo,
    pageSize,
    query,
    search,
    sorting,
}) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        let url = ASSET_REGISTRY;

        if (pageNo) {
            url = prefillUrl(url);
            url = `${url}page_no=${pageNo}`;
        }

        if (pageSize) {
            url = prefillUrl(url);
            url = `${url}&page_size=${pageSize}`;
        }

        if (query) {
            url = prefillUrl(url);
            url = `${url}&${query}`;
        }

        if (search) {
            url = prefillUrl(url);
            url = `${url}&search=${search}`;
        }

        if (sorting) {
            url = prefillUrl(url);
            url = `${url}&${sorting}`;
        }

        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getAssetRegistry = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        let url = ASSET_REGISTRY;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getAssetCity = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        let url = ASSET_CITY;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getAssetFloor = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        let url = ASSET_FLOOR;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getAssetBlock = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        let url = ASSET_BLOCK;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getAssetRoom = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        let url = ASSET_ROOM;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};