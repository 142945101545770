import { EMPLOYEE_PERSONAL_INFO, EMPLOYEE_DOCUMENT_INFO, EMPLOYEE_SALARY_INFO } from "constants";

// Employee personal Info put and post 
export const CreateEmployeeApi = (
  formData,
  authtoken,
  fromEdit,
  employeeId,
  onSuccess,
  onError
) => {

  const formEmpData = new FormData();
  formEmpData.append("professional_info", JSON.stringify(formData.professional_info))
  formEmpData.append("personal_info", JSON.stringify(formData.personal_info))
  formEmpData.append("bank_info", JSON.stringify(formData.bank_info))
  formEmpData.append("asset_info",formData.asset_info?.length > 0 ? JSON.stringify(formData.asset_info) : JSON.stringify([]))
  formEmpData.append("user_creation_info", JSON.stringify(formData.user_creation_info))
  formEmpData.append("professional_info__profile", formData.professional_info__profile ? formData.professional_info__profile : [])
  formEmpData.append("employee_id", employeeId)
  fetch(EMPLOYEE_PERSONAL_INFO, {
    method: fromEdit ? "PUT" : "POST",
    headers: {
      Authorization: `token ${authtoken}`,
    },
    body: formEmpData
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};


// Employee Document Info put and post 
export const CreateEmployeeDocuments = (
  formData,
  authtoken,
  fromEdit,
  employeeId,
  onSuccess,
  onError
) => {

  const formEmpData = new FormData();
  formEmpData.append("employe_document", JSON.stringify(formData.employee_document))
  formEmpData.append("educational_document", formData.educational_document?.length > 0 ? JSON.stringify(formData.educational_document) : JSON.stringify([]))
  formEmpData.append("professional_document", formData.professional_document?.length > 0 ? JSON.stringify(formData.professional_document) : JSON.stringify([]))
  formEmpData.append("employee_id", employeeId)
  for (const key in formData) {
    if(key.includes("__")){
      formEmpData.append(key, formData[key]);
    }
  }
  fetch(EMPLOYEE_DOCUMENT_INFO, {
    method: fromEdit ? "PUT" : "POST",
    headers: {
      Authorization: `token ${authtoken}`,
    },
    body: formEmpData
  })
    .then((response) => {
      if (response.status === 413) {
        onError("Request payload is too large");
      }
      return response.json()
    })
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    })
    .catch(error => {
      onError(error.message);
  });
};

// Employee Salary Info put and post 
export const CreateEmployeeSalary = (
  formData,
  authtoken,
  fromEdit,
  onSuccess,
  onError
) => {

  fetch(EMPLOYEE_SALARY_INFO, {
    method: fromEdit ? "PUT" : "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${authtoken}`
    },
    body: JSON.stringify(formData)
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};