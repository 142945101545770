import { useEffect, useState, useRef } from "react";
import { Card, Table, Tooltip, Space, } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { useNavigate } from 'react-router-dom';
import { ImagePaths } from "utils/ImagePath";
import ConfigurationFilter from "../ConfigurationFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getApprovalHierarchyPagination } from "hooks/api/configurationApi/approvalHierarchyApi";
import { APPROVAL_HIERARCHY } from "constants";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { convertToReadableFormat } from "components/common/validation";
import {checkUserPermissionFunc, deleteDraftItemsById} from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';

const initialData = {
    approval_type: "",
    approval_department: "",
    approval_is_active: "",
    departmentList: []
}

const ApprovalHierarchy = () => {
    let query = "";
    let queryRef = useRef("");
    const pageSize = 10;
    const authtoken = sessionStorage.getItem("token")

    const [editVisible, setEditVisible] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState("");
    const [dataToBeUpdated, setDataToBeUpdated] = useState({});
    const [dataUpdated, setDataUpdated] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({...initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [isDraft, setIsDraft] = useState(false);
    const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
    const tableContainerHeight = isDraft ? "77.5vh" : "72vh";

    const navigate = useNavigate();

    useEffect(() => {
        if (!authtoken) {
            window.location.href = '/';
        }
    }, [authtoken]);

    const get_employee_deparment = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    departmentList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    departmentList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                departmentList: []
            }))
        }
    }

    useEffect(() => {
        get_employee_deparment();
    }, []);

    const userPermission = checkUserPermissionFunc("configuration", "Approval Hierarchy", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getApprovalHierarchyPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message, _message.errorMsgText]);

    const addClick = () => {
        setEditVisible(false);
        setDataToBeUpdated({});
        navigate('/configuration/approvalhierarchy/add', { state: { fromEdit: false, approval_type_id: null, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
    };

    const handleEditClick = (details) => {
        setDataToBeUpdated(details);
        if (editVisible && Object.keys(dataToBeUpdated).length > 0) {
            ToastMsg("warning", "Please cancel the existing edit operation!")
        }
        else if (!editVisible) {
            setEditVisible(true);
            navigate('/configuration/approvalhierarchy/add', { state: { fromEdit: true, approval_type_id: details.approval_type_id, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
            setDataToBeUpdated(details);
        }
    };

    const handleSearch = () => {
        setEditVisible(false)
        setDataToBeUpdated({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        queryRef.current = isDraft ? `is_draft=True` : "";
        refetch(pagination.current,
            pageSize,
            queryRef.current,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `approval_type=${data.approval_type ? data.approval_type : ""}&department=${data.approval_department ? data.approval_department : ""}&is_active=${data.approval_is_active !== undefined ? data.approval_is_active : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            approval_type: "",
            approval_department: "",
            approval_is_active: "",
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const columns = [
        {
            title: "Approval Type",
            dataIndex: "approval_type_name",
            key: "approval_type_name",
            sorter: true,
            width: "27.5%",
            fixed: "left",
            render: (_, record) => (<p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record.approval_type_name ? convertToReadableFormat(record.approval_type_name) : "-"}</p>)
        },
        {
            title: "Department",
            dataIndex: "approval_department_name",
            key: "approval_department_name",
            sorter: true,
            width: "27.5%",
            fixed: "left",
            render: (_, record) => {
                let colors = record?.is_active ? "black" : "#CACACA";
                return (record.approval_department_name?.length > 20 ?
                    <Tooltip title={record.approval_department_name}>{<span style={{ color: colors }}>{record.approval_department_name?.slice(0, 20) + "..."}</span>}</Tooltip>
                    : <p style={{ color: colors }}>{record.approval_department_name ? record.approval_department_name : "-"}</p>)
            }
        },
        {
            title: "No Of Levels ",
            dataIndex: "no_of_levels",
            key: "no_of_levels",
            sorter: true,
            width: "15%",
            align: 'right',
            fixed: "right",
            render: (_, record) => (<p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record.no_of_levels? record.no_of_levels : "-"}</p>)
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "is_active",
            sorter: true,
            width: "15%",
            render: (_, record) =>
                record.is_active === true ? (
                    <span style={{ color: "black" }}>Active</span>
                ) : (
                    <span style={{ color: "#CACACA" }}>Inactive</span>
                ),
            align: 'center',
            fixed: "left",
        },
    ];

    if (userPermission?.edit) {
        columns.push({
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: "15%",
            align: 'center',
            fixed: "left",
            render: (_, record) => (
                <div className="flex justify-center items-center">
                    <Space size={"middle"}>
                        <IconButton
                            title="Edit"
                            icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                            onClick={() => (loading ? "" : handleEditClick(record))}
                            testID={'ug_btn_edit'}
                        />
                        {record.is_draft === true ?
                            <IconButton
                                title="Delete"
                                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                                onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
                                testID={'ug_btn_delete'}
                            /> : null}
                    </Space>
                </div>
            ),
        })
    }
    
    //draft delete start
    const handleDeleteClicked = (id) => {
        setDeleteClicked((prevdata) => ({
          ...prevdata,
          id: id,
          isDeleteClicked: true
        }));
      }
    
      const handleCancelBtn = () => {
        setDeleteClicked((prevdata) => ({
          ...prevdata,
          id: undefined,
          isDeleteClicked: false
        }));
      }
    
      const handleDeleteBtn = async (id) => {
        try {
          const apiData = await deleteDraftItemsById(id, APPROVAL_HIERARCHY);
          if (apiData && apiData.status === "success") {
            ToastMsg("success", apiData.message);
            handleDraft();
          } else {
            ToastMsg("error", apiData.message);
          }
        } catch (error) {
          ToastMsg("error", error.message);
        } finally {
          handleCancelBtn();
        }
      }//draft delete end

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleDraft = () => {
        setIsDraft(true);
        setFilterVisible(false);
        setSorting("");
        setPageNo(1);
        queryRef.current = `is_draft=True`
        refetch(pageNo,
            pageSize,
            queryRef.current,
            searchText,
            "");
    }

    const handleBackToHome = () => {
        setIsDraft(false);
        setSorting("");
        setPageNo(1);
        queryRef.current = "";
        refetch(pageNo,
            pageSize,
            queryRef.current,
            searchText,
            "");
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&approval_type=" + (filterDatas.approval_type ? filterDatas.approval_type : "") +
        "&department=" + (filterDatas.approval_department ? filterDatas.approval_department : "") +
        "&is_active=" + (filterDatas.approval_is_active !== undefined ? filterDatas.approval_is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Approval Hierarchy"}
                    pageInfo={pageInfo}
                    handleSearch={handleSearch} selectedDetails={dataToBeUpdated} addClick={addClick}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    handleDraft={handleDraft} isDraft={isDraft} handleBackToHome={handleBackToHome}
                    url={APPROVAL_HIERARCHY} query={queryString}
                    isAddRequired={userPermission?.add ? true : false}
                    isDraftRequired={userPermission?.draft ? true : false}
                    testID={'ah'}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center  mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <ConfigurationFilter
                            pageName={"ApprovalHierarchy"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
                />
            </div>
            </div>
            {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
        </Card>
    );
};

export default ApprovalHierarchy;
