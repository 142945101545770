import React, { useState } from 'react';
import { Modal, Checkbox } from 'antd';
import MyButton from 'components/ui/Button/MyButton';

const CopyShiftPopup = ({ onClose }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [copyshiftData, setCopyShiftData] = useState({
        first_Week: false,
        second_Week: false,
        third_Week: false,
        fourth_Week: false,
        fifth_Week: false,
    })

    const handleCancel = () => {
        setIsModalOpen(false);
        onClose();
    };

    const handleCheckboxChange = (week) => {
        setCopyShiftData((prevData) => ({
            ...prevData,
            [week]: !prevData[week]
        }));
    };

    const formatWeekLabel = (week) => {
        const weekMapping = {
            first_Week: "1st week",
            second_Week: "2nd week",
            third_Week: "3rd week",
            fourth_Week: "4th week",
            fifth_Week: "5th week"
        };
        return weekMapping[week] || week;
    };

    return (
        <Modal title=""
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={"30vw"}
            className="custom-modal"
        >
            <p className='common_add_tltTxt border-b'>Copy Shifts</p>
            <div className="my-5 w-full rostering_cpyshf_container">
                <div className="h-4/5">
                    <p className='password-tlt rostering_recurring_txt'>Shift Details</p>
                    <div className='m-2 grid grid-cols-3 gap-5'>
                        {Object.keys(copyshiftData).map((week) => (
                            <div className="checkbox-container" key={week}>
                                <Checkbox
                                    onChange={() => handleCheckboxChange(week)}
                                    checked={copyshiftData[week]}
                                >
                                    <span className='password-tlt rostering_recurring_txt'>{formatWeekLabel(week)}</span>
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flex justify-end items-end h-1/5'>
                    <MyButton htmlType="button" label={"Cancel"} onClick={handleCancel} outlined={true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
                    <MyButton htmlType="submit" label={"Copy Shift"} paddingX={"0 0.7vw"} />
                </div>
            </div>
        </Modal>
    );
}

export default CopyShiftPopup