import {
    PAYROLL_PERIOD, SUBORDINATE_SETUP, SUBORDINATE_CARD_LIST,
    CUSTOM_CONFIGURATION, SALARY_COMPONENT, SALARY_TEMPLATE
} from 'constants';
import { prefillUrl } from 'utils/StringUtils';
import { fetchReports, getMethod } from "components/common/CommonFuntion";

export const getPayrollPeriodPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token");
    let url = PAYROLL_PERIOD;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getAllPayrollPeriod = async (query) => {
    const authtoken = sessionStorage.getItem("token");
    let url = PAYROLL_PERIOD;

    if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getSubordinateSetupList = async () => {
    try {
      const authtoken = sessionStorage.getItem("token")
      const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
      };
      return await fetch(SUBORDINATE_SETUP, requestOptions)
        .then((response) => response.json())
        .then(data => data);
    } catch (error) {
      return { status: "fail", message: error.message };
    }
  }

  export const getSubordinateCardList = async (query) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };
  
    let url = SUBORDINATE_CARD_LIST;
  
    if (query) {
      url = prefillUrl(url);
      url = `${url}${query}`;
    }
  
    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  };

export const getCustomConfiguration = async () => {
    return await getMethod(CUSTOM_CONFIGURATION);
}

export const getSalaryComponentList = async (query) => {
    const url = `${SALARY_COMPONENT}?${query}`
    return await getMethod(url);
}

export const getSalaryComponentTblData = (params) => fetchReports(SALARY_COMPONENT, params);

export const getSalaryTemplateList = async (query) => {
    const url = `${SALARY_TEMPLATE}?${query}`
    return await getMethod(url);
}

export const getSalaryTemplateTblData = (params) => fetchReports(SALARY_TEMPLATE, params);