import React, { useEffect, useState } from 'react';
import { Card, Tooltip, Tabs } from "antd";
import { ImagePaths } from "utils/ImagePath";
import { useNavigate, useLocation } from "react-router-dom";
import { EmployeeDetails } from "components/common/CommonComponent";
import "./subordinate.css";
import Attendance from './Attendance';
import AttendanceSummary from './AttendanceSummary';
import SubordinateView from './SubordinateView';
import { getSubordiEmployeedetail } from "hooks/api/employeeApi/employeeSubordinateApi";

const SubordinateSubHome = () => {
    const authtoken = sessionStorage.getItem("token");
    const [activeTabKey, setActiveTabKey] = useState('1');
    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const navigate = useNavigate();
    const location = useLocation();
    const { employeeDetails } = location.state || {};
    const { employee, subordinate_id } = employeeDetails || {};
    const [empDetails, setEmpDetails] = useState({
        title: employee?.title ? employee?.title : "",
        profile: employee?.profile ? employee?.profile : "",
        employee_name: employee?.employee_name ? employee?.employee_name : "",
        designation: employee?.designation?.name ? employee?.designation?.name : "",
        employee_id: employee?.employee_id ? employee?.employee_id : "",
        department: employee?.department?.name ? employee?.department?.name : "",
        email: employeeDetails?.email_id ? employeeDetails?.email_id : "",
        phone_number: "",
        location: "",
        employee_type: "",
        shift: "",
        reporting_manager: "",
        work_location: "",
    });

    useEffect(() => {
        const getEmployeeDetails = async (id) => {
            const apiData = await getSubordiEmployeedetail(id)
            if (apiData && apiData?.status === "success") {
                let data = apiData?.data?.length > 0 ? apiData?.data[0] : {};
                setEmpDetails((prevState) => ({
                    ...prevState,
                    profile: data?.profile ? data?.profile : prevState?.profile,
                    employee_name: data?.employee_name ? data?.employee_name : prevState?.employee_name,
                    designation: data?.designation ? data?.designation : prevState?.designation,
                    employee_id: data?.employee_id ? data?.employee_id : prevState?.employee_id,
                    department: data?.department ? data?.department : prevState?.department,
                    email: data?.email ? data?.email : prevState?.email,
                    phone_number: data?.phone_number ? data?.phone_number : prevState?.phone_number,
                    location: data?.location ? data?.location : prevState?.location,
                    employee_type: data?.employee_type ? data?.employee_type : prevState?.employee_type,
                    shift: data?.shift ? data?.shift : prevState?.shift,
                    reporting_manager: data?.reporting_manager ? data?.reporting_manager : prevState?.reporting_manager,
                    work_location: data?.work_location ? data?.work_location : prevState?.work_location,
                }));
            }
        }
        if (subordinate_id) {
            getEmployeeDetails(subordinate_id)
        }
    }, []);

    const tabList = [
        { label: 'Attendance', children: <Attendance key={activeTabKey} employee_id={employee.id} />, key: '1' },
        { label: 'Attendance Summary', children: <AttendanceSummary key={activeTabKey} employee_id={employee.id} />, key: '2' },
        { label: 'Subordinates', children: <SubordinateView key={activeTabKey} employee_id={employee.id} />, key: '3' },
    ];

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    return (
        <div className="h-full overflow-hidden">
            <Card rootClassName='subEmployeeContainer'>
                <div className='w-full flex items-center border-b cursor-pointer'
                    onClick={() => { navigate('/employee/subordinates') }}>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='subEmployee_BackIcon' />
                    </Tooltip>
                    <p className='subEmployee_detail_txt ml-1'>Employee Details</p>
                </div>
                <div className='w-full grid grid-cols-12 mt-1 ml-2'>
                    <div className='col-span-3 border-r'>
                        <p className='subEmployee_details_tlt'>Employee Details</p>
                        <EmployeeDetails
                            details={{
                                title: empDetails?.title ? empDetails?.title : "",
                                first_name: empDetails?.employee_name ? empDetails?.employee_name : "",
                                designation_id: empDetails?.designation ?
                                    { id: 0, designation_name: empDetails?.designation }
                                    : { id: 0, designation_name: "" },
                                id: empDetails?.employee_id ? empDetails?.employee_id : ""
                            }} />
                        <p className='subEmployee_details_tlt'>Department</p>
                        <p className='subEmployee_details_content'>{empDetails?.department ? empDetails?.department : "-"}</p>
                    </div>
                    <div className='col-span-3 border-r ml-3'>
                        <p className='subEmployee_details_tlt'>Basic Information</p>
                        <p className='with-image subEmployee_basic_txt' data-image="email">{empDetails?.email ? empDetails?.email : "-"}</p>
                        <p className='with-image subEmployee_basic_txt' data-image="phone">{empDetails?.phone_number ? empDetails?.phone_number : "-"}</p>
                        <p className='with-image subEmployee_basic_txt' data-image="location">{empDetails?.location ? empDetails?.location : "-"}</p>
                    </div>
                    <div className='col-span-6 ml-3'>
                        <p className='subEmployee_details_tlt'>Professional Information</p>
                        <div className='grid grid-cols-3 items-center'>
                            <div >
                                <div className='subEmp_prof_subdiv'>
                                    <p className='subEmployee_details_tlt'>Employee Type</p>
                                    <p className='subEmployee_details_content'>{empDetails?.employee_type ? empDetails?.employee_type : "-"}</p>
                                </div>
                                <div className='subEmp_prof_subdiv'>
                                    <p className='subEmployee_details_tlt'>Shift</p>
                                    <p className='subEmployee_details_content'>{empDetails?.shift ? empDetails?.shift : "-"}</p>
                                </div>
                            </div>
                            <div>
                                <div className='subEmp_prof_subdiv'>
                                    <p className='subEmployee_details_tlt'>Reporting Manager</p>
                                    <p className='subEmployee_details_content'>{empDetails?.reporting_manager ? empDetails?.reporting_manager : "-"}</p>
                                </div>
                                <div className='subEmp_prof_subdiv'>
                                    <p className='subEmployee_details_tlt'>Work Location</p>
                                    <p className='subEmployee_details_content'>{empDetails?.work_location ? empDetails?.work_location : "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <div className="flex-1 overflow-hidden">
                <Tabs
                    defaultActiveKey="1"
                    onChange={handleTabChange}
                    items={tabList} />
            </div>
        </div>
    )
}

export default SubordinateSubHome;