import { EMPLOYEE_PROFILE } from "constants";
import { REPORTING_MANAGER } from "constants";
import { EMPLOYEE_DOC } from "constants";
import { EMPLOYEE_PERSONAL_INFO, EMPLOYEE_DOCUMENT_INFO, EMPLOYEE_SALARY_INFO, POSTAL_PINCODE} from "constants";
import { prefillUrl } from "utils/StringUtils";


export const getEmployee = async (query) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  let url = EMPLOYEE_PERSONAL_INFO;

  if (query) {
    url = prefillUrl(url);
    url = `${url}${query}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeePagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  const authtoken = sessionStorage.getItem("token");
  let url = EMPLOYEE_PERSONAL_INFO;

  if (pageNo) {
    url = prefillUrl(url);
    url = `${url}page_no=${pageNo}`;
  }

  if (pageSize) {
    url = prefillUrl(url);
    url = `${url}&page_size=${pageSize}`;
  }

  if (query) {
    url = prefillUrl(url);
    url = `${url}&${query}`;
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  if (sorting) {
    url = prefillUrl(url);
    url = `${url}&${sorting}`;
  }
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeeById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_PERSONAL_INFO + `?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeeDocumentById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_DOCUMENT_INFO + `?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeeSalaryById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_SALARY_INFO + `?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeeProfileById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_PROFILE + `?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getEmployeeDocById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: 'GET',
    headers: { 'Authorization': `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_DOC + `?employee_id=${id}`, requestOptions).then((response) => response.json()).then(data => data);

}

export const deleteEmployeeDocById = async (id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  return await fetch(EMPLOYEE_DOC + `?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getReportingManager = async (query) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  let url = REPORTING_MANAGER;

  if (query) {
    url = prefillUrl(url);
    url = `${url}${query}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const getPostalPincode = async (pincode) => {
  const requestOptions = {
    method: "GET",
  };
  let url = POSTAL_PINCODE;

  if (pincode) {
    url = `${url}${pincode}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};