import React, { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const EmployeeExpChart = ({ chartData }) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(`chartdivexp`);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      })
    );

    // Define data
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 4,
          fixedWidthGrid: false,
        }),
      })
    );

    const data = Object.entries(chartData).map(([experience, value]) => ({
      experience: experience.replace(" year", "").replace("s", ""),
      value,
    }));

    var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 0.1,
      minGridDistance: 10,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "experience",
        // type: "CategoryAxis",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
      })
    );

    xRenderer.labels.template.setAll({
      // rotation: -90,
      centerY: am5.p50,
      centerX: am5.p50,
      // paddingRight: 15
    });

    xRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    var yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Experience in Organization",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "experience",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}",
        }),
      })
    );

    series.columns.template.setAll({
      tooltipText: "{categoryX}:{valueY}",
      width: am5.percent(90),
      tooltipY: 0,
      strokeOpacity: 0,
    });

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
    });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);
    legend.data.push(series);
    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300",
    });
    legend.valueLabels.template.set("forceHidden", true);

    var modal = am5.Modal.new(root, {
      content: "No data",
      paddingBottom:10

    });

    if (!data || data.length === 0){
      modal.open();
    }
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root._logo?.dispose();
      root.dispose();
    };
  }, []);

  return (
    <div id={`chartdivexp`} style={{ width: "100%", height: "250px" }} />
  );
}
export default EmployeeExpChart;
