import { EMPLOYEE_GET_REIMBURSEMENTS } from "constants";
import { useEffect, useState } from "react";
import { prefillUrl, toIndianCurrency } from "utils/StringUtils";

export const getEmployeeReimbursement = async ({ pageNo, pageSize, query, search, sorting }) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  let url = EMPLOYEE_GET_REIMBURSEMENTS;

  if (pageNo) {
    url = prefillUrl(url);
    url = `${url}page_no=${pageNo}`;
  }

  if (pageSize) {
    url = prefillUrl(url);
    url = `${url}&page_size=${pageSize}`;
  }

  if (query) {
    url = prefillUrl(url);
    url = `${url}&${query}`;
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  if (sorting) {
    url = prefillUrl(url);
    url = `${url}&${sorting}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

export const useGetEmployeeReimbursement = (pageNo, pageSize, query, search, sort) => {
  const [pageInfo, setPageInfo] = useState();
  const [sorting, setSorting] = useState()
  const [data, setData] = useState(null);

  const [message, setMessage] = useState();

  function refetch(pageNo, pageSize, query, search, sort) {
    setSorting(sort)
    fetchData(pageNo, pageSize, query, search, sorting);
  }

  async function fetchData(pageNo, pageSize, query, search, sorting) {
    const apiData = await getEmployeeReimbursement(pageNo, pageSize, query, search, sorting);
    if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
      console.log(apiData.detail)
    }
    const data = [];

    if (apiData) {
      if (apiData.status === "success") {
        apiData.data?.result?.forEach((element) => {
          // Emi Duration in months
          const emi_duration =
            (element.emi_duration ?? 1) > 1
              ? `${element.emi_duration} Months`
              : "1 Month";
          data.push({
            employee_information: element.employee?.name,
            level_1_approval: element.level_1_approval?.name,
            level_2_approval: element.level_2_approval?.name,
            level_3_approval: element.level_3_approval?.name,
            ...element,
            debit_from: element.debit_from || "--",
            emi_duration,
            pending: toIndianCurrency(element.pending_amount ?? 0),
            reimbursement: element.description,
            amount_approved: element.amount_approved ? element.amount_approved : 0
          });
        });

        setData(data);
        setPageInfo({
          pageNo: apiData.data.page_no,
          pageSize: apiData.data.page_size,
          totalCount: apiData.data.total_count,
          noOfPages: apiData.data.no_of_pages,
        });
      }
    }
  }

  useEffect(() => {
    setSorting(sort ? sort : sorting)
    fetchData(pageNo, pageSize, query, search, sorting);
  }, [pageNo, pageSize, query, search, sorting]);

  return [data, pageInfo, message, refetch];
};
