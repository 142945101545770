import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Checkbox, Tooltip, Upload, Button, DatePicker, Collapse, Col } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { MYPLANS } from "constants";
import { noBlankSpacesValidator } from "components/common/validation";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/en_US';
import { handleOnKeyNumeric } from 'components/common/validation';
import { CheckOutlined, DeleteFilled, DeleteTwoTone, LockOutlined } from "@ant-design/icons";

export const UpdatePlan = () => {
  const [formData, setFormData] = useState({
    requested_date: dayjs(),
    current_plan: "",
    requested_by: "",
    comments: "",
    bill_cycle: "",
    user_count: null,
    user_cost: 0,
    active_user_count: 0,
    active_user_total: 0,
    existing_plan: "",
    total_amount: 0,
  });

  const [loading, setLoading] = useState(false);
  const prevPropsRef = useRef(); // Holds previous props for comparison
  const navigate = useNavigate();
  const location = useLocation();
  const { editDetails = {}, billCycle = 'monthly', type, current_plan, data = {}, crcycle, listindex } = location.state || {}; // Destructure with default values
  const [form] = Form.useForm(); // Form instance
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const [addUser, setAddUser] = useState(false);
  const [currentData, setCurrentData] = useState(data?.plans[listindex]);
  const { Panel } = Collapse;

  useEffect(() => {
    // Prevent unnecessary state updates
    if (prevPropsRef.current !== editDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        current_plan: current_plan,
        existing_plan: current_plan,
        active_user_count: data?.active_users ? data?.active_users : 0,
        active_user_total: billCycle === "monthly" ? data?.plans[listindex]?.price_per_month * data?.active_users : (data?.plans[listindex]?.price_per_year * ((100 - data?.plans[listindex]?.discount) / 100)).toFixed(2) * data?.active_users,
        bill_cycle: billCycle,
        requested_by: sessionStorage.getItem("employee_name"),
        requested_date: dayjs(),
      }));

      form.setFieldsValue({
        requested_date: dayjs(),
        current_plan: editDetails?.current_plan || "",
        requested_by: sessionStorage.getItem("employee_name"),
        active_user_count: data?.active_usfers ? data?.active_users : 0,
        active_user_total: billCycle === "monthly" ? data?.plans[listindex]?.price_per_month * data?.active_users : (data?.plans[listindex]?.price_per_year * ((100 - data?.plans[listindex]?.discount) / 100)).toFixed(2) * data?.active_users,
      });

      setIsChecked(billCycle !== "monthly")

      prevPropsRef.current = editDetails; // Update reference only if editDetails changed
    }
  }, [editDetails, billCycle, form]); // Add dependencies

  useEffect(() => {
    let total = !isChecked ? currentData?.price_per_month * formData?.user_count
      : (currentData?.price_per_year * ((100 - currentData?.discount) / 100)).toFixed(2) * formData?.user_count;
    let activeTotal = !isChecked ? currentData?.price_per_month * data?.active_users
      : (currentData?.price_per_year * ((100 - currentData?.discount) / 100)).toFixed(2) * data?.active_users
    let netAmt = total + activeTotal;
    setFormData((prevFormData) => ({
      ...prevFormData,
      user_cost: total,
      active_user_total: activeTotal,
      total_amount: netAmt,
    }))
  }, [isChecked, currentData, formData?.user_count])

  const enableAddUser = () => {
    setAddUser(true);
  }

  const handleDelete = () => {
    setAddUser(false);
    setFormData({
      ...formData,
      user_count: null,
      user_cost: 0,
    });
  }

  const handlePlanSelect = (index) => {
    setCurrentData(data?.plans[index]);
    setFormData({
      ...formData,
      current_plan: data?.plans[index]?.packege_name
    })
  }

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });

  };

  const handleToggleChange = (event) => {
    setIsChecked(event.target.checked); // Updates the state based on the checkbox's checked value
    setFormData({
      ...formData,
      bill_cycle: event.target.checked ? "yearly" : "monthly",
    })
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false, disabled = false) => {
    return (
      <>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <Input
          autoComplete='off'
          style={styles.textField}
          disabled={disabled}
          type={"text"}
          name={statekey}
          value={formData[statekey]}
          maxLength={maxLength}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          data-testID={testID}
        />
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/myplans');
  }, [navigate, form])

  const handleSubmit = useCallback(() => {
    let methods = (type === "upgrade") ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData.requested_date = updatedFormData.requested_date ? dayjs(updatedFormData.requested_date, "DD-MM-YYYY").format("YYYY-MM-DD") : null;

      fetch(MYPLANS, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
              requested_date: "",
              current_plan: "",
              requested_by: "",
              comments: "",
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, handleBackCancel]);

  return (
    <Card className='h-full '>
      <div><label className="common_add_tltTxt m-3">{type === "upgrade" ? "Change Plan" : "Add User"}</label></div>
      <div className="flex w-full" >
        <div style={{ width: "30%" }}>
          <Card style={{ overflowY: "scroll", height: "82dvh" }}>
            {data?.plans?.map((plan, index) => (
              <div
                key={plan?.packege_name}
                className={`my-2 shadow-md`}
              >
                <Collapse
                  accordion
                  expandIconPosition="end"
                >
                  <Panel
                    className={currentData?.packege_name === plan.packege_name ? 'selected-plan' : 'normal-plan'}
                    activeKey={index}
                    header={(
                      <div className={currentData?.packege_name === plan.packege_name ? 'selected-plan' : 'normal-plan'} >
                        <p className="header_txt">{plan.packege_name}</p>
                        <p className="sub_txt" style={{ height: "8dvh" }}>
                          {plan.packege_description}
                        </p>
                        <p className="text-sm mt-8 mb-2 flex">
                          <span className="planrs_Txt mr-2">₹{isChecked ? (plan?.price_per_month * ((100 - plan?.discount) / 100)).toFixed(2) : plan?.price_per_month} </span>
                          <label> Per Users / Monthly (Billed {isChecked ? "Yearly" : "Monthly"})</label>
                        </p>
                        <Button
                          className="plan_btn"
                          style={{ margin: "0px" }}
                          type="button"
                          onClick={() => handlePlanSelect(index)}
                        >
                          {data?.current_plan === plan.packege_name
                            ? 'Current Plan'
                            : (plan.packege_name === "Standard Package" && data?.current_plan === "Basic Package")
                              || (plan.packege_name === "Premium Package" && (data?.current_plan === "Basic Package" || data?.current_plan === "Standard Package"))
                              ? 'Upgrade Plan'
                              : 'Change Plan'}
                        </Button>
                      </div>
                    )}
                  >
                    {plan?.sub_modules?.map((module) => (
                      module.sub_module_list && Object.keys(module.sub_module_list).length > 0 ? (
                        <Collapse accordion key={module.module_name} expandIconPosition="end">
                          <Panel
                            className="submenuAcc"
                            style={{ textTransform: "capitalize" }}
                            header={
                              <div className="flex" style={{ fontWeight: "500" }}>
                                <label style={{ width: "32dvh" }}>{module.module_name.replace(/_/g, " ")}</label>
                                <label>{!module.is_subscribed && <LockOutlined />}</label>
                              </div>
                            }
                            key={module.module_name}
                          >
                            <ul>
                              {Object.entries(module.sub_module_list).map(([subModule, isSubscribed]) => (
                                <li
                                  key={subModule}
                                  className="flex"
                                  style={{
                                    justifyContent: "space-between",
                                    textTransform: "capitalize",
                                    padding: "3px 10px",
                                  }}
                                >
                                  {subModule.replace(/_/g, " ")} {!isSubscribed && <LockOutlined />}
                                </li>
                              ))}
                            </ul>
                          </Panel>
                        </Collapse>
                      ) : (
                        <div
                          className="flex"
                          key={module.module_name}
                          style={{

                            padding: "10px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          <label style={{ width: "32dvh" }}> {module.module_name.replace(/_/g, " ")} </label> {!module.is_subscribed && <LockOutlined />}
                        </div>
                      )
                    ))}
                  </Panel>
                </Collapse>
              </div>

            ))}
          </Card>
        </div>
        <div className='h-5/6 border-2' style={{ width: "70%" }}>
          <Form
            layout="inline"
            form={form}
            colon={false}
            requiredMark={false}
            className='w-full'
            name="basicform"
            initialValues={formData}
            onFinish={handleSubmit}
            onSubmit={(e) => e.preventDefault()}
            data-testid='hs_form'
          >
            <div style={{ height: "460px" }}>
              <label className="px-3 common_add_tltTxt">Order Details</label>
              <div className="grid grid-cols-2 border-b">
                <Form.Item
                  style={styles.formItem}
                  name="requested_date"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <div className='commonInputTlt'>Requested Date <span className='requiredTxt'>*</span></div>
                  <DatePicker
                    style={styles.textField}
                    name="date"
                    value={formData.requested_date ? formData.requested_date : undefined}
                    format={"DD-MM-YYYY"}
                    allowClear={true}
                    inputReadOnly={true}
                    locale={locale}
                    data-testid={'sub_from'}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  style={styles.formItem}
                  name="current_plan"
                >
                  {renderInput('Existing Plan', "existing_plan", 'existing_plan', 10, true, true)}
                </Form.Item>
              </div>
              <div className="border-b">
                <label className="px-3 common_add_tltTxt">User Details</label>
                <Form.Item
                  style={styles.formItem}
                  name="requested_by"
                >
                  {renderInput('Requested By', "requested_by", 'requested_by', 50, true, true)}
                </Form.Item>
                <Form.Item name="comments" style={styles.formItem}
                  rules={[{ required: true, message: "This field is required" },
                  { validator: noBlankSpacesValidator }
                  ]}>
                  <Input.TextArea
                    bordered={true}
                    className="textarea"
                    rows={2}
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                    maxLength={250}
                    style={{ border: "1px solid gray" }}
                    placeholder="Enter Comments"
                    name="Comments"
                    value={formData.comments ? formData.comments : ""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        comments: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex pt-3">
                <label className="px-3 py-1 pr-10 common_add_tltTxt">Payment Summary</label>
                <div className="flex mr-1 ml-10">
                  <input
                    type="checkbox"
                    id="toggle"
                    className="toggleCheckbox "
                    checked={isChecked} // This binds the checkbox state to the component
                    onChange={handleToggleChange} // Event handler for change event
                  />
                  <label htmlFor="toggle" className="toggleContainer">
                    <div>Monthly</div>
                    <div>Yearly</div>
                  </label>
                  {isChecked ? <label className="ml-2 highlight_txt">Get {currentData?.discount}% Discount on your subscription</label> : null}
                </div>
              </div>
              <div className="border-b">
                <div className="flex py-2 px-2" style={{ justifyContent: "space-between", width: "80dvh", alignItems: "center" }}>
                  <div style={{ width: "50%" }}>
                    <label className="common_employee_nameTxt">Current Users</label>
                    <p className="pla_desTxt">₹ {isChecked ? (currentData?.price_per_month * ((100 - currentData?.discount) / 100)).toFixed(2) : currentData?.price_per_month} / Users / Monthly (Billed {isChecked ? "Yearly" : "Monthly"}) </p>
                  </div>
                  <label style={{ width: "20%" }}>{formData?.active_user_count}</label>
                  <label style={{ width: "30%" }}>₹ {formData?.active_user_total}</label>
                </div>
                {addUser ?
                  <div className="flex" ><div className="flex py-2 px-2" style={{ width: "80dvh", alignItems: "center" }}>
                    <div style={{ width: "50%" }}>
                      <label className="common_employee_nameTxt">No Of Users</label>
                      <p className="pla_desTxt">₹ {isChecked ? (currentData?.price_per_month * ((100 - currentData?.discount) / 100)).toFixed(2) : currentData?.price_per_month} / Users / Monthly (Billed {isChecked ? "Yearly" : "Monthly"}) </p>
                    </div>
                    <div style={{ width: "20%" }}>
                      <Input
                        autoComplete='off'
                        type="text"
                        maxLength={4}
                        onKeyDown={handleOnKeyNumeric}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        value={formData?.user_count}
                        name="user_count"
                        style={{ width: "8dvh", border: "1px solid grey", }}
                        required>
                      </Input>
                    </div>
                    <label style={{ width: "12%" }}>₹ {formData?.user_cost}</label>
                    <DeleteTwoTone className="py-3 pl-2 cursor-pointer" twoToneColor="red" onClick={handleDelete} />
                  </div>
                  </div>
                  : null}
                {!addUser ?
                  <div className="flex gap-2 pl-2 w-fit cursor-pointer" onClick={enableAddUser} >
                    <img src={ImagePaths.addicon.default} className="" alt={"add icon"} />
                    <label className="addText cursor-pointer">Add Users</label>
                  </div> : null}
              </div>
              <div className="py-2 px-2 flex" style={{ width: "70dvh" }}>
                <label className="common_add_tltTxt" style={{ width: "80%" }}>Total Amount </label>
                <label>₹ {formData?.total_amount}</label>
              </div>
            </div>
            <div className='w-full relative inset-y-10'>
              <Form.Item>
                <div className='flex justify-end items-end mr-2'>
                  <MyButton htmlType="button" label="Back" onClick={handleBackCancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="sub_cancel" />
                  <MyButton htmlType="submit" label={"Send Mail"} loading={loading} paddingX={"0 1.2vw"} testID="sub_save" />
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default UpdatePlan;
