import { Card, DatePicker, Select, Spin, Typography, message } from "antd";
import MyHeader from "components/layout/MyHeader";
import React, { useEffect, useState } from "react";
import useGetEmployerDashboard from "./apihook/useGetEmployerDashboard";
import EmployeeTypeChart from "./apihook/employeeTypeChart";
import EmployeeAgeChart from "./apihook/employeeAgeChart";
import EmployeeExpChart from "./apihook/employeeExperienceChart";
import EmployeesChart from "./apihook/employeesChart";
import DashboardHoliday from "./apihook/dashboardHoliday";
import CommonHeaderWithLayout from "components/layout/CommonHeader";
import DashboardnoticeBoard from "./apihook/dashboardnoticeBoard";
import { dashboardMonthEnum } from "components/common/enum";
import GetETypeApi from "./apihook/GetETypeApi";
import DashboardLeave from "./apihook/dashboardLeave";
import dayjs from "dayjs";
import DashboardBirthAnni from "./apihook/dashboardBirthAnni";
import { Audio, ThreeDots } from "react-loader-spinner";
const { Text } = Typography;

function Dashboard() {
  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);
  const [eType, setEType] = useState({});
  const dateFormat = "YYYY-MM-DD";
  const [data, _message, refetch] = useGetEmployerDashboard();

  const monthOptions = dashboardMonthEnum.map((option) => (
    <Select.Option key={option.value} value={option.value}>
      {option.text}
    </Select.Option>
  ));

  const today = new Date()
    .toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const [leaveMonth, setLeaveMonth] = useState(dayjs(today, dateFormat));
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [holidayMonth, setHolidayMonth] = useState(new Date().getMonth() + 1);
  const [birthdayMonth, setBirthdayMonth] = useState(new Date().getMonth() + 1);

  function getActionView() {
    window.scrollTo(0, 0);
    return (
      <>
        <div className="h-10 grid grid-cols-1">
          <div className="">
            <MyHeader
              className=" font-semibold text-2xl  "
              title={"Good Morning " + data.employer_name + "!"}
            />
            <p style={{ fontSize: "12px", padding: 0, lineHeight: "25px" }}>
              Keep your face always toward the sunshine, and shadows will fall
              behind you !
            </p>
          </div>
          {/* <div className="">
          <MyButton onClick={() => addClick()} label="Add"></MyButton>
        </div> */}
        </div>
      </>
    );
  }

  const handleEType = (month) => {
    setCurrentMonth(month);
    const formData = month;
    GetETypeApi(
      formData,
      authtoken,
      (data) => {
        //onSuccess
        // message.success(data);
        setEType(data.data);
      },
      (error) => {
        //onError
        message.error(error);
      }
    );
  };

  const handleHoliday = (month) => {
    setHolidayMonth(month);
  };

  const handleLeave = (date, dateString) => {
    setLeaveMonth(date);
  };

  const handleBirthday = (month) => {
    setBirthdayMonth(month);
  };

  return (
    <div>
      {!data && (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#04B7B1"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            justifySelf: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.8,
            width: "100%",
            height: "100%",
          }}
          wrapperClassName=""
          visible={true}
        />
      )}
      {data && (
        <div className="h-full">
          <div>
            <CommonHeaderWithLayout action={getActionView()} />
          </div>
          <Card style ={{height: "83.5vh"}} className="overflow-x-hidden overflow-y-auto">
            <div className="grid grid-cols-12">
              <div className="col-span-5">
                <Card
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "5px" }}
                  className="h-72 mt-2 mr-2"
                >
                  <div>
                    <Text strong>Employees</Text>
                  </div>
                  <EmployeesChart
                    chartData={
                      data && data.employee.length > 0 ? data.employee : []
                    }
                  ></EmployeesChart>
                </Card>
              </div>
              <div className="col-span-4">
                <Card
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "5px" }}
                  className="h-72 mt-2"
                >
                  <div className="grid grid-cols-2">
                    <div>
                      <Text strong>Employee Type</Text>
                    </div>
                    <div>
                      <Select
                        // suffixIcon={<CaretDownOutlined />}
                        value={currentMonth}
                        style={{
                          width: "60px",
                          border: 1,
                          float: "right",
                          fontFamily: "Urbanist",
                        }}
                        onChange={(value) => handleEType(value)}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                  </div>
                  <EmployeeTypeChart
                    chartData={
                      data && Object.entries(eType).length > 0
                        ? eType
                        : data.employee_type
                        ? data.employee_type
                        : []
                    }
                  ></EmployeeTypeChart>
                </Card>
              </div>
              <div className="col-span-3">
                <Card
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "5px" }}
                  className="h-72 mt-2 ml-2"
                >
                  <div className="grid grid-cols-2">
                    <div>
                      <Text strong>Holidays</Text>
                    </div>
                    <div>
                      <Select
                        // suffixIcon={<CaretDownOutlined />}
                        value={holidayMonth}
                        style={{
                          width: "60px",
                          border: 1,
                          float: "right",
                          fontFamily: "Urbanist",
                        }}
                        onChange={(value) => handleHoliday(value)}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                  </div>
                  <DashboardHoliday month={holidayMonth}></DashboardHoliday>
                </Card>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-4 pt-2 ">
                <DashboardnoticeBoard data={data}></DashboardnoticeBoard>
              </div>

              <div className="col-span-4 pt-2 ">
                <Card
                  // title="Leave Approval"
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "0px" }}
                  className="mr-2 ml-0 mb-2"
                >
                  <div className="grid grid-cols-2">
                    <div style={{ width: "250px" }} className="p-2">
                      <Text strong>Leave Approval</Text>
                    </div>
                    <div className="pr-2">
                      <DatePicker
                        style={{ width: "100px", float: "right" }}
                        value={leaveMonth}
                        onChange={(date, dateString) => handleLeave(date)}
                        allowClear={false}
                        inputReadOnly={true}
                        format={"DD/MM/YYYY"}
                      ></DatePicker>
                    </div>
                  </div>
                  <div className="h-80 overflow-auto">
                    <Card
                      style={{ border: 0, borderRadius: "0px" }}
                      bodyStyle={{ padding: "0px" }}
                      className="ml-2 mr-0 h-full"
                    >
                      <DashboardLeave date={leaveMonth}></DashboardLeave>
                    </Card>
                  </div>
                </Card>
              </div>
              <div className="col-span-4 pt-2 pb-2">
                <Card
                  // title="Notice Board"
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "0px" }}
                  className="mr-0 mb-2"
                >
                  <div className="grid grid-cols-2">
                    <div style={{ width: "250px" }} className="p-2">
                      <Text strong>Birthday And Anniversary</Text>
                    </div>
                    <div>
                      <Select
                        value={birthdayMonth}
                        style={{
                          width: "60px",
                          border: 1,
                          float: "right",
                          fontFamily: "Urbanist",
                        }}
                        onChange={(value) => handleBirthday(value)}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                  </div>
                  <div className="h-80 overflow-auto">
                    <Card
                      // title="Birthday And Anniversary"
                      style={{ border: 0, borderRadius: "0px" }}
                      bodyStyle={{ padding: "0px" }}
                      className="ml-2 mr-0 h-full"
                    >
                      <DashboardBirthAnni
                        month={birthdayMonth}
                      ></DashboardBirthAnni>
                    </Card>
                  </div>
                </Card>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-6">
                <Card
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "10px" }}
                  className="h-72 m-0 ml-0"
                >
                  <div>
                    <Text strong>Experience In organization </Text>
                  </div>
                  <EmployeeExpChart
                    chartData={
                      data && data.experience_in_organization
                        ? data.experience_in_organization
                        : []
                    }
                  ></EmployeeExpChart>
                </Card>
              </div>
              <div className="col-span-6">
                <Card
                  style={{ borderRadius: "0px" }}
                  bodyStyle={{ padding: "10px" }}
                  className="h-72 ml-2"
                >
                  <div>
                    <Text strong>Age of Employees (In Years)</Text>
                  </div>
                  <EmployeeAgeChart
                    chartData={
                      data && data.employee_age.length > 0
                        ? data.employee_age
                        : []
                    }
                  ></EmployeeAgeChart>
                </Card>
              </div>
            </div>
          </Card>
        </ div>
      )}
    </div>
  );
}

export default Dashboard;
