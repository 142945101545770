import React, { useEffect } from "react";
import { useState } from "react";
import { Avatar, Card, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import nodata from "../../../assets/nodata.png";
import getBirthAnniAPI from "./getBirthAnniAPI";
import { getMonthName } from "components/common/validation";

function DashboardBirthAnni({ month }) {
  const [badata, setBAData] = useState(null);
  const [shouldRefetch, setShouldFetch] = useState(1);
  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  function refetch() {
    setShouldFetch(shouldRefetch + 1);
  }

  const getBAAPI = async (month) => {
    getBirthAnniAPI(
      month,
      authtoken,
      (data) => {
        //onSuccess
        // message.success(data);
        setBAData(data.data)
      },
      (error) => {
        //onError
        message.error(error);
      }
    );
  };

  useEffect(() => {
    getBAAPI(month)
  },[month, shouldRefetch])

  return (
    <div>
      <div
        style={{ borderRadius: "0px" }}
        className="ml-0 grid grid-rows-2 p-2"
      >
        {badata &&
          badata.anniversary.length > 0 &&
          badata.anniversary.map((item) => (
            <div className="pb-2">
              <Card  className=""
              style={{ borderRadius: "0px", paddingTop:"2px", paddingLeft:"4px", boxShadow: "1px 1px 6px 1px rgba(208, 216, 243, 0.6)" }}
                bodyStyle={{ padding: "4px" }}>
              <div className="grid grid-cols-5 gap-2 pr-2">
                <div className="col-span-1">
                  <Avatar
                    shape="circle"
                    size={40}
                    style={{
                      backgroundColor: "#E4F2F0",
                      color: "#04B7B1",
                    }}
                    icon={<UserOutlined />}
                  />
                </div>
                <div className="col-span-3">
                  <div className="col-span-1">
                    <p>{item.employee_name}</p>
                    <p style={{ fontSize: 10 }}>
                      {item.designation.designation_name} | {item.employee_id}
                    </p>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="col-span-1">
                    <p>{item.month_of_joining}</p>
                    <p>{item.day_of_joining}</p>
                    <p>{item.experience}</p>
                  </div>
                </div>
              </div>
              </Card>
            </div>
          ))}
        {badata &&
          badata.birthday.length > 0 &&
          badata.birthday.map((item) => (
            <div className="pb-2">
              <Card  className=""
              style={{ borderRadius: "0px", paddingTop:"2px", paddingLeft:"4px", boxShadow: "1px 1px 6px 1px rgba(208, 216, 243, 0.6)" }}
                bodyStyle={{ padding: "4px" }}>
              <div className="grid grid-cols-5 gap-2 pr-2">
                <div className="col-span-1">
                  <Avatar
                    shape="circle"
                    size={40}
                    style={{
                      backgroundColor: "#E4F2F0",
                      color: "#04B7B1",
                    }}
                    icon={<UserOutlined />}
                  />
                </div>
                <div className="col-span-3">
                  <div className="col-span-1">
                    <p>{item.employee_name}</p>
                    <p style={{ fontSize: 10 }}>
                      {item.designation.designation_name} | {item.employee_id}
                    </p>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="col-span-1">
                    <p className="grid place-content-center">
                      {getMonthName(item.birth_month)}
                    </p>
                    <p className=" grid place-content-center">
                      {item.birth_date}
                    </p>
                  </div>
                </div>
              </div>
              </Card>
            </div>
          ))}
        {badata &&
          badata.anniversary.length === 0 &&
          badata.birthday.length === 0 && (
            <div className="grid grid-cols-1 place-content-center row-span-2">
              <div className="grid place-content-center">
                <img src={nodata}></img>
              </div>
              <div className="grid place-content-center">
                Looks like no data is here
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default DashboardBirthAnni;
