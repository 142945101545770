import { EMPLOYEE_DEPARTMENT } from "constants";

const CreateEmployeeDepartment = (formData, authtoken, onSuccess, onError, method) => {
  try {
    fetch(EMPLOYEE_DEPARTMENT, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          onSuccess(data.message);
        } else if (data.status === "fail") {
          onError(data.message);
        }
      });
  } catch (error) {
    onError(error.message);
  }
};

export default CreateEmployeeDepartment;
