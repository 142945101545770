import { RESIGNATION_DETAILS } from "constants";
import {
  EMPLOYEE_CANDIDATE_INFO,
  EMPLOYEE_CANDIDATE_DOCUMENT_INFO,
  EMPLOYEE_CANDIDATE_SALARY_INFO,
  EMPLOYEE_CANDIDATE_FINAL_INFO
} from "constants";

// Employee personal Info put and post 
export const CreateEmployeeApi = (
  formData,
  authtoken,
  fromEdit,
  employeeId,
  onSuccess,
  onError
) => {

  const formEmpData = new FormData();
  formEmpData.append("professional_info", JSON.stringify(formData.professional_info))
  formEmpData.append("personal_info", JSON.stringify(formData.personal_info))
  formEmpData.append("professional_info__profile", formData.professional_info__profile ? formData.professional_info__profile : [])
  formEmpData.append("employee_id", employeeId)
  fetch(EMPLOYEE_CANDIDATE_INFO, {
    method: fromEdit ? "PUT" : "POST",
    headers: {
      Authorization: `token ${authtoken}`,
    },
    body: formEmpData
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};


// Employee Document Info put and post 
export const CreateEmployeeDocuments = (
  formData,
  authtoken,
  fromEdit,
  employeeId,
  onSuccess,
  onError
) => {

  const formEmpData = new FormData();
  formEmpData.append("employe_document", JSON.stringify(formData.employee_document))
  formEmpData.append("educational_document", formData.educational_document?.length > 0 ? JSON.stringify(formData.educational_document) : JSON.stringify([]))
  formEmpData.append("professional_document", formData.professional_document?.length > 0 ? JSON.stringify(formData.professional_document) : JSON.stringify([]))
  formEmpData.append("employee_id", employeeId)
  for (const key in formData) {
    if (key.includes("__")) {
      formEmpData.append(key, formData[key]);
    }
  }
  fetch(EMPLOYEE_CANDIDATE_DOCUMENT_INFO, {
    method: fromEdit ? "PUT" : "POST",
    headers: {
      Authorization: `token ${authtoken}`,
    },
    body: formEmpData
  })
    .then((response) => {
      if (response.status === 413) {
        onError("Request payload is too large");
      }
      return response.json()
    })
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    })
    .catch(error => {
      onError(error.message);
    });
};

// Employee Salary Info put and post 
export const CreateEmployeeSalary = (
  formData,
  authtoken,
  fromEdit,
  onSuccess,
  onError
) => {

  fetch(EMPLOYEE_CANDIDATE_SALARY_INFO, {
    method: fromEdit ? "PUT" : "POST",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `token ${authtoken}`
    },
    body: JSON.stringify(formData)
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};


// Employee Document Info put and post 
export const CreateEmployeeOnboarding = (
  formData,
  authtoken,
  fromEdit,
  employeeId,
  onSuccess,
  onError
) => {

  const formEmpData = new FormData();
  formEmpData.append("onboarding_process_details", JSON.stringify(formData.onboarding_process_details));
  formEmpData.append("onboarding_document_details", formData.onboarding_document_details?.length > 0 ? JSON.stringify(formData.onboarding_document_details) : JSON.stringify([]));
  formEmpData.append("employee_id", employeeId);
  formEmpData.append("move_to_employee", formData.move_to_employee);
  formEmpData.append("send_mail", formData.send_mail);

  for (const key in formData) {
    if (key.includes("__")) {
      formEmpData.append(key, formData[key]);
    }
  }
  fetch(EMPLOYEE_CANDIDATE_FINAL_INFO, {
    method: "PUT",
    headers: {
      Authorization: `token ${authtoken}`,
    },
    body: formEmpData
  })
    .then((response) => {
      if (response.status === 413) {
        onError("Request payload is too large");
      }
      return response.json()
    })
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    })
    .catch(error => {
      onError(error.message);
    });
};

export const EmployeeResignation = (
  formData,
  authtoken,
  fromEdit,
  employeeId,
  onSuccess,
  onError
) => {

  const formEmpData = new FormData();
  formEmpData.append("stage", "3");
  if (formData.resignationData?.interview_id) {
    formEmpData.append("interview_id", (formData.resignationData?.interview_id));
  }
  formEmpData.append("resignation_id", formData.resignationData?.id);
  formEmpData.append("interview_by", formData.InterviewedBy);
  formEmpData.append("interview_date", "2024-08-29");
  formEmpData.append("interview_status", "approved");
  formEmpData.append("interview_comments", formData?.Exit_interview_comments);
  formEmpData.append("verified_by", formData?.Exit_verified_by);
  formEmpData.append("verified_date", "2024-08-27");
  formEmpData.append("verified_status", "approved");
  formEmpData.append("verified_comments", formData?.verified_comments);
  formEmpData.append("final_settlement_date", "2024-08-27");
  formEmpData.append("final_settlement_status", formData?.final_settlement_status);
  formEmpData.append("final_settlement_comments", formData?.final_settlement_comments);
  formEmpData.append("resignation_documents", formData.onboarding_document_details?.length > 0 ? JSON.stringify(formData.onboarding_document_details) : JSON.stringify([]));
  formEmpData.append("employee_id", employeeId);
  formEmpData.append("is_process_finished", formData.is_finish_process);
  formEmpData.append("send_mail", formData.send_mail);

  for (const key in formData) {
    if (key.includes("__")) {
      formEmpData.append(key, formData[key]);
    }
  }
  fetch(RESIGNATION_DETAILS, {
    method: "PUT",
    headers: {
      Authorization: `token ${authtoken}`,
    },
    body: formEmpData
  })
    .then((response) => {
      if (response.status === 413) {
        onError("Request payload is too large");
      }
      return response.json()
    })
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    })
    .catch(error => {
      onError(error.message);
    });
};