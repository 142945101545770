import { APPROVAL_HIERARCHY } from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const getApprovalHierarchyPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token");
    let url = APPROVAL_HIERARCHY;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getAllApprovalHierarchy = async (query) => {
    const authtoken = sessionStorage.getItem("token");
    let url = APPROVAL_HIERARCHY;

    if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}