import { useEffect, useState, useContext } from "react";
import { PAYROLL_APPROVAL_INBOX } from "constants";
import { prefillUrl } from "utils/StringUtils";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";

export const getApprovalInboxPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = PAYROLL_APPROVAL_INBOX;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const useGetApprovalInbox = (pageNo, pageSize, query, search, sort) => {
  const [data, setData] = useState(null);
  const [cardData, setCardData] = useState({});
  const [pageInfo, setPageInfo] = useState();
  const [message, setMessage] = useState({
    isErrorMsg: false,
    errorMsgText: ""
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logoutauth } = useContext(AuthContext);

  async function fetchData(pageNo, pageSize, query, search, sorting) {
    setMessage({
      isErrorMsg: false,
      errorMsgText: ""
    });
    setLoading(true);
    try {
      const apiData = await getApprovalInboxPagination({pageNo, pageSize, query, search, sorting});
      if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
        setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
        navigate("/");
        logoutauth();
      }
      if (apiData) {
        if (apiData.status === "fail") {
          setMessage({ isErrorMsg: true, errorMsgText: apiData.message });
        } else if (apiData.data) {
          setData(apiData.data.result);

          setPageInfo({
            pageNo: apiData.data.page_no,
            pageSize: apiData.data.page_size,
            totalCount: apiData.data.total_count,
            noOfPages: apiData.data.no_of_pages,
          });

          setCardData({
            total_leave_approvals: apiData.data.total_leave_approvals,
            total_reimbursements: apiData.data.total_reimbursements,
            total_advance_salary: apiData.data.total_advance_salary,
            total_resignations: apiData.data.total_resignations,
          })
        }
      }
    } catch (error) {
      setMessage({ isErrorMsg: true, errorMsgText: "Error fetching data" });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(pageNo, pageSize, query, search, sort);
  }, [pageNo, pageSize, query, search, sort]);

  function refetch(pageNo, pageSize, query, search, sortings) {
    fetchData(pageNo, pageSize, query, search, sortings);
  }

  return [data, cardData, pageInfo, loading, message, refetch];
};