import { REPORTING_MANAGER } from 'constants';
import { EMPLOYER, EMPLOYER_DASHBOARD, ADMIN_PASSWORD_POLICY } from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const getEmployer = async () => {
    try {
        const authtoken = sessionStorage.getItem("token")
        let url = EMPLOYER;
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },
        };
        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}

export const getEmployerDashboardAPI = async () => {
    const authtoken = sessionStorage.getItem("token")

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYER_DASHBOARD, requestOptions).then((response) => response.json()).then(data => data);
}

export const getReportingManagerAPI = async (query) => {
    const authtoken = sessionStorage.getItem("token")

    let url = REPORTING_MANAGER;

    if (query) {
        url = prefillUrl(url);
        url = `${url}drop_down=${query}`;
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getReportingManagerByDesignationAPI = async (query, designation) => {
    const authtoken = sessionStorage.getItem("token")

    let url = REPORTING_MANAGER;

    if (query) {
        url = prefillUrl(url);
        url = `${url}drop_down=${query}`;
    }

    if (designation) {
        url = prefillUrl(url);
        url = `${url}&designation=${designation}`;
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getPasswordPolicy = async () => {
    try {
        const authtoken = sessionStorage.getItem("token")
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },
        };
        return await fetch(ADMIN_PASSWORD_POLICY, requestOptions)
            .then((response) => response.json())
            .then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}