import { EMPLOYEE_GET_ADVANCE_SALARY } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getEmployeeAdvanceSalary = async ({ pageNo, pageSize, query, search, sorting }) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  let url = EMPLOYEE_GET_ADVANCE_SALARY;

  if (pageNo) {
    url = prefillUrl(url);
    url = `${url}page_no=${pageNo}`;
  }

  if (pageSize) {
    url = prefillUrl(url);
    url = `${url}&page_size=${pageSize}`;
  }

  if (query) {
    url = prefillUrl(url);
    url = `${url}&${query}`;
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  if (sorting) {
    url = prefillUrl(url);
    url = `${url}&${sorting}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};
