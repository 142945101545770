import React, { useState, useRef } from 'react';
import { Select, DatePicker, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from 'components/ui/Button/MyButton';
import { approvalStatusEnum, statusEnum } from 'components/common/enum';
import { disabledFutureDate } from "components/common/validation";

const EmployeeFilter = ({ pageName, filterDatas, closeFilter, filteredData, clearData }) => {
  const [filterData, setFilterData] = useState({
    leave_type: filterDatas.leave_type,
    from_date: filterDatas.from_date,
    to_date: filterDatas.to_date,
    leave_approval_status: filterDatas.leave_approval_status,
    leaveTypeList: filterDatas.leaveTypeList,
    reimbursement_type: filterDatas.reimbursement_type,
    reimbursement_approval_status: filterDatas.reimbursement_approval_status,
    reimbursementTypeList: filterDatas.reimbursementTypeList,
    designation_name: filterDatas.designation_name,
    department_name: filterDatas.department_name,
    reporting_manager: filterDatas.reporting_manager,
    emplyee_group: filterDatas.emplyee_group,
    is_active: filterDatas.is_active,
    designationList: filterDatas.designationList,
    departmentlist: filterDatas.departmentlist,
    reportingManagerList: filterDatas.reportingManagerList,
    employeeGroupList: filterDatas.employeeGroupList,
    advance_salary_date: filterDatas.advance_salary_date,
    approval_status: filterDatas.approval_status,
    reimbursement_date: filterDatas.reimbursement_date,
    emp_sub_id: filterDatas.emp_sub_id,
    emp_sub_designation: filterDatas.emp_sub_designation,
    emp_sub_department: filterDatas.emp_sub_department,
    // emp_sub_reporting_mngr: filterDatas.emp_sub_reporting_mngr,
    emp_sub_group: filterDatas.emp_sub_group,
    emp_sub_is_active: filterDatas.emp_sub_is_active,
    emp_sub_designList: filterDatas.emp_sub_designList,
    emp_sub_dptlist: filterDatas.emp_sub_dptlist,
    emp_sub_repMgrList: filterDatas.emp_sub_repMgrList,
    emp_sub_GroupList: filterDatas.emp_sub_GroupList
  });
  const hideCursorRef = useRef({});

  const handleChange = (name, value) => {
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleClear = () => {
    setFilterData((prevState) => ({
      ...prevState,
      leave_type: "",
      from_date: "",
      to_date: "",
      leave_approval_status: "",
      reimbursement_type: "",
      reimbursement_approval_status: "",
      designation_name: "",
      department_name: "",
      reporting_manager: "",
      emplyee_group: "",
      is_active: "",
      advance_salary_date: "",
      approval_status: "",
      reimbursement_date: "",
      emp_sub_id: "",
      emp_sub_designation: "",
      emp_sub_department: "",
      // emp_sub_reporting_mngr: "",
      emp_sub_group: "",
      emp_sub_is_active: "",
    }))
    clearData(filterData);
  }

  const renderSelect = (label, statekey, list = approvalStatusEnum, showKey = "text", valueKey = "value") => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }
    return (
      <div className="fltTextFieldDiv">
        <div className='commonInputTlt'>{label}</div>
      <Select
        showSearch
        className="fltTextField"
        value={filterData[statekey] ? filterData[statekey] : undefined}
        name={statekey}
        onChange={(value) => handleChange(statekey, value)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        ref={hideCursorRef.current[statekey]}
        onSelect={() => {
          hideCursorRef.current[statekey].current.blur();
        }}
      >
        {list?.length > 0 && list?.map((option) => (
          ((valueKey === "id" && option.is_active === true) || valueKey !== "id") ?
          <Select.Option key={option[valueKey]} value={option[valueKey]}>
             {statekey === "reporting_manager" ? option[showKey] + (option['employee_id'] ? " - " + option['employee_id']: "") : option[showKey]}
          </Select.Option> : null
        ))}
      </Select>
      </div>
    )
  }

  const renderDatePicker = (label, statekey) => {
    return (
      <div className="fltTextFieldDiv">
      <div className='commonInputTlt'>{label}</div>
      <DatePicker
        className="fltTextField"
        disabledDate={disabledFutureDate}
        name={statekey}
        value={filterData[statekey] ? filterData[statekey] : ""}
        onChange={(date) => handleChange(statekey, date)}
        allowClear={true}
        inputReadOnly={true}
        format={'DD-MM-YYYY'}
        placeholder = ""
      />
      </div>
    )
  }

  return (
    <div className='h-full w-full'>
      <div className="flex justify-between items-center filterTltDiv">
        <p className="common_addTltTxt">{"Filter"}</p>
        <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter} /></Tooltip>
      </div>
      <div className="flex flex-col items-center fltContentDiv">
        {pageName === "LeaveRequest" ?
          <>
            {renderSelect("Leave Type", "leave_type", filterData.leaveTypeList, "leave_name", "id")}
            {renderDatePicker("From Date", "from_date")}
            {renderDatePicker("To Date", "to_date")}
            {renderSelect("Status", "leave_approval_status")}
          </>
          : pageName === "ReimbursementRequest" ?
            <>
              {renderDatePicker("Select Date", "reimbursement_date")}
              {renderSelect("Reimbursement Type", "reimbursement_type", filterData.reimbursementTypeList, "reimbursement_type_name", "id")}
              {renderSelect("Status", "reimbursement_approval_status")}
            </>
            : pageName === "Employees" ?
              <>
                {renderSelect("Select Designation", "designation_name", filterData.designationList, "designation_name", "id")}
                {renderSelect("Select Department", "department_name", filterData.departmentlist, "department_name", "id")}
                {renderSelect("Select Reporting Manager", "reporting_manager", filterData.reportingManagerList, "first_name", "id")}
                {renderSelect("Select Employee Group", "emplyee_group", filterData.employeeGroupList, "employee_group_name", "id")}
                {renderSelect("Status", "is_active", statusEnum)}
              </>
              : pageName === "AdvanceSalaryRequest" ?
                <>
                  {renderDatePicker("Select Date", "advance_salary_date")}
                  {renderSelect("Status", "approval_status")}
                </>
                : pageName === "Subordinates" ?
                  <>
                    {renderSelect("Select Employee Name", "emp_sub_id", filterData.emp_sub_repMgrList, "first_name", "id")}
                    {renderSelect("Select Designation", "emp_sub_designation", filterData.emp_sub_designList, "designation_name", "id")}
                    {renderSelect("Select Department", "emp_sub_department", filterData.emp_sub_dptlist, "department_name", "id")}
                    {/* {renderSelect("Select Reporting Manager", "emp_sub_reporting_mngr", filterData.emp_sub_repMgrList, "first_name", "id")} */}
                    {renderSelect("Select Employee Group", "emp_sub_group", filterData.emp_sub_GroupList, "employee_group_name", "id")}
                    {renderSelect("Status", "emp_sub_is_active", statusEnum)}
                  </> :
                  null}
      </div>
      <div className='flex justify-center items-end'>
        <MyButton label="Clear" onClick={handleClear} outlined = {true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
        <MyButton label={"Apply"} onClick={() => filteredData(filterData)} paddingX={"0 0.5vw"} />
      </div>
    </div>
  )
}

export default EmployeeFilter;

