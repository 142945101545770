import React, { useEffect, useState } from "react";

const CustomOtpInput = ({ numInputs = 4, onChange }) => {
    const [otp, setOtp] = useState(Array(numInputs).fill(""));

    useEffect(() => {
        // Automatically focus on the first input when the component mounts
        document.getElementById(`otp-input-0`).focus();
      }, []);

    const handleChange = (element, index) => {
        const value = element.value;
        if (value.length === numInputs) {
            // Handle pasting a full OTP at once
            const newOtp = value.split("").slice(0, numInputs);
            setOtp(newOtp);
            onChange(newOtp.join(""));
        } else if (/^\d$/.test(value) || value === "") {
            // Handle single character input
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            onChange(newOtp.join(""));

            // Move to the next input if a number is entered
            if (value && index < numInputs - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text");
        if (/^\d+$/.test(pasteData) && pasteData.length === numInputs) {
            const newOtp = pasteData.split("").slice(0, numInputs);
            setOtp(newOtp);
            onChange(newOtp.join(""));

            // Move focus to the last input
            document.getElementById(`otp-input-${numInputs - 1}`).focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    return (
        <div
            style={{ display: "flex", justifyContent: "center", gap: "8px" }}
            onPaste={handlePaste}
        >
            {otp.map((_, index) => (
                <input
                    key={index}
                    id={`otp-input-${index}`}
                    className=" border-b-2 border-gray-300 outline-none"
                    type="text"
                    maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{ width: "40px", textAlign: "center", fontSize: "16px" }}
                />
            ))}
        </div>
    );
};

export default CustomOtpInput;
