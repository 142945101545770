import React, { useEffect } from "react";
import { Card, Spin } from "antd";
import ToastMsg from "components/common/ToastMsg";
import useGetData from "components/common/useGetData";
import { getSubordinateViewList } from "hooks/api/employeeApi/employeeSubordinateApi";
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
import { EmployeeDetails } from "components/common/CommonComponent";
import { ensureHash } from "components/common/CommonFuntion";
import { ImagePaths } from "utils/ImagePath";

const StyledNode = styled.div`
display: inline-block;
border: 1px solid #D6D6D6;
border-radius: 2px;
width: 13dvw;
height: fit-content;
background-color: #EFEFEF;
white-space: nowrap;
`;

const StyledNodeWoData = styled.div`
display: inline-flex;
justify-content: center;
align-items: center;
border: 1px solid #D6D6D6;
border-radius: 2px;
width: 13dvw;
height: 5dvw;
background-color: transparent;
white-space: nowrap;
`;

function SubordinateView({ employee_id }) {
  const authtoken = sessionStorage.getItem("token");
  const tableContainerHeight = "58dvh";

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [data, _message, refetch, loading] = useGetData(getSubordinateViewList, { id: employee_id });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const styles = {
    fltContainer: {
      width: "100%",
      height: tableContainerHeight,
      overflow: "auto",
    },
  }

  const hasActiveDescendants = (node) => {
    if (!node?.subordinates || node?.subordinates.length === 0) {
      return false;
    }
    return node?.subordinates?.some(sub => sub?.is_active || hasActiveDescendants(sub));
  };

  const renderTreeNodes = (nodes) => {
    return nodes?.map((node) => {
      const hasActiveSubordinates = node?.subordinates && node?.subordinates?.some(sub => sub?.is_active || hasActiveDescendants(sub));
      const shouldRenderNode = node?.is_active || hasActiveSubordinates;

      // If the node shouldn't be rendered, return null
      if (!shouldRenderNode) {
        return null;
      }
      const label = node?.is_active ?
        <StyledNode>
          <div className="subordinate_cardTxt"
            style={{
              backgroundColor: node?.card?.colour ? ensureHash(node?.card?.colour) : "transparent",
              display: node?.card?.colour ? "flex" : "none"
            }}>
            {node?.card?.title ? node?.card?.title : ""}
            {node?.is_manager ?
              <img src={ImagePaths.teamLead.default} alt="teamlead" className='commonTblBackIcon' style={{ marginLeft: "10px" }} />
              : null}
          </div>
          <div className='flex justify-between items-center p-1'>
            <EmployeeDetails details={{
              ...node?.employee,
              "title": node?.employee?.title ? node?.employee?.title : "",
              "profile": node?.employee?.profile ? node?.employee?.profile : "",
              "designation_id": node?.employee.designation ?
                { id: node?.employee.designation.id, designation_name: node?.employee.designation.name }
                : { id: 0, designation_name: "" },
              "first_name": node?.employee.employee_name ? node?.employee.employee_name : "",
              "id": node?.employee.employee_id ? node?.employee.employee_id : ""
            }}/>
          </div>
        </StyledNode>
        : (!node?.is_active && node.subordinates && node.subordinates.length > 0) ?
          <StyledNodeWoData>
            <img src={ImagePaths.addOff.default} alt="addIcon" className='subordinate_wo_add_icon' />
            <p className="subEmployee_details_content">Add Employee</p>
          </StyledNodeWoData> : null
      return label ? (
        <TreeNode key={node.id} label={label}>
          {node.subordinates && node.subordinates.length > 0 ? renderTreeNodes(node.subordinates) : null}
        </TreeNode>
      ) : null
    }).filter(Boolean);
  };

  return (
    <Card style={styles.fltContainer}>
      {loading && (
        <div className="loaderOverlay">
          <Spin />
        </div>
      )}
      {!Array.isArray(data) && !loading ?
      <div className = "w-full h-full overflow-auto my-3">
        <Tree
          lineWidth={'1px'}
          lineColor={'#D6D6D6'}
          lineBorderRadius={'3px'}
          label={data?.is_active ?
            <StyledNode>
              <div className="subordinate_cardTxt"
                style={{ backgroundColor: data?.card?.colour ? ensureHash(data?.card?.colour) : "transparent",
                  display: data?.card?.colour ? "flex" : "none"
                 }}>
                {data?.card?.title ? data?.card?.title : ""}
                {data?.is_manager ?
                <img src={ImagePaths.teamLead.default} alt="teamlead" className='commonTblBackIcon' style={{ marginLeft: "10px" }} />
                : null}
              </div>
              <div className='flex justify-between items-center p-1'>
                <EmployeeDetails details={{
                  ...data?.employee,
                  "title": data?.employee?.title ? data?.employee?.title : "",
                  "profile": data?.employee?.profile ? data?.employee?.profile : "",
                  "designation_id": data?.employee.designation ?
                    { id: data?.employee.designation.id, designation_name: data?.employee.designation.name }
                    : { id: 0, designation_name: "" },
                  "first_name": data?.employee.employee_name ? data?.employee.employee_name : "",
                  "id": data?.employee.employee_id ? data?.employee.employee_id : ""
                }}/>
              </div>
            </StyledNode>
            : <StyledNodeWoData>
              <img src={ImagePaths.addOff.default} alt="addIcon" className='subordinate_wo_add_icon' />
              <p className="subEmployee_details_content">Add Employee</p>
            </StyledNodeWoData>}
        >
          {data?.subordinates?.length > 0 ? renderTreeNodes(data?.subordinates) : null}
        </Tree>
        </div>
        : <div style={styles.fltContainer} className="flex justify-center items-center subEmployee_detail_txt">No Data Found</div>}
    </Card>
  );
}

export default SubordinateView;