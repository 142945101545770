import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getSubordinateMonthlyAttendanceList } from "hooks/api/employeeApi/employeeSubordinateApi";

function AttendanceSummary({employee_id}) {
  const authtoken = sessionStorage.getItem("token");
  let query = `employee_id=${employee_id}`;
  const pageSize = 10;
  const searchText = "";
  const pageNo = 1;

  const [dataUpdated, setDataUpdated] = useState(false);
  const [sorting, setSorting] = useState("");
  const tableContainerHeight = "58dvh";

    useEffect(() => {
      if (!authtoken) {
        window.location.href = "/";
      }
    }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getSubordinateMonthlyAttendanceList, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery);
  }

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
        (<p>{(record?.year && record?.year !== "NaT") ? record?.year : "-"}</p>),
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
        (<p>{(record?.month) ? record?.month : "-"}</p>),
    },
    {
      title: "Working Days",
      dataIndex: "working_days",
      key: "working_days",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
        (<p>{(record?.working_days) ? record?.working_days : "-"}</p>),
    },
    {
      title: "Present",
      key: "present",
      dataIndex: "present",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
        (<p>{(record?.present) ? record?.present : "-"}</p>),
      align: 'center',
    },
    {
        title: "Absent",
        key: "absent",
        dataIndex: "absent",
        sorter: true,
        width: "12.5%",
        render: (_, record) =>
          (<p>{(record?.absent) ? record?.absent : "-"}</p>),
        align: 'center',
      },
  ];

  const styles = {
    fltContainer: {
      height: tableContainerHeight,
    },
  }

  return (
        <Card style={styles.fltContainer}>
        <Table 
          columns={columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 150px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </Card>
  );
}

export default AttendanceSummary;
