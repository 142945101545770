import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Select, TimePicker, Input, DatePicker } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { EMPLOYEE_ATTENDANCE_DAILY } from "constants";
import dayjs from "dayjs";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import { EmployeeDetails } from "components/common/CommonComponent";
import { attendanceStatus } from 'components/common/enum';
import locale from 'antd/es/date-picker/locale/en_US';
import { formatTimeSpan } from 'components/common/validation';

function EditDayWiseAttendance({ fromEdit, editDetails, onHide, onSave }) {
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const prevPropsRef = useRef();

  const [formData, setFormData] = useState({
    id: null,
    date: null,
    employeeDetails: {},
    shift: "",
    department: "",
    location: "",
    status: "",
    check_in: null,
    check_out: null,
    over_time: null,
    break_hrs: null,
    working_hrs: null,
    description: "",
    company_id: companyid,
  });
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isEditClked, setIsEditClked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    statusError: "",
    checkInError: "",
    checkOutError: ""
  });
  const hideCursorRef = useRef(null);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      let { employee_details } = editDetails;
      let empDetails = {
        title: employee_details.title ? employee_details.title : "",
        first_name: employee_details.employee_name ? employee_details.employee_name : "",
        last_name: "",
        designation_id: {
          id: employee_details?.designation?.id ? employee_details?.designation?.id : "",
          designation_name: employee_details?.designation?.name ? employee_details?.designation?.name : ""
        },
        id: employee_details.employee_id,
        profile: employee_details.profile,
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails?.attendance_id ? editDetails?.attendance_id : null,
        date: editDetails?.date ? dayjs(editDetails?.date).format('DD/MM/YYYY') : null,
        employeeDetails: { ...empDetails },
        shift: editDetails?.shift ? editDetails?.shift : "",
        department: employee_details?.department?.name ? employee_details?.department?.name : "",
        location: editDetails?.location ? editDetails?.location : "",
        status: editDetails?.status ? editDetails?.status : "",
        check_in: (editDetails?.check_in && editDetails.check_in !== "NaT") ? editDetails.check_in : null,
        check_out: (editDetails?.check_out && editDetails.check_out !== "NaT") ? editDetails.check_out : null,
        over_time: (editDetails?.overtime && editDetails.overtime !== "NaT") ? formatTimeSpan(editDetails.overtime) : null,
        break_hrs: (editDetails?.break_hrs && editDetails.break_hrs !== "NaT") ? dayjs(editDetails?.break_hrs, 'H:mm:ss').format('HH:mm:ss') : null,
        working_hrs: (editDetails?.total_working_hours && editDetails.total_working_hours !== "NaT") ? formatTimeSpan(editDetails.overtime) : null,
        description: editDetails?.description ? editDetails?.description : "",
        shift_working_hours: (editDetails?.shift_working_hours && editDetails.shift_working_hours !== "NaT") ? dayjs(editDetails.shift_working_hours, 'H:mm:ss').format('HH:mm:ss') : null,
      }));
      prevPropsRef.current = editDetails;
    }
  }, [editDetails])

  const calculateTotalWorkingHrs = () => {
    if(formData.check_in && formData.check_out){
    let totalSeconds = 0;

    // Convert check-in and check-out times to moments
     let checkInTime = dayjs(formData.check_in, 'YYYY-MM-DDTHH:mm:ss');
     let checkOutTime = dayjs(formData.check_out, 'YYYY-MM-DDTHH:mm:ss');
  
    if (checkOutTime.isBefore(checkInTime)) {
      checkOutTime = checkOutTime.add(1, 'day');
    }

    // Calculate duration in seconds between check-in and check-out
    const durationSeconds = checkOutTime.diff(checkInTime, 'seconds');

    // Convert break hours and overtime to seconds
    const breakSeconds = formData.break_hrs ? parseTimeToSeconds(formData.break_hrs) : 0;
    // const overtimeSeconds = formData.over_time ? parseTimeToSeconds(formData.over_time) : 0;

    // Deduct break seconds from the total duration
    const netDurationSeconds = durationSeconds - breakSeconds;

    if (netDurationSeconds < 0) {
      // Handle negative net duration
      return;
    }

   // Calculate shift working hours in seconds
   const shiftWorkingHoursSeconds = formData.shift_working_hours ? parseTimeToSeconds(formData.shift_working_hours) : 0;

   // Calculate overtime in seconds
   const overtimeSeconds = netDurationSeconds - shiftWorkingHoursSeconds;

   // Ensure overtime is not negative
   const calculatedOvertimeSeconds = Math.max(overtimeSeconds, 0);

   // Add overtime seconds to the net duration
   totalSeconds = netDurationSeconds ;

   // Calculate total hours, minutes, and remaining seconds
   const formattedTotalWorkingHrs = formatSecondsToTime(totalSeconds);
   const formattedOvertime = formatSecondsToTime(calculatedOvertimeSeconds);

    setFormData((prevState) => ({
      ...prevState,
      working_hrs: formattedTotalWorkingHrs,
      over_time: formattedOvertime
    }));
  } else{
    setFormData((prevState) => ({
      ...prevState,
      working_hrs: "00:00:00",
      over_time: "00:00:00"
    }));
  }} 

  const parseTimeToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString ? timeString?.split(':').map(Number) : [0, 0, 0];
    return (hours * 3600) + (minutes * 60) + seconds;
  }

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  }

  const formatSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };
  
  useEffect(() => {
    calculateTotalWorkingHrs();
  }, [formData.check_in, formData.check_out, formData.break_hrs, formData.over_time])

  const handleCancel = () => {
    setIsModalOpen(false);
    onHide();
    setFormData({
      id: null,
      date: null,
      employeeDetails: {},
      shift: "",
      department: "",
      location: "",
      status: "",
      check_in: null,
      check_out: null,
      over_time: null,
      break_hrs: null,
      working_hrs: null,
      description: "",
      company_id: companyid,
    });
  };

  const handleEdit = () => {
    setIsEditClked((prevState) => (!prevState))
  }

  const handleSubmit = useCallback(() => {
    let hasErrors = false;
    let newErrors = {};
    if (!formData.status) {
      newErrors.statusError = "This field is required.";
      hasErrors = true;
    }

    if (formData.status === "present" || formData.status === "on-duty") {
      if (!formData.check_in) {
        newErrors.checkInError = "This field is required.";
        hasErrors = true;
      }
      if (!formData.check_out) {
        newErrors.checkOutError = "This field is required.";
        hasErrors = true;
      }
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      ...newErrors
    }));

    if (hasErrors) {
      return;
    }

    let methods = "PUT";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = {
        id: formData?.id ? formData?.id : null,
        status: formData?.status ? formData?.status : "",
        // check_in: formData.check_in ? dayjs(formData.check_in, 'h:mm A').format('YYYY-MM-DD HH:mm:ss') : null,
        // check_out: formData.check_out ? dayjs(formData.check_out, 'h:mm A').format('YYYY-MM-DD HH:mm:ss') : null,
        check_in: (formData?.check_in && formData?.date)
          ? dayjs(formData.check_in, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
          : null,
        check_out: (formData?.check_out && formData?.date)
          ? dayjs(formData.check_out, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
          : null,
        working_hrs: formData.working_hrs ? formData.working_hrs : null,
        over_time: formData.over_time ? formData.over_time : "00:00:00",
        break_hrs: formData.break_hrs ? dayjs(formData.break_hrs, 'h:mm:ss').format('HH:mm:ss') : "00:00:00",
        description: formData?.description ? formData?.description : "",
      };

      fetch(EMPLOYEE_ATTENDANCE_DAILY, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            onSave();
            handleCancel();
            setLoading(false);
            setFormData({
              id: null,
              date: null,
              employeeDetails: {},
              shift: "",
              department: "",
              location: "",
              status: "",
              check_in: null,
              check_out: null,
              over_time: null,
              break_hrs: null,
              working_hrs: null,
              description: "",
              company_id: companyid,
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, fromEdit, companyid, handleCancel, onSave]);

  const handleTimeChange = (value, timeString, name) => {
    const updatedValue = name === 'break_hrs' ? timeString : value?.format('YYYY-MM-DDTHH:mm:ss');

    setFormData((prev) => ({
      ...prev,
      [name]: updatedValue,
      ...(name === 'check_in' && !value ? { check_out: null } : {}),
    }));
    
  if (formData.status === "present" || formData.status === "on-duty") {
    if (name === "check_in" && timeString) {
      setErrors(prevErrors => ({
        ...prevErrors,
        checkInError: "",
      }));
    }
    if (name === "check_out" && timeString) {
      setErrors(prevErrors => ({
        ...prevErrors,
        checkOutError: "",
      }));
    }
  }
  }

  const getErrorKey = (stateKey) => {
    return {
      check_in: 'checkInError',
      check_out: 'checkOutError'
    }[stateKey] || '';
  }

  const renderTimePicker = (label, statekey, required) => {
    const errorKey = getErrorKey(statekey);
    return (
      <div>
      <TimePicker
        size="large"
        name={statekey}
        value={formData[statekey] ? dayjs(formData[statekey],"HH:mm:ss"): null}
        onChange={(time, timeString) => handleTimeChange(time, timeString, statekey)}
        format={"HH:mm:ss"}
        style={{ width: "100%" }}
        allowClear={true}
        inputReadOnly={true}
        locale={locale}
        placeholder = ""
      />
      {required && errors[errorKey] ? <p className="ant-form-item-explain requiredTxt">{errors[errorKey]}</p> : null}
      </div>
    )
  }


  const disabledDateFrom = (restrictionDate) => (current) => {
    if (restrictionDate) {
      const startDate = dayjs(restrictionDate);
      return current && current < startDate.startOf('second');
    }
    return current && current > dayjs().endOf('second');
  };

  const renderDatePicker = (label, statekey, required) => {
    const errorKey = getErrorKey(statekey);
    return (
      <div>
      <DatePicker
        size="large"
        name={statekey}
        value={formData[statekey] ? dayjs(formData[statekey]) : null}
        onChange={(time, timeString) => handleTimeChange(time, timeString, statekey)}
        format={"DD-MM-YYYY h:mm A"}
        style={{ width: "100%" }}
        allowClear={true}
        inputReadOnly={true}
        locale={locale}
        placeholder = ""
        showTime
        disabled={statekey === "check_out" && !formData.check_in? true : false}
        disabledDate={statekey === "check_out"? disabledDateFrom(formData.check_in) : null }
      />
      {required && errors[errorKey] ? <p className="ant-form-item-explain requiredTxt">{errors[errorKey]}</p> : null}
      </div>
    )
  }

  const renderInput = (statekey) => {
    return (
      <Input
        autoComplete='off'
        type={"text"}
        name={statekey}
        value={formData[statekey]}
        disabled={true}
        style={{ width: "100%" }}
      />
    )
  }

  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  let isCheckInReq = (formData.status === "present" || formData.status === "on-duty");

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"70dvw"}
      className="custom-modal"
    >
      <div div className="w-full">
        <div className="flex justify-between items-center border-b pb-2.5">
          <p className="common_DayWiseTxt">{`Day-Wise Attendance (${formData?.date})`}</p>
          <IconButton className="mr-20" title="Edit" icon={<img src={ImagePaths.modalEdit.default} alt="Edit" />} onClick={handleEdit} />
        </div>
        <div className="p-3">
          <div className="grid grid-cols-6 gap-3 border-b p-3 mb-2">
            <div className="col-span-2" >
              <p className="common_dayWiseTltTxt pb-2">Employee Information</p>
              <EmployeeDetails details={{...formData.employeeDetails}}/>
            </div>
            <div>
              <p className="common_dayWiseTltTxt pb-2">Shift</p>
              <p className="common_DayWiseSubTxt">{formData.shift ? formData.shift : ""}</p>
            </div>
            <div>
              <p className="common_dayWiseTltTxt pb-2">Department</p>
              <p className="common_DayWiseSubTxt">{formData.department ? formData.department : ""}</p>
            </div>
            <div>
              <p className="common_dayWiseTltTxt pb-2">Location</p>
              <p className="common_DayWiseSubTxt">{formData.location ? formData.location : "-"}</p>
            </div>
            <div>
              <p className="common_dayWiseTltTxt pb-2">Status <span className='requiredTxt'>*</span></p>
              <Select
                showSearch
                size="small"
                className={errors.statusError ? "ant-select-status-error" : ""}
                style={{ width: "100%" }}
                value={formData.status ? formData.status : ""}
                name={"status"}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    status: value,
                  });
                  if(value){
                    setErrors((prevState) => ({
                      ...prevState,
                      statusError: ""
                    }));
                  }
                }}
                filterOption={filterOption}
                ref={hideCursorRef}
                onSelect={() => {
                  hideCursorRef.current.blur();
                }} 
              >
                {attendanceStatus.map((option) => (
                  <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                ))}
              </Select>
              {errors.statusError && <p className="ant-form-item-explain requiredTxt">{errors.statusError}</p>}
            </div>
          </div>
          <p className="common_dayWiseTltTxt p-2">Time Tracker</p>
          {
            isEditClked ?
              <div className="w-full grid grid-cols-7 gap-3 p-3">
                <div className="col-span-2">
                  <p className="common_dayWiseTltTxt pb-2">
                  Check-In Date & Time { isCheckInReq ? <span className='requiredTxt'>*</span> : null}
                  </p>
                  {renderDatePicker("Check-In", "check_in", isCheckInReq)}
                </div>
                <div className="col-span-2">
                  <p className="common_dayWiseTltTxt pb-2">
                  Check-out Date & Time {isCheckInReq ? <span className='requiredTxt'>*</span> : null}
                  </p>
                  {renderDatePicker("Check-out", "check_out", isCheckInReq)}
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">Overtime</p>
                  {renderInput('over_time')}
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">Break Hrs</p>
                  {renderTimePicker("Break Hours", "break_hrs")}
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">Total Working hrs</p>
                  {renderInput('working_hrs')}
                </div>
              </div>
              :
              <div className="grid grid-cols-5 gap-3 p-3">
                <div>
                  <p className="common_dayWiseTltTxt pb-2">
                  Check-In Date & Time {isCheckInReq ? <span className='requiredTxt'>*</span> : null}
                  </p>
                  <p className={`common_DayWiseSubTxt ${errors.checkInError ? "requiredTxt" : ""}`}>{formData.check_in ? dayjs(formData.check_in).format('DD-MM-YYYY h:mm A') : "00:00"}</p>
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">
                  Check-out Date & Time {isCheckInReq ? <span className='requiredTxt'>*</span> : null}
                  </p>
                  <p className={`common_DayWiseSubTxt ${errors.checkOutError ? "requiredTxt" : ""}`}>{formData.check_out ? dayjs(formData.check_out).format('DD-MM-YYYY h:mm A') : "00:00"}</p>
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">Overtime</p>
                  <p className="common_DayWiseSubTxt">{formData.over_time ? formData.over_time : "00:00:00"}</p>
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">Break Hrs</p>
                  <p className="common_DayWiseSubTxt">{formData.break_hrs ? formData.break_hrs : "00:00:00"}</p>
                </div>
                <div>
                  <p className="common_dayWiseTltTxt pb-2">Total Working hrs</p>
                  <p className="common_DayWiseSubTxt">{formData.working_hrs ? formData.working_hrs : "00:00:00"}</p>
                </div>
              </div>
          }
          <div className="p-3">
            <p id="common_salary_Txt" className="pb-2">Description</p>
            <Input.TextArea
              bordered={true}
              className="textarea"
              rows={4}
              autoSize={{
                minRows: 4,
                maxRows: 5,
              }}
              maxLength = {150}
              style={{ border: "1px solid gray" }}
              placeholder="Enter Reason"
              name="description"
              value = {formData.description ? formData.description : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  description: e.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className='flex justify-end items-end'>
          <MyButton htmlType="button" label="Cancel" onClick={handleCancel} outlined = {true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="submit" label={"Update"} onClick={handleSubmit} loading={loading} paddingX={"0 1.2vw"} />
        </div>
      </div>
    </Modal>
  );
}
export default EditDayWiseAttendance;

