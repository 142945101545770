import { EMPLOYEE_SHIFT } from 'constants';
import { prefillUrl } from 'utils/StringUtils';


export const getEmployeeShift = async (query) => {
    const authtoken = sessionStorage.getItem("token")

    let url = EMPLOYEE_SHIFT;

    if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getEmployeeShiftPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = EMPLOYEE_SHIFT;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getEmployeeShiftById = async (id) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },

    };

    return await fetch(EMPLOYEE_SHIFT + `?id=${id}`, requestOptions).then((response) => response.json()).then(data => data);
}

export const updateEmployeeDesignation = async (input) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'PUT',
        headers: { 'Authorization': `token ${authtoken}` },
        body: JSON.stringify(input)
    };

    return await fetch(EMPLOYEE_SHIFT, requestOptions).then((response) => response.json()).then(data => data);
}