import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row, Space, message, InputNumber } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Pattern from "components/ui/Images/Pattern";
import VerifyOTPApi from "./apihook/VerifyOTPApi";
import CheckUserApi from "./apihook/CheckUserApi";
import MySecondaryLargeButton from "components/ui/Button/MySecondaryLargeButton";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { ClockCircleOutlined } from "@ant-design/icons";
import CustomOtpInput from "components/common/CustomeOTPInput";

const OtpVerification = () => {
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [timerRunning, setTimerRunning] = useState(false);
  const { state } = useLocation();
  const authtoken = sessionStorage.getItem("token");
  const id = state ? parseInt(state.id) : 0;
  const email = state ? state.email : "";
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState("");

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  useEffect(() => {
    let timerInterval;
    const startTimer = () => {
      setSeconds(60);
      setTimerRunning(true);
      timerInterval = setInterval(() => {
        setSeconds(prevSeconds => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(timerInterval);
            setTimerRunning(false);
            return 0;
          }
        });
      }, 1000);
    };
    startTimer();
    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  const onFinish = () => {
    let otp_value = otp
    setLoadings(true);
    VerifyOTPApi(
      otp_value,
      authtoken,
      id,
      email,
      (data) => {
        //onSuccess
        setLoadings(false);
        ToastMsg("success", data.message);
        navigate("/resetpassword", { state: { id: id, user_name: data.user_name, email: email } });
      },
      (error) => {
        //onError
        setLoadings(false);
        ToastMsg("error", error);
      }
    );
  }

  const handleBack = () => {
    navigate("/forgotpassword", { state: { id: id, email: email } });
  }

  const handleOTPClick = (otp_value) => {
    if (otp_value === 1) {
      setLoadings(true);
      CheckUserApi(
        { email: email },
        authtoken,
        (data) => {
          //onSuccess
          setLoadings(false);
          ToastMsg("success", data.message);
        },
        (error) => {
          //onError
          setLoadings(false);
          ToastMsg("error", error);
        }
      );
    }
  }

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Pattern className="h-screen w-full pattern-text"></Pattern>
        </Col>
        <Col flex={4}>
          <div
            className="flex flex-col"
            style={{ padding: "30px", height: "100vh" }}
          >
            <img src={ImagePaths.loginLogo.default} className="login_Logo" alt={"App Logo"} />
            <div className="flex items-center justify-center">
              <Card
                className=" drop-shadow-lg items-center justify-center px-5 mt-10"
                bordered={false}
                style={{ width: 400 }}
              >
                <div className="grid grid-cols-9">
                  <div className="col-span-9">
                    <div className="pb-2 flex items-center">
                      <img src={ImagePaths.backArrow.default} alt="Back" className="forgot_BackIcon" onClick={() => handleBack()} />
                      <p className="login_tlt">Verify Your OTP</p>
                    </div>
                    <p className="userPermission-checkbox-subTlt text-center"> Confirmation code has been sent to your </p>
                    <p className="userPermission-checkbox-subTlt text-center">{email ? email : "Registered Mail ID"}</p>
                  </div>
                </div>
                <div
                  className="mt-5 flex justify-evenly items-center"
                >
                  <CustomOtpInput numInputs={4} onChange={handleOtpChange} />
                </div>
                <p className="mt-2 ml-5 userPermission-checkbox-subTlt">Please enter the coded for verification</p>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="items-center justify-center my-2">
                    <MySecondaryLargeButton
                      type="primary"
                      label={loadings ? "VERIFY OTP..." : "VERIFY OTP"}
                      loading={loadings}
                      className="mt-2 items-center"
                      disabled={!otp.length === 4}
                      onClick={onFinish}
                      htmlType="submit"
                    />
                  </div>
                </Space>

                <div
                  className={`text-center my-5 userPermission-checkbox-subTlt ${timerRunning ? "disabled opacity-50" : ""}`}
                  onClick={() => (timerRunning ? null : handleOTPClick(1))}
                  style={{ cursor: timerRunning ? "not-allowed" : "pointer" }}
                >
                  Haven’t Received Code ? Send again
                </div>
                <p className="userPermission-checkbox-subTlt text-center">
                  <ClockCircleOutlined className="mr-2" /> {`00 : ${seconds}`}
                </p>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
const styles = {
  inputBox: {
    width: "3vw",
    border: "1px solid #E9E9E9",
    backgroundColor: "#F5F8F7",
  },
}
export default OtpVerification;
