import MyButton from "components/ui/Button/MyButton";
import React, { useEffect, useState, useRef, useCallback } from "react";
import CreateEmployeeDepartmentApi from "./apihook/CreateEmployeeDepartmentApi";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from "components/common/validation";
import { Form, Input, Checkbox } from 'antd';
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";

const authtoken = sessionStorage.getItem("token");
const companyid = sessionStorage.getItem("userId");
const initialState = {
  code: "",
  department_name: "",
  notes: "",
  is_active: true,
  company_id: companyid,
}

const AddEditEmployeeDepartment = ({
  employeeDepartmentData,
  fromEdit,
  onCancel,
  onUpdated,
  isActive
}) => {
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    if (employeeDepartmentData !== null &&
      Object.keys(employeeDepartmentData).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(employeeDepartmentData) &&
      JSON.stringify(formData) !== JSON.stringify(employeeDepartmentData)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: employeeDepartmentData?.id,
        code: employeeDepartmentData?.code,
        department_name: employeeDepartmentData?.department_name,
        notes: employeeDepartmentData?.notes ? employeeDepartmentData?.notes : "",
        is_active: employeeDepartmentData?.is_active,
        company_id: employeeDepartmentData?.company_id || companyid,
      }));
      form.setFieldsValue({
        code: employeeDepartmentData?.code,
        department_name: employeeDepartmentData?.department_name,
        notes: employeeDepartmentData?.notes ? employeeDepartmentData?.notes : "",
        is_active: employeeDepartmentData?.is_active,
      });
      prevPropsRef.current = employeeDepartmentData;
    }
  }, [employeeDepartmentData, formData, form])

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const handleCancel = () => {
    form.resetFields();
    setFormData(initialState)
    onCancel();
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      [name]: value,
    });
  };

  const renderInput = (statekey, maxLength = 150) => {
    return (
      <Input
        autoComplete='off'
        style={{ width: "15vw" }}
        disabled = {statekey === "code"}
        type="text"
        name={statekey}
        value={formData[statekey]}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
      />
    )
  }

  const handleSubmit = (isDraftClked) => {
    setLoading(true);
    let updatedFormData = { ...formData };
    if (isDraftClked) {
      updatedFormData = { ...updatedFormData, is_draft: true };
    } else {
      updatedFormData = { ...updatedFormData, is_draft: false };
    }
    CreateEmployeeDepartmentApi(
      updatedFormData,
      authtoken,
      (data) => {
        //onSuccess
        ToastMsg("success", data);
        onUpdated && onUpdated();
        handleCancelBtn();
        setLoading(false);
        handleCancel();
      },
      (error) => {
        //onError
        ToastMsg("error", error);
        setLoading(false);
        setIsCancelClk(false);
      },
      fromEdit ? "PUT" : "POST"
    );
  }

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    handleCancel();
  }

  const keysToExclude = ['is_active', 'company_id'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <div className="w-full" style={{ marginBottom: '0.4vw' }}>
      <p className='common_add_tltTxt'>{fromEdit ? "Edit Department" : "Add Department"}</p>
      <Form
        layout="inline"
        form={form}
        colon={false}
        requiredMark={false}
        style={{ width: "100%" }}
        name="basicform"
        onFinish={handleSave}
        onSubmit={(e) => e.preventDefault()}
        initialValues={formData}
      >
        <div className='w-full flex justify-between align-center'>
          {fromEdit ? <Form.Item
            name="code"
              rules={[{ required: true, message: "This field is required" },
            { validator: noBlankSpacesValidator }
            ]}
          >
            <div className='commonInputTlt'>Department Code <span className='requiredTxt'>*</span></div>
            {renderInput("code", 10)}
          </Form.Item> : null}
          <Form.Item
            name="department_name"
              rules={[{ required: true, message: "This field is required" },
            { validator: noBlankSpacesValidator }
            ]}
          >
            <div className='commonInputTlt'>Department Name <span className='requiredTxt'>*</span></div>
            {renderInput("department_name", 50)}
          </Form.Item>
          <Form.Item name="notes">
          <div className='commonInputTlt'>Notes</div>
            {renderInput("notes", 150)}
          </Form.Item>
          <Form.Item name="is_active">
            <Checkbox
              disabled = {fromEdit ? !isActive : true }
              checked={formData.is_active}
              onChange={(e) => handleChange("is_active", e.target.checked)}
            > Active
            </Checkbox>
          </Form.Item>
          <Form.Item >
            <div className='flex justify-center items-end'>
              <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
              <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : onCancel} outlined = {true} paddingX={"0 0.7vw"} />
            </div>
          </Form.Item>
        </div>
      </Form>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} /> : null}
    </div>
  );
};

export default AddEditEmployeeDepartment