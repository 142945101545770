import {
    DAY_WISE_ATTENDANCE_REPORTS,
    EMPLOYEE_ESI_REPORTS,
    EMPLOYEE_PF_REPORTS,
    PAYROLL_DETAIL_PERIOD_REPORTS,
    SALARY_PROCESS_BANK_REPORTS,
    EARNING_DEDUCTION_REPORTS,
    MONTH_WISE_ATTENDANCE_REPORTS,
    SALARY_SUMMARY_REPORTS,
    MUSTER_ROLL_REPORTS,
    SUBORDINATES_REPORTS,
    ATTENDANCE_CORRECTION_REPORTS,
    ROSTERING_REPORT,
    LEAVE_PERMISSION_REPORT,
} from "constants";
import { prefillUrl } from "utils/StringUtils";

const fetchReports = async (url, { pageNo, pageSize, query, search, sorting }) => {
    try {
        const authtoken = sessionStorage.getItem("token");

        if (pageNo) {
            url = prefillUrl(url);
            url = `${url}page_no=${pageNo}`;
        }

        if (pageSize) {
            url = prefillUrl(url);
            url = `${url}&page_size=${pageSize}`;
        }

        if (query) {
            url = prefillUrl(url);
            url = `${url}&${query}`;
        }

        if (search) {
            url = prefillUrl(url);
            url = `${url}&search=${search}`;
        }

        if (sorting) {
            url = prefillUrl(url);
            url = `${url}&${sorting}`;
        }

        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getDayWiseAttendanceReportsPagination = (params) => fetchReports(DAY_WISE_ATTENDANCE_REPORTS, params);
export const getEmpEsiReportsPagination = (params) => fetchReports(EMPLOYEE_ESI_REPORTS, params);
export const getEmpPfReportsPagination = (params) => fetchReports(EMPLOYEE_PF_REPORTS, params);
export const getPayrollDetailPeriodReportsPagination = (params) => fetchReports(PAYROLL_DETAIL_PERIOD_REPORTS, params);
export const getSalaryBankReportsPagination = (params) => fetchReports(SALARY_PROCESS_BANK_REPORTS, params);
export const getEarningsdeductionReportPagination = (params) => fetchReports(EARNING_DEDUCTION_REPORTS, params);
export const getMonthWiseAttendanceReportsPagination = (params) => fetchReports(MONTH_WISE_ATTENDANCE_REPORTS, params);
export const getSalarySummaryReports = (params) => fetchReports(SALARY_SUMMARY_REPORTS, params);
export const getMusterRollReports = (params) => fetchReports(MUSTER_ROLL_REPORTS, params);
export const getSubordinatesReportsPagination = (params) => fetchReports(SUBORDINATES_REPORTS, params);
export const getAttendanceCorrectionReportsPagination = (params) => fetchReports(ATTENDANCE_CORRECTION_REPORTS, params);
export const getRosterReports = (params) => fetchReports(ROSTERING_REPORT, params);
export const getLeavepermissionReports = (params) => fetchReports(LEAVE_PERMISSION_REPORT, params);