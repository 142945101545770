import {
  EMPLOYEE_SUBORDINATE_EMPLOYEELIST,
  EMPLOYEE_SUBORDINATE_ATTENDANCELIST,
  EMPLOYEE_SUBORDINATE_MONTHLY_ATTENDANCELIST,
  EMPLOYEE_SUBORDINATE_SUBORDINATESLIST
} from "constants";
import { fetchReports, getMethod } from "components/common/CommonFuntion";

export const getSubordinateEmployeeList = (params) => fetchReports(EMPLOYEE_SUBORDINATE_EMPLOYEELIST, params);
export const getSubordinateAttendanceList = (params) => fetchReports(EMPLOYEE_SUBORDINATE_ATTENDANCELIST, params);
export const getSubordinateMonthlyAttendanceList = (params) => fetchReports(EMPLOYEE_SUBORDINATE_MONTHLY_ATTENDANCELIST, params);

export const getSubordinateViewList = async (id) => {
  const url = `${EMPLOYEE_SUBORDINATE_SUBORDINATESLIST}?employee_id=${id}`;
  return await getMethod(url);
};

export const getSubordiEmployeedetail = async (id) => {
  const url = `${EMPLOYEE_SUBORDINATE_EMPLOYEELIST}?subordinate_id=${id}`
  return await getMethod(url);
}
