import {
    EMPLOYEE_CANDIDATE_INFO,
    EMPLOYEE_CANDIDATE_SALARY_INFO,
    EMPLOYEE_CANDIDATE_DOCUMENT_INFO,
    EMPLOYEE_CANDIDATE_FINAL_INFO
} from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getCandidateInfoPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token");
    let url = EMPLOYEE_CANDIDATE_INFO;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }
    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
};


export const getEmployeeOnboardingDocumentById = async (id) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYEE_CANDIDATE_DOCUMENT_INFO + `?id=${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
};

export const getEmployeeOnboardingSalaryById = async (id) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYEE_CANDIDATE_SALARY_INFO + `?id=${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
};

export const getEmployeeOnboardingById = async (id) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYEE_CANDIDATE_INFO + `?id=${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
};

export const getEmployeeFinalOnboardingById = async (id) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYEE_CANDIDATE_FINAL_INFO + `?employee_id=${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
};