import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>404 - Page Not Found</h1>
            <p style={styles.message}>Oops! The page you are looking for might be under construction or does not exist.</p>
            <Link to={"/home"} style={styles.link}>
                Go to Home
            </Link>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        padding: '50px',
        margin: "20dvh",
    },
    heading: {
        fontSize: '2.6dvw',
        color: '#333',
        marginBottom: '0.625dvw'
    },
    message: {
        fontSize: '1.5dvw',
        color: '#666',
        marginBottom: '0.625dvw'
    },
    image: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
    },
    link: {
        display: 'block',
        marginTop: '20px',
        fontSize: '1.3dvw',
        color: '#007BFF',
        textDecoration: 'none',
    },
};

export default PageNotFound;
