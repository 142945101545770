import { REPORTING_MANAGER_LIST } from "constants";
import { EMPLOYEE_MIGRATION_LIST } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getEmployeeMigratedList = async ({ pageNo, pageSize, query, search, sorting }) => {
    try {
      const authtoken = sessionStorage.getItem("token");
      let url = EMPLOYEE_MIGRATION_LIST;
  
      if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
      }
  
      if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
      }
  
      if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
      }
  
      if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
      }
  
      if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
      }
  
      const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
      };
  
      return await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
    } catch (error) {
      return { status: "fail", message: error.message };
    }
  };

  export const getEmployeeList = async (query) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };
  
    let url = REPORTING_MANAGER_LIST;
  
    if (query) {
      url = prefillUrl(url);
      url = `${url}${query}`;
    }
  
    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  };