import { HOME, HAPPYMETER, CLOCKIFY } from 'constants';

export const getHomeDetails = async () => {
  try {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `token ${authtoken}` },
    };
    return await fetch(HOME, requestOptions)
      .then((response) => response.json())
      .then(data => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
}

export const getHappyMeter = async (query) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    let url = HAPPYMETER;

    if (query) {
      url = `${url}${query}`;
    }

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
}

export const getClockify = async (query) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    let url = CLOCKIFY;

    if (query) {
      url = `${url}${query}`;
    }

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
}