import { isUserExists } from "hooks/api/userAPI";
import moment from "moment";
import dayjs from "dayjs"

export const validateAadharNumber = (_, value, callback) => {
   if (value && !/^\d{12}$/.test(value)) {
      callback('Invalid aadhar number');
    } else {
      callback();
    }
  };
  
  // Custom validation rule for driving license number
export const validateDrivingLicenseNumber = (_, value, callback) => {
     if (value && !/^[A-Za-z]{2}\d{13}$/.test(value)) {
      callback('Invalid driving license number');
    } else {
      callback();
    }
  };

  export const validatePANCard = (_, value, callback) => {
    if (value && !/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value)) {
      callback('Invalid PAN card number');
    } else {
      callback();
    }
  };

  export const validateAlphabetsOnly = (_, value, callback) => {
    if (value && !/^[A-Za-z\s]+$/.test(value)) {
      callback('Enter only alphabets');
    } else {
      callback();
    }
  };

  export const validateFNAlphabetsOnly = (_, value, callback) => {
    if(!value){
      callback("")
    }
    else if (!/^[A-Za-z\s]+$/.test(value)) {
      callback('Enter only alphabets');
    } else {
      callback();
    }
  };

  export const validateMobileMax10Digits = async (_, value, callback) => {
    if(!value){
      return Promise.reject('');
    }
    else if (!/^[0-9]{10}$/.test(value)) {
      return Promise.reject('');
    } else if(value){
      const userExists = await isUserExists(value,'mobile')
      if(userExists.mobile){
        return Promise.reject('Mobile No exist!');
      } 
    } else {
      return Promise.reject();
    }
  };

  export const validateMobile10Digit = async (_, value) => {
    if(!value){
      return Promise.reject('This field is required');
    }
    else if (!/^[0-9]{10}$/.test(value)) {
      return Promise.reject('Please enter a valid 10-digit mobile number.');
    } else {
      return Promise.resolve();
    }
  };

  export const validateMobile10Digits = async (_, value) => {
    if (value && !/^[0-9]{10}$/.test(value)) {
      return Promise.reject('Please enter a valid 10-digit mobile number.');
    } else {
      return Promise.resolve();
    }
  };

  export const validateMobile = async (_, value, callback) => {
    if(!value){
      callback("")
    }
    else if (!/^[0-9]{10}$/.test(value)) {
      callback('');
    } else {
      callback();
    }
  };

  export const validateUAN = (_, value, callback) => {
    if (value && !/^[1]{1}[0-9]{11}$/.test(value)) {
      callback('Enter valid UAN');
    } else {
      callback();
    }
  };

  export const validateEsic = (_, value, callback) => {
    if (value && !/^[0-9]{17}$/.test(value)) {
      callback('Enter valid ESIC');
    } else {
      callback();
    }
  };

  export const validateNumeric = (_, value) => {
    return new Promise((resolve, reject) => {
      if (value && !/^\d+$/.test(value)) {
        reject('Enter only numeric value');
      } else {
        resolve();
      }
    });
  };

  export const validateNumericMandatory = (_, value, callback) => {
    if(!value){
      callback("This field is required")
    }
    else if (value && !/^\d+$/.test(value)) {
      callback('Enter only numeric value');
    } else {
      callback();
    }
  };

  export const validatePFNumber = (_, value, callback) => {
    if (value && !/^[A-Za-z0-9]{22}$/.test(value)) {
      callback('Invalid PF number');
    } else {
      callback();
    }
  };

  export const validateNumericMax10Digits = (_, value, callback) => {
    if (value && !/^[0-9]{10}$/.test(value)) {
      callback('Please enter exactly 10 digits');
    } 
    else {
      callback();
    }
  };

  export const validateNumericMax6Digits = (_, value, callback) => {
    if (value && !/^[0-9]{6}$/.test(value)) {
      callback('Please enter exactly 6 digits');
    } else {
      callback();
    }
  };

  export const validateBankAccountNumber = (_, value, callback) => {
    if (value && !/^\d{9,18}$/.test(value)) {
      callback('Please enter a valid account number.');
    } else {
      callback();
    }
  };

  export const validateIFSCCode = (_, value, callback) => {
    if (value && !/^[A-Za-z0-9]{11}$/.test(value)) {
      callback('Please enter a valid IFSC code.');
    } else {
      callback();
    }
  };

  export const validateLeaveTotal = (_, val, callback) => {
    if (!val) {
      callback('');
    }else if (val && !/^\d+(\.\d+)?$/.test(val)) {
      callback('Please enter a numeric value.');
    } else if (parseFloat(val) <= 0) {
      callback('value greater than 0.');
    } else {
      callback();
    }
  };

  export const validatePassPortNumber = (_, value) => {
    return new Promise((resolve, reject) => {
      if (value && !/^[A-PR-WY-Z][1-9]\d\s?\d{4}[1-9]$/.test(value)) {
        reject('Invalid Passport No');
      } else {
        resolve();
      }
    });
  };

  export const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  export const disabledPastDate = current => {
    // If the date is before today, disable it
    return current && current < moment().startOf('day');
  };

  export const disabledTimesheetDate = (current) => {
    const sevenDaysAgo = moment().subtract(8, 'days');
    
    // Disable dates that are more than 7 days in the past or in the future
    return current <= sevenDaysAgo || current >= moment();
  };

  export function getMonthStartAndEndDates(year, monthNumber) {
    // Initialize the first day of the month
    const firstDay = new Date(year, monthNumber, 1);
  
    // Calculate the last day of the month
    const lastDay = new Date(year, monthNumber + 1, 0);
  
    // Return the first and last day as Date objects
    return { monthStart: firstDay, monthEnd: lastDay };
  }

  export function getWeeksInMonth(monthStart, monthEnd) {
    const weeks = [];

    let currentDate = monthStart;
    let weekIndex = 0;
    let days = [];

    while (currentDate <= monthEnd) {
      const dayOfWeek = moment(currentDate).day();

      if (dayOfWeek === 1 && days.length !== 0) {
        weeks.push(days);
        days = [];
        weekIndex++;
      }
      days.push(currentDate);

      currentDate = moment(currentDate).add(1, 'day');
    }
    if (days.length !== 0) {
      weeks.push(days);
    }
    return weeks;
  }

export const passwordValidator = (_, value) => {
  let userPasswordPolicy = JSON.parse(sessionStorage.getItem("passwordPolicy"));
  let passwordData = Object.keys(userPasswordPolicy)?.length > 0 ? userPasswordPolicy : {};
  if(!value){
    return Promise.reject('This field is required');
  }

  if (!(passwordData.min_length <= value.length && value.length <= passwordData.max_length)) {
    return Promise.reject(`Password should be between ${passwordData.min_length} and ${passwordData.max_length} characters`);
  }

  if (!(passwordData.min_number <= (value.match(/[0-9]/g) || []).length && (value.match(/[0-9]/g) || []).length <= passwordData.max_number)) {
    return Promise.reject(`Password should contain ${passwordData.min_number} to ${passwordData.max_number} numeric characters`);
  }

  if (!(passwordData.min_lower <= (value.match(/[a-z]/g) || []).length && (value.match(/[a-z]/g) || []).length <= passwordData.max_lower)) {
    return Promise.reject(`Password should contain ${passwordData.min_lower} to ${passwordData.max_lower} lowercase characters`);
  }

  if (!(passwordData.min_upper <= (value.match(/[A-Z]/g) || []).length && (value.match(/[A-Z]/g) || []).length <= passwordData.max_upper)) {
    return Promise.reject(`Password should contain ${passwordData.min_upper} to ${passwordData.max_upper} uppercase characters`);
  }

  if (!(passwordData.min_chars <= (value.match(/[a-z]/g) || []).length + (value.match(/[A-Z]/g) || []).length && (value.match(/[a-zA-Z]/g) || []).length <= passwordData.max_chars)) {
    return Promise.reject(`Password should contain ${passwordData.min_chars} to ${passwordData.max_chars} characters`);
  }

  if (!passwordData.password_combination && value.match(/[^A-Za-z0-9]/g)) {
    return Promise.reject('Password should not contain any special characters');
  }
  return Promise.resolve();
};

export const userValidator = async (_, value, { getFieldValue }, employeeId) => {
  const originalUsername = getFieldValue(["user_creation_info", "user_name"]);
  // Initialize initialUsername with the initial value

  // If both employeeId (user created) and value are present, skip validation 
  // Also, if the value hasn't changed, skip validation
  if (employeeId && value && value === originalUsername) {
    return Promise.resolve(); 
  }
  // Check if the value is empty or not present
  if (!value) {
    return Promise.resolve();
  }
  // Check if value is present before calling isUserExists
  if (value) {
    try {
      const userExists = await isUserExists(value, 'user');
      if (userExists && userExists.user_name) {
        return Promise.reject('User already exists!');
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      console.error('Error checking user existence:', error);
      return Promise.reject('Error checking user existence');
    }
  }
};

export const emailValidator = async (_, value, { getFieldValue }, employeeId) => {
  const originalEmailId = getFieldValue(["user_creation_info", "email_address"]);
  // If the value hasn't changed, skip validation
  if (employeeId && value && value === originalEmailId) {
    return Promise.resolve();
  }
  if (!value) {
    return Promise.resolve();
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return Promise.reject('Invalid Email ID');
  }
  if (value){
    try {
      const userExists = await isUserExists(value, 'email');
      if (userExists && userExists.email) {
        return Promise.reject('Email ID exist!');
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      console.error('Error checking user existence:', error);
      return Promise.reject('Error checking user existence');
    }
  }
}

  export const emailValidation = async (_, value) => {
   if(value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){
      return Promise.reject('Invalid Email ID');
    } else {
      return Promise.resolve();
    }
  }

  export function timeConvertor(timeStr){
    if(timeStr){
      const originalTime = timeStr;
    const timeArray = originalTime.split(":");
    const hours = parseInt(timeArray[0], 10);
    const minutes = parseInt(timeArray[1], 10);
    const seconds = parseInt(timeArray[2], 10);

    const formattedTime = new Date(0, 0, 0, hours, minutes, seconds)
      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    
      return formattedTime;
    }
    else{
      return "";
    }
    
  }

  export function numberValidation(value){
    if (Number.isInteger(value)) {
      // return Math.round(value,2)
      return value
    } else {
      return Number(value).toFixed(2) * 1;
    }
  }

  export function formatDateTime(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const year = dateTime.getFullYear();
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = dateTime.getDate().toString().padStart(2, '0');
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const ampm = dateTime.getHours() >= 12 ? 'PM' : 'AM';

    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  }

export const getSameDayOfMonth = (givenDate, targetYear, targetMonth) => {
  // Extract year, month, and day components from the given date string
  const [givenYear, givenMonth, givenDay] = givenDate.split('-');
  // Create a Date object for the given date
  const date = new Date(givenDate);
  // Get the day of the week for the given date
  const dayOfWeek = date.getDay();

  // Create a Date object for the target year and month
  const targetDate = new Date(targetYear, targetMonth, 1);
  // Calculate the number of days to add for the same day in the target month
  const daysToAdd = (7 + dayOfWeek - targetDate.getDay()) % 7;
  // Add the days to the target date to find the same day in the target month
  targetDate.setDate(targetDate.getDate() + daysToAdd);
  //targetDate.setDate(targetDate.getDate() + (givenDay - 1))
  return targetDate;
  };

  export const getWeekAndDay = (givenDate) => {
    // Create a Date object for the given date
    const date = new Date(givenDate);

    // Get the day of the month (1-31)
    const dayOfMonth = date.getDate();

    // Calculate the week of the month
    const weekOfMonth = Math.ceil(dayOfMonth / 7);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = date.getDay();

    return { weekOfMonth, dayOfWeek };
  }

  export const getDateForWeekAndDay = (targetWeek, targetDay) => {
    // Create a Date object for the current date
    const date = new Date();
    // Set the date to the first day of the week (Sunday)
    date.setDate(date.getDate() - date.getDay());
    // Adjust the date based on the target week and day
    date.setDate(date.getDate() + targetWeek * 7 + targetDay);

    return date;
  };

    // Function to calculate the date 18 years ago from today
  export const getEighteenYearsAgoDate = () => {
    const currentDate = new Date();
    const eighteenYearsAgoDate = new Date();
    eighteenYearsAgoDate.setFullYear(currentDate.getFullYear() - 18);
    return eighteenYearsAgoDate;
  };

  export function convertToReadableFormat(str) {
    // Split the string by underscores and capitalize each word
    const specialCharsPattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    // Use the match() method to find all occurrences of special characters
    const matches = str.match(specialCharsPattern);
    let words = str.split(matches).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return words.join(' ');
  }

  export function removeNullFromString(inputString) {
    // Replace all occurrences of 'null' with an empty string
    const stringWithoutNull = inputString.replace(/null/g, '');
    return stringWithoutNull;
  }

  export function getMonthName (monthNumber) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthIndex = parseInt(monthNumber, 10) - 1;

    if (monthIndex >= 0 && monthIndex < monthNames.length) {
      return monthNames[monthIndex];
    }

    return 'Invalid Month';
  };

  export const noBlankSpacesValidator = (_, value) => {
    if (value && value.trim() === '') {
      return Promise.reject('');
    }
    return Promise.resolve();
  };

  export const BlankSpacesValidator = (_, value) => {
    if (value && value.trim() === '') {
      return Promise.reject('');
    }
    return Promise.resolve();
  };

export const areValuesNotEmptyExceptKeys = (obj, keysToExclude) => {
  for (let key in obj) {
    if (!keysToExclude.includes(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== false &&
      obj[key] !== 0 &&
      obj[key] !== '' &&
      !(Array.isArray(obj[key]) && obj[key].length === 0) && // Check for empty array
      !(typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) // Check for empty object
    ) {
      return true; // If a non-empty value is found for a key other than the excluded keys
    }
  }
  return false; // Return false if all values are empty or excluded
};

export const validatePercentage = (rule, value) => {
  if (value && (value < 0 || value >= 101)) {
    return Promise.reject('Value must be between 0 and 101');
  } else {
    return Promise.resolve();
  }
};

export const extractImageName = (url) => {
  let result = "";
  if(url){
    result = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'))
  }
  return result
}

export const validateNumericMax6Digit = (_, value) => {
  if (value && value.length > 6) {
    return Promise.reject('Value must be maximum 6 digits');
  } else {
    return Promise.resolve();
  }
};

export const dateConvertor = (timestamp) => {
  let dateObject = new Date(timestamp);
  let year = dateObject.getFullYear();
  let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  let day = dateObject.getDate().toString().padStart(2, '0');
  let formattedDate = day + '-' + month + '-' + year;
  return formattedDate;
}

export const splitTime = (value) => {
  let timeStr = value?.split("T")[1]
  return timeStr
}

export const formatTime12Hour = (timeString) => {
  const date = new Date(timeString);
  const formattedTime = date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return formattedTime;
}

export const formatTimeToAMPM = (timeString) => {
  const formattedTime = moment(timeString, 'HH:mm:ss').format('h:mm A');
  return formattedTime;
};

export const validateRange = (min, max) => (_, value) => {
  if (value && (value < min || value > max)) {
    return Promise.reject(`Value must be within ${min} to ${max}`);
  } else {
    return Promise.resolve();
  }
};

export const validateRangeMandatory = (min, max) => (_, value) => {
  if(!value){
    return Promise.reject('This field is required');
  }
  if (value && (value < min || value > max)) {
    return Promise.reject(`Value must be within ${min} to ${max}`);
  } else {
    return Promise.resolve();
  }
};

export const disabledFutureDate = (current) => {
  return current && current > moment().endOf('day');
};

export const extractFileExtension = (url) => {
  const formatMatch = url.match(/\.([^.?]+)(?:\?|$)/);
  if (formatMatch) {
    return formatMatch[1];
  } else {
    return null;
  }
}

export const calculateExperience = (startDate, endDate) => {
  if(startDate && endDate){
  const start = new Date(startDate);
  const end = new Date(endDate);   
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();    
    if (months < 0) {
      years--;
      months += 12;
    }   
    return `${years}.${months}`;
  }
  return '0.0';
};

export const allowAlphaCapsNum = (e) => {
  const regex = /^[A-Z\d]$/;
  const specialKeys = [
    'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete'
  ];
  if (!regex.test(e.key) && !specialKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const notAllowAlphaCaps = (e) => {
  const regex = /^[a-z\d\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/;
  const specialKeys = [
    'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete'
  ];
  if (!regex.test(e.key) && !specialKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export function removeUnderscores(inputText) {
  return inputText.replace(/_/g, ' ');
};

export function formatTimeSpan(input) {
if(input){
  const [daysPart, timePart] = input.includes('days') || input.includes('day') ? input.split(', ') : ['0 days', input];
  const [daysStr] = daysPart.split(' ');
  const days = parseInt(daysStr, 10);
  const [hours, minutes, seconds] = timePart.split(/[:,]/).map(Number);
  const totalHours = days * 24 + hours;
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  return `${totalHours}:${formattedMinutes}:${formattedSeconds}`;
 }
 return null;
}

export const handleOnKeyDecimal = (e) => {
  const key = e.key;
  const currentValue = e.target.value;
  if (!/^[\d.]$/.test(key) && key !== "Backspace" && key !== "Delete" && key !== "ArrowLeft" && key !== "ArrowRight" && key !== "Home" && key !== "End") {
    e.preventDefault();
    return;
  }

  if (key === "." && (currentValue.includes(".") || e.target.selectionStart === 0)) {
    e.preventDefault();
    return;
  }
  let newValue = currentValue;
  if (key === "Backspace" || key === "Delete") {
    newValue = currentValue; // Actual value will be handled by default
  } else {
    newValue = currentValue + key;
  }
  if (key !== "Backspace" && key !== "Delete") {
    if (parseFloat(newValue) > 100 || parseFloat(newValue) < 0) {
      e.preventDefault();
      return;
    }
    const [integerPart, decimalPart] = newValue.split(".");
    if (decimalPart && decimalPart.length > 2) {
      e.preventDefault();
      return;
    }
  }
};

export const handleOnKeyNumeric = (e) => {
  const { key } = e;
  if (!/^\d$/.test(key) && key !== "Backspace" && key !== "Delete" && key !== "ArrowLeft" && key !== "ArrowRight" && key !== "Home" && key !== "End") {
    e.preventDefault();
  }
}

export const handleOnKeyNumericDecimal = (e) => {
  const { key } = e;
  if (!/^\d$/.test(key) && key !== "." && key !== "Backspace" && key !== "Delete" && key !== "ArrowLeft" && key !== "ArrowRight" && key !== "Home" && key !== "End") {
    e.preventDefault();
  } else {
    const currentValue = e.target.value;

    if (key === ".") {
        // Prevent more than one dot
        if (currentValue.includes(".")) {
            e.preventDefault();
        }
    } else if (/^\d$/.test(key)) {
        // Allow digits only if not exceeding two decimal places
        const [integerPart, decimalPart] = currentValue.split(".");
        if (decimalPart && decimalPart.length >= 2 && currentValue.includes(".")) {
            e.preventDefault();
        }
    }
}
}

export const containsBasic = (name) => {
  const keywordPattern = /\bbasic\b|\bbasic\s*pay\b|\bbasicpay\b|\bbasic\s*salary\b|\bbasicsalary\b|\bb\s*pay\b|\bbpay\b|\bbs\b/i;
  return keywordPattern.test(name.toLowerCase().replace(/\s+/g, ''));
};

export const containsHRA = (name) => {
  const keywordPattern = /\bhra\b|\bhouse\s*rent\s*allowance\b|\brent\s*allowance\b|\bhousing\s*allowance\b/i;
  return keywordPattern.test(name.toLowerCase().replace(/\s+/g, ''));
};

export const containsProvidentFund = (name) => {
  const keywordPattern = /\bpf\b|\bprovident\s*fund\b|\bprovidentfund\b|\bepf\b|\bemployee\s*provident\s*fund\b/i;
  return keywordPattern.test(name.toLowerCase().replace(/\s+/g, ''));
};

export const containsESI = (name) => {
  const keywordPattern = /\besi\b|\besic\b|\bemployee\s*state\s*insurance\b|\bemployee\s*state\s*insurance\s*corporation\b/i;
  return keywordPattern.test(name.toLowerCase().replace(/\s+/g, ''));
};

export const containsDearnessAllowance = (name) => {
  const keywordPattern = /\bda\b|\bdearness\s*allowance\b|\bdearnessallowance\b/i;
  return keywordPattern.test(name.toLowerCase().replace(/\s+/g, ''));
};

export const containsTaxTerms = (name) => {
  const keywordPattern = /\btds\b|\btcs\b|\btax\s*deducted\s*source\b|\btax\s*deducted\s*at\s*source\b|\btax\s*collected\s*source\b|\btax\s*collected\s*at\s*source\b/i;
  return keywordPattern.test(name.toLowerCase().replace(/\s+/g, ''));
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
