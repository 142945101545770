import MyButton from "components/ui/Button/MyButton";
import React, { useEffect, useState, useCallback } from "react";
import { Card, Input, Table, Space, Dropdown, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useGetRunPayrollProcess } from "hooks/api/payrollApi/runPayrollApi";
import { CommonAmountCard } from "components/common/CommonComponent";
import { ImagePaths } from "utils/ImagePath";
import EditSalaryDetails from "./EditSalaryDetails";
import PublishPopUp from "./PublishPopUp";
import ToastMsg from "components/common/ToastMsg";
import { useNavigate, useLocation } from 'react-router-dom';
import { toIndianCurrency } from "utils/StringUtils";
import moment from "moment";
import { PAYROLL_PUBLISH, PAYROLL_PROCESS } from "constants";
import {getCSVData} from "components/common/CommonFuntion";
import { CalendarOutlined } from "@ant-design/icons";

function RunPayrollStep2() {
  const query = "";
  const [searchText, setSearchText] = useState("");
  const [FiltersearchText, setFilterSearchText] = useState("");
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [selectedPaySlips, setSelectedPaySlips] = useState([]);
  const [isProcessClked, setIsProcessClked] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const tableContainerHeight = "60.5vh";

  const location = useLocation();
  const { PaySlipId } = location.state || {};

  const authtoken = sessionStorage.getItem("token")
  useEffect(() => {
    if (!authtoken) {
      window.location.href = '/';
    }
  }, [authtoken]);

  const navigate = useNavigate();

  const [data, cardData, loading, _message, refetch] = useGetRunPayrollProcess(
    query,
    searchText,
    PaySlipId,
  );

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const columns = [
    {
      title: "Employee Information",
      dataIndex: "employee_name",
      key: "employee_name",
      width: "12.5%",
      sorter: (a, b) => a.employee_name.length - b.employee_name.length,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        let designation_name = record?.designation_name ? record?.designation_name : "";
        return (
          <div>
            <p id="common_weekoff_valTxt">{record.employee_name?.length > 20 ?
              <Tooltip title={`${record?.title} ${record?.employee_name}`}>
                <span id="common_weekoff_valTxt">{(record?.title ? record?.title : "") + record.employee_name?.slice(0, 20) + "..."}</span>
              </Tooltip>
              : record.employee_name ? `${record?.title ? record?.title : ""} ${record?.employee_name}` : "-"}</p>
            <p className="password-tlt">
              {record?.employee_code?.length > 10 ?
                <Tooltip title={record?.employee_code}>
                  <span className="password-tlt">{`( ${record?.employee_code?.slice(0, 10) + "... "} )`}</span>
                </Tooltip>
                :
                record?.employee_code ? `( ${record?.employee_code} )` : "-"
              }
              {" "}
              {designation_name?.length > 15 ? (
                <Tooltip title={designation_name}>
                  <span className="password-tlt">{designation_name?.slice(0, 15) + "... "}</span>
                </Tooltip>
              ) : (
                designation_name ? designation_name : "-"
              )}
            </p>
          </div>
        )
      }
    },
    {
      title: "CTC",
      dataIndex: "ctc",
      key: "ctc",
      align: 'center',
      width: "9.66%",
      sorter: (a, b) => a.ctc - b.ctc,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record.ctc)}</span>)
    },
    {
      title: "Earnings",
      dataIndex: "earnings",
      key: "earnings",
      align: 'center',
      width: "9.66%",
      sorter: (a, b) => a.earnings - b.earnings,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<a onClick={() => isProcessClked ? "" : handleEditClick(record)}>{toIndianCurrency(record.earnings)}</a>)
    },
    {
      title: "Deductions",
      dataIndex: "deductions",
      key: "deductions",
      align: 'center',
      width: "9.66%",
      sorter: (a, b) => a.deductions - b.deductions,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<a onClick={() => isProcessClked ? "" : handleEditClick(record)}>{toIndianCurrency(record.deductions)}</a>)
    },
    {
      title: "Net Pay",
      dataIndex: "net_pay",
      key: "net_pay",
      align: 'center',
      width: "9.66%",
      sorter: (a, b) => a.net_pay - b.net_pay,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record.net_pay)}</span>)
    },
    {
      title: "LOP Days",
      dataIndex: "lop_days",
      key: "lop_days",
      align: 'center',
      width: "6.50%",
      sorter: (a, b) => a.lop_days - b.lop_days,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{(record?.lop_days ? record?.lop_days : 0)}</span>)
    },
    {
      title: "LOP",
      dataIndex: "lop_amount",
      key: "lop_amount",
      align: 'center',
      width: "9.66%",
      sorter: (a, b) => a.lop_amount - b.lop_amount,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record?.lop_amount ? record?.lop_amount : 0)}</span>)
    },
    {
      title: "Reimbursements ",
      dataIndex: "reimbursements_total",
      key: "reimbursements_total",
      align: 'center',
      width: "12.4%",
      sorter: (a, b) => a.reimbursements_total - b.reimbursements_total,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record?.reimbursements_total ? record?.reimbursements_total : 0)}</span>)
    },
    {
      title: "Credit",
      dataIndex: "credits",
      key: "credits",
      align: 'center',
      width: "8.2%",
      sorter: (a, b) => a.credits - b.credits,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record.credits)}</span>)
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      align: 'center',
      width: "8.2%",
      sorter: (a, b) => a.debit - b.debit,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record.debit)}</span>)
    },
    {
      title: "Paid Salary",
      dataIndex: "paid_salary",
      key: "paid_salary",
      align: 'center',
      width: "10.4%",
      sorter: (a, b) => a.paid_salary - b.paid_salary,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (<span>{toIndianCurrency(record.paid_salary)}</span>)
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedPaySlips,
    // onChange: (selectedRowKeys) => {
    //   setSelectedPaySlips(selectedRowKeys);
    // },
    onSelect: (record, selected) => {
      const selectedRowKeysCopy = [...selectedPaySlips];
      if (selected) {
        selectedRowKeysCopy.push(record.payslip_id); // Assuming 'key' is a unique identifier of each row
      } else {
        const index = selectedRowKeysCopy.indexOf(record.payslip_id);
        if (index > -1) {
          selectedRowKeysCopy.splice(index, 1);
        }
      }
      setSelectedPaySlips(selectedRowKeysCopy);
    },
    onSelectAll: (selected) => {
      if (selected) {
        const allRowKeys = data.map(row => row.payslip_id);
        setSelectedPaySlips([...allRowKeys]);
      } else {
        setSelectedPaySlips([]);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: isProcessClked,
    }),
  };

  const handleProcessBtn = useCallback(() => {
    try {
      setLoadings(true)

      let updatedFormData = {
        payslip_ids: selectedPaySlips
      };

      fetch(PAYROLL_PUBLISH, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            setIsProcessClked(true);
            setLoadings(false);
            ToastMsg("success", data.message);
          }
          else if (data.status === "fail") {
            ToastMsg("error", data.message);
            setLoadings(false);
          }
        })
        .catch(error => {
          error.json().then(data => {
            setLoadings(false);
            ToastMsg("error", data);
          });
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [selectedPaySlips]);

  const renderInput = (value, isSuffix = true) => {
    return (
      <Input
        autoComplete='off'
        bordered={false}
        style={styles.textfield}
        disabled
        size="large"
        type="text"
        value={value ? value : ""}
        suffix={isSuffix ? <CalendarOutlined /> : null}
      />
    )
  }

  const handleBackToHome = () => {
    navigate('/payroll/payroll');
  }

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg('warning', "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
    }
  };

  const handlePublishClick = () => {
    setIsPublish(true);
  }

  const handlePublishPopCls = () => {
    setIsPublish(false);
  }

  const handleClearData = () => {
    setIsProcessClked(false);
  }

  const Searchhandler = (e) => {
    setFilterSearchText(e.target.value)
  }

  const handleSearch = () => {
    setEditVisible(false)
    setSelectedDetails({});
    setSearchText(FiltersearchText)
    refetch(
      query,
      searchText,
      PaySlipId);
    setDataUpdated(!dataUpdated);
  }

  const handleSave = () => {
    handleCancel();
    refetch(
      query,
      searchText,
      PaySlipId);
    setDataUpdated(!dataUpdated);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setSelectedDetails({});
  };

  let queryString = "?id=" + PaySlipId + "&search=" + searchText ;
  
  const tblStyles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  const items = [{
    key: "1",
    label: (<div className="empApproveTxt" onClick={() => getCSVData(PAYROLL_PROCESS, queryString)}> Export.csv</div>),
  },
  {
    key: "2",
    label: (<div className="empApproveTxt" onClick={() => getCSVData(PAYROLL_PROCESS, queryString, "xlsx")}> Export.xlsx</div>),
  }
  ];

  let payrollMonth = cardData?.payroll_month ? moment().month(cardData?.payroll_month - 1).format('MMMM') : "";
  let from_date = cardData?.from_date ? moment(cardData.from_date).format('DD-MM-YYYY') : "";
  let to_date = cardData?.to_date ? moment(cardData.to_date).format('DD-MM-YYYY') : "";
  return (
    <div className="h-full">
      <div className="flex justify-start" style ={{height: "15%",marginBottom: "10px"}}>
        <div className='flex items-center mr-5' onClick={handleBackToHome}>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' />
          </Tooltip>
          <p className='runpayRoll_Txt'>Back</p>
        </div>
        <CommonAmountCard label="Total Employee" amount={cardData?.total_employee ? cardData?.total_employee : 0} isCount={true} testID={"runpayRollcard"} />
        <CommonAmountCard label="No Of Days" amount={cardData?.total_days ? cardData?.total_days : 0} isCount = {true} testID={"runpayRollcard"} />
        <CommonAmountCard label="Total Paid Salary" amount={cardData?.total_paid_salary} testID={"runpayRollcard"} />
        <CommonAmountCard label="Total Deductions" amount={cardData?.total_deductions} testID={"runpayRollcard"} />
      </div>
      <Card style ={{height: "83%"}}>
        <div className="h-1/6 flex justify-between items-center">
          <div className="flex justify-between item-center">
            <div className="mr-4">
              <p className='common_filters_subTxt'>Payroll Period</p>
              {renderInput(payrollMonth ? `Payroll For ${payrollMonth}` : "")}
            </div>
            <div className="mr-4">
              <p className='common_filters_subTxt'>Payroll From Date</p>
              {renderInput(from_date)}
            </div>
            <div className="mr-4">
              <p className='common_filters_subTxt'>Payroll To Date</p>
              {renderInput(to_date)}
            </div>
          </div>
          <Space size={"middle"}>
            <Input
              data-testid={`runpayRoll_srch`}
              style={styles.searchInput}
              placeholder="Search"
              value={FiltersearchText}
              onChange={(e) => Searchhandler(e)}
              onPressEnter={(e) => handleSearch(e)}
              suffix={
                <div style={{ borderLeft: "2px solid #183433" }}>
                  <div className="m-1">
                    <SearchOutlined
                      style={styles.searchIcon}
                      className="site-form-item-icon p-1"
                      onClick={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
              }
            />
            {isProcessClked ? null
              : <MyButton className="mr-4" label={"Process"}
                loading={loadings}
                disabled={selectedPaySlips?.length === 0}
                bgColor={selectedPaySlips?.length === 0 ? "#cbcbcb" : null}
                paddingX={"0 1.2vw"} onClick={handleProcessBtn} />}

            {isProcessClked ? <>
              <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft" overlayStyle={styles.dropOverLay}>
                <MyButton className="mr-4" label={"Export"} paddingX={"0 1.2vw"} />
              </Dropdown>
              <MyButton className="mr-4" label={"Publish & Close"} paddingX={"0 1.2vw"} onClick={handlePublishClick} />
            </>
              : null}
          </Space>
        </div>
        <div className="h-5/6">
        <div className="mt-3 w-full custom-table" style={tblStyles.fltContainer}>
          <Table 
            rowKey={(record) => record.payslip_id}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data?.length > 0 ? data : null}
            style = {{"--table-container-height": tableContainerHeight}}
            scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
            loading={loading}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              showSizeChanger: false,
              showQuickJumper: true,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
            bordered
          />
          </div>
        </div>
      </Card>
      {editVisible ?
        <EditSalaryDetails payroll_id={PaySlipId} editDetails={selectedDetails} onSave={handleSave} onHide={handleCancel} />
        : null}
      {isPublish ?
        <PublishPopUp payroll_id={PaySlipId} handleCloseBack={handlePublishPopCls}  handleSuccess = {handleClearData} />
        : null}
    </div>
  );
}

const styles = {
  searchInput: {
    width: "17vw",
    border: "1px solid #616161",
    borderRadius: "0.2vw",
    outline: "none",
    marginTop: "-2vw",
  },
  searchIcon: {
    color: "#616161",
    cursor: "pointer",
    border: "1px solid #183433",
    fontSize: 12,
    borderRadius: 4,
  },
  dropOverLay: {
    width: "9vw",
    maxHeight: 300,
    overflowY: "auto",
    boxShadow: " 0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
  },
  textfield: {
    borderBottom: "1px solid",
    borderRadius: 0,
    outline: "none",
  }
}

export default RunPayrollStep2;
