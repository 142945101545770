import { REIMBURSEMENTS_CONFIG } from 'constants';
import { prefillUrl } from 'utils/StringUtils';


export const getReimbursement = async (query) => {
    const authtoken = sessionStorage.getItem("token")
    let url = REIMBURSEMENTS_CONFIG;
    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getReimbursementPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = REIMBURSEMENTS_CONFIG;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getReimbursementById = async (id) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },

    };

    return await fetch(REIMBURSEMENTS_CONFIG + `?id=${id}`, requestOptions).then((response) => response.json()).then(data => data);
}

export const updateEmployeeDocument = async (input) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'PUT',
        headers: { 'Authorization': `token ${authtoken}` },
        body: JSON.stringify(input)
    };

    return await fetch(REIMBURSEMENTS_CONFIG, requestOptions).then((response) => response.json()).then(data => data);
}