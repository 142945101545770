import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Card, Button, Upload, Tooltip } from 'antd'
import { EMPLOYER } from 'constants';
import {
    validateNumericMax6Digit,
    noBlankSpacesValidator,
    BlankSpacesValidator,
    emailValidation,
    validateMobile10Digits,
    extractImageName,
    notAllowAlphaCaps,
} from 'components/common/validation';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { imgUploadSize } from 'components/common/enum';
import { getPostalPincode } from "hooks/api/employeeApi/employeeInformationApi";

const EditCompanyProfile = () => {
    const companyid = sessionStorage.getItem("userId")
    const [formData, setFormData] = useState({
        id: 0,
        company_name: "",
        registration_number: "",
        business_type: "",
        profile: [],
        official_email_address: "",
        website: "",
        registered_address: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        contact_person: "",
        contact_person_number: "",
        pan_no: "",
        gst_number: "",
        registration_certificate: [],
        gst_certificate: []
    });
    const [loadings, setLoadings] = useState(false);
    const [errors, setErrors] = useState({ profile: "", registration_certificate: "", gst_certificate: "" });
    const prevPropsRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const { fromEdit, editDetails } = location.state || {};
    const [form] = Form.useForm();

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                company_name: editDetails.company_name ? editDetails.company_name : "",
                registration_number: editDetails.registration_number ? editDetails.registration_number : "",
                business_type: editDetails.business_type ? editDetails.business_type : "",
                profile: editDetails?.profile?.length > 0  ? editDetails.profile : [],
                official_email_address: editDetails.official_email_address ? editDetails.official_email_address : "",
                website: editDetails.website ? editDetails.website : "",
                registered_address: editDetails.registered_address ? editDetails.registered_address : "",
                city: editDetails.city ? editDetails.city : "",
                state: editDetails.state ? editDetails.state : "",
                country: editDetails.country ? editDetails.country : "",
                pincode: editDetails.pincode ? editDetails.pincode : "",
                contact_person: editDetails.contact_person ? editDetails.contact_person : "",
                contact_person_number: editDetails.contact_person_number ? editDetails.contact_person_number : "",
                pan_no: editDetails.pan_no ? editDetails.pan_no : "",
                gst_number: editDetails.gst_number ? editDetails.gst_number : "",
                registration_certificate: editDetails?.registration_certificate?.length > 0  ? editDetails.registration_certificate : [],
                gst_certificate: editDetails?.gst_certificate?.length > 0 ? editDetails.gst_certificate : []
            }));
            form.setFieldsValue({
                company_name: editDetails.company_name ? editDetails.company_name : "",
                registration_number: editDetails.registration_number ? editDetails.registration_number : "",
                business_type: editDetails.business_type ? editDetails.business_type : "",
                profile: editDetails?.profile?.length > 0  ? editDetails.profile : [],
                official_email_address: editDetails.official_email_address ? editDetails.official_email_address : "",
                website: editDetails.website ? editDetails.website : "",
                registered_address: editDetails.registered_address ? editDetails.registered_address : "",
                city: editDetails.city ? editDetails.city : "",
                state: editDetails.state ? editDetails.state : "",
                country: editDetails.country ? editDetails.country : "",
                pincode: editDetails.pincode ? editDetails.pincode : "",
                contact_person: editDetails.contact_person ? editDetails.contact_person : "",
                contact_person_number: editDetails.contact_person_number ? editDetails.contact_person_number : "",
                pan_no: editDetails.pan_no ? editDetails.pan_no : "",
                gst_number: editDetails.gst_number ? editDetails.gst_number : "",
                registration_certificate: editDetails?.registration_certificate?.length > 0 ? editDetails.registration_certificate : [],
                gst_certificate: editDetails?.gst_certificate?.length > 0 ? editDetails.gst_certificate : []
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, form, formData])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        form.setFieldsValue({
            ...formData,
            [name]: value,
        });
        form.validateFields([[name]]);

        if (name === "pincode" && value.length === 6) {
            getPostalPincodList(value);
        } else if (name === "pincode" && value.length !== 6) {
            handleClearPincode(value);
        }
    };

    const getPostalPincodList = async (pincode) => {
        try {
            const res = await getPostalPincode(pincode);
            const apiData = res[0];
            if (apiData && apiData.Status === "Success" && apiData.PostOffice) {
                const { Country, District, State } = apiData.PostOffice[0];
                form.setFields([
                    {
                        'country': Country,
                        'city': District,
                        'state': State,
                        'pincode': pincode
                    }
                ]);
                setFormData({
                    ...formData,
                    ["country"]: Country,
                    ["city"]: District,
                    ['state']: State,
                    ['pincode']: pincode
                });
            } else if (apiData.Status === "Error") {
                handleClearPincode(pincode);
                ToastMsg("error", apiData.Message);
            }
        } catch (error) {
            handleClearPincode(pincode);
            ToastMsg("error", 'No records found');
        }
    };

    const handleClearPincode = (pincode) => {
        form.setFields([
            { "country": "" },
            { "city": "" },
            { "state": "" },
            { "pincode": pincode },
        ]);
        setFormData({
            ...formData,
            ["country"]: "",
            ["city"]: "",
            ['state']: "",
            ['pincode']: pincode
        });
    };

    const handleBackCancel = useCallback(() => {
        navigate('/admin/companyprofile');
    }, [navigate])

    const getFileValue = (fileData) => {
        if (Array.isArray(fileData) && fileData.length > 0) {
            return fileData[0].originFileObj;
        } else if (typeof fileData === "string") {
            return fileData;
        } else {
            return "";
        }
    };

    const validateImageSizes = () => {
        const maxSize = imgUploadSize * 1024 * 1024;
        const profileType = typeof formData.profile;
        const regCertType = typeof formData.registration_certificate;
        const gstCertType = typeof formData.gst_certificate;
        let errors = {};

        if (regCertType !== "string" && formData.registration_certificate[0]?.size > maxSize) {
            errors = {
                ...errors,
                registration_certificate: `Registration certificate image size exceeds the limit ${imgUploadSize}MB!`
            };
        }

        if (gstCertType !== "string" && formData.gst_certificate[0]?.size > maxSize) {
            errors = {
                ...errors,
                gst_certificate: `GST certificate image size exceeds the limit ${imgUploadSize}MB!`
            };
        }

        if (profileType !== "string" && formData.profile[0]?.size > maxSize) {
            errors = {
                ...errors,
                profile: `Profile image size exceeds the limit ${imgUploadSize}MB!`
            };
        }

        setErrors(errors);

        // Check if any errors exist
        return Object.values(errors).find(error => !!error) || "";
    };
    
    const handleSubmit = useCallback(() => {
        const authtoken = sessionStorage.getItem("token")
        const imageSizeErrorMsg = validateImageSizes();
        if (imageSizeErrorMsg) {
            ToastMsg("error", imageSizeErrorMsg);
            return;
        }
        try {
            setLoadings(true)
            const dataForm = new FormData();
            dataForm.append("id", formData?.id ? formData?.id : "")
            dataForm.append("company_name", formData?.company_name ? formData?.company_name : "")
            dataForm.append("registration_number", formData?.registration_number ? formData?.registration_number : "")
            dataForm.append("business_type", formData?.business_type ? formData?.business_type : "")
            dataForm.append("profile", getFileValue(formData?.profile))
            dataForm.append("official_email_address", formData?.official_email_address ? formData?.official_email_address : "")
            dataForm.append("website", formData?.website ? formData?.website : "")
            dataForm.append("registered_address", formData?.registered_address ? formData?.registered_address : "")
            dataForm.append("city", formData?.city ? formData?.city : "")
            dataForm.append("state", formData?.state ? formData?.state : "")
            dataForm.append("country", formData?.country ? formData?.country : "")
            dataForm.append("pincode", formData?.pincode ? parseInt(formData?.pincode) : "")
            dataForm.append("contact_person", formData?.contact_person ? formData?.contact_person : "")
            dataForm.append("contact_person_number", formData?.contact_person_number ? parseInt(formData?.contact_person_number) : "")
            dataForm.append("pan_no", formData?.pan_no ? formData?.pan_no : "")
            dataForm.append("gst_number", formData?.gst_number ? formData?.gst_number : "")
            dataForm.append("registration_certificate", getFileValue(formData?.registration_certificate))
            dataForm.append("gst_certificate", getFileValue(formData?.gst_certificate))

            fetch(EMPLOYER, {
                method: "PUT",
                headers: {
                    'Authorization': `token ${authtoken}`
                },
                body: dataForm
            })
                .then((response) => {
                    if (response.status === 413) {
                        setLoadings(false);
                        ToastMsg("error", "Request payload is too large");
                    }
                    return response.json()
                })
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoadings(false);
                        setFormData({
                            id: 0,
                            company_name: "",
                            registration_number: "",
                            business_type: "",
                            profile: [],
                            official_email_address: "",
                            website: "",
                            registered_address: "",
                            city: "",
                            state: "",
                            country: "",
                            pincode: "",
                            contact_person: "",
                            contact_person_number: "",
                            pan_no: "",
                            gst_number: "",
                            registration_certificate: [],
                            gst_certificate: []
                        })
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData, fromEdit, companyid, handleBackCancel]);

    const renderInput = (statekey, maxLength = 150, isReadOnly = false) => {
        return (
            <Input
                autoComplete='off'
                type={"text"}
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                disabled={isReadOnly}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                onKeyDown={statekey === 'official_email_address' ? notAllowAlphaCaps : null}
            />
        )
    }

    const handleFileChange = (statekey, fileList) => {
        form.setFieldsValue({
            [statekey]: fileList
        });
        handleChange(statekey, fileList);
    };

    const renderUpload = (label, statekey) => {
        let ImageUrl = ((formData[statekey] && !Array.isArray(formData[statekey]) && typeof formData[statekey] === "string"))
            ? extractImageName(formData[statekey]) : "";
        let isDocAvail = Array.isArray(formData[statekey]) && formData[statekey]?.length > 0 && formData[statekey][0]?.name;
        return (
            <Form.Item
                name={statekey}
                style={{ width: "100%" }}
                validateStatus={errors[statekey] ? "error" : ""}
                help={errors[statekey]}
                fileList={(!ImageUrl && formData[statekey]) ? formData[statekey] : []}
                key={statekey}
            >
                <p id="common_admin_orgTxt" style ={styles.tltTxt}>{label}</p>
                <Input
                    bordered
                    placeholder={`Upload ${statekey === "profile" ? "Logo" : "Certificate"} Here`}
                    readOnly
                    style={styles.uploadInput}
                    value={ImageUrl ? ImageUrl : (isDocAvail ? formData[statekey][0]?.name : "")}
                    suffix={
                        (ImageUrl || isDocAvail) ?
                            (
                                <Button
                                    onClick={() => handleFileChange(statekey, [])}
                                    style={styles.uploadBtn}
                                > Delete Files</Button>
                            ) :
                            <Upload
                                maxCount={1}
                                fileList={formData[statekey] ? formData[statekey] : []}
                                onChange={({ fileList }) => handleFileChange(statekey, fileList)}
                                beforeUpload={(file) => {
                                    const isPDF = file.type === 'application/pdf';
                                    const isSizeValid = file.size / 1024 / 1024 < imgUploadSize;
                                    const isImage = file.type.startsWith('image/');
                                    if (!isImage && !isPDF) {
                                        let msg = "You can only upload image or PDF files!";
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            [statekey]: msg
                                        }))
                                        ToastMsg("error", msg);
                                        return false;
                                    }

                                    if (!isSizeValid) {
                                        let msg = `File must be smaller than ${imgUploadSize}MB!`;
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            [statekey]: msg
                                        }))
                                        ToastMsg("error", msg);
                                        return false;
                                    }
                                    setErrors(prevErrors => ({ ...prevErrors, [statekey]: "" }));
                                    return false;
                                }}
                                accept= {statekey === "profile" ? "image/*" : ".pdf, image/*"} 
                                showUploadList={false}
                            >
                                <Button style={styles.uploadBtn}>Browse Files</Button>
                            </Upload>
                    }
                />
            </Form.Item>
        )
    }

    return (
        <Card style ={{overflow: "hidden", height: "100%"}}>
            <div className='flex items-center' style={{ borderBottom: "1px solid #D6D6D6", height: "9%", padding: "0.18vw" }}>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt' style={{ marginRight: "0.625vw" }}>Edit Organization Information</p>
                </div>
            </div>
            <div style = {{marginTop: "16px", height: "80vh", overflowY: "auto", overflowX: "hidden" }}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    initialValues={formData}
                    onFinish={handleSubmit}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className='w-full h-full grid grid-rows-2 grid-cols-3 gap-4 border-b' style ={styles.divStyle}>
                        <Form.Item name="company_name">
                            <div className='commonInputTlt'>Organization Name</div>
                            {renderInput("company_name", 150, true)}
                        </Form.Item>
                        <Form.Item name="registration_number">
                            <div className='commonInputTlt'>Registration Number</div>
                            {renderInput("registration_number", 150, true)}
                        </Form.Item>
                        <Form.Item name="business_type">
                            <div className='commonInputTlt'>Business Type</div>
                            {renderInput("business_type", 150, true)}
                        </Form.Item>
                        <Form.Item name="pan_no">
                            <div className='commonInputTlt'>PAN No</div>
                            {renderInput("pan_no", 150, true)}
                        </Form.Item>
                        <Form.Item name="gst_number">
                            <div className='commonInputTlt'>GSTIN</div>
                            {renderInput("gst_number", 150, true)}
                        </Form.Item>
                    </div>
                    <p id="common_admin_orgTxt" style ={styles.tltTxt}>Organization Address</p>
                    <div className='w-full grid grid-cols-3 gap-4 border-b' style ={styles.divStyle}>
                        <Form.Item
                            name="registered_address"
                            rules={[
                                {
                                    required: false,
                                    validator: BlankSpacesValidator,
                                },
                            ]}
                        >
                            <div className='commonInputTlt'>Organization Address</div>
                            {renderInput("registered_address")}
                        </Form.Item>
                        <div className='grid grid-cols-2 gap-3'>
                            <Form.Item
                                name="pincode"
                                rules={[
                                    {
                                        required: false,
                                        validator: validateNumericMax6Digit,
                                    },
                                ]}
                            > 
                                <div className='commonInputTlt'>Pincode</div>
                                {renderInput("pincode", 6)} </Form.Item>
                            <Form.Item
                                name="city"
                                rules={[
                                    {
                                        required: false,
                                        validator: noBlankSpacesValidator,
                                    },
                                ]}
                            >
                                <div className='commonInputTlt'>City</div>
                                {renderInput("city", 50)}</Form.Item>
                        </div>
                        <div className='grid grid-cols-2 gap-3'>
                            <Form.Item
                                name="state"
                                rules={[
                                    {
                                        required: false,
                                        validator: noBlankSpacesValidator,
                                    },
                                ]}
                            >
                                <div className='commonInputTlt'>State</div>
                                {renderInput("state", 50)}
                            </Form.Item>
                            <Form.Item
                                name="country"
                                rules={[
                                    {
                                        required: false,
                                        validator: BlankSpacesValidator,
                                    },
                                ]}
                            >
                                <div className='commonInputTlt'>Country</div>
                                {renderInput('country', 50)}
                            </Form.Item>
                        </div>
                    </div>
                    <p id="common_admin_orgTxt" style ={styles.tltTxt}>Organization Details</p>
                    <div className='w-full grid grid-cols-3 gap-4 border-b' style ={styles.divStyle}>
                        <Form.Item
                            name="website"
                            rules={[
                                {
                                    required: false,
                                    validator: noBlankSpacesValidator,
                                },
                            ]}
                        >
                            <div className='commonInputTlt'>Website / URL</div>
                            {renderInput("website")}
                        </Form.Item>
                        <Form.Item
                            name="official_email_address"
                            rules={[{ validator: emailValidation}]}>
                            <div className='commonInputTlt'>Mail ID</div>
                            {renderInput("official_email_address", 50)}
                        </Form.Item>
                        <div className='grid grid-cols-2 gap-3'>
                            <Form.Item
                                name="contact_person"
                                rules={[{
                                    required: false,
                                    validator: BlankSpacesValidator
                                }]}
                            > 
                                <div className='commonInputTlt'>Contact Person</div>
                                {renderInput("contact_person", 50)}</Form.Item>
                            <Form.Item
                                name="contact_person_number"
                                rules={[
                                    {
                                        required: false,
                                        validator: validateMobile10Digits,
                                    },
                                ]}
                            >
                                <div className='commonInputTlt'>Contact Number</div>
                                {renderInput("contact_person_number")}</Form.Item>
                        </div>
                    </div>
                    <p id="common_admin_orgTxt" style ={styles.tltTxt}>Documents</p>
                    <div className='w-full grid grid-cols-3 gap-4' style ={{marginBottom: "1vw"}}>
                        {renderUpload("Registration Certificate", "registration_certificate")}
                        {renderUpload("GST Certificate", "gst_certificate")}
                        {renderUpload("Organization Logo", "profile")}
                    </div>
                    <Form.Item className='w-full'>
                        <div className='flex justify-end items-start'>
                            <MyButton htmlType="button" label="Cancel" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.5vw"} />
                            <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} />
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </Card>
    );
}

const styles = {
    divStyle: {
        marginBottom: "1vw",
        paddingBottom: "1.5vw"
    },
    tltTxt: {
        paddingBottom: "0.75vw"
    },
    uploadInput: {
        flex: 1,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        paddingRight: 0,
    },
    uploadBtn: {
        height: "100%",
        boxShadow: 0,
        fontFamily: "Urbanist",
        backgroundColor: "#cbcbcb",
        borderColor: "#cbcbcb",
        border: "none",
        padding: "0.3vw",
        margin: 0,
        color: "#183433",
        fontSize: '0.85vw',
        fontWeight: "bold",
    }
}

export default EditCompanyProfile
