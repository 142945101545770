import ToastMsg from "components/common/ToastMsg";
import { startOfWeek, addDays, format } from 'date-fns';
import { prefillUrl } from "utils/StringUtils";

export const getPrintData = async (url, query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };
        if (url && query) {
            url = `${url}${query}&export_type=pdf`;
        }
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            const errorResponse = await response.json();
            if (errorResponse && errorResponse.message) {
                throw new Error(errorResponse.message);
            } else {
                throw new Error('Something went wrong. Please try again later.');
            }
        }
        const fileBlob = await response.blob();
        const fileURL = URL.createObjectURL(fileBlob);
        window.open(fileURL);
    } catch (error) {
        ToastMsg("error", error.message);
    }
};

export const getCSVData = async (url, query, type) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };
        if (url && query) {
            url = `${url}${query}&export_type=${type === 'xlsx' ? "xlsx" : "csv"}`;
        }
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            const errorResponse = await response.json();
            if (errorResponse && errorResponse.message) {
                throw new Error(errorResponse.message);
            } else {
                throw new Error('Something went wrong. Please try again later.');
            }
        }
        
        // Use .arrayBuffer() for binary data like xlsx
        const blobData = await response.arrayBuffer();
        const csvBlob = new Blob([blobData], { type: type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' });
        const csvURL = URL.createObjectURL(csvBlob);
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[-T]/g, '').replace(/:/g, '');
        const fileNameArr = url.split("/");
        let fileName = fileNameArr[fileNameArr.length - 2].replace(/_/g, "");
        fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1);
        const pom = document.createElement('a');
        pom.href = csvURL;
        pom.setAttribute('download', `${fileName}${formattedDate}.${type === 'xlsx' ? "xlsx" : "csv"}`);
        document.body.appendChild(pom);
        pom.click();
        document.body.removeChild(pom);
    } catch (error) {
        ToastMsg("error", error.message);
    }
};

export const downloadPDF = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank"
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[-T]/g, '').replace(/:/g, '');
    link.setAttribute('download', `${formattedDate}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const transformUserPermissionArr = (listData) => {
    if (listData?.length > 0) {
        let transformedArray = listData.map(module => {
            let subMenu = [];
            let modulePermission = false;

            if (module.module_name === 'home' ||
                module.module_name === 'dashboard' ||
                module.module_name === 'roster'
            ) {
                let moduleList = module.module_list[module.module_name];
                return {
                    label: module.module_name,
                    permission: moduleList.permission,
                    active: moduleList.active,
                    edit: moduleList.edit,
                    add: moduleList.add,
                    draft: moduleList.draft
                };
            } else {
                for (const [key, value] of Object.entries(module.module_list)) {
                    subMenu.push({
                        label: key,
                        permission: value.permission,
                        add: value.add,
                        edit: value.edit,
                        draft: value.draft,
                        active: value.active
                    });
                    if (value.permission) {
                        modulePermission = true;
                    }
                }
            }
            return {
                label: module.module_name,
                permission: modulePermission,
                subMenu: subMenu
            };
        });
        return transformedArray;
    }
}

export const checkUserPermissionFunc = (MenuName, SubMenuName = null, Type) => {
    try {
        let accessObject = undefined;
        let userPermissionList = JSON.parse(sessionStorage.getItem("loginUserPermission"));
        let accessData = userPermissionList?.length > 0 ? userPermissionList : [];
        // const isAdmin = JSON.parse(sessionStorage.getItem("is_admin"));

        // if (!isAdmin) {  // Check Access only for User role
            let data = {}
            if (Type === 'Menu') {
                data = accessData.find(list => list['label'].toLowerCase().replace(/_/g, " ") === MenuName.toLowerCase())
            } else if (Type === 'SubMenu') {
                data = accessData.find(menu => menu['label'].toLowerCase().replace(/_/g, " ") === MenuName.toLowerCase())
                data = data.subMenu.find(item => item['label'].toLowerCase().replace(/_/g, " ") === SubMenuName.toLowerCase());
            }
            if (data) {
                accessObject = data;
            }
        // }
        if (accessObject === undefined) {
            return {
                "add": false,
                "edit": false,
                "draft": false,
                "active": false,
                "permission": false
            }
        } else {
            return accessObject
        }
    }
    catch (e) {
        // if (JSON.parse(sessionStorage.getItem("is_admin"))) {
        //     return {
        //         "add": true,
        //         "edit": true,
        //         "draft": true,
        //         "active": true,
        //         "permission": true
        //     };
        // } else {
            return {
                "add": false,
                "edit": false,
                "draft": false,
                "active": false,
                "permission": false
            };
        // }
    }
}

export const deleteDraftItemsById = async (id, api, isFormData = false) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        let url = api;
        let formData = undefined;
        let requestOptions = {
            method: "PUT",
            headers: {
                Authorization: `token ${authtoken}`,
            },
            body: formData,
        };
        if (isFormData) {
            formData = new FormData();
            formData.append("id", id);
            formData.append("is_active", false);
            formData.append("draft_delete", true);
            formData.append("is_draft", true);
        } else {
            formData = {
                "id": id,
                "is_active": false,
                "draft_delete": true,
                "is_draft": true,
            }
            requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `token ${authtoken}`,
                },
                body: JSON.stringify(formData),
            };
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}

export const deleteItemsById = async (api, isFormData = false, fetchedData) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        let url = api;
        let requestOptions;
        if (isFormData) {
            requestOptions = {
                method: "PUT",
                headers: {
                    Authorization: `token ${authtoken}`,
                },
                body: fetchedData,
            };
        } else {
            requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `token ${authtoken}`,
                },
                body: JSON.stringify(fetchedData),
            };
        }
        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}

export const getWeekDates = (date) => {
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 }); // Assuming week starts on Monday

  const datesWithDays = [];

  let currentDatePointer = startOfWeekDate;

  for (let i = 0; i < 7; i++) {
    datesWithDays.push({
      day: format(currentDatePointer, 'EEEE'),
      date: format(currentDatePointer, 'dd/MM/yyyy'),
    });
    currentDatePointer = addDays(currentDatePointer, 1);
  }

  return datesWithDays;
}
export const fetchReports = async (url, { pageNo, pageSize, query, search, sorting }) => {
    try {
        const authtoken = sessionStorage.getItem("token");

        if (pageNo) {
            url = prefillUrl(url);
            url = `${url}page_no=${pageNo}`;
        }

        if (pageSize) {
            url = prefillUrl(url);
            url = `${url}&page_size=${pageSize}`;
        }

        if (query) {
            url = prefillUrl(url);
            url = `${url}&${query}`;
        }

        if (search) {
            url = prefillUrl(url);
            url = `${url}&search=${search}`;
        }

        if (sorting) {
            url = prefillUrl(url);
            url = `${url}&${sorting}`;
        }

        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const ensureHash = (colorCode) => {
    if (colorCode?.startsWith('#')) {
        return colorCode;
    } else {
        return `#${colorCode}`;
    }
}

export const getMethod = async (url) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },
        };
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const groupBy = (data, key) => {
    return data?.reduce((acc, item) => {
        // Determine the key value for grouping
        let keyValue = typeof key === 'function' ? key(item) : item[key] ?? 'N/A';
        if (typeof keyValue === 'string') {
            keyValue = keyValue?.split('-')?.map(part => (part === 'null' || part === 'undefined' ? 'N/A' : part))?.join('-');
        }
        // Check if the key already exists in the accumulator
        if (!acc[keyValue]) {
            // Initialize an empty array for this key if it doesn't exist
            acc[keyValue] = [];
        }

        // Push the current item into the array for this key
        acc[keyValue].push(item);

        return acc;
    }, {});
}

export const extractIdFromGroupedData = (groupedData) => {
    return Object.entries(groupedData)?.reduce((result, [key, values]) => {
        // Check if the group contains exactly one item
        if (values.length === 1) {
            // Extract the salary template ID from the single item in the group
           // if (values[0].is_active) {
                const item = values[0];
                const id = item.id; // Adjust if the ID is nested in a different structure
                // Add the extracted ID to the result array
                result.push(id);
           // }
        }
        return result;
    }, []);
}