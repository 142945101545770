/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import loginPattern from "assets/loginPattern.gif";

const Pattern = ({
    className = "",
    src = "",
    
}) => (
    <img src={loginPattern} className={className}/> 
);

export default Pattern; 