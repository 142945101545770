import { PAYROLL_PROCESS } from "constants";
import { useEffect, useState, useContext } from "react";
import { prefillUrl } from "utils/StringUtils";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";

//Normal api
export const runPayrollProcessApi = async (query = "", search = "", summary_id) => {
  const authtoken = sessionStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `token ${authtoken}` },
  };

  let url = PAYROLL_PROCESS;
  if (summary_id) {
    url = prefillUrl(url);
    url = `${url}id=${summary_id}`;
  }
  if (query) {
    url = prefillUrl(url);
    url = `${url}&${query}`;
  }

  if (search) {
    url = prefillUrl(url);
    url = `${url}&search=${search}`;
  }

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
};

//  Effect API
export const useGetRunPayrollProcess = (query, search, summary_id) => {
  const [data, setData] = useState(null);
  const [cardData, setCardData] = useState({});
  const [message, setMessage] = useState({
    isErrorMsg: false,
    errorMsgText: ""
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logoutauth } = useContext(AuthContext);

  async function fetchData(query, search, summary_id) {
    setMessage({
      isErrorMsg: false,
      errorMsgText: ""
    });
    setLoading(true);
    try {
      const apiData = await runPayrollProcessApi(query, search, summary_id);
      if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
        setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
        navigate("/");
        logoutauth();
      }
      if (apiData) {
        if (apiData.status === "fail") {
          setMessage({ isErrorMsg: true, errorMsgText: apiData.message });
        } else if (apiData.data) {
          setData(apiData.data.result);


          setCardData({
            payroll_month: apiData.data.payroll_month,
            from_date: apiData.data.from_date,
            to_date: apiData.data.to_date,
            total_paid_salary: apiData.data.total_paid_salary,
            total_deductions: apiData.data.total_deductions,
            total_employee: apiData.data.total_employee,
            total_days: apiData.data.total_days,
          })
        }
      }
    } catch (error) {
      setMessage({ isErrorMsg: true, errorMsgText: "Error fetching data" });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(query, search, summary_id);
  }, [query, search, summary_id]);

  function refetch(query, search, summary_id) {
    fetchData(query, search, summary_id);
  }

  return [data, cardData, loading, message, refetch];
};
