import React from "react";
import { Card, Col, Row } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Pattern from "components/ui/Images/Pattern";
import MySecondaryLargeButton from "components/ui/Button/MySecondaryLargeButton";
import { ImagePaths } from "utils/ImagePath";
import SuccessGif from "assets/animation/Success.gif";

const PasswordSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user_name } = location.state || {};

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Pattern className="h-screen w-full pattern-text"></Pattern>
        </Col>
        <Col flex={4}>
          <div
            className="flex flex-col"
            style={{ padding: "30px", height: "100vh" }}
          >
            <img src={ImagePaths.loginLogo.default} className = "login_Logo" alt={"App Logo"} />
            <div className="flex items-center justify-center">
                <Card 
                  className="drop-shadow-lg"
                  bordered={false}
                  style={{width:400,height:350}}
                >
                <img src={SuccessGif} className="login_success_icon" alt={"Success"} />
                <p className="login_tlt text-center mb-3">{`Congratulation ${user_name ? user_name : "User"}!`}</p>
                <p className="userPermission-checkbox-subTlt font-medium mb-6 text-center">Your Password has been Changed successfully</p>
                <MySecondaryLargeButton
                  type="primary"
                  label="Back to login"
                  className="mt-2 items-center"
                  htmlType="submit"
                  onClick={() => navigate('/')}
                />
                </Card>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordSuccess;
