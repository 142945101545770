import { CHANGE_PASSWORD } from "constants";

const ChangePasswordApi = (
  formData,
  authtoken,
  id,
  email,
  onSuccess,
  onError
) => {
  let url = CHANGE_PASSWORD;

  formData = {
    user_id: parseInt(id),
    new_password: formData.newPassword,
    re_enter_password: formData.confirmPassword,
    email: email
  }

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${authtoken}`,
    },
    body: JSON.stringify(formData)
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};

export default ChangePasswordApi;
