import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getAttendanceCorrectionReportsPagination } from "hooks/api/reportsApi.js";
import { ATTENDANCE_CORRECTION_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from "dayjs";
import { disabledFutureDate } from "components/common/validation";
import { formatTime12Hour } from 'components/common/validation';

const initialData = {
  // attendance_correction_from_date: new Date(),
  // attendance_correction_to_date: new Date(),
  attendance_correction_designation: "",
  attendance_correction_department: "",
  attendance_correction_employee_name: "",
}
const AttendanceCorrectionReportView = () => {
  const authtoken = sessionStorage.getItem("token");
  const pageSize = 10;

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";
  const dateFormat = 'YYYY-MM-DD'
  const [dates, setDates] = useState({
    attendance_correction_from_date: dayjs(),
    attendance_correction_to_date: dayjs(),
})
  const parsedFromDate = dates?.attendance_correction_from_date ? dayjs(dates?.attendance_correction_from_date) : null;
  const parsedToDate = dates?.attendance_correction_to_date ? dayjs(dates?.attendance_correction_to_date) : null;
  const [query, setQuery] = useState(`from_date=${parsedFromDate ? parsedFromDate.format(dateFormat) : ""}&to_date=${parsedToDate ? parsedToDate.format(dateFormat) : ""}&designation=${filterDatas.attendance_correction_designation ? filterDatas.attendance_correction_designation : ""}&department=${filterDatas.attendance_correction_department ? filterDatas.attendance_correction_department : ""}&employee=${filterDatas.attendance_correction_employee_name ? filterDatas.attendance_correction_employee_name : ""}`);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getAttendanceCorrectionReportsPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  useEffect(() => {
    const parsedFromDate = dates.attendance_correction_from_date ? dayjs(dates.attendance_correction_from_date) : null;
    const parsedToDate = dates.attendance_correction_to_date ? dayjs(dates.attendance_correction_to_date) : null;
    const newQuery = `from_date=${parsedFromDate ? parsedFromDate.format(dateFormat) : ""}&to_date=${parsedToDate ? parsedToDate.format(dateFormat) : ""}&designation=${filterDatas.attendance_correction_designation ? filterDatas.attendance_correction_designation : ""}&department=${filterDatas.attendance_correction_department ? filterDatas.attendance_correction_department : ""}&employee=${filterDatas.attendance_correction_employee_name ? filterDatas.attendance_correction_employee_name : ""}`;
    setQuery(newQuery); 
  }, [filterDatas, dates]);

  // filter start
  const handleFromDateChange = (value) => {
    setDates((prevState) => ({
      ...prevState,
      attendance_correction_from_date: value,
    }));
  }

  const handleToDateChange = (value) => {
    setDates((prevState) => ({
      ...prevState,
      attendance_correction_to_date: value,
    }));
  }

  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   `from_date=${data.attendance_correction_from_date ? dayjs(data.attendance_correction_from_date).format(dateFormat) : ""}&to_date=${data.attendance_correction_to_date ? dayjs(data.attendance_correction_to_date).format(dateFormat) : ""}&designation=${data.attendance_correction_designation ? data.attendance_correction_designation : ""}&department=${data.attendance_correction_department ? data.attendance_correction_department : ""}&employee=${data.attendance_correction_employee_name ? data.attendance_correction_employee_name : ""}`,
    //   searchText,
    //   sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({...initialData});
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   query,
    //   searchText,
    //   sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  }

  const formatSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };

  const columns = [
    {
      title: "Employee Details",
      dataIndex: "employee__employee_name",
      key: "employee__employee_name",
      width: "230px",
      sorter: true,
      fixed: true,
      render: (_, record) => {
        let empDetails = {
          ...record.employee,
          "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
          "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
          "id": record?.employee.employee_id
        }
        return <EmployeeDetails details={empDetails} />;
      }
    },
    {
      title: "Date",
      dataIndex: "actual_date",
      key: "actual_date",
      sorter: true,
      width: "150px",
      render: (_, record) =>
      <p style={{ color: "black" }}>{record?.actual_date ? record?.actual_date : "-"}</p>,
    },
    {
      title: "Attendance Status",
      dataIndex: "actual_status",
      key: "actual_status",
      sorter: true,
      width: "120px",      
      render: (_, record) =>
        <p className="capitalize" style={{ color: "black" }}>{record?.actual_status ? record?.actual_status : "-"}</p>,
    },
    {
      title: "Check In",
      dataIndex: "actual_check_in",
      key: "actual_check_in",
      sorter: true,
      width: "150px",
      render: (_, record) => <p style={{ color: "black" }}>{(record?.actual_check_in && record?.actual_check_in !== "NaT") ? formatTime12Hour(record?.actual_check_in) : "00:00"}</p>,
      align: 'center',
    },
    {
      title: "Check Out",
      dataIndex: "actual_check_out",
      key: "actual_check_out",
      sorter: true,
      width: "150px",
      render: (_, record) => <p style={{ color: "black" }}>{(record?.actual_check_out && record?.actual_check_out !== "NaT") ? formatTime12Hour(record?.actual_check_out) : "00:00"}</p>,
      align: 'center',
    },
    {
      title: "Break Hours",
      dataIndex: "actual_break_hrs",
      key: "actual_break_hrs",
      sorter: true,
      width: "100px",
      render: (_, record) =>
      <p style={{ color: "black" }}>{(record?.actual_break_hrs &&  record?.actual_break_hrs !== "NaT") ? formatSecondsToTime(record?.actual_break_hrs) : "00:00"}</p>,
    },
    {
      title: "Overtime",
      dataIndex: "actual_overtime",
      key: "actual_overtime",
      sorter: true,
      width: "100px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{(record?.actual_overtime &&  record?.actual_overtime !== "NaT") ? formatSecondsToTime(record?.actual_overtime) : "00:00"}</p>,
    },
    {
      title: "Mod Date",
      dataIndex: "modified_date",
      key: "modified_date",
      sorter: true,
      width: "150px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{record?.modified_date ? record?.modified_date : "-"}</p>,
    },
    {
      title: "Mod Status",
      dataIndex: "modified_status",
      key: "modified_status",
      sorter: true,
      width: "100px",
      render: (_, record) =>
       <p className="capitalize" style={{ color: "black" }}>{record?.modified_status ? record?.modified_status : "-"}</p>,
    },
    {
      title: "Mod Check In",
      dataIndex: "modified_check_in",
      key: "modified_check_in",
      sorter: true,
      width: "150px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{(record?.modified_check_in && record?.modified_check_in !== "NaT") ? formatTime12Hour(record?.modified_check_in) : "00:00"}</p>,
    },
    {
      title: "Mod Check Out",
      dataIndex: "modified_check_out",
      key: "modified_check_out",
      sorter: true,
      width: "150px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{(record?.modified_check_out && record?.modified_check_out !== "NaT") ? formatTime12Hour(record?.modified_check_out) : "00:00"}</p>,
    },
    {
      title: "Mod Break Hours",
      dataIndex: "modified_break_hrs",
      key: "modified_break_hrs",
      sorter: true,
      width: "100px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{(record?.modified_break_hrs && record?.modified_break_hrs !== "NaT")  ? formatSecondsToTime(record?.modified_break_hrs) : "-"}</p>,
    },
    {
      title: "Mod Overtime",
      dataIndex: "modified_overtime",
      key: "modified_overtime",
      sorter: true,
      width: "80px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{(record?.modified_overtime && record?.modified_overtime !== "NaT") ? formatSecondsToTime(record?.modified_overtime) : "-"}</p>,
    },
    {
      title: "Mod Username",
      dataIndex: "modified_user",
      key: "modified_user",
      sorter: true,
      width: "100px",
      render: (_, record) =>
       <p style={{ color: "black" }}>{record?.modified_user ? record?.modified_user : "-"}</p>,
    },
  ];
  const disabledDateFrom = (restrictionDate) => (current) => {
    if (restrictionDate) {
        const startDate = dayjs(restrictionDate, 'DD-MM-YYYY');
        return current && current < startDate.startOf('day');
    }
    return current && current > dayjs().endOf('day');
};

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&from_date=" + (parsedFromDate ? parsedFromDate.format(dateFormat) : null) +
    "&to_date=" + (parsedToDate ? parsedToDate.format(dateFormat) : null) +
    "&designation=" + (filterDatas.attendance_correction_designation ? filterDatas.attendance_correction_designation : "") +
    "&department=" + (filterDatas.attendance_correction_department ? filterDatas.attendance_correction_department : "") +
    "&employee=" + (filterDatas.attendance_correction_employee_name ? filterDatas.attendance_correction_employee_name : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      // border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Attendance Correction"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={ATTENDANCE_CORRECTION_REPORTS} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired = {true} handleDateChange = {handleFromDateChange}
          selectedDate={dates.attendance_correction_from_date} isToDateRequired = {true}
          handleToDateChange = {handleToDateChange} selectedToDate={dates.attendance_correction_to_date}
          fromDateTlt = {"Select From Date"} toDateTlt = {"Select To Date"}
          disabledFromDate = {disabledFutureDate} disabledToDate = {disabledFutureDate && disabledDateFrom(dates.attendance_correction_from_date)}
          // isContentRequired={true} contentToShow={`Date : ${dayjs(filterDatas.attendance_correction_from_date).format('DD MMM YYYY')} - ${dayjs(filterDatas.attendance_correction_to_date).format('DD MMM YYYY')}`}
        />
      </div>
      <div className="relative h-5/6 mt-1">
        {filterVisible ?
          <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
            <ReportsFilter
              pageName={"Attendance Correction"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={"mt-5 w-full"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
          />
        </div>
      </div>
    </Card>
  );
}

export default AttendanceCorrectionReportView
