import React, { useState, useRef } from 'react';
import { Input, Select, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from 'components/ui/Button/MyButton';
import { statusEnum } from 'components/common/enum';

const MasterFilter = ({ pageName, filterDatas, closeFilter, filteredData, clearData }) => {
    const [filterData, setFilterData] = useState({
        employee_type_code: filterDatas.employee_type_code,
        employee_type_name: filterDatas.employee_type_name,
        is_active: filterDatas.is_active,
        department_code: filterDatas.department_code,
        department_name: filterDatas.department_name,
        is_dept_active: filterDatas.is_dept_active,
        designation_code: filterDatas.designation_code,
        designation_name: filterDatas.designation_name,
        designation_is_active: filterDatas.designation_is_active,
        reimbursements_code: filterDatas.reimbursements_code,
        reimbursements_name: filterDatas.reimbursements_name,
        reimbursements_is_active: filterDatas.reimbursements_is_active,
        employee_group_code: filterDatas.employee_group_code,
        employee_group_name: filterDatas.employee_group_name,
        employee_group_is_active: filterDatas.employee_group_is_active,
        asset_type_code: filterDatas.asset_type_code,
        asset_type_name: filterDatas.asset_type_name,
        assettype_is_active: filterDatas.assettype_is_active,
        asset_category_code: filterDatas.asset_category_code,
        asset_category_name: filterDatas.asset_category_name,
        asset_category_is_active: filterDatas.asset_category_is_active,
        leave_policy_code: filterDatas.leave_policy_code,
        leave_policy_name: filterDatas.leave_policy_name,
        leave_expiry: filterDatas.leave_expiry,
        leave_carry_forward: filterDatas.leave_carry_forward,
        leave_policy_is_active: filterDatas.leave_policy_is_active,
        asset_subcategory_code: filterDatas.asset_subcategory_code,
        asset_subcategory_name: filterDatas.asset_subcategory_name,
        asset_category: filterDatas.asset_category,
        asset_subcategory_is_active: filterDatas.asset_subcategory_is_active,
        categoryList: filterDatas.categoryList,
        ProcessTypeList: filterDatas.ProcessTypeList,
        letter_type_name: filterDatas.letter_type_name,
        letter_type_code: filterDatas.letter_type_code,
        process_type_name: filterDatas.process_type_name,
        letter_type_is_active: filterDatas.letter_type_is_active
    });
    const hideCursorRef = useRef({});

    const handleChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFilterData((prevdata) => ({
            ...prevdata,
            employee_type_code: "",
            employee_type_name: "",
            is_active: "",
            department_code: "",
            department_name: "",
            is_dept_active: "",
            designation_code: "",
            designation_name: "",
            designation_is_active: "",
            reimbursements_code: "",
            reimbursements_name: "",
            reimbursements_is_active: "",
            employee_group_code: "",
            employee_group_name: "",
            employee_group_is_active: "",
            asset_type_code: "",
            asset_type_name: "",
            assettype_is_active: "",
            asset_category_code: "",
            asset_category_name: "",
            asset_category_is_active: "",
            leave_policy_code: "",
            leave_policy_name: "",
            leave_expiry: "",
            leave_carry_forward: "",
            leave_policy_is_active: "",
            asset_subcategory_code: "",
            asset_subcategory_name: "",
            asset_category: "",
            asset_subcategory_is_active: "",
            process_type_name: "",
            letter_type_name: "",
            letter_type_code: "",
            process_type_name: "",
            letter_type_is_active: ""
        }))
        clearData();
    }

    const renderInput = (label, statekey) => {
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <Input
                autoComplete='off'
                className="fltTextField"
                type="text"
                name={statekey}
                value={filterData[statekey] ? filterData[statekey] : ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            </div>   
        )
    }

    const renderSelect = (label, statekey, list = statusEnum, showKey = "text", valueKey = "value") => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <Select
                className="fltTextField"
                value={filterData[statekey] ? filterData[statekey] : ""}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                ref={hideCursorRef.current[statekey]}
                onSelect={() => {
                    hideCursorRef.current[statekey].current.blur();
                }}
                >
                    {list.map((option) => (
                        (statekey === "asset_category" && option.is_active === true) || statekey !== "asset_category" ?
                            <Select.Option key={option[valueKey]} value={option[valueKey]}>{option[showKey]}</Select.Option>
                            : null
                    ))}
            </Select>
            </div>
        )
    }

    return (
        <div className='h-full w-full'>
            <div className="flex justify-between items-center filterTltDiv">
                <p className="common_addTltTxt">{"Filter"}</p>
                <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter} /></Tooltip>
            </div>
            <div className="flex flex-col items-center fltContentDiv" >
                {pageName === "EmployeeType" ?
                    <>
                        {renderInput("Employee Code", "employee_type_code")}
                        {renderInput("Employee Type", "employee_type_name")}
                        {renderSelect("Status", "is_active")}
                    </>
                    : pageName === "Department" ? <>
                        {renderInput("Department Code", "department_code")}
                        {renderInput("Department Name", "department_name")}
                        {renderSelect("Status", "is_dept_active")}
                    </>
                    : pageName === "Designation" ? <>
                        {renderInput("Designation Code", "designation_code")}
                        {renderInput("Designation Name", "designation_name")}
                        {renderSelect("Status", "designation_is_active")}
                    </>
                    : pageName === "Reimbursements" ? <>
                        {renderInput("Reimbursements Code", "reimbursements_code")}
                        {renderInput("Reimbursements Name", "reimbursements_name")}
                        {renderSelect("Status", "reimbursements_is_active")}
                    </>
                    : pageName === "EmployeeGroup" ? <>
                        {renderInput("Employee Group Code", "employee_group_code")}
                        {renderInput("Employee Group Name", "employee_group_name")}
                        {renderSelect("Status", "employee_group_is_active")}
                    </>
                    : pageName === "AssetType" ? <>
                        {renderInput("Asset Type Code", "asset_type_code")}
                        {renderInput("Asset Type Name", "asset_type_name")}
                        {renderSelect("Status", "assettype_is_active")}
                    </>
                    : pageName === "AssetCategory" ? <>
                        {renderInput("Asset Category Code", "asset_category_code")}
                        {renderInput("Category Name", "asset_category_name")}
                        {renderSelect("Status", "asset_category_is_active")}
                    </>
                    : pageName === "LeaveType" ? <>
                        {renderInput(" Leave Policy Code", "leave_policy_code")}
                        {renderInput("Leave Policy Name", "leave_policy_name")}
                        {/* {renderSelect("Leave Expiry", "leave_expiry", leavePolicyExpiryEnum)} */}
                        {renderSelect("Status", "leave_policy_is_active")}
                    </>
                    : pageName === "AssetSubCategory" ? <>
                        {renderInput("Asset Sub Category Code", "asset_subcategory_code")}
                        {renderInput("Sub Category Name", "asset_subcategory_name")}
                        {renderSelect("Asset Category", "asset_category", filterData.categoryList, "category_name", "id")}
                        {renderSelect("Status", "asset_subcategory_is_active")}
                    </>
                    : pageName === "LetterType" ? <>
                        {renderInput("Letter Type Code", "letter_type_code")}
                        {renderInput("Letter Type Name", "letter_type_name")}
                        {renderSelect("Process Type Name", "process_type_name", filterData.ProcessTypeList, "text", "value")}
                        {renderSelect("Status", "letter_type_is_active")}
                    </>
                    : null}
            </div>
            <div className='flex justify-center items-end'>
                <MyButton label="Clear" onClick={handleClear} outlined = {true} paddingX={"0 0.7vw"} marginRight={"0.625vw"} />
                <MyButton label={"Apply"} onClick={() => filteredData(filterData)} paddingX={"0 0.5vw"} />
            </div>
        </div>
    )
}

export default MasterFilter;

