import { USER_VALIDATION } from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const isUserExists = async (user,query) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    let url = USER_VALIDATION;
    if (query==='user') {
        url = prefillUrl(url);
        url = `${url}user_name=${user}`;
    }
    else if (query==='email') {
        url = prefillUrl(url);
        url = `${url}email=${user}`;
    }
    if (query==='mobile') {
        url = prefillUrl(url);
        url = `${url}mobile=${user}`;
    }

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}