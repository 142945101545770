import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip, Space } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getMusterRollReports } from "hooks/api/reportsApi.js";
import { MUSTER_ROLL_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from 'dayjs';

const initialData = {
  muster_month_year: new Date(),
  muster_designation: "",
  muster_department: "",
  muster_employee_name: "",
}

const statusConfig = {
  "present": { label: "P", bgColor: "#28A745" },
  "absent": { label: "A", bgColor: "#A72828" },
  "holiday": { label: "HD", bgColor: "#EEBA35" },
  "on-duty": { label: "OD", bgColor: "#3595EE" },
  "week-off": { label: "WO", bgColor: "#F9827A" },
  "compensation": { label: "Comp", bgColor: "#FEB8C3" },
  "WFH": { label: "WFH", bgColor: "#8DD6F5"},
  "leave": { label: "L", bgColor: "#CCA6FC"},
  "N/A": { label: "N/A", bgColor: "#b4b1b1" },
};

function MusterRoll() {
  const authtoken = sessionStorage.getItem("token");
  const pageSize = 10;

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const parsedDate = selectedDate ? dayjs(selectedDate) : null;
  const [query, setQuery] = useState(`month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.muster_designation ? filterDatas.muster_designation : ""}&department=${filterDatas.muster_department ? filterDatas.muster_department : ""}&employee=${filterDatas.muster_employee_name ? filterDatas.muster_employee_name : ""}`);
  const tableContainerHeight = "72vh";

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    const parsedDate = selectedDate ? dayjs(selectedDate) : null;
    const newQuery = `month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.muster_designation ? filterDatas.muster_designation : ""}&department=${filterDatas.muster_department ? filterDatas.muster_department : ""}&employee=${filterDatas.muster_employee_name ? filterDatas.muster_employee_name : ""}`;
    setQuery(newQuery); 
  }, [filterDatas]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getMusterRollReports, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  useEffect(() => {
    let parsedDate;
    if (dayjs(filterDatas.muster_month_year, 'MMMM YYYY', true).isValid()) {
      parsedDate = dayjs(filterDatas.muster_month_year, 'MMMM YYYY');
    } else {
      parsedDate = dayjs(filterDatas.muster_month_year);
    }
    
    // Calculate the number of days in the specified month
    const daysInMonth = parsedDate.daysInMonth();
    const newColumns = [];

    // Generate dynamic columns for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const columnDate = parsedDate.date(day).format('YYYY-MM-DD');
      const columnConfig = {
        title: dayjs(columnDate).format('DD'),
        width: 80,
        align: "center",
        dataIndex: columnDate,
        key: columnDate.toString(),
        render: (text, record) => {
          // Extract attendance data for the specific day
          const attendanceData = record.attendance_dict.find(item => item.date === columnDate);
          const status = attendanceData ? attendanceData.status : 'N/A';
          const statusInfo = status ? statusConfig[status] : statusConfig['N/A'];
          const lateMinute = attendanceData ? attendanceData.LM : '';
          return (<div>
           <p className="musterStatusTxt">{statusInfo?.label}</p>
           <p className="musterStatusTxt" style={{color: "#999292"}}>{lateMinute ? `${lateMinute} LM` : ""}</p>
          </div>)
        },
      };
      newColumns.push(columnConfig);
    }

    // Update the state with the new columns
    setDynamicColumns(newColumns);
  }, [filterDatas.muster_month_year, data]);

  // filter start
  const renderFilter = (data) => {
    // const parsedDate = data.muster_month_year ? dayjs(data.muster_month_year) : null;
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   `month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${data.muster_designation ? data.muster_designation : ""}&department=${data.muster_department ? data.muster_department : ""}&employee=${data.muster_employee_name ? data.muster_employee_name : ""}`,
    //   searchText,
    //   sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({...initialData});
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   query,
    //   searchText,
    //   sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "Employee Details",
      dataIndex: "employee__employee_name",
      key: "employee__employee_name",
      width: 200,
      sorter: true,
      fixed: 'left',
      render: (_, record) => {
        let {employee} = record;
        let empDetails = {
          ...employee,
          "first_name": employee?.employee_name ? employee?.employee_name : "",
          "profile": employee?.profile ? employee?.profile : "",
          "designation_id": employee?.designation?.name ?
            { id: employee?.designation?.id, designation_name: employee?.designation?.name } : { id: 0, designation_name: "" },
          "id": employee?.employee_id ? employee?.employee_id : ""
        }
        return <EmployeeDetails details={empDetails} />;
      }
    },
    {
      title: "Total Working days",
      dataIndex: "total_working_days",
      key: "total_working_days",
      sorter: true,
      fixed: "left",
      width: 150,
      align: "center",
    },
    {
      title: "Present",
      dataIndex: "present",
      key: "present",
      sorter: true,
      fixed: "left",
      width: 80,
      align: "center",
    },
    {
      title: "Absent",
      dataIndex: "absent",
      key: "absent",
      sorter: true,
      fixed: "left",
      width: 80,
      align: "center",
    },
    ...dynamicColumns
  ];

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&month=" + (parsedDate ? parsedDate.month() + 1 : "") +
    "&year=" + (parsedDate ? parsedDate.year() : "") +
    "&designation=" + (filterDatas.muster_designation ? filterDatas.muster_designation : "") +
    "&department=" + (filterDatas.muster_department ? filterDatas.muster_department : "") +
    "&employee=" + (filterDatas.muster_employee_name ? filterDatas.muster_employee_name : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

   // Date changes start - Filter the data based on the selected date
   const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilterDatas({ ...filterDatas, muster_month_year: date });
    setDataUpdated(!dataUpdated);
  };// Date changes end

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Muster Roll"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={MUSTER_ROLL_REPORTS} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
          format="MMM YYYY" picker="month"
          isExcelRequired={false} isPrintRequired={false}
        />
      </div>
      <div className="relative h-5/6 mt-1">
        {filterVisible ?
          <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
            <ReportsFilter
              pageName={"Muster Roll"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={"mt-5 w-full"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ x: 3500, y: `calc(${tableContainerHeight} - 140px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
          />
        </div>
      </div>
    </Card>
  );
}

export default MusterRoll;