import React from "react";
import { Button } from "antd";

const MySecondaryLargeButton = ({
  label = "",
  onClick = () => {},
  className = "",
  icon = "",
  type = "primary",
  htmlType = "",
  loading = "",
  disabled = false,
}) => (
  <Button block
    disabled={disabled}
    style={{
      paddingLeft: 10,
      paddingRight: 7,
      background: "#04B7B1",
      borderColor: "#04B7B1",
      borderRadius: 5,
      boxShadow: "0",
      height: "2.5vw",
    }}
    htmlType={htmlType}
    icon={icon}
    type={type}
    className={className}
    onClick={onClick}
    loading={loading}
  >
    {label}
  </Button>
);

export default MySecondaryLargeButton;
