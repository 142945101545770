import { approvalTypeEnum } from "components/common/enum";
import MyButton from "components/ui/Button/MyButton";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Card, Checkbox, Select, Button, Tooltip } from 'antd';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { areValuesNotEmptyExceptKeys } from 'components/common/validation';
import { APPROVAL_HIERARCHY } from "constants";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { getAllApprovalHierarchy } from "hooks/api/configurationApi/approvalHierarchyApi";

const AddEditApprovalHierarch = () => {
    const authtoken = sessionStorage.getItem("token");
    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const [formData, setFormData] = useState({
        approval_type_name: "",
        approval_department: "",
        level: [{
            no_of_levels: "Level 1",
            department: "",
            approval_employee: ""
        }],
        is_active: true,
        removed_levels: [],
    });
    const [loading, setLoading] = useState(false);
    const [isCancelClk, setIsCancelClk] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const { fromEdit, approval_type_id, isActive } = location.state || {};
    const [form] = Form.useForm();

    const get_employee_deparment = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDepartmentList([...apiData.data])
            } else {
                setDepartmentList([])
            }
        } catch (error) {
            setDepartmentList([])
        }
    }

    const getEmployeeList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setEmployeeList([...apiData.data])
            } else {
                setEmployeeList([])
            }
        } catch (error) {
            setEmployeeList([])
        }
    }

    const getApprovalDetails = async (id) => {
        try {
            const apiData = await getAllApprovalHierarchy(`id=${id}`);
            if (apiData && apiData.status === "success" && apiData.data && apiData.data.result.length > 0) {
                assignEditDetails({ ...apiData.data.result[0] });
            }
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        if (approval_type_id && fromEdit) {
            getApprovalDetails(approval_type_id);
        }
    }, [approval_type_id])
    
    useEffect(() => {
        get_employee_deparment();
        getEmployeeList();
    }, []);

    const assignEditDetails = (datas) => {
        if (datas && Object.keys(datas).length > 0) {
            let approvalList = datas.level.length > 0 ? datas.level.map(item => ({
                id: item.id,
                no_of_levels: item.no_of_levels,
                department: item.department_id,
                approval_employee: item.approval_employee_id,
            })) : [{
                no_of_levels: "Level 1",
                department: "",
                approval_employee: "",
            }]

            setFormData((prevFormData) => ({
                ...prevFormData,
                approval_type_id: datas.approval_type_id,
                approval_type_name: datas.approval_type_name ? datas.approval_type_name : "",
                approval_department: datas.approval_department_id ? datas.approval_department_id : "",
                level: approvalList,
                is_active: datas.is_active,
            }));
            form.setFieldsValue({
                approval_type_name: datas.approval_type_name ? datas.approval_type_name : "",
                approval_department: datas.approval_department_id ? datas.approval_department_id : "",
                level: approvalList,
                is_active: datas.is_active,
            });
        }
    }

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        form.setFieldsValue({
            ...formData,
            [name]: value,
        });
    };

    const handleApprovalChange = (name, index, value) => {
        let newItems = [...formData.level];
        let errors = [];
        if(name === "department"){
            newItems[index]["department"] = value;
            newItems[index]["approval_employee"] = "";
            errors.push({
                name: ['level', index, 'department'],
                value: value,
                errors: undefined,
            }, {
                name: ['level', index, 'approval_employee'],
                value: "",
                errors: undefined,
            });
        }
        newItems[index][name] = value;
        errors.push({
            name: ['level', index, name],
            value: value,
            errors: undefined,
        });
        setFormData({
            ...formData,
            level: newItems,
        });
        form.setFields(errors);
    };

    const renderInput = (label, index, statekey, testID = '', maxLength = 150) => {
        return (
            <Input
                autoComplete='off'
                style={styles.textField}
                size='large'
                type={"text"}
                placeholder={label}
                name={statekey}
                readOnly= {statekey === "no_of_levels" ? true : false}
                value={formData["level"][index][statekey]}
                maxLength={maxLength}
                onChange={(e) => handleApprovalChange(e.target.name, index, e.target.value)}
                data-testid={testID}
            />
        )
    }

    const renderSelect = (label, statekey, testID = '', list, showKey = "text", valueKey = "value",required = false) => {
        return (
            <>
            <div id='common_weekoff_valTxt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
            <Select
                showSearch
                style={statekey === "deduct_from" ? styles.percentageField : styles.textField}
                value={formData[statekey] ? formData[statekey] : ""}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                data-testid={testID}
            >
                {list.map((option) => (
                    ((statekey === "approval_department") && (option.id === formData.approval_department || option.is_active === true)
                        || (statekey !== "approval_department")) ?
                    <Select.Option key={option[valueKey]} value={option[valueKey]}>{option[showKey]}</Select.Option>
                    : null
                ))}
            </Select>
            </>
        )
    }

    const checkForDuplicates = (levelArray) => {
        let msg = ""
        const encounteredIds = new Set();
        for (const item of levelArray) {
            const { approval_employee } = item;
            if (encounteredIds.has(approval_employee)) {
                msg = "Same Approval Employee Found";
            }
            encounteredIds.add(approval_employee);
        }
        return msg;
    };

    const handleApprovalAdd = () => {
        const allPreviousLevelsFilled = formData.level.every((item) => {
            return item.no_of_levels && item.department && item.approval_employee;
        });
        let isDuplicate = checkForDuplicates(formData.level);
        if (allPreviousLevelsFilled && isDuplicate === "") {
            const newItems = [...formData.level, {
                no_of_levels: `Level ${formData?.level?.length+1}`,
                department: "",
                approval_employee: ""
            }];
            setFormData({
                ...formData,
                level: newItems,
            });
        } else {
            ToastMsg("error", isDuplicate ? isDuplicate : "Please fill all previous levels before adding a new one.");
        }
    };   

    const handleApprovalRemove = (index ,items) => {
        if (index >= 0 && index < formData.level.length) {
            const newItems = [...formData.level];
            let deletedIds = [...formData.removed_levels];
            newItems.splice(index, 1);
            newItems.forEach((item, i) => {
                item.no_of_levels = `Level ${i+1}`
            })
            if(fromEdit && items?.id){
                deletedIds.push(items.id);
            }
            setFormData({
                ...formData,
                "level": newItems,
                'removed_levels': deletedIds
            });
        }
    };  

    const handleBackCancel = useCallback(() => {
        form.resetFields();
        navigate('/configuration/approvalhierarchy');
    }, [navigate, form])

    const handleCancelBtn = () => {
        setIsCancelClk(false);
        handleBackCancel();
    }

    const handleSubmit = useCallback((isDraftClked) => {
        let methods = fromEdit ? "PUT" : "POST";
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoading(true)
            let updatedFormData = { ...formData };
            updatedFormData = { ...updatedFormData, is_draft: isDraftClked }
            if(!fromEdit){
            delete updatedFormData["removed_levels"];
            }
            fetch(APPROVAL_HIERARCHY, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoading(false);
                        setFormData({
                            approval_type_name: "",
                            approval_department: "",
                            level: [{
                                no_of_levels: "Level 1",
                                department: "",
                                approval_employee: ""
                            }],
                            is_active: true,
                        })
                    }
                    else if (data.status === "fail") {
                        setLoading(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            setIsCancelClk(false);
        }
    }, [formData, fromEdit, handleBackCancel]);

    const checkAllMandatory = useCallback((isDraftClked) => {
        let isDuplicate = checkForDuplicates(formData.level);
        if (isDuplicate !== "") {
            ToastMsg("error", isDuplicate);
            return;
        }
    
        if (!isDraftClked) {
          form
            .validateFields()
            .then(() => {
              handleSubmit(false);
            })
            .catch(error => {
              console.error("Validation Error:", error);
            });
        } else {
          handleSubmit(true);
        }
      }, [form, formData, handleSubmit]);

    const handleSave = useCallback(() => {
        checkAllMandatory(false);
    }, [checkAllMandatory]);

    const handleSaveAsDraft = useCallback(() => {
        checkAllMandatory(true);
    }, [checkAllMandatory]);

    const keysToExclude = ['is_active', 'level'];
    const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

    return (
        <Card className='h-full'>
            <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt'>{fromEdit ? "Edit Approval Hierarchy" : "Add Approval Hierarchy"}</p>
                </div>
                <Checkbox
                    disabled = {fromEdit ? !isActive : true }
                    checked={formData.is_active}
                    onChange={(e) => handleChange("is_active", e.target.checked)}
                    data-testid={'ah_sts'}
                >
                    Active
                </Checkbox>
            </div>
            <div className='h-5/6' style={{ margin: "16px 0px" }}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    initialValues={formData}
                    onFinish={handleSave}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid={'ah_form'}
                >
                    <div className='w-full grid grid-cols-3 gap-x-3 gap-y-2 m-1'>
                        <Form.Item
                            style={styles.formItem}
                            name="approval_type_name"
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            {renderSelect('Approval Type', "approval_type_name", 'ah_type', approvalTypeEnum, "text", "value", true)}
                        </Form.Item>
                        <Form.Item
                            style={styles.formItem}
                            name="approval_department"
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            {renderSelect('Select Department', "approval_department", 'ah_dep', departmentList, "department_name", "id", true)}
                        </Form.Item>
                        <Form.Item style={styles.emptyFormItem} />
                    </div>
                    <p className="common_DayWiseSubTxt" style ={{marginLeft : "2.3vw"}}>Approval Hierarchy</p>

                    <div className='w-full' style={{ margin: "16px", height: "22vw", overflowY: "auto", overflowX: "hidden" }}>
                        {formData.level.map((item, index) => (
                            <div key={index} className='grid grid-cols-3 gap-x-5 gap-y-2' style={{margin: "0.625vw 1.5vw"}}>
                                <Form.Item name={["level", index, "no_of_levels"]}>
                                    <p id="common_weekoff_valTxt">Priority Level</p>
                                    {renderInput("", index, "no_of_levels", 'ah_level', 50)}
                                </Form.Item>
                                <Form.Item
                                    name={["level", index, "department"]}
                                    rules={[{ required: true, message: "This field is required" }]}
                                >   
                                    <p id="common_weekoff_valTxt">Select the Approvers Department <span className='requiredTxt'>*</span></p>
                                    <Select
                                        name="department"
                                        value={item.department}
                                        onChange={(value) => handleApprovalChange("department", index, value)}
                                        allowClear
                                        style={styles.textField}
                                        data-testid={'ah_dep'}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {departmentList &&
                                            departmentList.map((items) => (
                                                (items.id === item.department || items.is_active) ?
                                                <Select.Option key={items.id} value={items.id}>
                                                    {items.department_name}
                                                </Select.Option>
                                                : null
                                            ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name={["level", index, "approval_employee"]}
                                    rules={[{ required: true, message: "This field is required" }]}
                                >
                                    <p id="common_weekoff_valTxt">Select The Approval Employee <span className='requiredTxt'>*</span></p>
                                    <Select
                                        name="approval_employee"
                                        value={item.approval_employee}
                                        onChange={(value) => handleApprovalChange("approval_employee", index, value)}
                                        allowClear
                                        style={styles.textField}
                                        data-testid={'ah_emp'}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {employeeList && employeeList.map((employee, index) => {
                                            let { department_id, id, first_name, last_name, employee_id, is_active } = employee || {};
                                            let isDepartMatch = (department_id && department_id[0] && department_id[0].id) === item.department;
                                            if (index === 0 || (isDepartMatch && (id === item.approval_employee || is_active))) {
                                                const displayText = `${first_name ? first_name : ""} ${last_name ? last_name : ""} ${employee_id ? "-" + employee_id : ""}`;
                                                return (
                                                    <Select.Option key={id} value={id}>
                                                        {displayText}
                                                    </Select.Option>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Select>
                                </Form.Item>
                                {index > 0 && (
                                    <div className="grid col-span-3 place-content-end mr-5">
                                        <Button
                                            onClick={() => handleApprovalRemove(index, item)}
                                            icon={<MinusCircleOutlined />}
                                            style={{ border: 0 }}
                                            size="large"
                                            data-testid={'ah_remove'}
                                        ></Button>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="flex flex-row justify-start ml-2">
                            <Button
                                onClick={handleApprovalAdd}
                                icon={<PlusCircleOutlined />}
                                style={{ border: 0 }}
                                size="large"
                                data-testid={'ah_add'}
                            ></Button>
                        </div>
                    </div>
                    <div className='w-full relative inset-y-10'>
                        <Form.Item >
                            <div className='flex justify-end items-end mr-2'>
                                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="ah_cancel" />
                                {fromEdit ? null
                                    : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="ah_draft" />}
                                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="ah_save" />
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
            {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID='ah' /> : null}
        </Card>
    );
};

const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "1vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    },
    percentageField: {
        width: "10.5vw"
    },
}
export default AddEditApprovalHierarch
