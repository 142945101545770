import { INDIVIDUAL_ROSTERING_GET, ROSTERING_GET } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getRosterPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = ROSTERING_GET;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const getRosterDetailsById = async (query) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(`${INDIVIDUAL_ROSTERING_GET}?${query}`, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const deleteRosterById = async (id) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = ROSTERING_GET;

    const formData = {
      roster_id: id,
      is_active: false
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${authtoken}`
      },
      body: JSON.stringify(formData),
    };
    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};
