import React from "react";

const MyHeader = ({
  title,
  className = "",
}) => (
  <h1 className={className} >
    {title}
  </h1>
);

export default MyHeader;
