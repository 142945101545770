import React, { useState, useEffect, useCallback, useRef } from 'react';
import { leavePolicyExpiryEnum } from 'components/common/enum';
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Select, Card, Checkbox, Tooltip } from 'antd'
import { EMPLOYEE_LEAVE_POLICY } from 'constants';
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys, validateRange } from 'components/common/validation';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";

export const AddEmployeeLeavePolicy = () => {
  const companyid = sessionStorage.getItem("userId")

  const [formData, setFormData] = useState({
    code: '',
    leave_name: '',
    no_of_days: 0,
    notes: '',
    leave_expiry: '',
    leave_carry_forward: false,
    is_active: true,
    company_id: companyid
  });
  const [loadings, setLoadings] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        code: editDetails.code ? editDetails.code : "",
        leave_name: editDetails.leave_name ? editDetails.leave_name : "",
        no_of_days: editDetails.no_of_days ? editDetails.no_of_days : "",
        notes: editDetails.notes ? editDetails.notes : "",
        leave_expiry: editDetails.leave_expiry ? editDetails.leave_expiry : "",
        leave_carry_forward: editDetails.leave_carry_forward ? editDetails.leave_carry_forward : false,
        is_active: editDetails.is_active,
        company_id: editDetails.company_id
      }));
      form.setFieldsValue({
        code: editDetails.code ? editDetails.code : "",
        leave_name: editDetails.leave_name ? editDetails.leave_name : "",
        no_of_days: editDetails.no_of_days ? editDetails.no_of_days : "",
        notes: editDetails.notes ? editDetails.notes : "",
        leave_expiry: editDetails.leave_expiry ? editDetails.leave_expiry : "",
        leave_carry_forward: editDetails.leave_carry_forward ? editDetails.leave_carry_forward : false,
        is_active: editDetails.is_active,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const handleChange = (name, value) => {
    switch (name) {
      case "no_of_days":
        if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 30)) {
          setFormData({
            ...formData,
            no_of_days: value,
          });
          form.setFieldsValue({
            ...formData,
            no_of_days: value,
          });
        }
        break;
      default:
        setFormData({
          ...formData,
          [name]: value,
        });
        form.setFieldsValue({
          ...formData,
          [name]: value,
        });
        break;
    }
  };

  const handleBackCancel = useCallback(() => {
    navigate('/master/leavetype');
  }, [navigate])

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    handleBackCancel();
  }

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoadings(true)
      let updatedFormData = { ...formData };
      updatedFormData = {
        ...updatedFormData,
        no_of_days: updatedFormData?.no_of_days ? +updatedFormData.no_of_days : 0,
        leave_expiry: updatedFormData?.leave_expiry ? +updatedFormData.leave_expiry : null,
        is_draft: isDraftClked ? true : false
      };
      fetch(EMPLOYEE_LEAVE_POLICY, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoadings(false);
            setFormData({
              code: '',
              leave_name: '',
              no_of_days: 0,
              notes: '',
              leave_expiry: '',
              leave_carry_forward: false,
              is_active: true,
              company_id: companyid
            })
          }
          else if (data.status === "fail") {
            setLoadings(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoadings(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  }, [formData, fromEdit, companyid, handleBackCancel]);

  const handleKeyDown = (e) => {
    // Allow only numbers, backspace, and delete keys
    if (!/^[\d.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
      e.preventDefault();
    }  
  };

  const renderInput = (statekey, maxLength = 150) => {
    return (
      <Input
        autoComplete='off'
        style={styles.textField}
        disabled = {statekey === "code" || (statekey === "leave_name" && formData.leave_name === "Work From Home")}
        type={"text"}
        name={statekey}
        value={formData[statekey] ? formData[statekey] : ""}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        onKeyDown={statekey === "no_of_days" ? handleKeyDown : null}
      />
    )
  }

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active', 'company_id'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Leave Type" : "Add Leave Type"}</p>
        </div>
        <Checkbox
          disabled = {fromEdit ? !isActive : true }
          checked={formData.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          onFinish={handleSave}
          initialValues={formData}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className='w-full flex justify-between items-center m-2'>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              <div className='commonInputTlt'>Leave Policy Code <span className='requiredTxt'>*</span></div>
              {renderInput("code", 10)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="leave_name"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              <div className='commonInputTlt'>Leave Policy Name <span className='requiredTxt'>*</span></div>
              {renderInput("leave_name", 50)}
            </Form.Item>

            <Form.Item
              style={styles.formItem}
              name="no_of_days"
              rules={[{
                required: true,
                message: "This field is required"
              },
              { validator: validateRange(1, 30)}]}
            >
              <div className='commonInputTlt'>No of Days <span className='requiredTxt'>*</span></div>
              {renderInput("no_of_days", 2)}
            </Form.Item>
            {!fromEdit ? <Form.Item name="notes" style={styles.formItem}>
              <div className='commonInputTlt'>Notes</div>
              {renderInput("notes", 150)}
            </Form.Item> : null}
          </div>
          <div className='w-full flex justify-between items-center m-2'>
            {fromEdit ? <Form.Item name="notes" style={styles.formItem}>
            <div className='commonInputTlt'>Notes</div>
              {renderInput("notes", 150)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="leave_expiry"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Leave Expiry <span className='requiredTxt'>*</span></div>
              <Select
                style={styles.textField}
                value={formData.leave_expiry}
                name={"leave_expiry"}
                onChange={(value) => handleChange("leave_expiry", value)}
              >
                {leavePolicyExpiryEnum.map((option) => (
                  <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={styles.emptyFormItem} />
          </div>
          <div className='w-full flex justify-between items-center m-2'>
            <Form.Item name="leave_carry_forward" style={styles.formItem}>
              <Checkbox
                style={styles.textField}
                checked={formData.leave_carry_forward}
                onChange={(e) => handleChange("leave_carry_forward", e.target.checked)}
              >
                Leave Carry Forward
              </Checkbox>
            </Form.Item>
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                {fromEdit ? null
                  : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />}
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} /> : null}
    </Card>
  );
}

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default AddEmployeeLeavePolicy
