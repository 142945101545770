import React, { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Table, Tooltip } from 'antd';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import useGetData from "components/common/useGetData";
import { getAttendanceLogchart } from "hooks/api/attendanceApi/dayWiseAttendanceApi";
import StackedBarChart from './StatckedBarChart';
import dayjs from "dayjs";
import { EmployeeDetails } from "components/common/CommonComponent";

const AttendanceLogChart = () => {
    const authtoken = sessionStorage.getItem("token");
    const tableContainerHeight = "77.5vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const navigate = useNavigate();
    const location = useLocation();
    const { employeeId } = location.state || {};

    const [data, _message, loading] = useGetData(getAttendanceLogchart, { id: employeeId });
    let { attendance, employee_details } = data || {};

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    const handleBackCancel = useCallback(() => {
        navigate('/attendance/attendancelog');
    }, [navigate]);

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            sorter: true,
            width: "11%",
            render: (_, record) => (<span>{record?.date ? dayjs(record.date, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}</span>)
        },
        {
            title: "",
            dataIndex: "graph",
            key: "graph",
            width: "65%",
            render: (_, record) => (<StackedBarChart
                list={record?.log?.length > 0 ? record?.log : []}
                shift_check_in={record?.shift_check_in ? record?.shift_check_in : null}
                shift_check_out={record?.shift_check_out ? record?.shift_check_out : null}
            />)
        },
        {
            title: "Arrival",
            dataIndex: "arrival",
            key: "arrival",
            width: "12%",
            render: (_, record) => (<p>{record?.arrival ? record?.arrival : "-"}</p>)
        },
        {
            title: "Total Duration",
            key: "working_hrs",
            dataIndex: "working_hrs",
            width: "12%",
            render: (_, record) => (<p>{record?.working_hrs ? record?.working_hrs : "-"}</p>)
        },
    ];

    let empDetails = {
        ...employee_details,
        title: employee_details?.title ? employee_details?.title : "",
        first_name: employee_details?.employee_name ? employee_details?.employee_name : "",
        last_name: "",
        designation_id: {
            id: employee_details?.designation?.id ? employee_details?.designation?.id : "",
            designation_name: employee_details?.designation?.name ? employee_details?.designation?.name : ""
        },
        id: employee_details?.employee_id,
        profile: employee_details?.profile,
    }

    const styles = {
        fltContainer: {
          border: "1px solid #cbcbcb",
          height: tableContainerHeight,
        },
      }

    return (
        <Card className='h-full overflow-hidden'>
            <div className='h-1/6 flex justify-between items-center p-1' >
                <div className='flex items-center'>
                    <Tooltip title={"Back to log"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <EmployeeDetails details={empDetails} />
                </div>
                <div className='grid grid-cols-4 gap-3'>
                    <div className='flex items-center'>
                        <div className='dotBtn' style={{ backgroundColor: "#D94853" }}></div>
                        <span>Late</span>
                    </div>
                    <div className='flex items-center'>
                        <div className='dotBtn' style={{ backgroundColor: "#0E862A" }}></div>
                        <span>On-Time</span>
                    </div>
                    <div className='flex items-center'>
                        <div className='dotBtn' style={{ backgroundColor: "#285BA7" }}></div>
                        <span>Over Time</span>
                    </div>
                    <div className='flex items-center'>
                        <div className='dotBtn' style={{ backgroundColor: "#CACACA" }}></div>
                        <span>Break Hours</span>
                    </div>
                </div>
            </div>
            <div className='h-5/6' style={{ margin: "1vw 0vw" }}>
                <div className={"mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={columns}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    dataSource={attendance?.length > 0 ? attendance : null}
                    loading={loading}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        showSizeChanger: false,
                        showQuickJumper: true,
                    }}
                />
                </div>
            </div>
        </Card>
    )
}

export default AttendanceLogChart;