import { LOGIN_URL } from 'constants';


const login = async (username, password) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "username": username, "password": password })
    };

    return await fetch(LOGIN_URL, requestOptions).then((response) => response.json()).then(data=>data);
    
}

export { login }