import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd';
import useGetEmployer from 'pages/admin/organization/useGetEmployer';
import MyButton from "components/ui/Button/MyButton";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from 'utils/ImagePath';
import { DownloadOutlined } from "@ant-design/icons";
import { downloadPDF } from "components/common/CommonFuntion";
import { useNavigate } from 'react-router-dom';
import {checkUserPermissionFunc} from "components/common/CommonFuntion";
import ToastMsg from "components/common/ToastMsg";

const CompanyProfilePage = () => {
  const authtoken = sessionStorage.getItem("token")
  useEffect(() => {
    if (!authtoken) {
      window.location.href = '/';
    }
  }, [authtoken]);

  const [data, _message, loading] = useGetEmployer();
  const navigate = useNavigate();

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const userPermission = checkUserPermissionFunc("administrator", "Organization", "SubMenu");

  const handleEdit = (details) => {
    navigate('/admin/companyprofile/edit', { state: { fromEdit: true, editDetails: details } });
  }
  return (
    <Card className="overflow-x-hidden overflow-y-auto h-full relative">
      {loading && (
        <div className="loaderOverlay">
          <Spin />
        </div>
      )}
      <div className='flex justify-between items-center border-b pb-3' >
        <p id="common_admin_orgTxt">Organization Information</p>
        <MyButton disabled = { userPermission?.edit ? false : true} onClick={() => handleEdit(data)} label={"Edit"} />
      </div>
      <div className='grid grid-cols-12' style={{ height: "90%", marginTop: "0.625vw" }}>
        <div className="col-span-9 h-full">
          <div class="grid grid-cols-3 gap-5 mb-5 mt-5 border-b pb-4">
            <div>
              <p id="common_admin_orgTlt">Organization Name</p>
              <p id="common_admin_orgTxt">{data?.company_name ? data?.company_name : "-"}</p>
            </div>
            <div >
              <p id="common_admin_orgTlt">Business Type</p>
              <p id="common_admin_orgTxt">{data?.business_type ? data?.business_type : "-"}</p>
            </div>
            <div >
              <p id="common_admin_orgTlt">Registration Number </p>
              <p id="common_admin_orgTxt">{data?.registration_number ? data?.registration_number : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt">PAN No </p>
              <p id="common_admin_orgTxt">{data?.pan_no ? data?.pan_no : "-"}</p>
            </div>
            <div className='col-span-2'>
              <p id="common_admin_orgTlt">GSTIN</p>
              <p id="common_admin_orgTxt">{data?.gst_number ? data?.gst_number : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt">Official Email ID </p>
              <p id="common_admin_orgTxt">
                {data?.official_email_address ? data?.official_email_address : "-"}
              </p>
            </div>
            <div >
              <p id="common_admin_orgTlt">Landline Number</p>
              <p id="common_admin_orgTxt">{data?.landline ? data?.landline : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt">Website </p>
              <p id="common_admin_orgTxt">{data?.website ? data?.website : "-"}</p>
            </div>
          </div>
          <p id="common_admin_orgTxt">Organization Address</p>
          <div className="grid grid-cols-3 gap-5 mb-5 mt-3 border-b pb-4">
            <div>
              <p id="common_admin_orgTlt">Registered Address</p>
              <p id="common_admin_orgTxt">{data?.registered_address ? data?.registered_address : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt">Pincode</p>
              <p id="common_admin_orgTxt">{data?.pincode ? data?.pincode : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt">City</p>
              <p id="common_admin_orgTxt">{data?.city ? data?.city : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt">State</p>
              <p id="common_admin_orgTxt">{data?.state ? data?.state : "-"}</p>
            </div>
            <div>
              <p id="common_admin_orgTlt" className="col-span-2">Country</p>
              <p id="common_admin_orgTxt">{data?.country ? data?.country : "-"}</p>
            </div>
          </div>
          <p id="common_admin_orgTxt">Contact Person</p>

          <div className="grid grid-cols-3 gap-5 mt-3">
            <div>
              <p id="common_admin_orgTlt" className="col-span-2">Contact Person</p>
              <p id="common_admin_orgTxt">{data?.contact_person ? data?.contact_person : "-"} </p>
            </div>
            <div>
              <p id="common_admin_orgTlt" className="col-span-2">Contact Number</p>
              <p id="common_admin_orgTxt">{data?.contact_person_number ? data?.contact_person_number : "-"} </p>
            </div>
          </div>
        </div>
        <div className="col-span-3 h-full">
          <div className="border-l h-full">
            <div className="h-1/2 border-b mb-5 pb-8" >
              <p id="common_admin_orgTxt" className='m-3'>Organization Logo</p>
              {data?.profile ? 
              <img className='admin_orgLogo_div1'
                src={data?.profile}
                alt="company Profile" /> :
                <div className='admin_orgLogo_div1'>
                  <div className='admin_orgLogo_div2'>
                    <img className={'admin_orgLogo'}
                      src={ImagePaths.companyProfile.default}
                      alt="company Profile" />
                  </div>
                </div>}
            </div>
            {(data?.registration_certificate || data?.gst_certificate)?
             <div className="h-1/2">
              <p id="common_admin_orgTxt" className='m-2'>Documents</p>
              {data?.registration_certificate ? 
              <div className="flex justify-between items-center border ml-3 mb-3 p-2">
                <div className='flex items-center'>
                  <img src={ImagePaths.pdfOn.default} alt="file" className='admin_org_pdf' />
                  <div>
                    <p id="common_salary_subTxt">Registration Certificate</p>
                    <p id="common_admin_orgTlt">File</p>
                  </div>
                </div>
                <IconButton
                 title="Download Pdf"
                  icon={<DownloadOutlined style={styles.downloadIcon}/>}
                    onClick={() => data?.registration_certificate ? downloadPDF(data?.registration_certificate) : {}} />
              </div> : null}
              {data?.gst_certificate ?
              <div className="flex justify-between items-center border ml-3 mb-3  p-2">
                <div className='flex items-center'>
                  <img src={ImagePaths.pdfOn.default} alt="file" className='admin_org_pdf' />
                  <div>
                    <p id="common_salary_subTxt">GST Certificate</p>
                    <p id="common_admin_orgTlt">File</p>
                  </div>
                </div>
                <IconButton
                 title="Download Pdf"
                  icon={<DownloadOutlined style={styles.downloadIcon} />} 
                  onClick={() => data?.gst_certificate ? downloadPDF(data?.gst_certificate) : {}}/>
              </div> : null}
            </div> : null}
          </div>
        </div>
      </div>
    </Card>
  );
};

const styles = {
  downloadIcon: {
    color: "#04B7B1",
    fontSize: "1.3vw",
    fontWeight: "bold"
  }
}

export default CompanyProfilePage;