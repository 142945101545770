import { SALARY_DEDUCTION } from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const getSalaryDeduction = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token");
    let url = SALARY_DEDUCTION;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getAllSalaryDeduction = async (query) => {
    const authtoken = sessionStorage.getItem("token");
    let url = SALARY_DEDUCTION;

    if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getSalaryDeductionById = async (id) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },

    };

    return await fetch(SALARY_DEDUCTION + `?id=${id}`, requestOptions).then((response) => response.json()).then(data => data);
}

export const updateSalaryDeduction = async (input) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: 'PUT',
        headers: { 'Authorization': `token ${authtoken}` },
        body: input
    };

    return await fetch(SALARY_DEDUCTION, requestOptions).then((response) => response.json()).then(data => data);
}

export const addSalaryDeduction = async (input) => {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `token ${authtoken}` },
        body: input
    };

    return await fetch(SALARY_DEDUCTION, requestOptions).then((response) => response.json()).then(data => data);
}