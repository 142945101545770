import React, { useState, useCallback, useRef, useEffect } from 'react';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Checkbox } from 'antd'
import { EMPLOYEE_GROUP } from 'constants';
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from 'components/common/validation';
import ToastMsg from "components/common/ToastMsg";
import {CommonCancelPopup} from "components/common/CommonComponent";

const AddEditEmployeeGroup = ({ fromEdit, editDetails, onHide, onSave, isActive }) => {
    const authtoken = sessionStorage.getItem("token");
    const companyid = sessionStorage.getItem("userId");
    const prevPropsRef = useRef();

    const initialState = {
        code: '',
        employee_group_name: '',
        notes: '',
        is_active: true,
        company: companyid,
    }
    const [formData, setFormData] = useState(initialState);
    const [loadings, setLoadings] = useState(false);
    const [isCancelClk, setIsCancelClk] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                code: editDetails.code ? editDetails.code : "",
                employee_group_name: editDetails.employee_group_name,
                notes: editDetails.notes ? editDetails.notes : "",
                is_active: editDetails.is_active,
                company: editDetails.company_id,
            }));
            form.setFieldsValue({
                code: editDetails.code ? editDetails.code : "",
                employee_group_name: editDetails.employee_group_name,
                notes: editDetails.notes ? editDetails.notes : "",
                is_active: editDetails.is_active,
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        form.setFieldsValue({
            [name]: value,
        });
    };

    const renderInput = (statekey, maxLength = 150) => {
        return (
            <Input
                autoComplete='off'
                style={{ width: "15vw", marginRight: "0.625vw" }}
                disabled = {statekey === "code"}
                type="text"
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
        )
    }

    const handleSubmit = useCallback((isDraftClked) => {
        let methods = fromEdit ? "PUT" : "POST";
        let updatedFormData = { ...formData };
      if (isDraftClked) {
        updatedFormData = { ...updatedFormData, is_draft: true };
      } else {
        updatedFormData = { ...updatedFormData, is_draft: false };
      }
        try {
            setLoadings(true)
            fetch(EMPLOYEE_GROUP, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        setLoadings(false);
                        setFormData(initialState);
                        handleCancelBtn();
                        ToastMsg("success", data.message);
                        onSave();
                    }
                    else if (data.status === "fail") {
                        ToastMsg("error", data.message);
                        setLoadings(false);
                    }
                })
                .catch(error => {
                    error.json().then(data => {
                        setLoadings(false);
                        ToastMsg("error", data);
                    });
                });
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            setIsCancelClk(false);
        }
    }, [formData]);

    const checkAllMandatory = useCallback((isDraftClked) => {
        if (!isDraftClked) {
          form
            .validateFields()
            .then(() => {
              handleSubmit(false);
            })
            .catch(error => {
              console.error("Validation Error:", error);
            });
        } else {
          handleSubmit(true);
        }
      }, [form, handleSubmit]);
    
      const handleSave = useCallback(() => {
        checkAllMandatory(false);
      }, [checkAllMandatory]);
    
      const handleSaveAsDraft = useCallback(() => {
        checkAllMandatory(true);
      }, [checkAllMandatory]);

    const handleCancelBtn = () => {
        setIsCancelClk(false);
        onHide();
    }

    const keysToExclude = ['is_active', 'company'];
    const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

    return (
        <div className = "w-full" style={{ marginBottom: '0.4vw' }}>
            <p className='common_add_tltTxt'>{fromEdit ? "Edit Employee Group" : "Add Employee Group"}</p>
            <Form
                layout="inline"
                form={form}
                colon={false}
                requiredMark={false}
                style={{ width: "100%" }}
                name="basicform"
                initialValues={formData}
                onFinish={handleSave}
                onSubmit={(e) => e.preventDefault()}
            >
                <div className='w-full flex justify-between align-center'>
                    {fromEdit ? <Form.Item
                        name="code"
                        rules={[{ required: true, message: "This field is required" },
                        { validator: noBlankSpacesValidator }
                        ]}
                    >
                        <div className='commonInputTlt'>Employee Group Code <span className='requiredTxt'>*</span></div>
                        {renderInput("code", 10)}
                    </Form.Item> : null}
                    <Form.Item
                        name="employee_group_name"
                        rules={[{ required: true, message: "This field is required" },
                        { validator: noBlankSpacesValidator }
                        ]}
                    >
                        <div className='commonInputTlt'>Employee Group Name <span className='requiredTxt'>*</span></div>
                        {renderInput("employee_group_name", 50)}
                    </Form.Item>
                    <Form.Item name="notes">
                        <div className='commonInputTlt'>Notes</div>
                        {renderInput("notes")}
                    </Form.Item>
                    <Form.Item name="is_active">
                        <Checkbox
                            disabled={fromEdit ? !isActive : true}
                            checked={formData.is_active}
                            onChange={(e) => handleChange("is_active", e.target.checked)}
                        >
                            Active
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <div className='flex justify-center items-end'>
                            <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                            <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit)? () => {setIsCancelClk(true)} : onHide} outlined = {true} paddingX={"0 0.7vw"} />
                        </div>
                    </Form.Item>
                </div>
            </Form>
            {isCancelClk ? <CommonCancelPopup handleCancelBtn = {handleCancelBtn} handleDraftBtn = {handleSaveAsDraft}/> : null}
        </div>
    );
}

export default AddEditEmployeeGroup;
