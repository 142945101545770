import { RESIGNATION_DETAILS } from 'constants';
import { EMPLOYEE_RESIGNATION_REQUEST, EMPLOYEE_APPROVER } from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const getEmployeeResignation = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token");
    let url = EMPLOYEE_RESIGNATION_REQUEST;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}


export const getEmployeeApprover = async (query) => {
    try {
      const authtoken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
      };
  
      let url = EMPLOYEE_APPROVER;
  
      if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
      }
  
      return await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
    } catch (error) {
      return { status: "fail", message: error.message };
    }
  };

export const getResignationDetails = async (query) => {
    try {
      const authtoken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: { Authorization: `token ${authtoken}` },
      };
  
      let url = RESIGNATION_DETAILS;
  
      if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
      }
  
      return await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => data);
    } catch (error) {
      return { status: "fail", message: error.message };
    }
  };
  
export const getEmployeeResigcount = async () => {
  const authtoken = sessionStorage.getItem("token");
  let url = EMPLOYEE_RESIGNATION_REQUEST;

  const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `token ${authtoken}` },
  };

  return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}
