import { salaryDeductEnum, salaryShareEnum } from "components/common/enum";
import MyButton from "components/ui/Button/MyButton";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Card, Checkbox, Select, Tooltip } from 'antd';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys, validateNumeric, validatePercentage } from 'components/common/validation';
import { SALARY_DEDUCTION } from "constants";

const AddEditSalaryDeduction = () => {
  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [formData, setFormData] = useState({
    code: "",
    deduction_name: "",
    deduct_from: [],
    is_fixed: false,
    is_active: true,
    share: "",
    deduction_value: 0,
    notes: "",
  });
  const [loading, setLoading] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();
  const [isSaveClk, setIsSaveClk] = useState(true);
  const hideCursorRef = useRef({});

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        code: editDetails.code ? editDetails.code : "",
        deduction_name: editDetails.deduction_name ? editDetails.deduction_name : "",
        deduct_from: editDetails.deduct_from ? editDetails.deduct_from[0] : [],
        is_fixed: editDetails.is_fixed ? editDetails.is_fixed : false,
        share: editDetails.share ? editDetails.share : "",
        deduction_value: editDetails.deduction_value ? editDetails.deduction_value : 0,
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
      }));
      form.setFieldsValue({
        code: editDetails.code ? editDetails.code : "",
        deduction_name: editDetails.deduction_name ? editDetails.deduction_name : "",
        deduct_from: editDetails.deduct_from ? editDetails.deduct_from[0] : [],
        is_fixed: editDetails.is_fixed ? editDetails.is_fixed : false,
        share: editDetails.share ? editDetails.share : "",
        deduction_value: editDetails.deduction_value ? editDetails.deduction_value : 0,
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const handleChange = (name, value) => {
    let updatedFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "is_fixed") {
      updatedFormData.is_fixed = value;
      updatedFormData.deduction_value = 0;
    }
    setFormData(updatedFormData);
    form.setFieldsValue(updatedFormData);
    form.validateFields([name]);
  };

  const handleKeyDown = (e) => {
    const value = e.target.value + e.key;
    // Allow only numbers, backspace, and delete keys
    if (!/^[\d.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
      e.preventDefault();
    }  
    if (!formData.is_fixed) {
      // If decimal point is already present, prevent entering another
      if (e.key === "." && value.split('.').length > 2) {
        e.preventDefault();
      }
      // Check if the resulting value is not within the range of 0 to 100
      if (parseFloat(value) < 0 || parseFloat(value) > 100) {
        e.preventDefault();
      }
    }
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete='off'
        disabled = {statekey === "code"}
        style={statekey === "deduction_value" ? styles.percentageField : styles.textField}
        type={"text"}
        name={statekey}
        value={formData[statekey] ? formData[statekey] : ""}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        onKeyDown={statekey === "deduction_value" ? handleKeyDown : null}
        data-testid={testID}
      />
      </>
    )
  }

  const renderSelect = (label, statekey, testID = '', list, showKey = "text", valueKey = "value", required = false) => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Select
        showSearch
        style={statekey === "deduct_from" ? styles.percentageField : styles.textField}
        value={formData[statekey] ? formData[statekey] : undefined}
        name={statekey}
        showArrow="true"
        maxTagCount={1}
        maxTagTextLength={8}
        onChange={(value) => handleChange(statekey, value)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        data-testid={testID}
        ref={hideCursorRef.current[statekey]}
        onSelect={() => {
          hideCursorRef.current[statekey].current.blur();
        }}
      >
        {list.map((option) => (
          <Select.Option key={option[valueKey]} value={option[valueKey]}>{option[showKey]}</Select.Option>
        ))}
      </Select>
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/configuration/salarydeductions');
  }, [navigate, form])

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    handleBackCancel();
  }

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      if (isDraftClked) {
        updatedFormData = {
          ...updatedFormData,
          deduction_value: updatedFormData?.deduction_value ? +updatedFormData.deduction_value : 0,
          deduct_from: updatedFormData?.deduct_from.length> 0? [updatedFormData?.deduct_from]: [],
          is_draft: true
        };
      } else {
        updatedFormData = {
          ...updatedFormData,
          deduction_value: updatedFormData?.deduction_value ? +updatedFormData.deduction_value : 0,
          deduct_from: updatedFormData?.deduct_from.length > 0? [updatedFormData?.deduct_from]: [],
          is_draft: false
        };
      }
      fetch(SALARY_DEDUCTION, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
              code: "",
              deduction_name: "",
              deduct_from: "",
              is_fixed: false,
              is_active: true,
              share: "",
              deduction_value: 0,
              notes: "",
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  }, [formData, fromEdit, handleBackCancel]);

  const handleSave = useCallback(() => {
    handleSubmit(false);
  }, [handleSubmit]);

  const handleSaveAsDraft = useCallback(() => {
    handleSubmit(true);
  }, [handleSubmit]);

  const dynamicValidator = (rule, value) => {
    const { is_fixed } = formData;
    if (!value && isSaveClk) {
      setIsSaveClk(false);
      return Promise.reject('This field is required');
    }
    if (is_fixed === false) {
      return validatePercentage(rule, value);
    } else {
      return validateNumeric(rule, value);
    }
  };

  const keysToExclude = ['is_active'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Salary Deduction" : "Add Salary Deduction"}</p>
        </div>
        <Checkbox
          disabled = {fromEdit ? !isActive : true }
          checked={formData.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
          data-testid={'sd_sts'}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0vw" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          data-testid={'sd_form'}
          initialValues={formData}
        >
          <div className='w-full grid grid-rows-2 grid-cols-3 gap-5 m-1'>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Deduction Code', "code", 'sd_code', 10, true)}
            </Form.Item>: null}
            <Form.Item
              style={styles.formItem}
              name="deduction_name"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Deduction Name', "deduction_name", 'sd_name', 50, true)}
            </Form.Item>

            <Form.Item
              style={styles.formItem}
              name="share"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {renderSelect('Share', "share", 'sd_share', salaryShareEnum, "text" , "value", true)}
            </Form.Item>
            <div className = "grid grid-cols-2 gap-3">
            <Form.Item
              style={styles.formItem}
              name="deduction_value"
              rules={[{ required: true, message: "This field is required" },
              { validator: dynamicValidator }]}
            >
              {renderInput(formData.is_fixed ? 'Enter Amount' : 'Enter Percentage', "deduction_value", 'sd_per', formData.is_fixed ? 7 : 5, true)}
            </Form.Item>
            <Form.Item
              style={styles.formItem}
              name="deduct_from"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {renderSelect('Deduct From', "deduct_from", 'sd_from', salaryDeductEnum, "text" , "value", true)}
            </Form.Item>
            </div>   
            <Form.Item name="notes" style={styles.formItem}>
              {renderInput('Notes', "notes", 'sd_notes', 150)}
            </Form.Item>
            <Form.Item style={styles.formItem} name="is_fixed">
              <Checkbox
                checked={formData.is_fixed}
                onChange={(e) => handleChange("is_fixed", e.target.checked)}
                data-testid={'sd_fixed'}
              >
                Fixed Amount
              </Checkbox>
            </Form.Item>
            <Form.Item style={styles.emptyFormItem} />
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="sd_cancel" />
                {fromEdit ? null
                  : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="sd_draft" />}
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="sd_save" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID={'sd'} /> : null}
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "14.5vw",
    margin: "1vw"
  },
  percentageField: {
    width: "10vw"
  },
}
export default AddEditSalaryDeduction
