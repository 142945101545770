export function removeLastComma(str) {
  return str.toString().replace(/,(\s+)?$/, "");
}

export const getIndianCurrency = (num) => {
  return "₹";
};

export const toIndianCurrency = (num) => {
  const curr = num.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return curr;
};

export const prefillUrl = (value) => {
  let url = value;
  if (!value.includes("?")) {
    url = `${url}?`;
  }
  return url;
};
