import React, { useEffect, useState, useRef } from "react";
import { Card, Table, Tooltip, Space, } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import AssetManagementFilter from "pages/assetManagement/AssetManagementFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getAssetRegistryPagination, getAssetCity, getAssetFloor, getAssetBlock, getAssetRoom } from "hooks/api/assetManagementApi/assetRegistryApi";
import { ASSET_REGISTRY } from "constants";
import { getMasterAssetCategory } from 'hooks/api/masterApi/masterAssetCategoryApi';
import { getAssetSubCategory } from "hooks/api/masterApi/assetSubCategoryApi";
import { getAsset } from 'hooks/api/assetAPI';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { checkUserPermissionFunc, deleteDraftItemsById } from "components/common/CommonFuntion";
import { CommonDeletePopup } from 'components/common/CommonComponent';

const initialData = {
    asset_category: undefined,
    asset_sub_category: undefined,
    purchase_date: undefined,
    is_active: undefined,
    assetCategoryList: [],
    assetSubCategoryList: [],
    asseTypeList: []
}

function AssetRegistry() {
    const authtoken = sessionStorage.getItem("token");
    let query = "";
    let queryRef = useRef("");
    const pageSize = 10;
    const [addVisible, setAddVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [isDraft, setIsDraft] = useState(false);
    const navigate = useNavigate();
    const dateFormat = 'DD-MM-YYYY'
    const [locationDetails, SetLocationDetails] = useState({
        assetCityList: [],
        assetBlockList: [],
        assetRoomList: [],
        assetFloorList: [],
    });
    const [deleteItem, setDeleteClicked] = useState({ id: undefined, isDeleteClicked: false });
    const tableContainerHeight = isDraft ? "77.5vh" : "72vh";


    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const userPermission = checkUserPermissionFunc("Asset Management", "Asset Registry", "SubMenu");

    const [data, pageInfo, loading, _message, refetch, extraDetails] = useApiData(getAssetRegistryPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    let autoGen = extraDetails.auto_gen ? extraDetails.auto_gen : false;

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);


    const getCategoryList = async () => {
        try {
            const apiData = await getMasterAssetCategory(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    assetCategoryList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    assetCategoryList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                assetCategoryList: []
            }))
        }
    }

    const getAssetSubCategoryList = async () => {
        try {
            const apiData = await getAssetSubCategory(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    assetSubCategoryList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    assetSubCategoryList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                assetSubCategoryList: []
            }))
        }
    }

    const getAssetTypeList = async () => {
        try {
            const apiData = await getAsset(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    asseTypeList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    asseTypeList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                asseTypeList: []
            }))
        }
    }

    const getAssetCityList = async () => {
        try {
            const apiData = await getAssetCity(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetCityList: [...apiData.data]
                }))
            } else {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetCityList: []
                }))
            }
        } catch (error) {
            SetLocationDetails((prevFilterData) => ({
                ...prevFilterData,
                assetCityList: []
            }))
        }
    }

    const getAssetBlockList = async () => {
        try {
            const apiData = await getAssetBlock(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetBlockList: [...apiData.data]
                }))
            } else {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetBlockList: []
                }))
            }
        } catch (error) {
            SetLocationDetails((prevFilterData) => ({
                ...prevFilterData,
                assetBlockList: []
            }))
        }
    }

    const getAssetFloorList = async () => {
        try {
            const apiData = await getAssetFloor(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetFloorList: [...apiData.data]
                }))
            } else {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetFloorList: []
                }))
            }
        } catch (error) {
            SetLocationDetails((prevFilterData) => ({
                ...prevFilterData,
                assetFloorList: []
            }))
        }
    }

    const getAssetRoomList = async () => {
        try {
            const apiData = await getAssetRoom(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetRoomList: [...apiData.data]
                }))
            } else {
                SetLocationDetails((prevFilterData) => ({
                    ...prevFilterData,
                    assetRoomList: []
                }))
            }
        } catch (error) {
            SetLocationDetails((prevFilterData) => ({
                ...prevFilterData,
                assetRoomList: []
            }))
        }
    }

    useEffect(() => {
        getCategoryList();
        getAssetSubCategoryList();
        getAssetTypeList();
        getAssetCityList();
        getAssetBlockList();
        getAssetFloorList();
        getAssetRoomList();
    }, []);


    const addClick = () => {
        setEditVisible(false);
        setSelectedDetails({});
        navigate('/assetmanagement/assetregistry/add', { state: { fromEdit: false, editDetails: {}, locationDetails, filterDatas, isActive: userPermission?.active !== undefined ? userPermission?.active : true, isAutoGen : autoGen } });
    };

    const handleEditClick = (details) => {
        if (editVisible && Object.keys(selectedDetails).length > 0) {
            ToastMsg("warning", "Please cancel the existing edit operation!")
        }
        else if (!editVisible) {
            setEditVisible(true);
            navigate('/assetmanagement/assetregistry/add', { state: { fromEdit: true, editDetails: details, locationDetails, filterDatas, isActive: userPermission?.active !== undefined ? userPermission?.active : true, isAutoGen : autoGen } });
            setSelectedDetails(details);
        }
    };

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `category=${data.asset_category ? data.asset_category : ""}&sub_category=${data.asset_sub_category ? data.asset_sub_category : ""}&purchase_date=${data.purchase_date ? data.purchase_date : ""}&is_active=${data.is_active !== undefined ? data.is_active : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            asset_category: undefined,
            asset_sub_category: undefined,
            purchase_date: undefined,
            is_active: undefined,
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setEditVisible(false)
        setAddVisible(false)
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        queryRef.current = isDraft ? `is_draft=True` : "";
        refetch(pagination.current,
            pageSize,
            queryRef.current,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Asset ID",
            dataIndex: "asset_id",
            key: "asset_id",
            sorter: true,
            width: "17.05%",
            fixed: "left",
            render: (_, record) =>
            (record?.asset_id?.length > 20 ?
                <Tooltip title={record?.asset_id}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.code?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_id ? record?.asset_id : "-"}</p>)
        },
        {
            title: "Asset Name",
            dataIndex: "asset_name",
            key: "asset_name",
            sorter: true,
            width: "17.05%",
            fixed: "left",
            render: (_, record) =>
            (record?.asset_name?.length > 20 ?
                <Tooltip title={record?.asset_name}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_name?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.asset_name ? record?.asset_name : "-"}</p>)
        },
        {
            title: "Model Name",
            dataIndex: "model_name",
            key: "model_name",
            sorter: true,
            width: "17.05%",
            fixed: "left",
            render: (_, record) =>
            (record?.model_name?.length > 28 ?
                <Tooltip title={record?.model_name}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.model_name?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.model_name ? record?.model_name : "-"}</p>)
        },
        {
            title: "Category",
            dataIndex: "category_name",
            key: "category_name",
            sorter: true,
            width: "17.05%",
            fixed: "left",
            render: (_, record) =>
            (record?.category_name?.length > 28 ?
                <Tooltip title={record?.category_name}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.category_name?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.category_name ? record?.category_name : "-"}</p>)
        },

        {
            title: "Sub Category",
            dataIndex: "sub_category_name",
            key: "sub_category_name",
            sorter: true,
            width: "17.05%",
            fixed: "left",
            render: (_, record) =>
            (record?.sub_category_name?.length > 28 ?
                <Tooltip title={record?.sub_category_name}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.sub_category_name?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.sub_category_name ? record?.sub_category_name : "-"}</p>)
        },
        {
            title: "Purchase Date",
            dataIndex: "purchase_date",
            key: "purchase_date",
            sorter: true,
            width: "16.25%",
            render: (_, record) =>
                <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>
                    {record?.purchase_date ? dayjs(record?.purchase_date).format(dateFormat) : "-"}
                </p>,
            align: "center",
            fixed: "left",
        },
        {
            title: "Status",
            key: "is_active",
            dataIndex: "is_active",
            sorter: true,
            width: "10.6%",
            render: (_, record) =>
                record.is_active === true ? (
                    <span style={{ color: "black" }}>Active</span>
                ) : (
                    <span style={{ color: "#CACACA" }}>Inactive</span>
                ),
            align: 'center',
            fixed: "left",
        },
    ];

    if (userPermission?.edit) {
        columns.push( {
            title: "Action",
            key: "action",
            align: 'center',
            width: "10.6%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex justify-center items-center">
                    <Space size={"middle"}>
                        <IconButton
                            title="Edit"
                            icon={<img src={ImagePaths.edit.default} alt="Edit" />}
                            onClick={() => (loading ? "" : handleEditClick(record))}
                            testID={'ug_btn_edit'}
                        />
                        {record.is_draft === true ?
                            <IconButton
                                title="Delete"
                                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                                onClick={() => (loading ? "" : handleDeleteClicked(record?.id))}
                                testID={'ug_btn_delete'}
                            /> : null}
                    </Space>
                </div>
            ),
        })
      }

    //draft delete start
    const handleDeleteClicked = (id) => {
        setDeleteClicked((prevdata) => ({
            ...prevdata,
            id: id,
            isDeleteClicked: true
        }));
    }

    const handleCancelBtn = () => {
        setDeleteClicked((prevdata) => ({
            ...prevdata,
            id: undefined,
            isDeleteClicked: false
        }));
    }

    const handleDeleteBtn = async (id) => {
        try {
            const apiData = await deleteDraftItemsById(id, ASSET_REGISTRY);
            if (apiData && apiData.status === "success") {
                ToastMsg("success", apiData.message);
                handleDraft();
            } else {
                ToastMsg("error", apiData.message);
            }
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            handleCancelBtn();
        }
    }//draft delete end

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }


    const handleDraft = () => {
        setIsDraft(true);
        setFilterVisible(false);
        setSorting("");
        setPageNo(1);
        queryRef.current = `is_draft=True`
        refetch(1, pageSize, queryRef.current, searchText, "");
    };

    const handleBackToHome = () => {
        setIsDraft(false);
        setSorting("");
        setPageNo(1);
        queryRef.current = "";
        refetch(1, pageSize, queryRef.current, searchText, "");
    };


    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&category=" + (filterDatas.asset_category ? filterDatas.asset_category : "") +
        "&sub_category=" + (filterDatas.asset_sub_category ? filterDatas.asset_sub_category : "") +
        "&purchase_date=" + (filterDatas.purchase_date ? filterDatas.purchase_date : "") +
        "&is_active=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Asset Registry"}
                    addVisible={addVisible} editVisible={editVisible} pageInfo={pageInfo}
                    handleSearch={handleSearch} selectedDetails={selectedDetails}
                    addClick={addClick} onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    handleDraft={handleDraft} isDraft={isDraft} url={ASSET_REGISTRY} query={queryString} handleBackToHome={handleBackToHome}
                    testID={'astreg_btn_add'} isAddRequired = {userPermission?.add ? true : false} isDraftRequired = {userPermission?.draft ? true : false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <AssetManagementFilter
                            pageName={"AssetRegistry"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
                    />
                </div>
            </div>
            {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => handleDeleteBtn(deleteItem.id)} /> : null}
        </Card>
    );
}

export default AssetRegistry;