import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getPayrollDetailPeriodReportsPagination } from "hooks/api/reportsApi.js";
import { PAYROLL_DETAIL_PERIOD_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import { toIndianCurrency } from "utils/StringUtils";
import dayjs from "dayjs";

const initialData = {
  payroll_detail_date: new Date(),
  payroll_detail_designation: "",
  payroll_detail_department: "",
  payroll_detail_employee_name: "",
}

const PayrollDetailedSummary = () => {
  const authtoken = sessionStorage.getItem("token");
  const pageSize = 10;
  const dateFormat = "YYYY-MM-DD";

  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ ...initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const parsedDate = selectedDate ? dayjs(selectedDate) : null;
  const [query, setQuery] = useState(`month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.payroll_detail_designation ? filterDatas.payroll_detail_designation : ""}&department=${filterDatas.payroll_detail_department ? filterDatas.payroll_detail_department : ""}&employee=${filterDatas.payroll_detail_employee_name ? filterDatas.payroll_detail_employee_name : ""}`);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getPayrollDetailPeriodReportsPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  useEffect(() => {
    const parsedDate = selectedDate ? dayjs(selectedDate) : null;
    const newQuery = `month=${parsedDate ? parsedDate.month() + 1 : ""}&year=${parsedDate ? parsedDate.year() : ""}&designation=${filterDatas.payroll_detail_designation ? filterDatas.payroll_detail_designation : ""}&department=${filterDatas.payroll_detail_department ? filterDatas.payroll_detail_department : ""}&employee=${filterDatas.payroll_detail_employee_name ? filterDatas.payroll_detail_employee_name : ""}`;
    setQuery(newQuery);
  }, [filterDatas]);

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   `date=${data.payroll_detail_date ? dayjs(data.payroll_detail_date).format('YYYY-MM-DD') : ""}&designation=${data.payroll_detail_designation ? data.payroll_detail_designation : ""}&department=${data.payroll_detail_department ? data.payroll_detail_department : ""}&employee=${data.payroll_detail_employee_name ? data.payroll_detail_employee_name : ""}`,
    //   searchText,
    //   sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas({...initialData});
    setPageNo(1);
    // refetch(1,
    //   pageSize,
    //   query,
    //   searchText,
    //   sorting);
  }
  //filter end

  const handleSearch = () => {
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee__employee_name",
      key: "employee__employee_name",
      width: "16.05%",
      sorter: true,
      fixed: 'left',
      render: (_, record) => {
        let empDetails = {
          ...record.employee,
          "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
          "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
          "id": record?.employee.employee_id
        }
        return (
          <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
            <EmployeeDetails details={empDetails} />
          </span>
        )
      }
    },
    {
      title: "CTC",
      dataIndex: "ctc",
      key: "ctc",
      sorter: true,
      width: "9.2%",
      render: (_, record) =>
        record?.ctc?.length > 7 ? (
          <Tooltip title={record?.ctc}>
            {
              <span>{record?.ctc?.slice(0, 7) + "..."}</span>
            }
          </Tooltip>
        ) : (
          <p>{record?.ctc ? toIndianCurrency(record?.ctc) : "-"}</p>
        ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Earning",
      dataIndex: "earnings",
      key: "earnings",
      sorter: true,
      width: "9.2%",
      render: (_, record) => record?.earnings?.length > 7 ? (
        <Tooltip title={record?.earnings}>
          {
            <span>{record?.earnings?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.earnings ? toIndianCurrency(record?.earnings) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Deductions",
      dataIndex: "deductions",
      key: "deductions",
      sorter: true,
      width: "9.2%",
      render: (_, record) => record?.deductions?.length > 7 ? (
        <Tooltip title={record?.deductions}>
          {
            <span>{record?.deductions?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.deductions ? toIndianCurrency(record?.deductions) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Net Pay",
      dataIndex: "net_pay",
      key: "net_pay",
      sorter: true,
      width: "9.16%",
      render: (_, record) => record?.net_pay?.length > 7 ? (
        <Tooltip title={record?.net_pay}>
          {
            <span>{record?.net_pay?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.net_pay ? toIndianCurrency(record?.net_pay) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "LOP",
      dataIndex: "lop",
      key: "lop",
      sorter: true,
      width: "9.2%",
      render: (_, record) => record?.lop?.length > 7 ? (
        <Tooltip title={record?.lop}>
          {
            <span>{record?.lop?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.lop ? toIndianCurrency(record?.lop) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Reimbursements",
      dataIndex: "reimbursements",
      key: "reimbursements",
      width: "9.63%",
      sorter: true,
      render: (_, record) => record?.reimbursements?.length > 7 ? (
        <Tooltip title={record?.reimbursements}>
          {
            <span>{record?.reimbursements?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.reimbursements ? toIndianCurrency(record?.reimbursements) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Credit",
      dataIndex: "credits",
      key: "credits",
      width: "9.16%",
      sorter: true,
      render: (_, record) => record?.credits?.length > 7 ? (
        <Tooltip title={record?.credits}>
          {
            <span>{record?.credits?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.credits ? toIndianCurrency(record?.credits) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      width: "9.2%",
      sorter: true,
      render: (_, record) => record?.debit?.length > 7 ? (
        <Tooltip title={record?.debit}>
          {
            <span>{record?.debit?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.debit ? toIndianCurrency(record?.debit) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
    {
      title: "Paid Salary",
      dataIndex: "paid_salary",
      key: "paid_salary",
      width: "10%",
      sorter: true,
      render: (_, record) => record?.paid_salary?.length > 7 ? (
        <Tooltip title={record?.paid_salary}>
          {
            <span>{record?.paid_salary?.slice(0, 7) + "..."}</span>
          }
        </Tooltip>
      ) : (
        <p>{record?.paid_salary ? toIndianCurrency(record?.paid_salary) : "-"}</p>
      ),
      align: "right",
      fixed: 'right',
    },
  ];

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&month=" + (parsedDate ? parsedDate.month() + 1 : null) +
    "&year=" + (parsedDate ? parsedDate.year() : null) +
    "&designation=" + (filterDatas.payroll_detail_designation ? filterDatas.payroll_detail_designation : "") +
    "&department=" + (filterDatas.payroll_detail_department ? filterDatas.payroll_detail_department : "") +
    "&employee=" + (filterDatas.payroll_detail_employee_name ? filterDatas.payroll_detail_employee_name : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  // Date changes start - Filter the data based on the selected date
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFilterDatas({ ...filterDatas, payroll_detail_date: date });
    setDataUpdated(!dataUpdated);
  };// Date changes end

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader pageName={"Payroll Detailed Summary"}
          pageInfo={pageInfo} handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={PAYROLL_DETAIL_PERIOD_REPORTS} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
          format="MMM YYYY" picker="month"
        />
      </div>
      <div className="relative h-5/6 mt-1">
        {filterVisible ?
          <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
            <ReportsFilter
              pageName={"Payroll Detailed Summary"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={"mt-5 w-full custom-table"} style={styles.fltContainer}>
          <Table
            columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
            dataSource={data?.length > 0 ? data : null}
            loading={loading}
            style={{ "--table-container-height": tableContainerHeight }}
            scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
            onChange={handleTableChange}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              defaultPageSize: pageSize,
              showSizeChanger: false,
              showQuickJumper: true,
              total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
              pageSizeOptions: ["10", "20", "30"],
              pageSize,
              defaultCurrent: pageNo,
            }}
            key={dataUpdated ? "updated" : "not-updated"}
            bordered
          />
        </div>
      </div>
    </Card>
  );
}

export default PayrollDetailedSummary