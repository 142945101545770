import React, { useEffect } from "react";
import moment from "moment";
import nodata from "../../../assets/nodata.png";
import { Card, Typography } from "antd";
const { Text } = Typography;

function DashboardnoticeBoard({ data }) {

  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  return (
    <div>
      <div className="flex w-full">
        <div className="bg-white rounded-md p-0 w-full">
          <Card
            // title="Notice Board"
            style={{ borderRadius: "0px" }}
            bodyStyle={{ padding: "0px" }}
            className="mr-2 mb-2"
          >
            <div className="grid grid-cols-2">
            <div style={{ width: "100%" }} className="p-2">
                <Text strong>Notice Board</Text>
              </div>
            </div>
            <div className="h-80 overflow-auto">
              {data &&
                data.notice.map((item) => (
                  <div className="pb-2">
                    <Card
                    className="mr-2 ml-0 mb-2"
                      style={{
                        backgroundColor: "#FAFAFA",
                        border: 0,
                        borderRadius: "0px",
                        borderLeft: "2px solid #D94854",
                      }}
                      bodyStyle={{ padding: "10px" }}
                    >
                      <Text strong style={{ fontSize: 16 }}>
                        {item.title}
                      </Text>
                      <p style={{ fontSize: 13 }}>{item.description}</p>
                      <div className="grid grid-cols-2 pt-2">
                        <div></div>
                        <div className="float-right">
                          <p style={{ fontSize: 13, float: "right" }}>
                            {item.created_by}
                          </p>
                          <br />
                          <p style={{ fontSize: 11, float: "right" }}>
                            {moment(item.published_date).format("DD-MM-YYYY")} |{" "}
                            {moment(item.published_date).format("hh:mm A")}
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              {data && data.notice.length === 0 && (
                <div className="grid grid-cols-1 place-content-center w-full">
                  <div className="grid place-content-center">
                    <img src={nodata}></img>
                  </div>
                  <div className="grid place-content-center">
                    Looks like no data is here
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DashboardnoticeBoard;
