import { login } from 'hooks/api/loginApi';

export const validateLoginForm = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  return errors;
};
  
export const loginPage = async (values, onSuccess, onError) => {
  try {
    const data = await login(values.username, values.password);
    onSuccess(data);
  } catch (error) {
    onError(error.message);
  }
};


