import React, { useEffect, useState, useRef, useCallback  } from "react";
import { Card, Table, Tag, Dropdown, Space, Modal, Input, Form, Select, Tooltip, } from "antd";
import IconButton from "components/ui/Button/IconButton";
import moment from "moment";
import { ImagePaths } from "utils/ImagePath";
import EmployeeFilter from "../EmployeeFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmployeeAdvanceSalary } from "hooks/api/employeeApi/getEmployeeAdvanceSalary"
import { EMPLOYEE_GET_ADVANCE_SALARY } from "constants";
import { toIndianCurrency } from "utils/StringUtils";
import AddEditAdvanceSalary from "./AddEditAdvanceSalary";
import { deleteDraftItemsById, deleteItemsById, } from "components/common/CommonFuntion";
import ApprovalLogPopUp from "../ApprovalLogPopUp";
import dayjs from "dayjs";
import { CommonCarouselPopup, CommonDeletePopup } from 'components/common/CommonComponent';
import { emiEnum } from "components/common/enum";
import { extractImageName, noBlankSpacesValidator, validateNumericMandatory } from "components/common/validation";
import MyButton from "components/ui/Button/MyButton";
import { EyeOutlined } from "@ant-design/icons";

const initialData = {
  advance_salary_date: "",
  approval_status: "",
}
function AdvanceSalaryRequest() {
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;
  const dateFormat = "YYYY-MM-DD";
  const authtoken = sessionStorage.getItem("token");

  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [isApprovalLog, setIsApprovalLog] = useState(false);
  const [approvalData, setApprovalData] = useState([]);
  const [deleteItem, setDeleteClicked] = useState({ record: undefined, isDeleteClicked: false , isDraft: false, });
  const [isCancelPopup, setIsCancelPopup] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [isImgOpen, setImgOpen] = useState(false);
  const tableContainerHeight = isDraft ? "77.5vh" : "72vh";
  const companyid = sessionStorage.getItem("userId");
  const [formData, setFormData] = useState({
    advance_requested: "",
    emi_period: "",
    reason: "",
    company_id: companyid,
    is_active: true,
    cancel_reason: ""
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (!authtoken) {
      window.location.href = '/';
    }
  }, [authtoken]);

  const queryString =
  "?page_no=" + pageNo +
  "&page_size=" + pageSize +
  "&date=" + (filterDatas.advance_salary_date ? filterDatas.advance_salary_date : "") +
  "&status=" + (filterDatas.approval_status !== undefined ? filterDatas.approval_status : "") +
  "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getEmployeeAdvanceSalary, {
    pageNo,
    pageSize,
    queryString,
    searchText,
    sorting
  });

  // const userPermission = checkUserPermissionFunc("employee", "Advance Salary Request", "SubMenu");

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const addClick = () => {
    setAddVisible(true);
    setEditVisible(false);
    setSelectedDetails({});
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg('warning', "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
      setAddVisible(false);
    }
  };

  const handleSave = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsDraft(false);
    refetch(pageNo,
      pageSize,
      queryString,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setAddVisible(false);
    setSelectedDetails({});
    setIsCancelPopup(false)
  };


  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `date=${data.advance_salary_date ? dayjs(data.advance_salary_date).format("YYYY-MM-DD") : ""}&status=${data.approval_status ? data.approval_status : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
  }
  //filter end

  const handleSearch = () => {
    setEditVisible(false)
    setAddVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    queryRef.current = isDraft ? `is_draft=True` : "";
    refetch(pagination.current,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const CancelPopup = (record) => {
    let data = record ? record : null;
    let PrevData = {
      id: data.id,
      advance_requested: data.advance_requested ? data.advance_requested : 0,
      emi_period: data.emi_period ? data.emi_period : 0,
      reason: data.reason ? data.reason : "",
      is_active: data.is_active,
      uploaded_documents: data.uploaded_documents?.length > 0 ?
        data.uploaded_documents.map(url => ({
          name: extractImageName(url),
          url: url
        })) : [],
      company: parseInt(data.company_id),
      cancel_reason: ""
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...PrevData,
    }));
    form.setFieldsValue({
      ...PrevData
    });
    setIsCancelPopup(true)
  }

  const handleClear = () => {
    setFormData({
      ...formData,
      cancel_reason: "",
    });
    form.setFieldsValue({
      ...formData,
      cancel_reason: "",
    });
  }

  const handlePreviewImage = () => {
    if (formData?.uploaded_documents.length > 0) {
      setImgOpen(true);
    }
  };

  const handlePreviewCancel = () => {
    setImgOpen(false);
  };

  const handleSubmit = useCallback(() => {
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData = {
        ...updatedFormData,
        advance_requested: updatedFormData?.advance_requested ? +updatedFormData?.advance_requested : 0,
        is_draft: false,
        request_type: "cancel request",
        approval_status: "cancel inprogress",
      };
      fetch(EMPLOYEE_GET_ADVANCE_SALARY, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleSave();
            handleCancel();
            setLoading(false);
            setFormData({
              advance_requested: "",
              emi_period: "",
              reason: "",
              company_id: companyid,
              is_active: true,
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  }, [formData, companyid, handleCancel, handleSave]);

  const handleDraft = () => {
    setIsDraft(true);
    setFilterVisible(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = `is_draft=True`
    refetch(1,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      sorting);
  }

  const handleBackToHome = () => {
    setIsDraft(false);
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      sorting);
  }

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: +value,
    });
  };

  const handleCancelPopup = () => {
    return (
      <Modal
        centered
        open={isCancelPopup}
        onCancel={handleCancel}
        footer={null}
        width={"28vw"}
        destroyOnClose
      >
        <div className="w-full">
          <p id="common_ApprovalTxt">{"Cancel Advance salary"}</p>
          <Form
            name="createRuleForm"
            form={form}
            layout="vertical"
            initialValues={formData}
            onFinish={handleSubmit}
            onSubmit={(e) => e.preventDefault()}
            colon={false}
            requiredMark={false}
            style={{ width: "100%" }}
          >
            <div className="grid grid-cols-1">
              <Form.Item
                label="Enter Amount"
                name="advance_requested"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(new Error('This field is required'));
                      }
                      if (parseFloat(value) <= 0) {
                        return Promise.reject(new Error('Enter a valid amount'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Input
                  autoComplete='off'
                  style={{ width: "100%" }}
                  size='large'
                  disabled={true}
                  name={"advance_requested"}
                  value={formData.advance_requested ? formData.advance_requested : ""}
                  maxLength={7}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={(e) => {
                    // Allow only numbers, backspace, and delete keys
                    if (!/^[\d]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Select EMI Period"
                name="emi_period"
                rules={[{ required: true, message: "This field is required", validator: validateNumericMandatory }]}
                tooltip={{
                  title: "This field is required",
                  icon: <span style={{ color: "red" }}>*</span>,
                }}
              >
                <Select
                  name="emi_period"
                  disabled={true}
                  defaultValue={selectedDetails?.emi_period ? selectedDetails?.emi_period : ""}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      emi_period: Number(value),
                    });
                  }}
                >
                  {emiEnum.map((option) => (
                    <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Reason" name="reason">
                <Input.TextArea
                  bordered="true"
                  className="mt-2 textarea"
                  rows={3}
                  disabled={true}
                  autoSize={{
                    minRows: 3,
                    maxRows: 4,
                  }}
                  maxLength={150}
                  placeholder="Enter reason for applying for a advance salary"
                  name="reason"
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      reason: value.target.value,
                    });
                  }}
                  value={formData.reason ? formData.reason : ""}
                />
              </Form.Item>
              <Form.Item name="uploaded_documents">
                {formData.uploaded_documents?.length > 0 ?
                  <div className="grid grid-cols-2 gap-x-3 gap-y-2 mt-2">
                    {formData.uploaded_documents.map((file, index) => (
                      <div key={index} className="flex justify-between items-center border-b" style={{ paddingInline: "0.3dvw" }}>
                        {file?.name?.length > 15 ?
                          <Tooltip title={file?.name}>{<span style={{ fontSize: "0.8vw" }}>{"..." + file?.name?.slice(-14)}</span>}</Tooltip>
                          : <p style={{ fontSize: "0.8vw" }}>{file?.name ? file?.name : "-"}</p>}
                        <div>
                          <IconButton
                            title="View"
                            icon={<EyeOutlined style={styles.closeIcon} />}
                            type="button"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent the default form submission behavior
                              handlePreviewImage(index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  : null}
              </Form.Item>
              <div className="leaveText">
                <Form.Item
                  label="Reason For Cancel"
                  name="cancel_reason"
                  rules={[{ required: true, message: "This field is required" },
                  { validator: noBlankSpacesValidator }
                  ]}
                  tooltip={{
                    title: "This field is required",
                    icon: <span style={{ color: "red" }}>*</span>,
                  }}
                >
                  <Input.TextArea
                    bordered={true}
                    className="mt-2 textarea"
                    maxLength={150}
                    rows={3}
                    autoSize={{
                      minRows: 3,
                      maxRows: 4,
                    }}
                    placeholder="Enter reason for Cancel"
                    name="cancel_reason"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        cancel_reason: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="mt-3">
              <div className='flex justify-end items-end'>
                <MyButton htmlType="button" label={"Clear"} onClick={handleClear} bgColor={"#cbcbcb"} loading={isLoading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                <MyButton htmlType="submit" label={"Send Request"} loading={isLoading} paddingX={"0 1.2vw"} />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    )
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const handleApprovalLog = (record) => {
    let data = record?.approval_log?.length > 0 ? record?.approval_log : [];
    setApprovalData(record);
    setIsApprovalLog(true)
  }

  const handleApprovalLogCls = () => {
    setIsApprovalLog(false)
    setApprovalData([]);
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: "13%",
      render: (_, record) => (
        <span style={{ color: "black" }}>
          {record.date ? moment(record.date, dateFormat).format('DD-MM-YYYY') : "-"}
        </span>
      ),
      align: "center",
      fixed: "left",
    },
    {
      title: `Advance requested (INR)`,
      dataIndex: "advance_requested",
      key: "advance_requested",
      sorter: true,
      width: "17.56%",
      render: (_, record) => (<span style={{ color: "black" }}>{toIndianCurrency(record?.advance_requested || 0)}</span>),
      align: "right",
      fixed: "left",
    },
    {
      title: "Debit From",
      dataIndex: "debit_from",
      key: "debit_from",
      sorter: true,
      width: "13%",
      render: (_, record) => (
        <span style={{ color: "black" }}>
          {record.debit_from ? moment(record.debit_from, dateFormat).format('DD-MM-YYYY') : "-"}
        </span>
      ),
      align: 'center',
      fixed: "left",
    },
    {
      title: "EMI Period",
      dataIndex: "emi_period",
      key: "emi_period",
      sorter: true,
      width: "10%",
      render: (_, record) => (
        <span style={{ color: "black" }}>
          {record.emi_period ? record.emi_period : "-"}
        </span>
      ),
      align: "right",
      fixed: "left",
    },
    {
      title: "Advance Approved (INR)",
      dataIndex: "advance_approved",
      key: "advance_approved",
      sorter: true,
      width: "17.88%",
      render: (_, record) => ( <div>{toIndianCurrency(record?.advance_approved || 0)}</div>),
      align: "right",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "approval_status",
      key: "approval_status",
      sorter: true,
      width: "14.06%",
      align: 'center',
      fixed: "left",
      render: (_, record) => {
        let colors = (record.approval_status === "rejected" || record.approval_status === "cancelled") ? "#D94854" : (record.approval_status === "pending" || record.approval_status === "cancel inprogress") ? "#E28F12" : "#0E862A";
        return (<Tag className="statusDiv" color={colors} key={record.approval_status}>{record.approval_status}</Tag>)
      }
    },
    {
      title: "Action",
      key: "action",
      align: 'center',
      width: "11.5%",
      fixed: "left",
      render: (_, record) => {
        const items = [
          {
            key: "1",
            label: (<div className="empApproveTxt">Approval Log</div>),
          },
          {
            key: "2",
            label: (<div className="empApproveTxt">Cancel Request</div>),
            disabled: record.approval_status !== "approved" || !record?.enable_cancel_request,
          }
        ];
        return (
          <div className="flex justify-center items-center">
            <Space size={"middle"}>
              <IconButton title="Edit"
                disabled={record.approval_status !== "pending"}
                icon={<img src={record.approval_status === "pending" ? ImagePaths.edit.default : ImagePaths.editOff.default} alt="Edit" />}
                onClick={() => loading ? "" : handleEditClick(record)} />

              {record.approval_status === "pending" ? <IconButton
                title="Delete"
                icon={<img src={ImagePaths.delete.default} alt="Delete" />}
                onClick={() => (loading ? "" : record.is_draft === true ? handleDeleteClicked(record, true) : handleDeleteClicked(record, false))}
                testID={'ug_btn_delete'}
              /> : null}

              <Dropdown menu={{
                items,
                onClick: ({ key }) => {
                  if (key === '1') {
                    handleApprovalLog(record)
                  }
                  else {
                    CancelPopup(record)
                  }
                }
              }} trigger={['click']} placement="bottomLeft" overlayStyle={styles.dropOverLay}>
                <IconButton title="" icon={<img src={ImagePaths.moreIcon.default} alt="more" />} />
              </Dropdown>
            </Space>
          </div>
        );
    },
    }
  ];

  //draft delete start
  const handleDeleteClicked = (record, isFromDraft) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      record: record,
      isDeleteClicked: true,
      isDraft: isFromDraft,
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      record: undefined,
      isDeleteClicked: false
    }));
  }

  const handleDeleteBtn = async (record, isFromDraft) => {
    try {
      let apiData;
      if (isFromDraft) {
        apiData = await deleteDraftItemsById(record.id, EMPLOYEE_GET_ADVANCE_SALARY);
      } else {
        let fetchedData = {
          id: record.id,
          advance_requested: record.advance_requested ? record.advance_requested : 0,
          emi_period: record.emi_period ? record.emi_period : 0,
          reason: record.reason ? record.reason : "",
          company: companyid,
          is_active: false
        }
        apiData = await deleteItemsById(EMPLOYEE_GET_ADVANCE_SALARY, false, fetchedData);
      }
      if (apiData && apiData.status === "success") {
        ToastMsg("success", apiData.message);
        isFromDraft === true? handleDraft(): handleBackToHome();
      } else {
        ToastMsg("error", apiData.message);
      }
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      handleCancelBtn();
    }
  }//draft delete end

  const printData =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&date=" + (filterDatas.advance_salary_date ? filterDatas.advance_salary_date : "") +
    "&status=" + (filterDatas.approval_status !== undefined ? filterDatas.approval_status : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"} >
        <CommonTblHeader pageName={"Advance Salary Request"}
          pageInfo={pageInfo}
          handleSearch={handleSearch} selectedDetails={selectedDetails} addClick={addClick}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          handleDraft={handleDraft} isDraft={isDraft} handleBackToHome={handleBackToHome}
          url={EMPLOYEE_GET_ADVANCE_SALARY} query={printData} addBtnLabel={"New Request"}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <EmployeeFilter
              pageName={"AdvanceSalaryRequest"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={isDraft ? (dataUpdated ? "draftUpdated" : "draftNotUpdated") : (dataUpdated ? "updated" : "not-updated")}
        />
        </div>
      </div>
      {(isImgOpen && formData?.uploaded_documents.length > 0) ?
        <Modal
          centered
          open={isImgOpen}
          onCancel={handlePreviewCancel}
          footer={null}
          width={"50vw"}>
          <CommonCarouselPopup documents={[...formData?.uploaded_documents]} />
        </Modal> : null}
      {(addVisible || (editVisible && Object.keys(selectedDetails).length > 0)) ?
        <AddEditAdvanceSalary
          editDetails={selectedDetails}
          onHide={handleCancel}
          onSave={handleSave}
          fromEdit={editVisible}
        />
        : null}
      {isCancelPopup ? handleCancelPopup() : null}
      { isApprovalLog ? <ApprovalLogPopUp TblData = {approvalData} onClose = {handleApprovalLogCls}/>: null}
      {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => deleteItem.isDraft ? handleDeleteBtn(deleteItem.record, true) : handleDeleteBtn(deleteItem.record, false)} /> : null}
    </Card>
  );
}

export default AdvanceSalaryRequest;
