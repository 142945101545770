import { SEND_OTP } from "constants";

const CheckUserApi = (
  formData,
  authtoken,
  onSuccess,
  onError
) => {
  if(formData.email){
    fetch(SEND_OTP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          onSuccess(data);
        } else if (data.status === "fail") {
          onError(data.message);
        }
      });
  }
};

export default CheckUserApi;
