import logo from "assets/logo.png";
const { UserOutlined } = require("@ant-design/icons");
const { Avatar, Card } = require("antd");
const { Header } = require("antd/es/layout/layout");

function CommonHeaderWithLayout({ action, children }) {
  const address = sessionStorage.getItem("address")
  const companyName = sessionStorage.getItem("companyName")
  const profile = sessionStorage.getItem("profile")
  const serviceType = sessionStorage.getItem("serviceType")
  return (
    <div className="h-full">
      <Header
        style={{
          padding: 0,
          background: "#F5F8F7",
          height: "10%",
        }}
        className="mb-2 w-full"
      >
        {" "}
        <div class="lg:flex lg:items-center lg:justify-between pl-1">
          {/* Left View */}
          {action && <div class="min-w-0 flex-1">{action}</div>}

          {!action && <div class="min-w-0 flex-1"></div>}

          <div class="mt-2 flex lg:mt-0 lg:ml-4 items-center">
            <div class="min-w-0 flex-1">
              <h2 class="text-2xl font-normal leading-7 text-gray-900 sm:truncate sm:text-sm sm:tracking-tight">
                {serviceType==="super_admin"?companyName:""}
              </h2>
              <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                <div class=" flex items-center text-xs text-gray-500 break-all">
                  {serviceType==="super_admin"?address:""}
                </div>
              </div>
              {/* <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                <div class="flex items-center text-xs text-gray-500">
                  Coimbatore, Tamil Nadu - 641041
                </div>
              </div> */}
            </div>
            <span class="sm:ml-3">
              <Avatar shape="square" size={50} icon={<UserOutlined />} src={profile ? profile : logo} />
            </span>
          </div>
        </div>
        <hr></hr>
      </Header>
      
      {children && (
        <Card style={{height: "90%"}}>{children}</Card>
      )}
      
    </div>
  );
}

export default CommonHeaderWithLayout;
