import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const MyplanUpdate = () => {

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/myplans");
  }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', backgroundColor: '#f5f5f5' }}>
        <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '2rem', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
          <h1 style={{ fontSize: '2rem', color: '#333', paddingBottom: '5dvh' }}>Upgrade Required</h1>
          <p style={{ fontSize: '1rem', color: '#666', paddingBottom: '.8dvh' }}>
            You are currently subscribed to the Standard Plan. The feature you are trying to access is not included in your current plan.
          </p>
          <p style={{ fontSize: '1rem', color: 'red' }}>To use this feature, please upgrade to the Premium Plan.</p>
          <button style={{
            marginTop: '1.5rem',
            padding: '0.75rem 2rem',
            backgroundColor: 'rgb(4, 183, 177)',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem'
          }}
          onClick={handleNavigate} // Redirect to the subscription page
          >
            Subscribe Now
          </button>
        </div>
      </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        padding: '50px',
        margin: "20dvh",
    },
    heading: {
        fontSize: '2.6dvw',
        color: '#333',
        marginBottom: '0.625dvw'
    },
    message: {
        fontSize: '1.5dvw',
        color: '#666',
        marginBottom: '0.625dvw'
    },
    image: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
    },
    link: {
        display: 'block',
        marginTop: '20px',
        fontSize: '1.3dvw',
        color: '#007BFF',
        textDecoration: 'none',
    },
};

export default MyplanUpdate;
