import React, { useState, useEffect, useCallback, useRef } from 'react';
import MyButton from 'components/ui/Button/MyButton';
import { Input, Card, Spin, Radio, Select } from 'antd'
import ToastMsg from "components/common/ToastMsg";
import useGetData from "components/common/useGetData";
import { getCustomConfiguration, getSalaryComponentList } from "hooks/api/configurationApi/payrollPeriodApi";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import "../configuration.css";
import { CUSTOM_CONFIGURATION } from 'constants';
import { handleOnKeyDecimal, handleOnKeyNumeric } from 'components/common/validation';
import { salaryDeductEnum } from "components/common/enum";

const lopList = [
    { label: "Gross Salary", value: "gross_salary" },
    { label: "Net Salary", value: "net_salary" }
];

const yesNoList = [
    { label: "Yes", value: 'yes' },
    { label: "No", value: 'no' },
];

const CustomConfiguration = () => {
    const [formData, setFormData] = useState({
        salary_deduction: "",
        pf_limit: 15000, // from where the pf cap need to applied
        cap_amount: 1800, // what amount need to be applied
        lop_deduction: "",
        zero_payroll: "",
        modify_issued_documents: "",
    });
    const [loadings, setLoadings] = useState(false);
    const prevPropsRef = useRef();
    const refs = useRef({});

    const [sltTooltipState, setsltTooltipState] = useState({
        showTooltip: false,
        tooltipPosition: { x: 0, y: 0 },
        omittedValues: []
    });
    const [dropdownList, setDropDownList] = useState({
        salaryComponents: [], // it holds the component type = earning 
    });
    const [data, _message, refetch, loading] = useGetData(getCustomConfiguration);
    const userPermission = checkUserPermissionFunc("configuration", "Custom Configuration", "SubMenu");

    useEffect(() => {
        if (data !== null &&
            data.length > 0 &&
            Object.keys(data[0]).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(data[0]) &&
            JSON.stringify(formData) !== JSON.stringify(data[0])) {
            let Data = data[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: Data?.id ? Data?.id : undefined,
                salary_deduction: Data?.salary_deduction ? Data?.salary_deduction : "",
                pf_limit: Data?.pf_limit ? Data?.pf_limit : 0,
                cap_amount: Data?.cap_amount ? Data?.cap_amount : 0,
                lop_deduction: Data?.lop_deduction ? Data?.lop_deduction : "",
                zero_payroll: Data?.zero_payroll === true ? 'yes' : 'no',
                modify_issued_documents: Data?.modify_issued_documents === true ? 'yes' : 'no',
            }));
            prevPropsRef.current = Data;
            // session data set
            let userCustomConfig = JSON.parse(sessionStorage.getItem("customConfig"));
            let configData = Object.keys(userCustomConfig)?.length > 0 ? userCustomConfig : {};
            if (JSON.stringify(configData) !== JSON.stringify(Data)) {
                let dataList = Object.keys(Data)?.length > 0 ? Data : {};
                sessionStorage.setItem("customConfig", JSON.stringify(dataList));
            }
        }
    }, [data]);

    const getSalaryComponent = async () => {
        try {
            const apiData = await getSalaryComponentList(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    salaryComponents: [...apiData?.data?.filter(item => item?.component_type === "earning")]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    salaryComponents: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                salaryComponents: []
            }))
        }
    }

    useEffect(() => {
        getSalaryComponent();
    }, []);

    const onChange = (stateKey, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [stateKey]: value
        }))
    }

    const handleToggleTooltip = (visible, values, position) => {
        setsltTooltipState((prevState) => ({
            ...prevState,
            showTooltip: visible,
            tooltipPosition: position,
            omittedValues: values
        }))
    };

    const handleMouseEnter = (event, omittedValues) => {
        const rect = event.target.getBoundingClientRect();
        handleToggleTooltip(true, omittedValues, { x: rect.left, y: rect.bottom });
    };

    const handleMouseLeave = () => {
        handleToggleTooltip(false, [], { x: 0, y: 0 });
    };

    const renderMaxTagPlaceholder = (omittedValues) => (
        <div
            onMouseEnter={(event) => handleMouseEnter(event, omittedValues)}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: 'pointer' }}
        >
            +{omittedValues.length}
        </div>
    );

    const renderSelect = (label, statekey, list, showKey = "text", valueKey = "value", isMulti = false) => {
        if (!refs?.current[statekey]) {
            refs.current[statekey] = React.createRef();
        }
        return (
            <>
                <Select
                    showSearch
                    mode={isMulti ? "multiple" : null}
                    disabled = {!userPermission?.edit}
                    showArrow="true"
                    maxTagCount={2}
                    maxTagTextLength={6}
                    style={styles.border_select}
                    value={formData[statekey] ? formData[statekey] : undefined}
                    name={statekey}
                    onChange={(value) => onChange(statekey, value)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={label}
                    maxTagPlaceholder={(omittedValues) => renderMaxTagPlaceholder(omittedValues)}
                    ref={refs.current[statekey]}
                    onSelect={() => {
                        refs?.current[statekey]?.current?.blur();
                    }}
                >
                    {list?.map((option) => (
                        ((valueKey === "id" && option?.is_active === true) || valueKey !== "id") ?
                            <Select.Option key={option[valueKey]} value={option[valueKey]}>{option[showKey]}</Select.Option>
                            : null
                    ))}
                </Select>
                {sltTooltipState?.omittedValues?.length > 0 && sltTooltipState?.showTooltip && (
                    <div className="commonTooltipOfMutliSlt"
                        style={{ position: 'fixed', top: sltTooltipState?.tooltipPosition?.y + 10, left: sltTooltipState?.tooltipPosition?.x }}>
                        <ul className="commonTooltipOfMutliSltDiv">
                            {sltTooltipState?.omittedValues?.map(value => (
                                <li key={value?.key} className="commonTooltipOfMutliSltTxt" style={{ width: "8dvw" }}>{value?.label}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </>
        )
    }

    const renderInputs = (inputType = "", statekeyList, labelList) => {
        return (
            <div className='flex items-center gap-3'>
                {
                    statekeyList?.map((item, index) => {
                        return (
                            <div className='flex items-center' key={item}>
                                <p className='custom_config_sub_txt'>{labelList[index]}</p>
                                <Input
                                    className='ml-3'
                                    style={styles.border_input}
                                    disabled = {!userPermission?.edit}
                                    name={item}
                                    bordered={true}
                                    prefix={inputType === "percentage" ? "%" : inputType === "amount" ? "₹" : null}
                                    type={"text"}
                                    autoComplete='off'
                                    maxLength={inputType === "percentage" ? 5 : inputType === "amount" ? 7 : 50}
                                    value={formData[item]}
                                    onChange={(e) => onChange(item, (inputType === "percentage" || inputType === "amount") ? +e.target.value : e.target.value)}
                                    onKeyDown={inputType === "percentage" ? handleOnKeyDecimal : inputType === "amount" ? handleOnKeyNumeric : null}
                                />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderRadioButton = (key, list) => {
        return (
                <Radio.Group
                    className="custom-radio"
                disabled = {!userPermission?.edit}
                    onChange={(e) => onChange(key, e.target.value)} value={formData[key]}>
                    {list?.map(item => {
                        return (
                            <Radio value={item?.value} key={item?.value}>{item?.label}</Radio>
                        )
                    })}
                </Radio.Group>
        )
    }

    const renderConfigOptions = (label, stateKey, inputType, inputList = [], labelList = [], sltList = [],
        sltShowKey = "", sltValueKey = "", sltMulti = false, notes = "") => {
        return (
            <div className='w-full flex justify-between items-center my-2' style={{ height: "8dvh" }}>
                <p className='custom_config_sub_txt' >{label}</p>
                <div className='flex flex-col justify-end' >
                    {
                        inputType === "radioButton" ? renderRadioButton(stateKey, inputList)
                            : inputType === "renderInputs" ? renderInputs(stateKey, inputList, labelList)
                                : inputType === "renderSelect" ? renderSelect(labelList[0], stateKey, sltList, sltShowKey, sltValueKey, sltMulti)
                                    : null
                    }
                    {notes ? <p className='custom_config_note_txt'>{`Note: ${notes}`}</p> : null}
                </div>
            </div>
        );
    }

    const validateVal = () => {
        let msg = "";
        if ((formData?.pf_limit && !formData?.cap_amount) || (!formData?.pf_limit && formData?.cap_amount)) {
            msg = "Both Salary limit and Cap amount must be entered.";
        } else if(formData?.cap_amount >= formData?.pf_limit) {
            msg = "Both Cap amount must be less than Salary limit.";
        }
        return msg;
    }

    const handleSubmit = useCallback(() => {
        const authtoken = sessionStorage.getItem("token");
        let isValid = validateVal();
        if (isValid === "") {
            let updateFormData = { ...formData };
            updateFormData = {
                ...updateFormData,
                zero_payroll: updateFormData?.zero_payroll === 'yes' ? true : false,
                modify_issued_documents: updateFormData?.modify_issued_documents === 'yes' ? true : false,
            }
            try {
                setLoadings(true)
                fetch(CUSTOM_CONFIGURATION, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${authtoken}`
                    },
                    body: JSON.stringify(updateFormData)
                })
                    .then((response) => response.json())
                    .then(async data => {
                        if (data?.status === "success") {
                            setLoadings(false);
                            refetch();
                            ToastMsg("success", data?.message);
                        }
                        else if (data?.status === "fail") {
                            ToastMsg("error", data?.message);
                            setLoadings(false);
                        }
                    })
                    .catch(error => {
                        setLoadings(false);
                        ToastMsg("error", error);
                    });
            } catch (error) {
                ToastMsg("error", error?.message);
            }
        } else {
            ToastMsg("error", isValid);
        }
    }, [formData]);

    return (
        <Card style={{ height: "100%", overflow: "hidden", position: "relative"}}>
            {loading && (
                <div className="loaderOverlay">
                    <Spin />
                </div>
            )}
            <p className='runpayRoll_Txt border-b py-1'>Custom Configuration</p>
            <div style={{ height: "81dvh" }}>
                <div className='overflow-y-auto' style={{ height: "74dvh" }}>
                    {renderConfigOptions("Salary Deduction", "salary_deduction", "renderSelect", [], ["Select Components"], salaryDeductEnum, "text" , "value")}
                    {renderConfigOptions("Provident Fund Cap Amount", "amount", "renderInputs", ["pf_limit", "cap_amount"], ["Basic Salary Limit", "Cap Amount"],
                        [], "", "", false, "Conditions apply to values greater than or equal to the value stated in the above field")}
                    {renderConfigOptions("LOP Deduction", "lop_deduction", "radioButton", lopList)}
                    {renderConfigOptions("Allow To Run Payroll For Zero Attendance", "zero_payroll", "radioButton", yesNoList)}
                    {renderConfigOptions("Allow User To Delete The Issued Documents", "modify_issued_documents", "radioButton", yesNoList)}
                </div>
                <div className="w-full flex justify-center items-start" style={{ height: "7dvh" }}>
                    <MyButton htmlType="submit" disabled={!userPermission?.edit}
                        bgColor={userPermission?.edit ? "#334B49" : "#cbcbcb"}
                        onClick={handleSubmit} label={"Save"}
                        loading={loadings} paddingX={"0 1.2vw"}
                        marginRight={"0.625vw"} />
                </div>
            </div>
        </Card>
    );
}

const styles = {
    border_input: {
        width: "8dvw",
        borderRadius: "0px",
        border: "1px solid #CACACA",
        paddingLeft: "5px"
    },
    border_select: {
        width: "18dvw",
        borderRadius: "0px",
        border: "1px solid #CACACA",
    }
}

export default CustomConfiguration;