import React, { useState, useEffect, useCallback, useRef } from "react";
import { holidaytype } from "../../../components/common/enum";
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from "components/ui/Button/MyButton";
import { Card, Form, Input, Select, DatePicker, Checkbox, Tooltip } from "antd";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { HOLIDAY_SETUP } from "constants";
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from "components/common/validation";
import dayjs from "dayjs";
import { disabledPastDate } from "components/common/validation";

export const AddHolidaySetup = () => {
  const dateFormat = "YYYY-MM-DD";
  const [formData, setFormData] = useState({
    code: "",
    holiday_name: "",
    holiday_type: "",
    date: undefined,
    notes: "",
    is_active: true
  });
  const [loading, setLoading] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const prevPropsRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        code: editDetails.code ? editDetails.code : "",
        holiday_name: editDetails.holiday_name ? editDetails.holiday_name : "",
        holiday_type: editDetails.holiday_type ? editDetails.holiday_type : "",
        date: editDetails.date ? dayjs(editDetails.date, dateFormat) : undefined,
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
      }));
      form.setFieldsValue({
        code: editDetails.code ? editDetails.code : "",
        holiday_name: editDetails.holiday_name ? editDetails.holiday_name : "",
        holiday_type: editDetails.holiday_type ? editDetails.holiday_type : "",
        date: editDetails.date ? dayjs(editDetails.date, dateFormat) : undefined,
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData])

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });
  };

  const onChange = (date, dateString) => {
    setFormData({
      ...formData,
      date: date,
    });
    form.setFieldsValue({
      ...formData,
      date: date,
    });
  };

  const renderInput = (label, statekey, testID = '', maxLength = 150, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete='off'
        style={styles.textField}
        disabled = {statekey === "code"}
        type={"text"}
        name={statekey}
        value={formData[statekey]}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testID={testID}
      />
      </>
    )
  }

  const handleBackCancel = useCallback(() => {
    form.resetFields();
    navigate('/configuration/holiday');
  }, [navigate, form])

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    handleBackCancel();
  }

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    try {
      setLoading(true)
      let updatedFormData = { ...formData };
      updatedFormData.date = updatedFormData.date ? dayjs(updatedFormData.date, "DD-MM-YYYY").format("YYYY-MM-DD") : null;
      if (isDraftClked) {
        updatedFormData = { ...updatedFormData, is_draft: true };
      } else {
        updatedFormData = { ...updatedFormData, is_draft: false };
      }
      fetch(HOLIDAY_SETUP, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoading(false);
            setFormData({
              code: "",
              Deduction_name: "",
              deduct_from: "",
              fixed: false,
              is_active: true,
              share: "",
              deduction_value: 0,
              notes: "",
            })
          }
          else if (data.status === "fail") {
            setLoading(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  }, [formData, fromEdit, handleBackCancel]);

  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active', 'company_id'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Holiday Setup" : "Add Holiday Setup"}</p>
        </div>
        <Checkbox
          disabled = {fromEdit ? !isActive : true }
          checked={formData.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
          data-testid={'hs_sts'}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          data-testid='hs_form'
        >
          <div className='w-full grid grid-rows-2 grid-cols-3 gap-5 m-1'>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
            ]}
            >
              {renderInput('Holiday Code', "code", 'hs_code', 10, true)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="holiday_name"
              rules={[{ required: true, message: "This field is required" },
                { validator: noBlankSpacesValidator }
            ]}
            >
              {renderInput('Holiday Name', "holiday_name", 'hs_name', 50, true)}
            </Form.Item>
            <Form.Item
              name="holiday_type"
              style={styles.formItem}
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Holiday Type <span className='requiredTxt'>*</span></div>
              <Select
                style={styles.textField}
                value={formData.holiday_type}
                name={"holiday_type"}
                onChange={(value) => handleChange("holiday_type", value)}
                data-testid={'hs_type'}
              >
                {holidaytype.map((option) => (
                  <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={styles.formItem}
              name="date"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <div className='commonInputTlt'>Date <span className='requiredTxt'>*</span></div>
              <DatePicker
                style={styles.textField}
                name="date"
                value={formData.date ? formData.date : undefined}
                onChange={onChange}
                format={"DD-MM-YYYY"}
                allowClear={true}
                inputReadOnly={true}
                data-testid={'hs_from'}
                disabledDate={disabledPastDate}
                placeholder = ""
              />
            </Form.Item>
            <Form.Item name="notes" style={styles.formItem}>
              {renderInput('Notes', "notes", 'hs_notes', 150)}
            </Form.Item>
            <Form.Item style={styles.emptyFormItem} />
          </div>
          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className='flex justify-end items-end mr-2'>
                <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                {fromEdit ? null
                  : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_draft" />}
                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loading} paddingX={"0 1.2vw"} testID="hs_save" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID='hs' /> : null}
    </Card>
  );
};

const styles = {
  textField: {
    width: "23vw"
  },
  formItem: {
    margin: "1vw"
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default AddHolidaySetup;
