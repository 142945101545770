import React from "react";
import { Button } from "antd";

const IconButtonWarning = ({
  label = "",
  onClick = () => {},
  className = "",
  icon = "",
  type = "primary",
  htmlType = "",
}) => (
  <Button
    style={{ background: "#D94854", borderColor: "#D94854", borderRadius: 0 }}
    htmlType={htmlType}
    icon={icon}
    type={type}
    className={className}
    onClick={onClick}
  >
    {label}
  </Button>
);

export default IconButtonWarning;
