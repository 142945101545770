import { ASSET_EMPLOYEE_AUDIT } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getAssetEmpAuditPagination = async ({
    pageNo,
    pageSize,
    query,
    search,
    sorting,
}) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        let url = ASSET_EMPLOYEE_AUDIT;

        if (pageNo) {
            url = prefillUrl(url);
            url = `${url}page_no=${pageNo}`;
        }

        if (pageSize) {
            url = prefillUrl(url);
            url = `${url}&page_size=${pageSize}`;
        }

        if (query) {
            url = prefillUrl(url);
            url = `${url}&${query}`;
        }

        if (search) {
            url = prefillUrl(url);
            url = `${url}&search=${search}`;
        }

        if (sorting) {
            url = prefillUrl(url);
            url = `${url}&${sorting}`;
        }

        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};


export const deleteAssetEmpAudityId = async (id) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        let url = ASSET_EMPLOYEE_AUDIT;
        const requestOptions = {
            method: "PUT",
            headers: {
                Authorization: `token ${authtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
                is_active: 'False'
            }),
        };
        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};