import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getSubordinateAttendanceList } from "hooks/api/employeeApi/employeeSubordinateApi";
import dayjs from 'dayjs';
import { formatTime12Hour } from 'components/common/validation';

function Attendance({employee_id}) {
  const authtoken = sessionStorage.getItem("token");
  let query = `employee_id=${employee_id}`;
  const pageSize = 10;
  const searchText = "";
  const pageNo = 1;

  const [dataUpdated, setDataUpdated] = useState(false);
  const [sorting, setSorting] = useState("");
  const tableContainerHeight = "58dvh";

    useEffect(() => {
      if (!authtoken) {
        window.location.href = "/";
      }
    }, [authtoken]);

  const [data, pageInfo, loading, _message, refetch] = useApiData(getSubordinateAttendanceList, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
    refetch(pagination.current,
      pageSize,
      query,
      searchText,
      sortQuery);
    setSorting(sortQuery);
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
          (<p>{record?.date ? dayjs(record?.date).format('DD-MM-YYYY') : "-"}</p>)
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
      (record?.shift?.length > 15 ?
        <Tooltip title={record?.shift}>{<span>{record?.shift?.slice(0, 15) + "..."}</span>}</Tooltip>
        : <p>{record?.shift ? record?.shift : "-"}</p>)
    },
    {
      title: "Location",
      dataIndex: "notes",
      key: "notes",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
        (record?.location?.length > 15 ?
          <Tooltip title={record?.location}>{<span>{record?.location?.slice(0, 15) + "..."}</span>}</Tooltip>
          : <p>{record?.location ? record?.location : "-"}</p>)
    },
    {
      title: "Check In",
      key: "check_in",
      dataIndex: "check_in",
      sorter: true,
      width: "12.5%",
      render: (_, record) =>
      (<p>{(record?.check_in && record?.check_in !== "NaT") ? formatTime12Hour(record?.check_in) : "-"}</p>),
      align: 'center',
    },
    {
        title: "Check Out",
        key: "check_out",
        dataIndex: "check_out",
        sorter: true,
        width: "12.5%",
        render: (_, record) =>
          (<p>{(record?.check_out && record?.check_out !== "NaT") ? formatTime12Hour(record?.check_out) : "-"}</p>),
        align: 'center',
      },
      {
        title: "Overtime",
        key: "overtime",
        dataIndex: "overtime",
        sorter: true,
        width: "12.5%",
        render: (_, record) =>
          (<span>{(record?.overtime && record?.overtime !== "NaT") ?
             dayjs(record.overtime, 'H:mm:ss').format('HH:mm:ss') : "-"}</span>),
        align: 'center',
      },
      {
        title: "Break Hrs",
        key: "break_hrs",
        dataIndex: "break_hrs",
        sorter: true,
        width: "11.5%",
        render: (_, record) => (<span>{(record.break_hrs && record?.break_hrs !== "NaT") ? dayjs(record.break_hrs, 'H:mm:ss').format('HH:mm:ss') : "-"}</span>),
        align: 'center',
      },
      {
        title: "Total working Hrs",
        key: "total_working_hours",
        dataIndex: "total_working_hours",
        sorter: true,
        width: "13.5%",
        render: (_, record) =>
          (<span>
          {(record.total_working_hours && record?.total_working_hours !== "NaT" ) ? dayjs(record.total_working_hours, 'H:mm:ss').format('HH:mm:ss') : "-"}</span>),
        align: 'center',
      },
  ];

  const styles = {
    fltContainer: {
      height: tableContainerHeight,
    },
  }

  return (
        <Card style={styles.fltContainer}>
        <Table 
          columns={columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 150px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </Card>
  );
}

export default Attendance;
