import { LOGOUT_URL } from 'constants';

export const logout = async () => {
   
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` }
    };

    return await fetch(LOGOUT_URL, requestOptions).then((response) => response.json()).then(data=>data);
    
}