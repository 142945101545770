import React, { useState } from "react";
import { Card, Col, Form, Input, Row, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Pattern from "components/ui/Images/Pattern";
import CheckUserApi from "./apihook/CheckUserApi";
import MySecondaryLargeButton from "components/ui/Button/MySecondaryLargeButton";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { emailValidation } from "components/common/validation";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loadings, setLoadings] = useState(false);
  const authtoken = sessionStorage.getItem("token");
  const id = state ? parseInt(state.id) : 0;
  const email = state ? state.email:"";
  
  const onFinish = (values) => {
    setLoadings(true);
    CheckUserApi(
      values,
      authtoken,
      (data) => {
        //onSuccess
        setLoadings(false);
        ToastMsg("success", data.message);
        navigate("/otpverification", { state: { id: data.user_id, email: values.email } });
      },
      (error) => {
        //onError
        setLoadings(false);
        ToastMsg("error", error);
      }
    );
  }

  return (
    <div>
     
      <Row>
        <Col flex={1}>
          <Pattern className="h-screen w-full pattern-text"></Pattern>
        </Col>
        <Col flex={4}>
          <div
            className="flex flex-col"
            style={{ padding: "30px", height: "100vh" }}
          >
            <img src={ImagePaths.loginLogo.default} className = "login_Logo" alt={"App Logo"} />
            <div className="flex items-center justify-center">
              <Form
                layout="vertical"
                name="basicform"
                onFinish={onFinish}
                onSubmit={(e) => e.preventDefault()}
                colon={false}
                requiredMark={false}
                initialValues={{ remember: true }}
              >
                <Card
                  className=" drop-shadow-lg items-center justify-center px-5"
                  bordered={false}
                  style={{ width: 400,  height: 350}}
                >
                  <div className="grid grid-cols-9">
                    <div className="col-span-9">
                      <div className="pb-2 flex items-center">
                          <img src={ImagePaths.backArrow.default} alt="Back" className = "forgot_BackIcon" onClick={() => navigate("/")}/> 
                          <span className = "login_tlt">Forgot Password</span>
                      </div>

                      <p className="userPermission-checkbox-subTlt">
                        Enter your registered email ID to send OTP.
                      </p>
                    </div>
                  </div>

                  <label className="block mt-6">
                    <Form.Item
                      name="email"
                      rules={[{ required: true, message: 'Registered mail Id is required' },
                      {validator: emailValidation}
                    ]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                      initialValue={email ? email:""}
                    >
                      <Input size = "large" placeholder="Mail ID" />
                    </Form.Item>
                  </label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="items-center justify-center my-2">
                      <Form.Item>
                        <MySecondaryLargeButton
                          type="primary"
                          label={loadings?"SEND OTP...":"SEND OTP"}
                          loading={loadings}
                          className="mt-2 items-center"
                          htmlType="submit"
                        />
                      </Form.Item>
                    </div>
                  </Space>
                </Card>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
     
    </div>
  );
};

export default ForgotPassword;
