import { DASHBOARD_ETYPE } from "constants";

const GetETypeApi = (formData, authtoken, onSuccess, onError) => {  
  fetch(DASHBOARD_ETYPE+`?month=${formData}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `token ${authtoken}`,
    },
    // body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};

export default GetETypeApi;
