import { PAYROLL_LEAVE_APPROVAL } from "constants";
import { prefillUrl } from "utils/StringUtils";

export const getLeaveApprovalPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = PAYROLL_LEAVE_APPROVAL;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const handleApprovedStatusById = async (updatedSataus) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    let url = PAYROLL_LEAVE_APPROVAL;

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `token ${authtoken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedSataus),
    };
    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};