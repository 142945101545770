import React from "react";
import { Button, Tooltip } from "antd";

const IconButton = ({
  label = "",
  onClick = () => { },
  className = "",
  icon = "",
  type = null,
  htmlType = "",
  width = "2vw",
  height = "2vw",
  title = "",
  disabled = false,
  testID = '',
  tooltipPostion = "bottom"
}) => (
  <Tooltip title={title} placement={tooltipPostion}>
    <Button
      disabled={disabled}
      style={{ width: width, height: height, border: "none", outline: "none", boxShadow: "none", verticalAlign: 'middle' }}
      htmlType={htmlType} icon={icon} type={type} className={className} onClick={onClick} data-testid={testID}>
      {label}
    </Button>
  </Tooltip>
);

export default IconButton;
