
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, Table, Tooltip, Space, } from "antd";
import IconButton from "components/ui/Button/IconButton";
import moment from "moment";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getdocumentspagination } from "hooks/api/configurationApi/holidaySetupApi";
import { ADMIN_DOCUMENTS } from "constants";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import AdminFilter from "../AdminFilter";

const initialData = {
  document_code: "",
  document_name: "",
  is_active: "",
}

function Documents() {
  let query = "";
  let queryRef = useRef("");
  const pageSize = 10;
  const authtoken = sessionStorage.getItem("token");

  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState();
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight =  "72vh";
  const navigate = useNavigate();

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const queryString =
    "code=" + (filterDatas.document_code ? filterDatas.document_code : "") +
    "&document_name=" + (filterDatas.document_name ? filterDatas.document_name : "") +
    "&is_active=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const userPermission = checkUserPermissionFunc("administrator", "Organization Documents", "SubMenu");
  const [data, pageInfo, loading, _message, refetch] = useApiData(getdocumentspagination, {
    pageNo,
    pageSize,
    queryString,
    searchText,
    sorting
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message, _message.errorMsgText]);

  const addClick = () => {
    setEditVisible(false);
    setSelectedDetails({});
    navigate('/admin/organizationdocuments/add', { state: { fromEdit: false, editDetails: {}, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!")
    }
    else if (!editVisible) {
      setEditVisible(true);
      navigate('/admin/organizationdocuments/add', { state: { fromEdit: true, editDetails: details, isActive: userPermission?.active !== undefined ? userPermission?.active : true } });
      setSelectedDetails(details);
    }
  };

  const handleSearch = () => {
    setEditVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(1,
      pageSize,
      queryString,
      searchText,
      sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    refetch(pagination.current,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      sortQuery);
    setSorting(sortQuery)
  }

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(1,
      pageSize,
      `code=${data.document_code ? data.document_code : ""}&document_name=${data.document_name ? data.document_name : ""}&is_active=${data.is_active !== undefined ? data.is_active : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const columns = [
    {
      title: "Document Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: "14.28%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (record.code?.length > 20 ?
          <Tooltip title={record.code}>{<span style={{ color: colors }}>{record.code?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{record.code ? record.code : "-"}</p>)
      }
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
      key: "document_name",
      sorter: true,
      width: "17.96%",
      fixed: "left",
      render: (_, record) => {
        let colors = record?.is_active ? "black" : "#CACACA";
        return (record.document_name?.length > 20 ?
          <Tooltip title={record.document_name}>{<span style={{ color: colors }}>{record.document_name?.slice(0, 20) + "..."}</span>}</Tooltip>
          : <p style={{ color: colors }}>{record.document_name ? record.document_name : "-"}</p>)
      }
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      sorter: true,
      width: "17.88%",
      fixed: "left",
      render: (_, record) =>
      (record?.comments?.length > 28 ?
        <Tooltip title={record?.comments}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.comments?.slice(0, 25) + "..."}</span>}</Tooltip>
        : <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.comments ? record?.comments : "-"}</p>),
      fixed: 'left',
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      width: "12.28%",
      render: (_, record) =>
        record.is_active === true ? (
          <span style={{ color: "black" }}>Active</span>
        ) : (
          <span style={{ color: "#CACACA" }}>Inactive</span>
        ),
      align: 'center',
      fixed: "left",
    },
  ];
  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "9%",
      align: 'center',
      fixed: "left",
      render: (_, record) => (  
        <div className="flex justify-center items-center">
          <Space size={"middle"}>
            <IconButton
              title="Edit"
              icon={<img src={ImagePaths.edit.default} alt="Edit" />}
              onClick={() => (loading ? "" : handleEditClick(record))}
              testID={'d_btn_edit'}
            />
            <a href={record.document_url} target="_blank">
              <IconButton
                title="Download"
                icon={<img src={ImagePaths.downloadArrow.default} alt="Download" />}
                testID={'d_btn_Download'}
              />
            </a>
          </Space>
        </div>
      ),
    })
  }

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const handleBackToHome = () => {
    setSorting("");
    setPageNo(1);
    queryRef.current = "";
    refetch(1,
      pageSize,
      queryRef.current,
      queryString,
      searchText,
      "");
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const printdata =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&code=" + (filterDatas.document_code ? filterDatas.document_code : "") +
    "&document_name=" + (filterDatas.document_name ? filterDatas.document_name : "") +
    "&is_active=" + (filterDatas.is_active !== undefined ? filterDatas.is_active : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"} >
        <CommonTblHeader pageName={"Organization Documents"}
          pageInfo={pageInfo}
          handleSearch={handleSearch} selectedDetails={selectedDetails} addClick={addClick}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          handleBackToHome={handleBackToHome}
          url={ADMIN_DOCUMENTS} query={printdata}
          isAddRequired = {userPermission?.add ? true : false}
          isDraftRequired = {false}
          testID = {'documents'}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <AdminFilter
              pageName={"organizationdocument"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
        />
        </div>
      </div>
    </Card>
  );
}

export default Documents;
