import { useState, useEffect, useContext } from "react";
import { getEmployer } from "hooks/api/adminApi/employerAPI";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";

const useGetEmployer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // calling refetch will force a rerender on the hook
  const [shouldRefetch, setShouldFetch] = useState(1);
  const navigate = useNavigate();
  const { logoutauth } = useContext(AuthContext);

  const [message, setMessage] = useState({
    isErrorMsg: false,
    errorMsgText: ""
  });

  function refetch() {
    setShouldFetch(shouldRefetch + 1);
  }

  useEffect(() => {
    async function fetchData() {
      setMessage({
        isErrorMsg: false,
        errorMsgText: ""
      });
      setLoading(true);
      try {
        const apiData = await getEmployer();
        if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
          setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
          navigate("/");
          logoutauth();
        }
        if (apiData) {
          if (apiData.status === "fail") {
            setMessage({ isErrorMsg: true, errorMsgText: apiData.message });
          } else if (apiData.data) {
            setData(apiData.data);
          }
        }
      } catch (error) {
        setMessage({ isErrorMsg: true, errorMsgText: "Error fetching data" });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [shouldRefetch]);

  return [data, message, loading, refetch];
};

export default useGetEmployer;
