import { DatePicker, Form, Input, Select, Button, Checkbox, Upload, Tooltip, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/en_US';
import { getEmployeeApprover } from 'hooks/api/employeeApi/getEmployeeResignation';
import { DownloadOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import MyButton from 'components/ui/Button/MyButton';
import { ImagePaths } from 'utils/ImagePath';
import ToastMsg from 'components/common/ToastMsg';
import { ApprovedStatus, imgUploadSize, letterStatusEnum, SettlementStatus } from 'components/common/enum';
import { disabledPastDate, extractImageName } from 'components/common/validation';
import { LETTER_TYPE_API } from 'constants';
import { prefillUrl } from 'utils/StringUtils';
import { downloadPDF, getMethod } from 'components/common/CommonFuntion';
import { getEmployee } from 'hooks/api/employeeApi/employeeInformationApi';
import IconButton from 'components/ui/Button/IconButton';

const ResignationFinalStep = ({ formData, setFormData, triggerNext, triggerPrevious, ResignationSave, isLoading }) => {

    const [Approver, setApprover] = useState([]);
    const [FinalApprover, setFinalApprover] = useState([]);
    const [IsButtonDis, setIsButtonDis] = useState(false);
    const [Isloader, setIsloader] = useState(false);
    const [form] = Form.useForm();

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        form.setFieldsValue({
            ...formData,
            [name]: value,
        });
    };
    const [existingFiles, setExistingFiles] = useState([]);
    const [letterTypeApiList, setLetterTypeApiList] = useState([]);
    const [empList, setempList] = useState([]);
    const formRef = useRef(null);
    const [isDisable, setIsDisabled] = useState(false);
    const hideCursor = useRef(null);
    const hideCursorRef = useRef([]);
    const dateFormat = "YYYY-MM-DD";

    useEffect(() => {
        clearState();
        getapproverlist();
        getLetterTypeApi();
        employee();
    }, [])

    useEffect(() => {
        setIsButtonDis(false)
    }, [isLoading])

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            InterviewedBy: formData?.finalApproverDetails?.interview_by,
            Exit_Interview_date: formData?.finalApproverDetails?.interview_date ? dayjs(formData?.finalApproverDetails?.interview_date, dateFormat) : undefined,
            Exit_interview_status: formData?.finalApproverDetails?.interview_status,
            Exit_interview_comments: formData?.finalApproverDetails?.interview_comments,
            Exit_verified_by: formData?.finalApproverDetails?.verified_by,
            Exit_verified_date: formData?.finalApproverDetails?.verified_date ? dayjs(formData?.finalApproverDetails?.verified_date, dateFormat) : undefined,
            verified_status: formData?.finalApproverDetails?.verified_status,
            verified_comments: formData?.finalApproverDetails?.verified_comments,
            final_settlement_date: formData?.finalApproverDetails?.final_settlement_date ? dayjs(formData?.finalApproverDetails?.final_settlement_date, dateFormat) : undefined,
            final_settlement_status: formData?.finalApproverDetails?.final_settlement_status,
            final_settlement_comments: formData?.finalApproverDetails?.final_settlement_comments,
            onboarding_document_details: formData?.resignationData?.resignation_docs
        }));
        form.setFieldsValue({
            ...formData.finalApproverDetails,
            InterviewedBy: formData?.finalApproverDetails?.interview_by,
            Exit_Interview_date: formData?.finalApproverDetails?.interview_date ? dayjs(formData?.finalApproverDetails?.interview_date, dateFormat) : undefined,
            Exit_interview_status: formData?.finalApproverDetails?.interview_status,
            Exit_interview_comments: formData?.finalApproverDetails?.interview_comments,
            Exit_verified_by: formData?.finalApproverDetails?.verified_by,
            Exit_verified_date: formData?.finalApproverDetails?.verified_date ? dayjs(formData?.finalApproverDetails?.verified_date, dateFormat) : undefined,
            verified_status: formData?.finalApproverDetails?.verified_status,
            verified_comments: formData?.finalApproverDetails?.verified_comments,
            final_settlement_date: formData?.finalApproverDetails?.final_settlement_date ? dayjs(formData?.finalApproverDetails?.final_settlement_date, dateFormat) : undefined,
            final_settlement_status: formData?.finalApproverDetails?.final_settlement_status,
            final_settlement_comments: formData?.finalApproverDetails?.final_settlement_comments,
            onboarding_document_details: formData?.resignationData?.resignation_docs
        })
    }, [formData.finalApproverDetails]);

    useEffect(() => {
        if (formData?.resignationData?.is_process_finished) {
            setIsDisabled(true)
        }
    }, [form, formData]);

    const clearState = () => {
        setFormData({
            ...formData,
            InterviewedBy: "",
            Exit_Interview_date: undefined,
            Exit_interview_status: "",
            Exit_interview_comments: "",
            Exit_verified_by: "",
            Exit_verified_date: undefined,
            verified_status: "",
            verified_comments: "",
            final_settlement_date: undefined,
            final_settlement_status: "",
            final_settlement_comments: "",
        });
    };

    const getLetterTypeApi = async () => {
        try {
            let url = prefillUrl(LETTER_TYPE_API);
            url = `${url}${`drop_down=True`}`;

            const apiData = await getMethod(url);
            if (apiData && apiData.status === "success" && apiData.data) {
                const filteredData = apiData.data.filter(item => item.process_type_name === "off_bording");
                setLetterTypeApiList([...filteredData]);
            } else {
                setLetterTypeApiList([]);
                ToastMsg("error", apiData?.message);
            }
        } catch (error) {
            setLetterTypeApiList([]);
            ToastMsg("error", error?.message);
        }
    };

    const employee = async () => {
        setempList([])
        setIsloader(true)
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setempList([...apiData.data])
                setIsloader(false)
            } else {
                setempList([])
                setIsloader(false)
                ToastMsg("error", apiData?.message);
            }
        } catch (error) {
            setempList([])
            setIsloader(false)
            ToastMsg("error", error?.message);
        }
    };

    const getapproverlist = async () => {
        setApprover([])
        setFinalApprover([])
        try {
            const apiData = await getEmployeeApprover(`drop_down=True`);
            if (apiData.data && apiData.status === "success") {
                setApprover(apiData.data?.approver?.length > 0 ? [...apiData.data.approver] : [])
                setFinalApprover(apiData.data?.final_approver?.length > 0 ? [...apiData.data?.final_approver] : [])
            } else {
                setApprover([])
                setFinalApprover([])
                ToastMsg("error", apiData?.message);
            }
        } catch (error) {
            setApprover([])
            setFinalApprover([])
            ToastMsg("error", error?.message);
        }
    };

    const extractFileDetails = (file) => {
        if (typeof file === "string") {
            // URL-based file
            const fileName = file.split('/').pop().split('?')[0];
            const format = fileName.split('.').pop().toLowerCase();
            return { name: fileName, format, size: null };
        } else if (file.originFileObj) {
            // Object-based file with originFileObj
            return { name: file.name, format: file.type.split('/').pop().toLowerCase(), size: file.size };
        }
        return { name: '', format: '', size: null };;
    };

    const checkForDuplicateFiles = (newFile) => {
        if (typeof newFile === "object" && newFile.originFileObj) {
            const newFileDetails = extractFileDetails(newFile);

            return existingFiles?.some(existingFile => {
                if (existingFile) {
                    const existingFileDetails = extractFileDetails(existingFile);
                    return (
                        newFileDetails.name === existingFileDetails.name &&
                        newFileDetails.format === existingFileDetails.format &&
                        newFileDetails.size === existingFileDetails.size
                    );
                }
                return false;
            });
        }
        return false;
    };

    const handleOnboardingDocsChange = (name, index, value) => {
        let newItems = [...formData?.onboarding_document_details];
        newItems[index][name] = value;
        if (name === "uploaded_documents" && Array.isArray(value) && value.length > 0) {
            const isDuplicate = checkForDuplicateFiles(value[0]);
            if (isDuplicate) {
                ToastMsg("error", "Duplicate file detected. Please upload a different file.");
                return;
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            onboarding_document_details: newItems
        }));
        form.setFields([
            {
                name: ['onboarding_document_details', index, name],
                value: name === "send_mail" ? value === true ? "True" : "False" : value,
                errors: undefined,
            }
        ]);
    };

    const checkForOnboardDocumentDuplicates = (documents) => {
        const encounteredLetterType = new Set();

        for (const doc of documents) {
            const { letter_type } = doc;

            // Check for duplicates for letter_type
            if (letter_type) {
                if (encounteredLetterType.has(letter_type)) {
                    return "Same Letter Type Found";
                }
                encounteredLetterType.add(letter_type);
            }
        };
        return "";
    }

    const handleRemove = (index) => {
        const newItems = [...formData.onboarding_document_details];
        newItems.splice(index, 1);
        setFormData((prevData) => ({
            ...prevData,
            onboarding_document_details: newItems
        }));
    };

    const handleAdd = () => {
        const allPreviousLevelsFilled = formData.onboarding_document_details?.every((item) => {
            return item.letter_provided_date && item.letter_type && item.processed_by && item.approved_by && item.letter_status && item.document_comments;
        });
        let isDuplicate = checkForOnboardDocumentDuplicates(formData.onboarding_document_details);
        if (allPreviousLevelsFilled && isDuplicate === "") {
            const newItems = [...formData.onboarding_document_details,
            { letter_provided_date: "", letter_type: "", processed_by: "", approved_by: "", letter_status: "", is_send_mail: "", document_comments: "" },];
            setFormData((prevData) => ({
                ...prevData,
                onboarding_document_details: newItems
            }));
        } else {
            ToastMsg("error", isDuplicate ? isDuplicate : "Please fill all previous levels before adding a new one.");
        }
    };

    const beforeUpload = (file) => {
        const fileSizeMB = file.size / (1024 * 1024);
        const isWordDocument = file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        if (!isWordDocument) {
            ToastMsg("error", "Only Pdf & Word documents are supported");
            return false;
        }
        if (fileSizeMB > imgUploadSize) {
            ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
            return false;
        }
        return true;
    };

    const handleSubmit = (e, send_mail = false, move_to_employee = false) => {
        e.preventDefault();
        setIsButtonDis(true)
        form.validateFields()
            .then((values) => {
                const duplicateMsg = values?.onboarding_document_details?.length >= 2 ? checkForOnboardDocumentDuplicates(values.onboarding_document_details) : "";
                if (duplicateMsg !== "") {
                    ToastMsg("error", duplicateMsg);
                    setIsButtonDis(false)
                    return;
                } else {
                    ResignationSave(send_mail, move_to_employee, false)
                }
            }).catch((errorInfo) => {
                ToastMsg("error", "Form validation failed. Please check the required fields.");
                setIsButtonDis(false)
            });
    }

    const handleFinish = () => {
        setIsDisabled(true)
        triggerNext()
    }

    return (
        <div className='h-5/6' style={{ margin: "16px 0px" }}>
            <Form
                form={form}
                ref={formRef}
                requiredMark={false}
                className='w-full'
                name="basicform"
                initialValues={formData}
                onFinish={handleFinish}
                onSubmit={(e) => e.preventDefault()}
                data-testid='hs_form'
            >
                <div className="w-full" style={{ height: "65vh", overflowY: "scroll" }}>
                    {Isloader  && (
                        <div className="loaderOverlay">
                            <Spin />
                        </div>
                    )}
                    {isLoading  && (
                        <div className="loaderOverlay">
                            <Spin />
                        </div>
                    )}
                    <div className='ml-2' style={styles.header}>Exit Interview details</div>
                    <div style={styles.direction}>
                        <Form.Item
                            name="InterviewedBy"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Interviewed By <span className='requiredTxt'>*</span></div>
                            <Select
                                style={styles.textField}
                                value={formData.InterviewedBy}
                                disabled={isDisable}
                                name={"InterviewedBy"}
                                onChange={(value) => handleChange("InterviewedBy", value)}
                                data-testid={'hs_approver'}
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) => {
                                    const childrenText = option.children;
                                    return typeof childrenText === 'string' &&
                                        childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                                ref={(el) => {
                                    hideCursor.current = el;
                                }}
                                onSelect={() => {
                                    if (hideCursor.current) {
                                        hideCursor.current.blur();
                                    }
                                }}
                            >
                                {empList.map((option) => (
                                    (option.is_active === true || option.id === formData?.InterviewedBy) ?
                                        <Select.Option key={option.id} value={option.id}>
                                            {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                        </Select.Option> : null
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={styles.formItem}
                            name="Exit_Interview_date"
                        >
                            <div className='commonInputTlt'>Exit Interview Date <span className='requiredTxt'>*</span></div>
                            <DatePicker
                                style={styles.textField}
                                name="Exit_Interview_date"
                                locale={locale}
                                value={formData.Exit_Interview_date ? formData.Exit_Interview_date : dayjs()}
                                onChange={(date) => handleChange("Exit_Interview_date", date)}
                                format={"DD-MM-YYYY"}
                                allowClear={true}
                                disabled={isDisable}
                                disabledDate={disabledPastDate}
                                data-testid={'hs_from'}
                                placeholder=""
                            />
                        </Form.Item>
                        <Form.Item
                            name="Exit_interview_status"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Exit Interview Status<span className='requiredTxt'>*</span></div>
                            <Select
                                style={styles.textField}
                                value={formData.Exit_interview_status}
                                name={"Exit_interview_status"}
                                disabled={isDisable}
                                onChange={(value) => handleChange("Exit_interview_status", value)}
                                data-testid={'hs_final_approver'}
                            >
                                {ApprovedStatus.map((option) => (
                                    <Select.Option key={option.id} value={option.value}>{option.text}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='w-full'>
                        <Form.Item
                            name="Exit_interview_comments"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Comments<span className='requiredTxt'>*</span></div>
                            <Input.TextArea
                                bordered="true"
                                className="mt-2 textarea"
                                rows={3}
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                                maxLength={150}
                                disabled={isDisable}
                                value={formData.Exit_interview_comments ? formData.Exit_interview_comments : ""}
                                placeholder="Enter Exit interview Comments"
                                name="Exit_interview_comments"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={'rg_reason'}
                            />
                        </Form.Item>
                    </div>
                    <div style={styles.direction}>
                        <Form.Item
                            name="Exit_verified_by"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Verified By<span className='requiredTxt'>*</span></div>
                            <Select
                                style={styles.textField}
                                value={formData.Exit_verified_by}
                                name={"Exit_verified_by"}
                                disabled={isDisable}
                                onChange={(value) => handleChange("Exit_verified_by", value)}
                                data-testid={'hs_approver'}
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) => {
                                    const childrenText = option.children;
                                    return typeof childrenText === 'string' &&
                                        childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                                ref={(el) => {
                                    hideCursor.current = el;
                                }}
                                onSelect={() => {
                                    if (hideCursor.current) {
                                        hideCursor.current.blur();
                                    }
                                }}
                            >
                                {empList.map((option) => (
                                    (option.is_active === true || option.id === formData?.Exit_verified_by) ?
                                        <Select.Option key={option.id} value={option.id}>
                                            {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                        </Select.Option> : null
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={styles.formItem}
                            name="date"
                        >
                            <div className='commonInputTlt'>Verified Date <span className='requiredTxt'>*</span></div>
                            <DatePicker
                                style={styles.textField}
                                name="Exit_verified_date"
                                locale={locale}
                                disabled={isDisable}
                                value={formData.Exit_verified_date ? formData.Exit_verified_date : dayjs()}
                                onChange={() => { }}
                                format={"DD-MM-YYYY"}
                                allowClear={true}
                                data-testid={'hs_from'}
                                disabledDate={disabledPastDate}
                                placeholder=""
                            />
                        </Form.Item>
                        <Form.Item
                            name="verified_status"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Status<span className='requiredTxt'>*</span></div>
                            <Select
                                style={styles.textField}
                                value={formData.verified_status}
                                disabled={isDisable}
                                name={"verified_status"}
                                onChange={(value) => handleChange("verified_status", value)}
                                data-testid={'hs_approver'}
                            >
                                {ApprovedStatus.map((option) => (
                                    <Select.Option key={option.id} value={option.value}>{option.text}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='w-full'>
                        <Form.Item
                            name="verified_comments"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Comments<span className='requiredTxt'>*</span></div>
                            <Input.TextArea
                                bordered="true"
                                className="mt-2 textarea"
                                rows={3}
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                                disabled={isDisable}
                                maxLength={150}
                                value={formData.verified_comments ? formData.verified_comments : ""}
                                placeholder="Enter Verification Comments"
                                name="verified_comments"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={'rg_reason'}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ borderTop: "1px solid #c5c4c4", width: "99%", marginLeft: "0.25vw", marginBottom: "0.5vw", marginTop: "1vw" }}></div>
                    <div className='ml-2' style={styles.header}>Resignation Document Details</div>
                    <div className="grid" style={{ padding: "0.75vw" }}>
                        {formData?.onboarding_document_details?.map((item, index) => {
                            let ImageUrl = (!Array.isArray(item?.uploaded_documents) && (item.uploaded_documents && typeof item.uploaded_documents === "string"))
                                ? extractImageName(item?.uploaded_documents) : "";
                            let isDocAvail = item?.uploaded_documents && item?.uploaded_documents?.length > 0 && item?.uploaded_documents[0]?.name;
                            let fileName = ImageUrl || isDocAvail;
                            return (
                                <div key={index} className="grid grid-cols-3 gap-x-5">
                                    <div className="flex-initial ... text-left">
                                        <Form.Item name={["onboarding_document_details", index, "letter_type"]}
                                            rules={[{ required: true, message: "This field is required" },]}>
                                            <div className="commonInputTlt">Letter Type  {<span className='requiredTxt'>*</span>}</div>
                                            <Select
                                                showSearch
                                                value={item.letter_type ? item.letter_type : ""}
                                                onChange={(value) => handleOnboardingDocsChange("letter_type", index, value)}
                                                allowClear={true}
                                                disabled={isDisable}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={(el) => {
                                                    hideCursorRef.current[index] = el;
                                                }}
                                                onSelect={() => {
                                                    if (hideCursorRef.current[index]) {
                                                        hideCursorRef.current[index].blur();
                                                    }
                                                }}
                                            >
                                                {letterTypeApiList.map(option => (
                                                    (option.is_active === true || option.id === item.letter_type) ?
                                                        <Select.Option key={option.id} value={option.id}>
                                                            {option.letter_type_name}
                                                        </Select.Option> : null
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-initial ... text-left">
                                        <Form.Item name={["onboarding_document_details", index, "processed_by"]}
                                            rules={[{ required: true, message: "This field is required" },]}>
                                            <div className="commonInputTlt">Processed By  {<span className='requiredTxt'>*</span>}</div>
                                            <Select
                                                showSearch
                                                value={item.processed_by ? item.processed_by : ""}
                                                onChange={(value) => handleOnboardingDocsChange("processed_by", index, value)}
                                                allowClear={true}
                                                disabled={isDisable}
                                                filterOption={(input, option) => {
                                                    const childrenText = option.children;
                                                    return typeof childrenText === 'string' &&
                                                        childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }}
                                                ref={(el) => {
                                                    hideCursorRef.current[index] = el;
                                                }}
                                                onSelect={() => {
                                                    if (hideCursorRef.current[index]) {
                                                        hideCursorRef.current[index].blur();
                                                    }
                                                }}
                                            >
                                                {empList.map((option) => (
                                                    (option.is_active === true || option.id === item?.processed_by) ?
                                                        <Select.Option key={option.id} value={option.id}>
                                                            {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                                        </Select.Option> : null
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-initial ... text-left">
                                        <Form.Item name={["onboarding_document_details", index, "approved_by"]}
                                            rules={[{ required: true, message: "This field is required" },]}
                                        >
                                            <div className="commonInputTlt">Approved By  {<span className='requiredTxt'>*</span>}</div>
                                            <Select
                                                showSearch
                                                value={item.approved_by ? item.approved_by : ""}
                                                onChange={(value) => handleOnboardingDocsChange("approved_by", index, value)}
                                                allowClear={true}
                                                disabled={isDisable}
                                                filterOption={(input, option) => {
                                                    const childrenText = option.children;
                                                    return typeof childrenText === 'string' &&
                                                        childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }}
                                                ref={(el) => {
                                                    hideCursorRef.current[index] = el;
                                                }}
                                                onSelect={() => {
                                                    if (hideCursorRef.current[index]) {
                                                        hideCursorRef.current[index].blur();
                                                    }
                                                }}
                                            >
                                                {empList.map((option) => (
                                                    (option.is_active === true || option.id === item?.approved_by) ?
                                                        <Select.Option key={option.id} value={option.id}>
                                                            {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                                        </Select.Option> : null
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-initial ... text-left">
                                        <Form.Item
                                            name={["onboarding_document_details", index, "letter_provided_date"]}
                                            rules={[{ required: true, message: "This field is required" },]}>
                                            <div className='commonInputTlt'>Letter Provided Date {<span className='requiredTxt'>*</span>}</div>
                                            <DatePicker
                                                allowClear={true}
                                                locale={locale}
                                                style={{ width: "100%" }}
                                                name={"letter_provided_date"}
                                                format={"DD-MM-YYYY"}
                                                value={item?.letter_provided_date ? dayjs(item.letter_provided_date) : undefined}
                                                onChange={(date) => handleOnboardingDocsChange('letter_provided_date', index, date)}
                                                placeholder=""
                                                disabled={isDisable}
                                                disabledDate={disabledPastDate}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="flex-initial ... text-left">
                                        <Form.Item name={["onboarding_document_details", index, "letter_status"]}
                                            rules={[{ required: true, message: "This field is required" },]}
                                        >
                                            <div className="commonInputTlt">Letter Status {<span className='requiredTxt'>*</span>}</div>
                                            <Select
                                                showSearch
                                                value={item.letter_status ? item.letter_status : ""}
                                                onChange={(value) => handleOnboardingDocsChange("letter_status", index, value)}
                                                allowClear={true}
                                                disabled={isDisable}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                ref={(el) => {
                                                    hideCursorRef.current[index] = el;
                                                }}
                                                onSelect={() => {
                                                    if (hideCursorRef.current[index]) {
                                                        hideCursorRef.current[index].blur();
                                                    }
                                                }}
                                            >
                                                {letterStatusEnum.map(option => {
                                                    return (
                                                        <Select.Option key={option.value} value={option.value}>
                                                            {option.text}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="flex-initial ... text-left">
                                        <Form.Item name={["onboarding_document_details", index, "is_send_mail"]} style={styles.formItem}>
                                            <Checkbox
                                                style={styles.textField}
                                                checked={item.is_send_mail}
                                                disabled={isDisable}
                                                onChange={(e) => handleOnboardingDocsChange("is_send_mail", index, e.target.checked)}
                                            >
                                                Send Mail
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-initial ... text-left col-span-3">
                                        <Form.Item
                                            name={["onboarding_document_details", index, "document_comments"]}
                                            rules={[{ required: true, message: "This field is required" },]}
                                        >
                                            <div className='commonInputTlt'>Comments {<span className='requiredTxt'>*</span>}</div>
                                            <Input.TextArea
                                                bordered="true"
                                                className="mt-2 textarea"
                                                rows={3}
                                                autoSize={{
                                                    minRows: 3,
                                                    maxRows: 5,
                                                }}
                                                maxLength={150}
                                                disabled={isDisable}
                                                value={item?.document_comments ? item.document_comments : ""}
                                                placeholder={`Comments`}
                                                name="description"
                                                onChange={(e) => handleOnboardingDocsChange('document_comments', index, e.target.value)}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="flex-initial ... text-left col-span-2">
                                        <p id="common_weekoffTxt">Upload Document</p>
                                        <p className="commonInputTlt">Supported Format Pdf & Word</p>

                                        <Form.Item
                                            name={["onboarding_document_details", index, "uploaded_documents"]}
                                            rules={[{ required: true, message: 'Please upload a document!' }]}
                                        >
                                            <div>
                                                <Upload
                                                    accept=".pdf,.doc,.docx"
                                                    beforeUpload={beforeUpload}
                                                    onChange={({ fileList }) => handleOnboardingDocsChange('uploaded_documents', index, fileList)}
                                                    fileList={(Array.isArray(item?.uploaded_documents)) ? item?.uploaded_documents : []}
                                                    maxCount={1}
                                                    showUploadList={false}
                                                    disabled={isDisable}
                                                >
                                                    <MyButton className="flex items-center"
                                                        icon={ImagePaths.Upload.default}
                                                        htmlType="button"
                                                        label="Upload Documents"
                                                        paddingX={"1vw 1vw"}
                                                        testID="doc_upload" />
                                                </Upload>
                                            </div>
                                        </Form.Item>
                                    </div>

                                    <div className="flex justify-end col-span-1">
                                        {(index > 0) ? (
                                            <Button
                                                onClick={() => handleRemove(index)}
                                                icon={<MinusCircleOutlined className="plusIcon" />}
                                                style={{ border: 0 }}
                                                size="large"
                                                disabled={isDisable}
                                            ></Button>
                                        ) : null}
                                    </div>
                                    <div className="flex-initial ... text-left">
                                        {fileName ?
                                            (<div className="flex justify-between items-center p-2 my-2" style={styles.documentBorder}>
                                                <div className="flex items-center">
                                                    <img src={ImagePaths.pdfOn.default} alt="pdf" className='admin_org_pdf' />
                                                    {fileName.length > 20 ?
                                                        <Tooltip title={fileName}>{<span style={styles.docTxt}>{"..." + fileName?.slice(-19)}</span>}</Tooltip>
                                                        : <p style={styles.docTxt}>{fileName ? fileName : "-"}</p>}
                                                </div>
                                                <IconButton
                                                    title="Download Pdf"
                                                    icon={<DownloadOutlined style={styles.downloadIcon} />}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (ImageUrl) {
                                                            downloadPDF(item.uploaded_documents);
                                                        } else {
                                                            let url = URL.createObjectURL(item.uploaded_documents[0].originFileObj)
                                                            downloadPDF(url);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            ) : null}
                                    </div>
                                </div>)
                        })}
                        <div className="flex flex-row justify-start">
                            <Button
                                onClick={handleAdd}
                                icon={<PlusCircleOutlined className="plusIcon" />}
                                style={{ border: 0 }}
                                size="large"
                                disabled={isDisable}
                            ></Button>
                        </div>
                    </div>

                    <div style={{ borderTop: "1px solid #c5c4c4", width: "99%", marginLeft: "0.25vw", marginBottom: "0.5vw", marginTop: "1vw" }}></div>
                    <div className='ml-2' style={styles.header}>Final Settlement</div>
                    <div style={styles.direction}>
                        <Form.Item
                            style={styles.formItem}
                            name="final_settlement_date"
                        >
                            <div className='commonInputTlt'>Final Settlement Date <span className='requiredTxt'>*</span></div>
                            <DatePicker
                                style={styles.textField}
                                name="final_settlement_date"
                                locale={locale}
                                value={formData.final_settlement_date ? formData.final_settlement_date : dayjs()}
                                onChange={""}
                                format={"DD-MM-YYYY"}
                                allowClear={true}
                                inputReadOnly={true}
                                data-testid={'hs_from'}
                                disabled={true}
                                placeholder=""
                            />
                        </Form.Item>
                        <Form.Item
                            name="final_settlement_status"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Final Settlement Status<span className='requiredTxt'>*</span></div>
                            <Select
                                style={styles.textField}
                                value={formData.final_settlement_status ? formData.final_settlement_status : ""}
                                name={"final_settlement_status"}
                                onChange={(value) => handleChange("final_settlement_status", value)}
                                data-testid={'hs_approver'}
                                disabled={isDisable}
                            >
                                {SettlementStatus.map((option) => (
                                    <Select.Option key={option.id} value={option.value}>{option.text}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='w-full'>
                        <Form.Item
                            name="final_settlement_comments"
                            style={styles.formItem}
                            rules={[{ required: true, message: "This field is required" }]}
                        >
                            <div className='commonInputTlt'>Comments<span className='requiredTxt'>*</span></div>
                            <Input.TextArea
                                bordered="true"
                                className="mt-2 textarea"
                                rows={3}
                                autoSize={{
                                    minRows: 3,
                                    maxRows: 5,
                                }}
                                maxLength={150}
                                disabled={isDisable}
                                value={formData.final_settlement_comments ? formData.final_settlement_comments : ""}
                                placeholder="Enter Final Settlement Comments"
                                name="final_settlement_comments"
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                data-testid={'rg_reason'}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className='flex justify-end items-end mr-6 mt-3'>
                    <MyButton htmlType="button" label="Back" onClick={() => triggerPrevious()} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                    <MyButton htmlType="submit" label="Save" disabled={IsButtonDis} loading={isLoading} marginRight={"0.625vw"} paddingX={"0 1.2vw"} testID="hs_save" />
                    <MyButton htmlType="sendmail" label="Send Email" disabled={IsButtonDis} loading={isLoading} onClick={(e) => handleSubmit(e, true, false)} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID="hs_cancel" />
                    <MyButton htmlType="movetoemployee" label="Finish Process" loading={isLoading} onClick={(e) => handleSubmit(e, false, true)} disabled={formData.final_settlement_status !== "cleared" || IsButtonDis} paddingX={"0 1.2vw"} testID="hs_save" />
                </div>
            </Form>
        </div>
    );
};

const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "0.65vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    },
    header: {
        fontSize: "14px",
        fontWeight: 500
    },
    direction: {
        display: "flex",
        flexDirection: "row"
    },
    downloadIcon: {
        color: "#04B7B1",
        fontSize: "1.3vw",
        fontWeight: "bold"
    },
    documentBorder: {
        border: "1px solid #004B49",
        height: "6vh",
    },
}

export default ResignationFinalStep;
