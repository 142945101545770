import { Card, Space, Table, Tag, Tooltip } from 'antd';
import CommonTblHeader from 'components/common/CommonTblHeader';
import ToastMsg from 'components/common/ToastMsg';
import useApiData from 'components/common/useGetApiData';
import IconButton from 'components/ui/Button/IconButton';
import { EMPLOYEE_LEAVE } from 'constants';
import dayjs from 'dayjs';
import moment from 'moment';
import EmployeeFilter from 'pages/employee/EmployeeFilter';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImagePaths } from 'utils/ImagePath';
import { getEmployeeMigratedList } from './employeeMigrationApi';
import { EMPLOYEE_MIGRATION_LIST } from 'constants';

const initialData = {
    leave_type: "",
    from_date: "",
    to_date: "",
    leave_approval_status: "",
    leaveTypeList: []
}

function EmployeeMigration() {

    let query = "";
    let queryRef = useRef("");
    const pageSize = 10;
    const dateFormat = "YYYY-MM-DD";
    const authtoken = sessionStorage.getItem("token");

    const [pageNo, setPageNo] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [addVisible, setAddVisible] = useState(false);
    const [dataUpdated, setDataUpdated] = useState(false);
    const navigate = useNavigate();
    const tableContainerHeight = "72vh";

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    useEffect(() => {
        if (!authtoken) {
            window.location.href = '/';
        }
    }, [authtoken]);

    const [data, pageInfo, loading, _message, refetch, extras] = useApiData(getEmployeeMigratedList, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting
    });

    const handleSearch = () => {
        setEditVisible(false)
        setAddVisible(false)
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleBackToHome = () => {
        setSorting("");
        setPageNo(1);
        queryRef.current = "";
        refetch(1,
            pageSize,
            queryRef.current,
            searchText,
            "");
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        queryRef.current = "";
        refetch(pagination.current,
            pageSize,
            queryRef.current,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        let fromDate = data.from_date ? dayjs(data.from_date).format(dateFormat) : "";
        let toDate = data.to_date ? dayjs(data.to_date).format(dateFormat) : "";
        setPageNo(1);
        refetch(1,
            pageSize,
            `leave_type=${data.leave_type ? data.leave_type : ""}&from_date=${fromDate}&to_date=${toDate}&approval_status=${data.leave_approval_status ? data.leave_approval_status : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const clearFilter = (data) => {
        setFilterDatas((prevState) => ({
            ...prevState,
            ...initialData,
            leaveTypeList: data.leaveTypeList.length > 0 ? data.leaveTypeList : [],
        }));
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const handleFilter = () => {
        // setFilterVisible(true)
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const handleEditClick = (details) => {
        if (editVisible && Object.keys(selectedDetails).length > 0) {
            ToastMsg('warning', "Please cancel the existing edit operation!");
        } else {
            navigate('/datamigration/employeemigration/add', { state: { editDetails: details, limit: extras?.data?.extras?.limit  } });
        }
    };

    const addClick = () => {
        setAddVisible(true);
        setEditVisible(false);
        setSelectedDetails({});
        navigate('/datamigration/employeemigration/add' , { state: { limit: extras?.data?.extras?.limit } });
    };

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const columns = [
        {
            title: "Migrated Date & Time",
            dataIndex: "migration_date_time",
            key: "migration_date_time",
            sorter: true,
            width: "15%",
            render: (_, record) => (
                <span style={{ color: "black" }}>
                    {record.migration_date_time ? moment(record?.migration_date_time, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY | hh:mmA") : "-"}
                </span>
            ),
            align: 'center',
            fixed: "left",
        },
        {
            title: "Migrated By",
            dataIndex: "migrated_by",
            key: "migrated_by",
            sorter: true,
            width: "15%",
            render: (_, record) => {
                let colors = "black"
                return (record.migrated_by?.length > 20 ?
                    <Tooltip title={record.migrated_by}>{<span style={{ color: colors }}>{record.migrated_by?.slice(0, 20) + "..."}</span>}</Tooltip>
                    : <p style={{ color: colors }}>{record.migrated_by ? record.migrated_by : "-"}</p>)
            },
            align: 'center',
            fixed: "left",
        },
        {
            title: "Records Count",
            dataIndex: "count",
            key: "count",
            sorter: true,
            width: "10%",
            render: (_, record) => {
                let colors = "black";
                return (
                    <p style={{ color: colors }}>{`${record.count ? record.count : "0"}/100`}</p>
                )
            },
            align: 'center',
            fixed: "left",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: true,
            width: "15%",
            align: 'center',
            fixed: "left",
            render: (_, record) => {
                let colors = record.status === "rejected" ? "#D94854" : record.status === "pending" ? "#E28F12" : "#0E862A";
                return (<Tag className="statusDiv" style={{ width: "8vw" }} color={colors} key={record.status}>{record.status}</Tag>)
            }
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            width: "10%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex justify-center items-center">
                    <Space size={"middle"}>
                        <IconButton title="Edit"
                            disabled={(record?.status === "pending" || record?.reportstate) ? false : true}
                            icon={<img src={ ImagePaths.edit.default } alt="Edit" />}
                            onClick={() => loading ? "" : handleEditClick(record)} />
                    </Space>
                </div>
            ),
        }
    ];

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Employee Migration"}
                    pageInfo={pageInfo}
                    handleSearch={handleSearch} selectedDetails={selectedDetails} addClick={addClick}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    isDraftRequired={false} handleBackToHome={handleBackToHome} addDisabled={loading}
                    url={EMPLOYEE_MIGRATION_LIST} query={queryString} addBtnLabel={"New Migration"}
                    isFltRequired = {false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center  mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <EmployeeFilter
                            pageName={"LeaveRequest"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={(dataUpdated ? "updated" : "not-updated")}
                    />
                </div>
            </div>
        </Card>
    )
}

export default EmployeeMigration;