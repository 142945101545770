import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { getHolidaySetup } from "hooks/api/configurationApi/holidaySetupApi";
import { getMonthStartAndEndDates, getWeeksInMonth } from "components/common/validation";

function DashboardHoliday({ month }) {
  const [holidays, setHolidays] = useState([]);

  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  useEffect(() => {
    const getHolidays = async () => {
      let holidayslist = [];
      const data = await getHolidaySetup();
      if (data) {
        if (data.status === "fail") {
          // message.warning(data.message)
        } else {
          data.data.map((row) => {
            holidayslist.push(row.date);
          });
        }
      }
      setHolidays(holidayslist);
      
    };
    getHolidays();
  }, [setHolidays, month]);

  const { monthStart, monthEnd } = getMonthStartAndEndDates(2023, month-1);
  const weeksInMonth = getWeeksInMonth(monthStart, monthEnd);
  
  const daysInMonth = [];

  for (
    let date = monthStart;
    date <= monthEnd;
    date = moment(date).add(1, "days")
  ) {
    daysInMonth.push(date);
  }

  const daysOfWeek = moment.weekdaysShort(true);
  daysOfWeek.push(daysOfWeek.shift()); // move Sunday to the end of the array

  function isHoliday(date) {
    const dateString = dayjs(date).format("YYYY-MM-DD");
    return holidays.includes(dateString);
    // return false;
  }

  return (
    <div>
      <div className="py-2 flex justify-end">
        <div className="bg-white rounded-md p-0">
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr repeat(7, 1fr)",
                gridGap: "1px",
              }}
            >
              {daysOfWeek.map((day, index) => (
                <div
                  style={{
                    margin: "4px",
                    gridColumn: `${index + 2} / ${index + 3}`,
                  }}
                  key={day}
                >
                  {day}
                </div>
              ))}
              {weeksInMonth.map((week, weekIndex) => (
                <React.Fragment key={weekIndex}>
                  {week.map((date, dayIndex) => {
                    const dayOfWeek = ((moment(date).day() + 6) % 7) + 2;
                    const today = new Date();
                    let bgcolor = "";
                    let fontcolor = "";
                    let holidayStyle = "";
                    if (moment(today).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) {
                      bgcolor="black"
                      fontcolor="white"
                    }
                    else
                    {
                      bgcolor="white"
                      fontcolor="black"
                    }

                    if(isHoliday(date)){
                      holidayStyle="1px solid #D94854"
                    }
                    else{
                      holidayStyle="0px solid #D94854"
                    }
                    
                    return (
                      <div
                        key={`${weekIndex}-${dayIndex}`}
                        style={{
                          margin: "4px",
                          gridColumn: `${dayOfWeek} / ${dayOfWeek + 1}`,
                        }}
                      >
                        <span style={{ backgroundColor: `${bgcolor}`, color: `${fontcolor}`, borderRadius: "50px", padding: "2px", borderBottom:`${holidayStyle}` }}>{ moment(date).format("DD")}</span>
                      </div>
                    );
                  })}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHoliday;
