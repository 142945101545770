import React, { useEffect, useState, useRef } from "react";
import { Card, Table, Tooltip, Space, Upload, Button } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import MasterFilter from "pages/master/MasterFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getEmpTdsDeclarationRequests } from "hooks/api/employeeApi/getEmpTdsDeclarationApi";
import { EMPLOYEE_TDS_DECLARATION } from "constants";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import { imgUploadSize } from 'components/common/enum';
import { CommonTDSPopup, EmployeeDetails } from "components/common/CommonComponent";

function TDSDeclarationForm() {
    const authtoken = sessionStorage.getItem("token");
    const employeeName = sessionStorage.getItem("employee_name");
    const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));
    let query = "";
    let queryRef = useRef("");
    const pageSize = 10;

    const [selectedDetails, setSelectedDetails] = useState({});
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const tableContainerHeight = "72vh";
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [expandedData, setExpandedData] = useState({});
    const [finalData, setFinalData] = useState([]);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [docUploadKeys, setDocUploadKeys] = useState({});
    const [isUploadLoader, setIsuploadLoader] = useState(false);

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const userPermission = checkUserPermissionFunc("employee", "TDS Declaration", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getEmpTdsDeclarationRequests, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (data) {
            setFinalData(data?.map(item => ({ ...item, key: item.id })) || []);
        }
    }, [data]);


    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);


    const handleSave = () => {
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    };

    const handleSearch = () => {
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
        refetch(pagination.current,
            pageSize,
            queryRef.current,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    useEffect(() => {
        const newExpandedData = {};
        finalData.forEach(item => {
            if (expandedKeys.includes(item.key)) {
                newExpandedData[item.key] = {
                    quarter_1_document: item.quarter_1_document || null,
                    quarter_1_status: item.quarter_1_status || null,
                    quarter_2_document: item.quarter_2_document || null,
                    quarter_2_status: item.quarter_2_status || null,
                    quarter_3_document: item.quarter_3_document || null,
                    quarter_3_status: item.quarter_3_status || null,
                    quarter_4_document: item.quarter_4_document || null,
                    quarter_4_status: item.quarter_4_status || null,
                };
            }
        });
        setExpandedData(newExpandedData);
    }, [finalData]);


    const columns = [
        {
            title: "Employee Details",
            dataIndex: "employee",
            key: "employee__employee_name",
            sorter: true,
            width: "28%",
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                }
                return (<EmployeeDetails details={empDetails} />)
            }
        },
        {
            title: "Quarters",
            dataIndex: "quarters",
            key: "quarters",
            sorter: true,
            width: "15%",
            fixed: "left",
            render: (_, record) => {
                let quartersCount = 0
                if (record?.quarter_1_document) {
                    quartersCount = quartersCount + 1;
                }
                if (record?.quarter_2_document) {
                    quartersCount = quartersCount + 1;
                }
                if (record?.quarter_3_document) {
                    quartersCount = quartersCount + 1;
                }
                if (record?.quarter_4_document) {
                    quartersCount = quartersCount + 1;
                }
                return <p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{quartersCount + "/4"}</p>
            },
        },
        {
            title: "Financial Year",
            dataIndex: "financial_year",
            key: "financial_year",
            sorter: true,
            width: "17.5%",
            fixed: "left",
            render: (_, record) =>
                (<p style={{ color: record?.is_active ? "black" : "#CACACA" }}>{record?.financial_year ? record?.financial_year : "-"}</p>),
        },
        {
            title: "Form 16 Status",
            dataIndex: "form_16_status",
            key: "form_16_status",
            sorter: true,
            width: "17.5%",
            fixed: "left",
            render: (_, record) =>
            (record?.form_16_status?.length > 28 ?
                <Tooltip title={record?.form_16_status}>{<span style={{ color: record?.is_active ? "black" : "#CACACA" }} className="capitalize">{record?.form_16_status?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: record?.is_active ? "black" : "#CACACA" }} className="capitalize">{record?.form_16_status ? record?.form_16_status : "-"}</p>),
        },
    ];

    if (userPermission?.edit) {
        columns.push({
            title: "Action",
            dataIndex: "form_16_document",
            key: "form_16_document",
            align: 'center',
            width: "22%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex justify-center items-center">
                    <Space size={"middle"}>
                        <IconButton
                            title="Download"
                            icon={<img
                                src={record?.form_16_document ? ImagePaths.documentPdfOn.default
                                    : ImagePaths.documentPdfOff.default}
                                alt="Download"
                            />}
                            onClick={() => handleDownloadDocs(
                                record?.id,
                                record?.form_16_document,
                                'form_16_document',
                                'form_16_status')}
                            disabled={!record.form_16_document}
                        />
                        <DocsUpload
                            selectedId={record?.id}
                            docName={'form_16_document'}
                            docStatusName={'form_16_status'}
                            disabled={is_admin ? false : !record?.form_16_document}
                        />
                        <IconButton
                            title={expandedKeys.includes(record.key) ? 'Collapse' : 'Expand'}
                            icon={<img
                                src={ImagePaths.downArrow.default}
                                style={{
                                    transform: `rotate(${expandedKeys.includes(record.key) ? 0 : 180}deg)`,
                                    transition: 'transform 0.3s',
                                    height: '0.5vw'
                                }}
                                alt="arrow"
                            />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleExpandClick(record);
                            }}
                        />
                    </Space>
                </div>
            ),
        });
    }

    const handleExpandClick = (record) => {
        const isExpanded = expandedKeys.includes(record.key);
        if (isExpanded) {
            setExpandedKeys([]);
        } else {
            setExpandedKeys([record.key]);
            if (!expandedData[record.key]) {
                fetchExpandedData(record);
            }
        }
    };

    const fetchExpandedData = (record) => {
        const newExpandedData = {
            ...expandedData,
            [record.key]: {
                quarter_1_document: record?.quarter_1_document || null,
                quarter_1_status: record?.quarter_1_status || null,
                quarter_2_document: record?.quarter_2_document || null,
                quarter_2_status: record?.quarter_2_status || null,
                quarter_3_document: record?.quarter_3_document || null,
                quarter_3_status: record?.quarter_3_status || null,
                quarter_4_document: record?.quarter_4_document || null,
                quarter_4_status: record?.quarter_4_status || null,
            }
        };
        setExpandedData(newExpandedData);
    };

    const expandedRowRender = (record) => {
        const data = expandedData[record.key] || {};
        const columns = [
            {
                title: 'Period',
                dataIndex: 'period',
                key: 'period',
                width: "30%",
                fixed: "left",
            },
            {
                title: 'TDS Status',
                dataIndex: 'status',
                key: 'status',
                width: "30%",
                fixed: "left",
                render: (_, record) =>
                (record?.status?.length > 28 ?
                    <Tooltip title={record?.status}>{<span style={{ color: "black" }} className="capitalize">{record?.status?.slice(0, 25) + "..."}</span>}</Tooltip>
                    : <p style={{ color: "black" }} className="capitalize">{record?.status ? record?.status : "-"}</p>),

            },
        ];

        if (userPermission?.edit) {
            columns.push({
                title: 'Action',
                key: 'operation',
                width: "40%",
                fixed: "left",
                align: 'center',
                render: (_, periodRecord) => (
                    <div className="flex justify-center items-center">
                        <Space size={"middle"}>
                            <IconButton
                                title="Download"
                                icon={<img
                                    src={periodRecord.document ? ImagePaths.documentPdfOn.default
                                        : ImagePaths.documentPdfOff.default}
                                    alt="Download"
                                />}
                                onClick={() => handleDownloadDocs(
                                    record.id,
                                    periodRecord?.document,
                                    periodRecord?.document_name,
                                    periodRecord?.doc_sts_name)}
                                disabled={!periodRecord.document}
                            />
                            <DocsUpload
                                selectedId={record?.id}
                                docName={periodRecord.document_name}
                                docStatusName={periodRecord?.doc_sts_name}
                                disabled={is_admin ? false : !periodRecord.document} />
                        </Space>
                    </div>
                ),
            });
        }

        const rowData = [
            {
                key: '1',
                period: '1st Quarter',
                status: data.quarter_1_status || '-',
                document: data.quarter_1_document,
                document_name: "quarter_1_document",
                doc_sts_name: 'quarter_1_status',
            },
            {
                key: '2',
                period: '2nd Quarter',
                status: data.quarter_2_status || '-',
                document: data.quarter_2_document,
                document_name: "quarter_2_document",
                doc_sts_name: 'quarter_2_status',
            },
            {
                key: '3',
                period: '3rd Quarter',
                status: data.quarter_3_status || '-',
                document: data.quarter_3_document,
                document_name: "quarter_3_document",
                doc_sts_name: 'quarter_3_status',
            },
            {
                key: '4',
                period: '4th Quarter',
                status: data.quarter_4_status || '-',
                document: data.quarter_4_document,
                document_name: "quarter_4_document",
                doc_sts_name: 'quarter_4_status',
            }
        ];

        return <Table columns={columns} dataSource={rowData} pagination={false} />;
    };

    const handleDownloadDocs = (selectedId, fileUrl, docName, statusName) => {
        if (fileUrl) {
            downloadPDF(fileUrl);
        }

        try {
            let data = statusName === 'form_16_status' ?
                { 'id': selectedId } :
                { 'id': selectedId, [statusName]: `Downloaded by ${employeeName}` };

            fetch(EMPLOYEE_TDS_DECLARATION, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleSave();
                    }
                    else if (data.status === "fail") {
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    ToastMsg("error", error.message);
                });

        } catch (error) {
            ToastMsg("error", error.message);
        }
    };

    const DocsUpload = ({ selectedId, docName, docStatusName, disabled = false }) => {

        const handleUploadChange = ({ file }) => {
            if ((file.status === 'done' || file.status === 'error') && file?.originFileObj) {
                if (is_admin) {
                    //only throw confirm popup for admin
                    setIsOpenPopup(true);
                    setDocUploadKeys({ file, selectedId, docName, docStatusName });
                } else {
                    handleSubmitUpload(file, selectedId, docName, docStatusName, false);
                }
            }
        }

        return (
            <div>
                <Upload
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    beforeUpload={beforeUpload}
                    onChange={handleUploadChange}
                    maxCount={1}
                    showUploadList={false}
                    disabled={disabled}
                >
                    <IconButton
                        title="Upload"
                        icon={<img src={disabled ? ImagePaths.documentUploadOff.default : ImagePaths.documentUploadOutline.default} alt="Upload" />}
                        disabled={disabled}
                    />
                </Upload>
            </div>
        );
    };

    const beforeUpload = (file) => {
        const fileSizeMB = file.size / (1024 * 1024);
        const supportedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        const isSupportedDocument = supportedTypes.includes(file.type);

        if (!isSupportedDocument) {
            ToastMsg("error", "Only PDF, Word, and Excel documents are supported");
            return false;
        }
        if (fileSizeMB > imgUploadSize) {
            ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
            return false;
        }
        return true;
    };

    const downloadPDF = (url) => {
        const link = document.createElement('a');
        link.href = url;
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[-T]/g, '').replace(/:/g, '');
        link.setAttribute('download', `${formattedDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSubmitUpload = (file, selectedId, docName, docStatusName, uploadAll = false) => {
        try {
            setIsuploadLoader(true);
            const dataForm = new FormData();
            dataForm.append("id", selectedId);
            dataForm.append(docName, file?.originFileObj);
            dataForm.append('upload_all', uploadAll);
            if (docName === 'form_16_document') {
                dataForm.append(docStatusName, 'available');
            } else {
                dataForm.append(docStatusName, `Uploaded by ${employeeName}`);
            }

            fetch(EMPLOYEE_TDS_DECLARATION, {
                method: "PUT",
                headers: {
                    'Authorization': `token ${authtoken}`
                },
                body: dataForm
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleSave();
                        setIsuploadLoader(false);
                    }
                    else if (data.status === "fail") {
                        ToastMsg("error", data.message);
                        setIsuploadLoader(false);
                    }
                })
                .catch(error => {
                    ToastMsg("error", error.message);
                    setIsuploadLoader(false);
                });
        } catch (error) {
            ToastMsg("error", error.message);
            setIsuploadLoader(false);
        }
    };

    const handleConfirmBtn = () => {
        const { file, selectedId, docName, docStatusName } = docUploadKeys;
        handleSubmitUpload(file, selectedId, docName, docStatusName, true);
        setIsOpenPopup(false);
    };

    const handleCancelBtn = () => {
        setIsOpenPopup(false);
        setDocUploadKeys({});
    };

    const handleUploadOnlyEmpBtn = () => {
        const { file, selectedId, docName, docStatusName } = docUploadKeys;
        handleSubmitUpload(file, selectedId, docName, docStatusName, false);
        setIsOpenPopup(false);
    };

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"}>
                <CommonTblHeader pageName={"TDS Declaration Form"}
                    pageInfo={pageInfo} handleSearch={handleSearch} selectedDetails={selectedDetails}
                    onStateChange={handleStateChanges} columns={columns} url={EMPLOYEE_TDS_DECLARATION}
                    isAddRequired={false} isDraftRequired={false} isFltRequired={false} query={queryString}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">

                <div className={"mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={finalData?.length > 0 ? finalData : null}
                        loading={loading || isUploadLoader}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={(dataUpdated ? "updated" : "not-updated")}
                        expandable={{
                            expandedRowRender,
                            expandIcon: () => null,
                            expandedRowKeys: expandedKeys,
                        }}
                    />
                </div>
            </div>
            {isOpenPopup ? <CommonTDSPopup handleCancelBtn={handleCancelBtn} handleConfirmBtn={() => handleConfirmBtn()} handleExrabtn={() => handleUploadOnlyEmpBtn()} /> : null}
        </Card>
    );
}

export default TDSDeclarationForm;
