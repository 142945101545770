import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import ReportsFilter from "./ReportsFilter";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getSubordinatesReportsPagination } from "hooks/api/reportsApi.js";
import { SUBORDINATES_REPORTS } from "constants";
import { EmployeeDetails } from "components/common/CommonComponent";
import dayjs from "dayjs";

const initialData = {
    subordinate_designation: "",
    subordinate_department: "",
    subordinate_employee_name: "",
}

const Subordinates = () => {
    const authtoken = sessionStorage.getItem("token");
    // let query = "";
    const pageSize = 10;
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ ...initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [query, setQuery] = useState(`designation=${filterDatas.subordinate_designation ? filterDatas.subordinate_designation : ""}&department=${filterDatas.subordinate_department ? filterDatas.subordinate_department : ""}&employee=${filterDatas.subordinate_employee_name ? filterDatas.subordinate_employee_name : ""}`)
    const tableContainerHeight = "72vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    useEffect(() => {
        const newQuery = `designation=${filterDatas.subordinate_designation ? filterDatas.subordinate_designation : ""}&department=${filterDatas.subordinate_department ? filterDatas.subordinate_department : ""}&employee=${filterDatas.subordinate_employee_name ? filterDatas.subordinate_employee_name : ""}`;
        setQuery(newQuery); 
      }, [filterDatas]);

    const [data, pageInfo, loading, _message, refetch] = useApiData(getSubordinatesReportsPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `designation=${data.subordinate_designation ? data.subordinate_designation : ""}&department=${data.subordinate_department ? data.subordinate_department : ""}&employee=${data.subordinate_employee_name ? data.subordinate_employee_name : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas(initialData);
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : "";
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "employee__employee_name",
            key: "employee__employee_name",
            width: "22%",
            sorter: true,
            fixed: 'left',
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                }
                return <EmployeeDetails details={empDetails} />;
            }
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            sorter: true,
            width: "15%",
            fixed: 'left',
            render: (_, record) =>
            (record?.department?.length > 28 ?
                <Tooltip title={record?.department}>{<span style={{ color: "black" }}>{record?.department?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black" }}>{record?.department ? record?.department : "-"}</p>)
        },
        {
            title: "Date Of Joining",
            dataIndex: "date_of_joining",
            key: "date_of_joining",
            sorter: true,
            width: "15.74%",
            render: (_, record) => (<span>{record.date_of_joining ? dayjs(record.date_of_joining, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}</span>),
            align: 'center',
            fixed: 'left',
        },
        {
            title: "Employee Group",
            dataIndex: "employee_group",
            key: "employee_group",
            sorter: true,
            width: "18%",
            fixed: 'left',
            render: (_, record) =>
            (record?.employee_group?.length > 28 ?
                <Tooltip title={record?.employee_group}>{<span style={{ color: "black" }}>{record?.employee_group?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black" }}>{record?.employee_group ? record?.employee_group : "-"}</p>)
        },
        {
            title: "Email ID",
            dataIndex: "email_id",
            key: "email_id",
            width: "20.60%",
            sorter: true,
            fixed: 'left',
            render: (_, record) =>
            (record?.email_id?.length > 28 ?
                <Tooltip title={record?.email_id}>{<span style={{ color: "black" }}>{record?.email_id?.slice(0, 25) + "..."}</span>}</Tooltip>
                : <p style={{ color: "black" }}>{record?.email_id ? record?.email_id : "-"}</p>)
        },
        {
            title: "Status",
            key: "is_active",
            dataIndex: "is_active",
            sorter: true,
            width: "8.66%",
            render: (_, record) => <span style={{ color: "black" }}>{record.is_active === true ? "Active" : "Inactive"}</span>,
            align: 'center',
            fixed: 'left',
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&designation=" + (filterDatas.subordinate_designation ? filterDatas.subordinate_designation : "") +
        "&department=" + (filterDatas.subordinate_department ? filterDatas.subordinate_department : "") +
        "&employee=" + (filterDatas.subordinate_employee_name ? filterDatas.subordinate_employee_name : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"}>
                <CommonTblHeader pageName={"Subordinates"}
                    pageInfo={pageInfo} handleSearch={handleSearch}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    url={SUBORDINATES_REPORTS} query={queryString}
                    isAddRequired={false} isDraftRequired={false}
                    isContentRequired={true}
                />
            </div>
            <div className="relative h-5/6 mt-1">
                {filterVisible ?
                    <div className="absolute w-1/4 z-10" style={{ ...styles.fltContainer, background: "white" }}>
                        <ReportsFilter
                            pageName={"Subordinates"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={"mt-5 w-full"} style={styles.fltContainer}>
                    <Table
                        columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                        dataSource={data?.length > 0 ? data : null}
                        loading={loading}
                        style={{ "--table-container-height": tableContainerHeight }}
                        scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                        onChange={handleTableChange}
                        pagination={{
                            style: { marginRight: 20, padding: 0 },
                            defaultPageSize: pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                            pageSizeOptions: ["10", "20", "30"],
                            pageSize,
                            defaultCurrent: pageNo,
                        }}
                        key={dataUpdated ? "updated" : "not-updated"}
                    />
                </div>
            </div>
        </Card>
    );
}

export default Subordinates