import { EMPLOYEE_DESIGNATION } from 'constants';
import { prefillUrl } from 'utils/StringUtils';


export const getEmployeeDesignation = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token")

        let url = EMPLOYEE_DESIGNATION;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },
        };

        return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}

export const getEmployeeDesignationPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    try {
        const authtoken = sessionStorage.getItem("token")
        let url = EMPLOYEE_DESIGNATION;

        if (pageNo) {
            url = prefillUrl(url);
            url = `${url}page_no=${pageNo}`;
        }

        if (pageSize) {
            url = prefillUrl(url);
            url = `${url}&page_size=${pageSize}`;
        }

        if (query) {
            url = prefillUrl(url);
            url = `${url}&${query}`;
        }

        if (search) {
            url = prefillUrl(url);
            url = `${url}&search=${search}`;
        }

        if (sorting) {
            url = prefillUrl(url);
            url = `${url}&${sorting}`;
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },
        };

        return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}

export const getEmployeeDesignationById = async (id) => {
    try {
        const authtoken = sessionStorage.getItem("token")
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },

        };

        return await fetch(EMPLOYEE_DESIGNATION + `?id=${id}`, requestOptions).then((response) => response.json()).then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}

export const updateEmployeeDesignation = async (input) => {
    try {
        const authtoken = sessionStorage.getItem("token")
        const requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `token ${authtoken}` },
            body: JSON.stringify(input)
        };

        return await fetch(EMPLOYEE_DESIGNATION, requestOptions).then((response) => response.json()).then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}
