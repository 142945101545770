import React, { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Input, Button, Tooltip } from "antd";
import MyButton from "components/ui/Button/MyButton";
import ToastMsg from "components/common/ToastMsg";
import { toIndianCurrency } from "utils/StringUtils";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { PAYROLL_PAYSLIP_EDIT } from "constants";
import { useNavigate } from "react-router-dom";
import { removeUnderscores } from "components/common/validation.js";

const EditSalaryDetails = ({ payroll_id, editDetails, onSave, onHide }) => {
    const authtoken = sessionStorage.getItem("token");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [debitList, setDebitList] = useState([{ name: "", amount: 0 }]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [creditList, setCreditList] = useState([{ name: "", amount: 0 }]);
    const [totalCredit, setTotalCredit] = useState(0);
    const [reimburseList, setReimburseList] = useState([{ name: "", amount: 0 }]);
    const [totalReimburse, setTotalReimburse] = useState(0);
    const [totalPaidSlry, setTotalPaidSlry] = useState(0);
    const prevPropsRef = useRef();
    const navigate = useNavigate();

    const convertBreakUpsToArray = (breakups) => {
        if (Object.keys(breakups).length === 0) {
            return [{ name: "", amount: 0 }];
        }
        return Object.entries(breakups).map(([name, amount]) => {
            return { name, amount };
        });
    };

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData({ ...editDetails });
            setDebitList(convertBreakUpsToArray(editDetails?.debit_breakup ? editDetails?.debit_breakup : {}));
            setTotalDebit(editDetails.debit ? editDetails.debit : 0);
            setCreditList(convertBreakUpsToArray(editDetails?.credits_breakup ? editDetails?.credits_breakup : {}));
            setTotalCredit(editDetails.credits ? editDetails.credits : 0);
            setReimburseList(convertBreakUpsToArray(editDetails.reimbursements ? editDetails.reimbursements : {}));
            setTotalReimburse(editDetails.reimbursements_total ? editDetails.reimbursements_total : 0);
            setTotalPaidSlry(editDetails?.paid_salary ? parseFloat(editDetails?.paid_salary) : 0);
            prevPropsRef.current = editDetails;
        }
    }, [editDetails])

    const calculateTotal = (list, setter) => {
        const total = list.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
        setter(total);
    };

    const calculateTotalSalary = () => {
        let net_salary = formData?.net_pay ? parseFloat(formData?.net_pay) : 0;
        let lop_amount = formData?.lop_amount ? parseFloat(formData?.lop_amount) : 0;
        let totalSalary = (net_salary + totalCredit + totalReimburse) - (lop_amount + totalDebit);

        if (!isNaN(totalSalary) && isFinite(totalSalary)) {
            setTotalPaidSlry(parseFloat(totalSalary.toFixed(2)));
        }
    }

    useEffect(() => {
        if (debitList.length > 0) {
            calculateTotal(debitList, setTotalDebit);
        }
        if (creditList.length > 0) {
            calculateTotal(creditList, setTotalCredit);
        }
        if (reimburseList.length > 0) {
            calculateTotal(reimburseList, setTotalReimburse);
        }
    }, [debitList, creditList, reimburseList]);

    useEffect(() => {
        calculateTotalSalary();
    }, [totalDebit, totalCredit, formData.lop_amount, totalReimburse]);

    const handleListChange = (list, setter, index, statekey, value) => {
        let newList = [...list];
        if (statekey === "amount") {
            if (value === "" || value.length <= 7) {
                newList[index]["amount"] = +value;
            }
        } else {
            newList[index]["name"] = value.trim();
        }
        setter(newList);
    };

    const handleListAdd = (list, setter) => {
        const allPreviousLevelsFilled = list.every((item) => item.name && item.amount);
        if (allPreviousLevelsFilled) {
            const newItem = { name: "", amount: 0 };
            setter([...list, newItem]);
        } else {
            ToastMsg("error", "Please fill all previous levels before adding a new one.");
        }
    };

    const handleListRemove = (list, setter, index) => {
        const newItems = [...list];
        newItems.splice(index, 1);
        setter(newItems);
    };

    const handleClear = () => {
        let alreadyExistData = prevPropsRef.current;
        setFormData({ ...alreadyExistData });
        setDebitList(convertBreakUpsToArray(alreadyExistData?.debit_breakup ? alreadyExistData?.debit_breakup : {}));
        setTotalDebit(alreadyExistData.debit ? alreadyExistData.debit : 0);
        setCreditList(convertBreakUpsToArray(alreadyExistData?.credits_breakup ? alreadyExistData?.credits_breakup : {}));
        setTotalCredit(alreadyExistData.credits ? alreadyExistData.credits : 0);
        setReimburseList(convertBreakUpsToArray(alreadyExistData.reimbursements ? alreadyExistData.reimbursements : {}));
        setTotalReimburse(alreadyExistData.reimbursements_total ? alreadyExistData.reimbursements_total : 0);
        setTotalPaidSlry(alreadyExistData?.paid_salary ? parseFloat(alreadyExistData?.paid_salary) : 0)
    }

    const arrayToObject = (array) => {
        const obj = {};
        array.forEach(item => {
            if (item.amount !== 0) {
                obj[item.name] = item.amount
            }
        });
        return obj;
    }

    const handleUpdate = useCallback(() => {
            try {
                setLoading(true);
                let debit_breakup = arrayToObject(debitList);
                let credits_breakup = arrayToObject(creditList);
                let reimbursements_breakup = arrayToObject(reimburseList);
                let updatedFormData = {
                    "payslip_id": formData.payslip_id,
                    "debit": totalDebit ? totalDebit : 0,
                    "debit_breakup": Object.keys(debit_breakup)?.length > 0 ? debit_breakup : {},
                    "credits": totalCredit ? totalCredit : 0,
                    "credits_breakup": Object.keys(credits_breakup)?.length > 0 ? credits_breakup : {},
                    "lop_amount": formData?.lop_amount ? formData?.lop_amount : 0,
                    "lop_days": formData?.lop_days ? formData?.lop_days : 0,
                    "reimbursements": Object.keys(reimbursements_breakup)?.length > 0 ? reimbursements_breakup : {},
                    "reimbursements_total": totalReimburse ? totalReimburse : 0,
                    // "net_pay": totalPaidSlry ? totalPaidSlry : 0,
                    "paid_salary": +totalPaidSlry ? +totalPaidSlry : 0
                }
                fetch(PAYROLL_PAYSLIP_EDIT, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${authtoken}`
                    },
                    body: JSON.stringify(updatedFormData)
                })
                    .then((response) => response.json())
                    .then(async data => {
                        if (data.status === "success") {
                            setLoading(false);
                            onSave();
                            handleCancel();
                            ToastMsg("success", data.message);
                            navigate('/payroll/runpayroll/process', { state: { PaySlipId: payroll_id } })
                        }
                        else if (data.status === "fail") {
                            ToastMsg("error", data.message);
                            setLoading(false);
                        }
                    })
                    .catch(error => {
                        error.json().then(data => {
                            setLoading(false);
                            ToastMsg("error", data);
                        });
                    });
            } catch (error) {
                ToastMsg("error", error.message);
            }
    }, [debitList, creditList, formData, totalDebit, totalCredit, totalReimburse]);

    const handleCancel = () => {
        setIsModalOpen(false);
        onHide();
        handleClear();
    };

    const renderInput = (value) => {
        return (
            <Input
                autoComplete='off'
                readOnly={true}
                addonBefore={<div style={{ color: '#D9D9D9', fontWeight: 800 }}>{"₹"}</div>}
                style={{ width: "8vw" }}
                size="large"
                type={"text"}
                value={value ? value : "0"}
                className="input-group-container"
            />
        )
    }
    const handleChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const renderEditInput = (statekey, disabled = false) => {
        return (
            <Input
                autoComplete='off'
                disabled={{disabled}}
                style={{ width: "8vw" }}
                addonBefore={<div style={{ color : `${disabled ? 'rgb(217, 217, 217)' : "#04B7B1"}`, fontWeight: 800 }}>{"₹"}</div>}
                size="large"
                type={"number"}
                placeholder={"Enter Amount"}
                value={formData[statekey] ? formData[statekey] : ""}
                onChange={(e) => handleChange(statekey, e.target.value)}
                className="input-group-container"
            />
        )
    }

    return (
        <Modal
            centered
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            width={"60vw"}
            className="custom-modal"
        >
            <div div className="w-full">
                <p className="common_DayWiseTxt border-b pb-2.5">Edit Salary Detail</p>
                <div className="w-full flex justify-between items-center">
                    <div>
                        <p className="password-tlt">Employee Information</p>
                        <p id="common_weekoff_valTxt">{formData.employee_name?.length > 20 ?
                            <Tooltip title={`${formData?.title} ${formData?.employee_name}`}>
                                <span id="common_weekoff_valTxt">{(formData?.title ? formData?.title : "") + formData.employee_name?.slice(0, 20) + "..."}</span>
                            </Tooltip>
                            : formData.employee_name ? `${formData?.title ? formData?.title : ""} ${formData?.employee_name}` : "-"}</p>
                        <p className="password-tlt">{formData.employee_code ? `( ${formData.employee_code} ) ${formData.designation_name ? formData.designation_name : ""}`
                            : formData.designation_name ? formData.designation_name : ""}</p>
                    </div>
                    <div>
                        <p className="password-tlt">Total Paid Salary</p>
                        <p id='common_weekoffTxt'>{toIndianCurrency(totalPaidSlry ? totalPaidSlry : 0)}</p>
                    </div>
                </div>
                <div className="grid grid-cols-3 border mt-5">
                    <div className="overflow-y-auto" style={styles.earningContainer}>
                        <p className="pl-2 runpayRoll-editPop-tlt">Earnings</p>
                        {formData?.earnings_breakup && Object.keys(formData.earnings_breakup).map((key) => {
                            let earnings = formData?.earnings_breakup[key];
                            return (<div className="flex justify-between p-3">
                                <p id="common_home_upcomingTxt" className="capitalize">{removeUnderscores(key)}</p>
                                {renderInput(earnings.monthly_amount)}
                            </div>)
                        })}
                        <div className="flex justify-between p-3">
                            <p id="common_home_upcomingTxt">Total</p>
                            <p id="common_home_upcomingTxt" className="pr-1">{toIndianCurrency(formData?.earnings ? formData?.earnings : 0)}</p>
                        </div>
                        <div className="border-t">
                            <p className="pl-2 runpayRoll-editPop-tlt">Credit</p>
                            <div>
                                {creditList?.length > 0 ? creditList?.map((item, index) => (
                                    <div>
                                        <div className="flex justify-between p-3">
                                            <div>
                                                <p id="common_home_upcomingTxt">Credit Name</p>
                                                <Input
                                                    autoComplete='off'
                                                    style={{ width: "6vw" }}
                                                    size="large"
                                                    type={"text"}
                                                    placeholder={"Enter Name"}
                                                    value={item?.name ? item?.name : ""}
                                                    onChange={(e) => handleListChange(creditList, setCreditList, index, "name", e.target.value)}
                                                />
                                            </div>
                                            <Input
                                                autoComplete='off'
                                                style={{ width: "8vw" }}
                                                addonBefore={<div style={{ color: "#04B7B1", fontWeight: 800 }}>{"₹"}</div>}
                                                size="large"
                                                type={"number"}
                                                placeholder={"Enter Amount"}
                                                value={item?.amount ? item?.amount : ""}
                                                onChange={(e) => handleListChange(creditList, setCreditList, index, "amount", e.target.value)}
                                                className="input-group-container"
                                            />
                                        </div>
                                        {index > 0 && (
                                            <div className="flex justify-end">
                                                <Button
                                                    onClick={() => handleListRemove(creditList, setCreditList, index)}
                                                    icon={<MinusCircleOutlined />}
                                                    style={{ border: 0 }}
                                                    size="large"
                                                ></Button>
                                            </div>
                                        )}
                                    </div>
                                ))
                                    : null}
                                <div className="flex flex-row justify-start">
                                    <Button
                                        onClick={() => handleListAdd(creditList, setCreditList)}
                                        icon={<PlusCircleOutlined />}
                                        style={{ border: 0 }}
                                        size="large"
                                    ></Button>
                                </div>
                                <div className="flex justify-between p-3">
                                    <p id="common_home_upcomingTxt">Total</p>
                                    <p id="common_home_upcomingTxt" className="pr-1">{toIndianCurrency(totalCredit ? totalCredit : 0)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-l overflow-y-auto" style={styles.earningContainer}>
                        <p className="pl-2 runpayRoll-editPop-tlt">Deduction</p>
                        {formData?.deductions_breakup && Object.keys(formData.deductions_breakup).map((key) => {
                            let deductions = formData?.deductions_breakup[key];
                            return (
                                <React.Fragment key={key}>
                                    {key === "tds" ? (
                                        <div className="flex justify-between p-3">
                                            <p id="common_home_upcomingTxt" className="uppercase">{key}</p>
                                            {renderInput(deductions.employee_share_monthly_amount)}
                                        </div>
                                    ) : (
                                        <>
                                            <div className="flex justify-between p-3">
                                                <p id="common_home_upcomingTxt">
                                                    <span className="uppercase">{key}</span> Employee</p>
                                                {renderInput(deductions.employee_share_monthly_amount)}
                                            </div>
                                            <div className="flex justify-between p-3">
                                                <p id="common_home_upcomingTxt">
                                                    <span className="uppercase">{key}</span> Employer</p>
                                                {renderInput(deductions.employer_share_monthly_amount)}
                                            </div>
                                        </>
                                    )}
                                </React.Fragment>)
                        })}
                        <div className="flex justify-between p-3 mt-20">
                            <p id="common_home_upcomingTxt">Total</p>
                            <p id="common_home_upcomingTxt" className="pr-1">{toIndianCurrency(formData?.deductions ? formData?.deductions : 0)}</p>
                        </div>
                    </div>
                    <div className="border-l overflow-y-auto" style={styles.earningContainer}>
                        <p className="pl-2 runpayRoll-editPop-tlt">Debit</p>
                        {debitList?.length > 0 ? debitList?.map((item, index) => (
                            <div>
                                <div className="flex justify-between p-3">
                                    <div>
                                        <p id="common_home_upcomingTxt">Debit Name</p>
                                        <Input
                                            autoComplete='off'
                                            style={{ width: "6vw" }}
                                            size="large"
                                            type={"text"}
                                            placeholder={"Enter Name"}
                                            value={item?.name ? item?.name : ""}
                                            onChange={(e) => handleListChange(debitList, setDebitList, index, "name", e.target.value)}
                                        />
                                    </div>
                                    <Input
                                        autoComplete='off'
                                        style={{ width: "8vw" }}
                                        addonBefore={<div style={{ color: "#04B7B1", fontWeight: 800 }}>{"₹"}</div>}
                                        size="large"
                                        type={"number"}
                                        placeholder={"Enter Amount"}
                                        value={item?.amount ? item?.amount : ""}
                                        onChange={(e) => handleListChange(debitList, setDebitList, index, "amount", e.target.value)}
                                        className="input-group-container"
                                    />
                                </div>
                                {index > 0 && (
                                    <div className="flex justify-end">
                                        <Button
                                            onClick={() => handleListRemove(debitList, setDebitList, index)}
                                            icon={<MinusCircleOutlined />}
                                            style={{ border: 0 }}
                                            size="large"
                                        ></Button>
                                    </div>
                                )}
                            </div>
                        ))
                            : null}
                        <div className="flex flex-row justify-start">
                            <Button
                                onClick={() => handleListAdd(debitList, setDebitList)}
                                icon={<PlusCircleOutlined />}
                                style={{ border: 0 }}
                                size="large"
                            ></Button>
                        </div>
                        <div className="flex justify-between p-3 border-b">
                            <p id="common_home_upcomingTxt">Total</p>
                            <p id="common_home_upcomingTxt" className="pr-1">{toIndianCurrency(totalDebit ? totalDebit : 0)}</p>
                        </div>
                        <div className="border-b py-3">
                            <p className="pl-2 runpayRoll-editPop-tlt">{"Loss Of Pay(LOP)"}</p>
                            <div className="flex justify-between p-3">
                                <div>
                                    <p id="common_home_upcomingTxt">{formData?.lop_days ? formData?.lop_days : 0}</p>
                                    <p id="common_admin_orgTlt">No Of Lop</p>
                                </div>
                                {renderEditInput("lop_amount", true)}
                            </div>
                        </div>
                        <div className="py-3">
                            <p className="pl-2 runpayRoll-editPop-tlt">{"Reimbursments"}</p>
                            {reimburseList[0].name !== "" && reimburseList?.map((item, index) => (
                                <div className="flex justify-between p-3">
                                    <p id="common_home_upcomingTxt">{item.name}</p>
                                    <Input
                                        autoComplete='off'
                                        style={{ width: "8vw" }}
                                        addonBefore={<div style={{ color: "#04B7B1", fontWeight: 800 }}>{"₹"}</div>}
                                        size="large"
                                        type={"number"}
                                        placeholder={"Enter Amount"}
                                        value={item?.amount ? item?.amount : ""}
                                        onChange={(e) => handleListChange(reimburseList, setReimburseList, index, "amount", e.target.value)}
                                    />
                                </div>
                            ))}
                            <div className="flex justify-between p-3">
                                <p id="common_home_upcomingTxt">Total</p>
                                <p id="common_home_upcomingTxt" className="pr-1">{toIndianCurrency(totalReimburse ? totalReimburse : 0)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end items-end my-2'>
                    <MyButton htmlType="button" label="Reset" onClick={handleClear} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                    <MyButton htmlType="submit" label={"Update"} onClick={handleUpdate} loading={loading} paddingX={"0 1.2vw"} />
                </div>
            </div>
        </Modal>
    )
}

const styles = {
    earningContainer: {
        height: "51vh"
    }
}

export default EditSalaryDetails;