import React, { useContext, useEffect, useState } from "react";
import { Card, Checkbox, Form, Input, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Pattern from "components/ui/Images/Pattern";
import { loginPage } from "components/pages/Login/login";
import { AuthContext } from "context/auth/authentication";
import MySecondaryLargeButton from "components/ui/Button/MySecondaryLargeButton";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";

const Login = () => {
  const navigate = useNavigate();
  const { loginauth } = useContext(AuthContext);
  const [loadings, setLoadings] = useState([]);
  const { isAuthenticated } = useContext(AuthContext);
  const [keepSignedIn, setKeepSignedIn] = useState(false)
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated]);

  const onFinish = (values) => {
    setLoadings(true);
    const onSuccess = (data) => {
      if (data) {
        if (data.status === "success") {
          loginauth(data.data, keepSignedIn);
          setLoadings(false);
          navigate("/home");
        } else if (data.status === "fail") {
          setLoadings(false);
          ToastMsg("error", data.message);
        }
      }
    };
    const onError = (error) => {
      setLoadings(false);
      ToastMsg("error", error);
    };
    loginPage(values, onSuccess, onError);
  };

  const onChange = (e) => {
    setKeepSignedIn(e.target.checked)
  };
  return (
    <div className="h-screen grid grid-cols-12">
        <div className="col-span-5">
          <Pattern className="h-screen w-full pattern-text"/>
        </div>
          <div className="h-dvh col-span-7" style={{ padding: "1.875dvw"}}>
            <div class="flex justify-between mb-10">
              <img src={ImagePaths.loginLogo.default} className = "login_Logo" alt={"App Logo"} />
            </div>
            <div className="flex items-center justify-center">
              <Form
                layout="vertical"
                name="basicform"
                onFinish={onFinish}
                onSubmit={(e) => e.preventDefault()}
                colon={false}
                requiredMark={false}
                initialValues={{ remember: true }}
              >
                <Card
                  className=" drop-shadow-md items-center justify-center px-5"
                  bordered={false}
                  style={{ width: 400 }}
                >
                  {/* <p className="text-left password-tlt" >Sign in</p> */}
                  <p className = "text-center login_tlt">Welcome Back To HRBuddie !</p>

                  <label className="block mt-6">
                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[{ required: true, message: "Username is required" }]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                    >
                      <Input size="large" className="font-semibold" placeholder="Username" />
                    </Form.Item>
                  </label>
                  <label className="block">
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[{ required: true, message: "Password is required" }]}
                      tooltip={{
                        title: "This field is required",
                        icon: <span style={{ color: "red" }}>*</span>,
                      }}
                    >
                      <Input.Password size="large" className="font-semibold" placeholder="Password"/>
                    </Form.Item>
                  </label>

                  <div>
                  <Form.Item
                      name="keep_signed_in"
                    >
                    <Checkbox
                      onChange={onChange}
                      style={{
                        "--background-color": "white",
                        "--border-color": "grey",
                        "--inner-background-color": "blue",
                        "--inner-border-color": "blue",
                        "--width": "20px",
                        "--height": "20px",
                        "--inner-tick-color": "white",
                      }}
                    >
                      <span className="login-checkbox login-checkbox-tlt">Remember Me</span>
                    </Checkbox>
                    </Form.Item>
                  </div>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="items-center justify-center my-2">
                      <Form.Item>
                        <MySecondaryLargeButton
                          type="primary"
                          label="SIGN IN"
                          value={loadings ? "Loading..." : "Login!"}
                          className="mt-2 items-center"
                          htmlType="submit"
                          loading={loadings}
                        />
                      </Form.Item>
                    </div>
                  </Space>
                  <div
                    className="text-center"
                    onClick={() => navigate("/forgotpassword")}
                  >
                    <Link className="text-xs ">Forgot Your Password ?</Link>
                  </div>
                </Card>
              </Form>
            </div>
          </div>
    </div>
  );
};

export default Login;
