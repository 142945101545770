import { EMPLOYEE_DEPARTMENT } from "constants";
import { prefillUrl } from "utils/StringUtils";


export const getEmployeeDepartment = async ({ pageNo, pageSize, query, search, sorting }) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    let url = EMPLOYEE_DEPARTMENT;

    if (pageNo) {
      url = prefillUrl(url);
      url = `${url}&page_no=${pageNo}`;
    }

    if (pageSize) {
      url = prefillUrl(url);
      url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
      url = prefillUrl(url);
      url = `${url}&${query}`;
    }

    if (search) {
      url = prefillUrl(url);
      url = `${url}&search=${search}`;
    }

    if (sorting) {
      url = prefillUrl(url);
      url = `${url}&${sorting}`;
    }

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const getEmployeeAllDepartment = async (query) => {
  try {
    const authtoken = sessionStorage.getItem("token");

    let url = EMPLOYEE_DEPARTMENT;

    if (query) {
      url = prefillUrl(url);
      url = `${url}${query}`;
    }

    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const getEmployeeDepartmentById = async (id) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `token ${authtoken}` },
    };

    return await fetch(EMPLOYEE_DEPARTMENT + `?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const addEmployeeDepartment = async (input) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: { Authorization: `token ${authtoken}` },
      body: JSON.stringify(input),
    };

    return await fetch(EMPLOYEE_DEPARTMENT, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};

export const updateEmployeeDepartment = async (input) => {
  try {
    const authtoken = sessionStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: { Authorization: `token ${authtoken}` },
      body: JSON.stringify(input),
    };

    return await fetch(EMPLOYEE_DEPARTMENT, requestOptions)
      .then((response) => response.json())
      .then((data) => data);
  } catch (error) {
    return { status: "fail", message: error.message };
  }
};
