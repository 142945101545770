import { getAttendanceLog } from "hooks/api/attendanceApi/dayWiseAttendanceApi";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";
import ToastMsg from "components/common/ToastMsg";

const useGetEmployeeAttendnceLog = (date, pageNo, pageSize, query, search, sort) => {
    const [data, setData] = useState(null);
    const [pageInfo, setPageInfo] = useState();
    const [currentMonth, setCurrentMonth] = useState(new Date(date).getMonth() + 1)
    const [currentYear, setCurrentYear] = useState(new Date(date).getFullYear())
    const [message, setMessage] = useState({
        isErrorMsg: false,
        errorMsgText: ""
    });
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState();
    const navigate = useNavigate();
    const { logoutauth } = useContext(AuthContext);

    function refetch(selDate, pageNo, pageSize, query, search, sort) {

        setSorting(sort)
        setCurrentMonth(new Date(selDate).getMonth() + 1);
        setCurrentYear(new Date(selDate).getFullYear());
        fetchData(new Date(selDate).getMonth() + 1, new Date(selDate).getFullYear(), pageNo, pageSize, query, search, sort)
    }

    const fetchData = async (month, year, pageNo, pageSize, query, search, sort) => {
        try {
            const apiData = await getAttendanceLog(month, year, pageNo, pageSize, query, search, sort);

            if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
                setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
                navigate("/");
                logoutauth();
            }
            if(apiData.detail === "You are attempting to log in without an active subscription. Please subscribe to a plan to access the system."){
                setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
                ToastMsg("error", apiData.detail);
                navigate("/myplans");
              }
            if (apiData) {
                if (apiData.status === "fail") {
                    setMessage(apiData.message);
                } else {
                    setData(apiData.data.result);
                    setPageInfo({
                        pageNo: apiData.data.page_no,
                        pageSize: apiData.data.page_size,
                        totalCount: apiData.data.total_count,
                        noOfPages: apiData.data.no_of_pages,
                    });
                }
            }
        } catch (error) {
            setMessage({ isErrorMsg: true, errorMsgText: "Error fetching data" });
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        setSorting(sort ? sort : sorting)
        fetchData(currentMonth, currentYear, pageNo, pageSize, query, search, sort);
    }, []);

    return [data, pageInfo, loading, message, refetch];
};

export default useGetEmployeeAttendnceLog;
