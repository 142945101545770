import { notification } from 'antd';
const ToastMsg = (type, message) => {
    notification[type]({
        type: type,
        message: message,
        style: {
            width: "35vw",
            borderLeft: `6px solid ${type === "warning" ? "#E28F12" : type === "error" ? "Red" : "#0E862A"}`,
            borderRadius: '10px 0px 0px 10px',
        },
        placement: 'topRight',
    });
};
export default ToastMsg;

