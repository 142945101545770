import React, { useState, useEffect, useRef } from "react";
import { Select, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { statusEnum } from "components/common/enum";
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi.js";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { attendanceStatus } from 'components/common/enum';

const AttendanceFilter = ({
    pageName,
    filterDatas,
    closeFilter,
    filteredData,
    clearData,
}) => {
    const [filterData, setFilterData] = useState({
        employee_name: filterDatas.employee_name,
        designation_name: filterDatas.designation_name,
        department_name: filterDatas.department_name,
        status: filterDatas.status
    });

    const [designationList, setDesignationList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const hideCursorRef = useRef({});

    const handleChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFilterData((prevFilterData) => ({
            ...prevFilterData,
            designation_name: "",
            department_name: "",
            employee_name: "",
            status: ""
        }));
        clearData();
    };

    /*call designation*/
    const getEmployeeDesignationList = async () => {
        try {
            const apiData = await getEmployeeDesignation(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDesignationList([...apiData.data])
            } else {
                setDesignationList([])
            }
        } catch (error) {
            setDesignationList([])
        }
    }

    /*call departments*/
    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDepartmentList([...apiData.data]);
            } else {
                setDepartmentList([]);
            }
        } catch (error) {
            setDepartmentList([]);
        }
    }

    /*call employee api*/
    const getEmployeeList = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setEmployeeList([...apiData.data])
            } else {
                setEmployeeList([])
            }
        } catch (error) {
            setEmployeeList([])
        }
    }

    useEffect(() => {
        getEmployeeDesignationList();
        getDepartmentList();
        getEmployeeList();
    }, []);

    const renderSelect = (label, statekey, list = statusEnum, showKey = "text", valueKey = "value") => {
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div className="fltTextFieldDiv">
            <div className='commonInputTlt'>{label}</div>
            <Select
                className="fltTextField"
                value={filterData[statekey] ? filterData[statekey] : undefined}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                ref={hideCursorRef.current[statekey]}
                onSelect={() => {
                    hideCursorRef.current[statekey].current.blur();
                }}
            >
                {list.map((option) => (
                    (valueKey === "id" && option.is_active === true) || valueKey !== "id" ?
                    <Select.Option key={option[valueKey]} value={option[valueKey]}>
                         {statekey === "employee_name" ? option[showKey] + (option['employee_id'] ? " - " + option['employee_id'] : "") : option[showKey]}
                    </Select.Option> : null
                ))}
            </Select>
            </div>
        );
    };

    return (
        <div className="h-full w-full">
            <div className="flex justify-between items-center filterTltDiv">
                <p className="common_addTltTxt">{"Filter"}</p>
                <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter} /></Tooltip>
            </div>
            <div className="flex flex-col items-center fltContentDiv">
                {(pageName === "DaywiseAttendance" || pageName === "AttendanceLog") ? (
                    <>
                        {renderSelect("Select Employee", "employee_name", employeeList, "first_name", "id")}
                        {renderSelect("Select Designation", "designation_name", designationList, "designation_name", "id")}
                        {renderSelect("Select Department", "department_name", departmentList, "department_name", "id")}
                        {renderSelect("Status", "status", attendanceStatus)}
                    </>
                )
                    : null}
            </div>

            <div className="flex justify-center items-end">
                <MyButton
                    label="Clear"
                    onClick={handleClear}
                    outlined = {true}
                    paddingX={"0 0.7vw"}
                    marginRight={"0.625vw"}
                />
                <MyButton
                    label={"Apply"}
                    onClick={() => filteredData(filterData)}
                    paddingX={"0 0.5vw"}
                />
            </div>
        </div>
    );
};

export default AttendanceFilter;