import React, { useState, useRef, } from "react";
import { Input, Select, DatePicker, Tooltip} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { statusEnum, noticeStatusEnum } from "components/common/enum";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';
import { disabledFutureDate } from "components/common/validation";

const AdminFilter = ({
  pageName,
  filterDatas,
  closeFilter,
  filteredData,
  clearData,
}) => {
  const [filterData, setFilterData] = useState({
    user_group_name: filterDatas.user_group_name,
    document_code: filterDatas.document_code,
    document_name: filterDatas.document_name,
    is_active: filterDatas.is_active,
    from_date: filterDatas.from_date,
    to_date: filterDatas.to_date,
    status: filterDatas.status
  });
  const handleChange = (name, value) => {
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleClear = () => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      user_group_name: "",
      document_name: "",
      document_code:"",
      is_active: "",
      from_date: "",
      to_date: "",
      status: "",
    }));
    clearData();
  };

  const renderInput = (label, statekey, testID = '') => {
    return (
      <div className="fltTextFieldDiv">
      <div className='commonInputTlt'>{label}</div>
      <Input
        autoComplete="off"
        className="fltTextField"
        size="large"
        type="text"
        name={statekey}
        value={filterData[statekey] ? filterData[statekey] : ""}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testid={testID}
      />
      </div>
    );
  };

  const handleDatePickerChange = (date, name) => {
    setFilterData({
      ...filterData,
      [name]: dayjs(date, "YYYY-MM-DD"),
    });
  };

  const renderDatePicker = (label, statekey, testID = '') => {
    return (
      <div className="fltTextFieldDiv">
      <div className='commonInputTlt'>{label}</div>
      <DatePicker
        className="fltDatepickertextField"
        disabledDate={disabledFutureDate}
        name={statekey}
        value={filterData[statekey] ? filterData[statekey] : ""}
        format={"DD-MM-YYYY"}
        onChange={(date) => handleDatePickerChange(date, statekey,)}
        allowClear={false}
        inputReadOnly={true}
        locale={locale}
        data-testid={testID}
        placeholder = ""
      />
      </div>
    )
  }
  const hideCursorRef = useRef({});
  const renderSelect = (
    label,
    statekey,
    list = statusEnum,
    showKey = "text",
    valueKey = "value",
    testID = ''
  ) => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }
    return (
      <div className="fltTextFieldDiv">
      <div className='commonInputTlt'>{label}</div>
      <Select
        className="fltTextField"
        value={filterData[statekey] ? filterData[statekey] : ""}
        name={statekey}
        onChange={(value) => handleChange(statekey, value)}
        data-testid={testID}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        ref={hideCursorRef.current[statekey]}
        onSelect={() => {
          hideCursorRef.current[statekey].current.blur();
        }}
      >
        {list.map((option) => (
          <Select.Option key={option[valueKey]} value={option[valueKey]}>
            {option[showKey]}
          </Select.Option>
        ))}
      </Select>
      </div>
    );
  };

  return (
    <div className="h-full w-full">
      <div className="flex justify-between items-center filterTltDiv">
        <p className="common_addTltTxt">{"Filter"}</p>
        <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter}/></Tooltip>
      </div>
      <div className="flex flex-col items-center fltContentDiv">
        {pageName === "UserGroup" ? (
          <>
            {renderInput("User Group Name", "user_group_name", 'ad_flt_ug_in')}
            {renderSelect("Status", "is_active", statusEnum, 'text', 'value', 'ad_flt_ug_status')}
          </>
        ) : pageName === "NoticeBoard" ? (
          <>
            <div className="grid grid-cols-2 gap-5">
              {renderDatePicker("From Date", "from_date", 'ad_flt_nb_frmd')}
              {renderDatePicker("To Date", "to_date", 'ad_flt_nb_tod')}
            </div>
            {renderSelect("Status", "status", noticeStatusEnum, "text", "text", 'ad_flt_nb_status')}
          </>
        )
          : pageName === "organizationdocument" ? (
          <>
              {renderInput("Document Code", "document_code", 'ad_doc_code')}
              {renderInput("Document Name", "document_name", 'ad_doc_name')}
              {renderSelect("Status", "is_active", statusEnum, "text", "value", 'ad_flt_doc_status')}
          </>
        )
         : null}
      </div>

      <div className="flex justify-center items-end">
        <MyButton
          label="Clear"
          onClick={handleClear}
          outlined = {true}
          paddingX={"0 0.7vw"}
          marginRight={"0.625vw"}
          testID={"ad_flt_clearbtn"}
        />
        <MyButton
          label={"Apply"}
          onClick={() => filteredData(filterData)}
          paddingX={"0 0.5vw"}
          testID={"ad_flt_applybtn"}
        />
      </div>
    </div>
  );
};

export default AdminFilter;
