import React, { useState, useRef } from "react";
import { Select, DatePicker, Tooltip} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { statusEnum, rosterStatusEnum } from "components/common/enum";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';
import { disabledFutureDate } from "components/common/validation";

const AssetManagementFilter = ({
  pageName,
  filterDatas,
  closeFilter,
  filteredData,
  clearData,
}) => {
  const [filterData, setFilterData] = useState({
    asset_category: filterDatas.asset_category,
    asset_sub_category: filterDatas.asset_sub_category,
    purchase_date: filterDatas.purchase_date,
    is_active: filterDatas.is_active,
    assetCategoryList: filterDatas.assetCategoryList,
    assetSubCategoryList: filterDatas.assetSubCategoryList,
    issued_date: filterDatas.issued_date,
    return_date: filterDatas.return_date,
    hand_over_to: filterDatas.hand_over_to,
    approved_by: filterDatas.approved_by,
    employeeList: filterDatas.employeeList,
    roster_from_date: filterDatas.roster_from_date,
    roster_to_date: filterDatas.roster_to_date,
    roster_status: filterDatas.roster_status,
  });
  const hideCursorRef = useRef({});

  const handleChange = (name, value, type = 'input') => {
    switch (type) {
      case "datepicker":
        setFilterData({
          ...filterData,
          [name]: dayjs(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        setFilterData({
          ...filterData,
          [name]: value,
        });
        break;
    }
  };

  const handleClear = () => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      asset_category: undefined,
      asset_sub_category: undefined,
      purchase_date: undefined,
      is_active: undefined,
      issued_date: undefined,
      return_date: undefined,
      hand_over_to: undefined,
      approved_by: undefined,
      roster_from_date: null,
      roster_to_date: null,
      roster_status: "",
    }));
    clearData();
  };

  const renderDatePicker = (label, statekey, testID = '') => {
    return (
      <div className="fltTextFieldDiv">
      <div className='commonInputTlt'>{label}</div>
      <DatePicker
        className="fltTextField"
        disabledDate={disabledFutureDate}
        name={statekey}
        value={filterData[statekey] ? dayjs(filterData[statekey], "YYYY-MM-DD") : ""}
        format={"DD-MM-YYYY"}
        onChange={(date) => handleChange(statekey, date, 'datepicker')}
        allowClear={false}
        inputReadOnly={true}
        locale={locale}
        data-testid={testID}
        placeholder = ""
      />
      </div>
    )
  }

  const renderSelect = (label, statekey, list = statusEnum, showKey = "text", valueKey = "value", testID = '') => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }
    return (
      <div className="fltTextFieldDiv">
      <div className='commonInputTlt'>{label}</div>
      <Select
        className="fltTextField"
        value={filterData[statekey] ? filterData[statekey] : ""}
        name={statekey}
        onChange={(value) => handleChange(statekey, value)}
        data-testid={testID}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        ref={hideCursorRef.current[statekey]}
        onSelect={() => {
          hideCursorRef.current[statekey].current.blur();
        }}
      >
        {list.map((option) => (
          (valueKey === "id" && option.is_active === true) || valueKey !== "id" ?
          <Select.Option key={option[valueKey]} value={option[valueKey]}>
             {statekey === "hand_over_to" || statekey === "approved_by" 
             ? option[showKey] + (option['employee_id'] ? (" - " + option['employee_id']) : "") 
             : option[showKey]}
          </Select.Option>
          : null
        ))}
      </Select>
      </div>
    );
  };

  return (
    <div className="h-full w-full">
     <div className="flex justify-between items-center filterTltDiv">
        <p className="common_addTltTxt">{"Filter"}</p>
        <Tooltip title={"Close"} placement="bottom"><CloseOutlined className="fltCloseIcon" onClick={closeFilter}/></Tooltip>
      </div>
      <div className="flex flex-col items-center fltContentDiv">
        {pageName === "AssetRegistry" ? (
          <>
            {renderSelect("Select Category", "asset_category", filterData.assetCategoryList, "category_name", "id", 'ast_flt_cat')}
            {renderSelect("Select Sub-Category", "asset_sub_category", filterData.assetSubCategoryList, "asset_sub_category_name", "id", 'ast_flt_subcat')}
            {renderDatePicker("Select Purchase", "purchase_date", 'ast_flt_purdate')}
            {renderSelect("Status", "is_active", statusEnum, 'text', 'value', 'ast_flt_status')}
          </>
        ) : pageName === "AssetEmployeeAudit" ? (
          <>
            {renderDatePicker("Select Issued Date", "issued_date", 'ast_flt_issued_date')}
            {renderDatePicker("Select Return Date", "return_date", 'ast_flt_return_date')}
            {renderSelect("Select Handover to", "hand_over_to", filterData.employeeList, "first_name", "id", 'ast_flt_hot')}
            {renderSelect("Approved By", "approved_by", filterData.employeeList, "first_name", "id", 'ast_flt_approved_by')}
          </>
        )
          : pageName === "RosterHome" ? (
            <>
              {renderDatePicker("Select From Date", "roster_from_date", 'roster_flt_from_date')}
              {renderDatePicker("Select To Date", "roster_to_date", 'roster_flt_to_date')}
              {renderSelect("Status", "roster_status", rosterStatusEnum, "text", "value", 'roster_flt_status')}
            </>)
            : null}
      </div>

      <div className="flex justify-center items-end">
        <MyButton
          label="Clear"
          onClick={handleClear}
          outlined = {true}
          paddingX={"0 0.7vw"}
          marginRight={"0.625vw"}
          testID="ast_flt_clearbtn"
        />
        <MyButton
          label={"Apply"}
          onClick={() => filteredData(filterData)}
          paddingX={"0 0.5vw"}
          testID="ast_flt_applybtn"
        />
      </div>
    </div>
  );
};

export default AssetManagementFilter;