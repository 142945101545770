import { ASSET } from 'constants';
import { ASSET_DETAILS } from 'constants';
import { ASSET_MANAGEMENT } from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const getAssetPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = ASSET_MANAGEMENT;
    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}

export const  getAsset = async (query) => {
    const authtoken = sessionStorage.getItem("token")
    let url = ASSET_MANAGEMENT;

    if (query) {
        url = prefillUrl(url);
        url = `${url}${query}`;
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
        
    };

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}

export const  getAssetsById = async (id) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
        
    };

    return await fetch(ASSET_MANAGEMENT+`?id=${id}`,requestOptions).then((response) => response.json()).then(data=>data);
}

export const  addAssets = async (input) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `token ${authtoken}` },
        body: JSON.stringify(input) 
    };

    return await fetch(ASSET_MANAGEMENT,requestOptions).then((response) => response.json()).then(data=>data);
}

export const  updateAssets = async (input) => {
    const authtoken = sessionStorage.getItem("token")
    const requestOptions = {
        method: 'PUT',
        headers: { 'Authorization': `token ${authtoken}` },
        body: JSON.stringify(input) 
    };

    return await fetch(ASSET_MANAGEMENT,requestOptions).then((response) => response.json()).then(data=>data);
}

export const getAssetManagementPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = ASSET;
    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        if (query.asset_type_id) {
            url = prefillUrl(url);
            url = `${url}&asset_type_id=${query.asset_type_id}`;
        }
        if (query.brand_name) {
            url = prefillUrl(url);
            url = `${url}&brand=${query.brand_name}`;
        }
        if (query.status) {
            url = prefillUrl(url);
            url = `${url}&status=${query.status}`;
        }
        if (query.designation) {
            url = prefillUrl(url);
            url = `${url}&designation=${query.designation}`;
        }
        if (query.department) {
            url = prefillUrl(url);
            url = `${url}&department=${query.department}`;
        }
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}

export const  getAssetManagement = async (query) => {
    const authtoken = sessionStorage.getItem("token")
    let url = ASSET;

    if (query) {
        url = prefillUrl(url);
        url = `${url}drop_down=${query}`;
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
        
    };

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}

export const  getAssetManagementByStatus = async (query,status) => {
    const authtoken = sessionStorage.getItem("token")
    let url = ASSET;

    if (query) {
        url = prefillUrl(url);
        url = `${url}drop_down=${query}`;
    }
    if (status) {
        url = prefillUrl(url);
        url = `${url}&status=${status}`;
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
        
    };

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}

export const getAssetDetailsPagination = async ({ pageNo, pageSize, query, search, sorting }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = ASSET_DETAILS;

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        if (query.asset_id) {
            url = prefillUrl(url);
            url = `${url}&asset_id=${query.asset_id}`;
        }
        if (query.issued_date) {
            url = prefillUrl(url);
            url = `${url}&issued_date=${query.issued_date}`;
        }
        if (query.returned_date) {
            url = prefillUrl(url);
            url = `${url}&return_date=${query.returned_date}`;
        }
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sorting) {
        url = prefillUrl(url);
        url = `${url}&${sorting}`;
    }

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    return await fetch(url,requestOptions).then((response) => response.json()).then(data=>data);
}