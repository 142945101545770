import { MASTER_LEAVE_MANAGE } from "constants";

const WriteLeaveApi = (formData, authtoken, onSuccess, onError) => {
  fetch(MASTER_LEAVE_MANAGE, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `token ${authtoken}`,
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if (data.status === "success") {
        onSuccess(data.message);
      } else if (data.status === "fail") {
        onError(data.message);
      }
    });
};

export default WriteLeaveApi;
