import { getEmployerDashboardAPI } from "hooks/api/adminApi/employerAPI";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";

const useGetEmployerDashboard = () => {
  const [data, setData] = useState(null);
  const [ShouldRefetch, setShouldRefetch] = useState();
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const { logoutauth } = useContext(AuthContext);

  async function fetchData() {
    const apiData = await getEmployerDashboardAPI();
    if(apiData.detail && apiData.detail==="You are not authorized to perform this action"){
      logoutauth()
      navigate("/");
    }
    if (apiData) {
      if (apiData.status === "fail") {
        setMessage(apiData.message);
      } else {
        setData(apiData.data);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [ShouldRefetch]);

  function refetch() {
    setShouldRefetch(ShouldRefetch + 1)
  }

  return [data, message, refetch];
};

export default useGetEmployerDashboard;
