import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import nodata from "../../../assets/nodata.png";
import { Avatar, Space, Table, message } from "antd";
import Title from "antd/es/typography/Title";
import IconButton from "components/ui/Button/IconButton";
import IconButtonWarning from "components/ui/Button/IconButtonwarning";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import WriteLeaveApi from "./WriteLeaveApi";
import logo from "../../../assets/logo.png"
import { getEmployeeLeaveDashboard } from "hooks/api/employeeApi/employeeLeaveApi";

function DashboardLeave({ date }) {
  const [leavedata, setLeaveData] = useState(null);
  const [shouldRefetch, setShouldFetch] = useState(1);
  const authtoken = sessionStorage.getItem("token");
  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  function refetch() {
    setShouldFetch(shouldRefetch + 1);
  }

  const handleLeave = (status, id) => {
    const formData = {
      id : id,
      status: status
    }
    WriteLeaveApi(
      formData,
      authtoken,
      (data) => {
        //onSuccess
        message.success(data);
        refetch();
      },
      (error) => {
        //onError
        message.error(error);
      }
    );
  }

  const getLeaveAPI = async (leaveDate) => {
    const data = await getEmployeeLeaveDashboard(leaveDate);
    if (data) {
      if (data.status === "fail") {
        // message.warning(data.message)
      } else {
        setLeaveData(data.data);
      }
    }
  };

  useEffect(() => {
    getLeaveAPI(dayjs(date).format("YYYY-MM-DD"))
  },[date, shouldRefetch])

 const columns = [
   {
     title: "Employee Details",
     dataIndex: "employee_id",
     key: "employee_id",
     render: (_, record) => (
       <Space size="middle">
         <Avatar.Group>
           <Avatar
             className="shape-avatar"
             shape="round"
             size={40}
             src={
               record.employee.profile_image
                 ? record.employee.profile_image
                 : logo
             }
           ></Avatar>
           <div className="avatar-info pl-3">
             <Title level={5}>{record.employee.employee_name}</Title>
             <p style={{ color: "#616161" }}>
               {record.employee.designation_name +
                 " | " +
                 record.employee.employee_id}{" "}
             </p>
           </div>
         </Avatar.Group>{" "}
       </Space>
     ),
   },
   {
       title: 'Action',
       dataIndex: 'action',
       key: 'action',
       render: (_, record) => (
         <Space size="middle">
           <IconButton type='primary' icon={<CheckCircleOutlined />} onClick={() => handleLeave("accepted", record.id)}></IconButton>
           <IconButtonWarning type='primary' icon={<CloseCircleOutlined />} onClick={() => handleLeave("cancelled", record.id)} ></IconButtonWarning>
         </Space>
       ),
     },
 ];

  return (
    <div>
      <div
        //  title="Notice Board"
        // extra={<a href="#">More</a>}
        style={{ borderRadius: "0px" }}
        className="max-h-fit  ml-0 h-80 overflow-auto"
      >
        {/* {!data && <Spin tip="Loading" size="small" />} */}
        {leavedata && leavedata.length > 0 && (
          <Table
            columns={columns}
            dataSource={leavedata}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: false,
              pageSizeOptions: ["5", "10", "15"],
              hideOnSinglePage: true
            }}
            style={{ height: 540 }}
          />
        )}
        {leavedata && leavedata.length === 0 && (
          <div className="grid grid-cols-1 place-content-center">
            <div className="grid place-content-center">
              <img src={nodata}></img>
            </div>
            <div className="grid place-content-center">
              Looks like no data is here
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardLeave;
