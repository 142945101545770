import React, { useState, useEffect, useCallback, useRef } from 'react';
import { process_type_name } from 'components/common/enum';
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Select, Card, Checkbox, Tooltip } from 'antd'
import { areValuesNotEmptyExceptKeys, noBlankSpacesValidator } from 'components/common/validation';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";
import { LETTER_TYPE_API } from 'constants';

export const AddLetterType = ({ fromEdit, editDetails, onHide, onSave, isActive }) => {
    const companyid = sessionStorage.getItem("userId");
    const authtoken = sessionStorage.getItem("token");
    const prevPropsRef = useRef();

    const initialState = {
        code: '',
        letter_type_name: '',
        process_type_name: "",
        is_active: true,
        company_id: companyid
    }

    const [loadings, setLoadings] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [isCancelClk, setIsCancelClk] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                code: editDetails.code ? editDetails.code : "",
                letter_type_name: editDetails.letter_type_name ? editDetails.letter_type_name : "",
                process_type_name: editDetails.process_type_name ? editDetails.process_type_name : "",
                is_active: editDetails.is_active,
                company_id: editDetails.company_id
            }));
            form.setFieldsValue({
                code: editDetails.code ? editDetails.code : "",
                letter_type_name: editDetails.letter_type_name ? editDetails.letter_type_name : "",
                process_type_name: editDetails.process_type_name ? editDetails.process_type_name : "",
                is_active: editDetails.is_active,
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, form, formData])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        form.setFieldsValue({
            [name]: value,
        });
    };

    const renderInput = (statekey, maxLength = 150) => {
        return (
            <Input
                autoComplete='off'
                style={styles.textField}
                disabled={statekey === "code"}
                type={"text"}
                name={statekey}
                // placeholder={statekey === 'code' ? "Auto Generator" : null}
                value={formData[statekey] ? formData[statekey] : ""}
                maxLength={maxLength}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                onKeyDown={statekey === "no_of_days" ? handleKeyDown : null}
            />
        )
    }

    const handleCancelBtn = () => {
        setIsCancelClk(false);
        onHide();
    }

    const handleSubmit = useCallback((isDraftClked) => {
        let methods = fromEdit ? "PUT" : "POST";
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoadings(true)
            // let updatedFormData = {
            //     "letter_type_name": formData?.letter_name,
            //     "process_type_name": formData?.process_type,
            //     "is_active": formData?.is_active,
            //     "id": fromEdit ? formData?.id : "",
            //     "company_id": formData?.company_id,
            //     "code": fromEdit ? formData?.code : "",
            //     "is_draft": false
            // };
            let updatedFormData = { ...formData };
            if (isDraftClked) {
                updatedFormData = { ...updatedFormData, is_draft: true };
            } else {
                updatedFormData = { ...updatedFormData, is_draft: false };
            }
            fetch(LETTER_TYPE_API, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleCancelBtn();
                        setLoadings(false);
                        setFormData(initialState)
                        onSave();
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        } finally {
            setIsCancelClk(false);
        }
    }, [formData, fromEdit, companyid, initialState, onSave, authtoken]);

    const handleKeyDown = (e) => {
        // Allow only numbers, backspace, and delete keys
        if (!/^[\d.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
            e.preventDefault();
        }
    };

    const checkAllMandatory = useCallback((isDraftClked) => {
        if (!isDraftClked) {
            form
                .validateFields()
                .then(() => {
                    handleSubmit(false);
                })
                .catch(error => {
                    console.error("Validation Error:", error);
                });
        } else {
            handleSubmit(true);
        }
    }, [form, handleSubmit]);

    const handleSave = useCallback(() => {
        checkAllMandatory(false);
    }, [checkAllMandatory]);

    const handleSaveAsDraft = useCallback(() => {
        checkAllMandatory(true);
    }, [checkAllMandatory]);

    const keysToExclude = ['is_active', 'company_id'];
    const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

    return (
        <div className="w-full" style={{ marginBottom: '0.4vw' }}>
            <p className='common_add_tltTxt'>{fromEdit ? "Edit Letter Type" : "Add Letter Type"}</p>
            <Form
                layout="inline"
                form={form}
                colon={false}
                requiredMark={false}
                style={{ width: "100%" }}
                name="basicform"
                initialValues={formData}
                onFinish={handleSave}
                onSubmit={(e) => e.preventDefault()}
            >
                <div className='w-full flex justify-between align-center'>
                    {fromEdit ? <Form.Item
                        name="code"
                        rules={[{ required: true, message: "This field is required" },
                        { validator: noBlankSpacesValidator }
                        ]}          >
                        <div className='commonInputTlt'>Letter Type Code <span className='requiredTxt'>*</span></div>
                        {renderInput("code", 10)}
                    </Form.Item> : null}
                    <Form.Item
                        name="letter_type_name"
                        rules={[{ required: true, message: "This field is required" },
                        { validator: noBlankSpacesValidator }
                        ]}          >
                        <div className='commonInputTlt'>Letter Type Name <span className='requiredTxt'>*</span></div>
                        {renderInput("letter_type_name", 50)}
                    </Form.Item>
                    <Form.Item
                        name="process_type_name"
                        rules={[{ required: true, message: "This field is required" }]}
                    >
                        <div className='commonInputTlt'>Process Type Name <span className='requiredTxt'>*</span></div>
                        <Select
                            style={styles.textField}
                            value={formData.process_type_name}
                            name={"process_type_name"}
                            onChange={(value) => handleChange("process_type_name", value)}
                        >
                            {process_type_name.map((option) => (
                                <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="is_active">
                        <Checkbox
                            disabled={fromEdit ? !isActive : true}
                            checked={formData.is_active}
                            onChange={(e) => handleChange("is_active", e.target.checked)}
                        >
                            Active
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <div className='flex justify-center items-end'>
                            <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                            <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : onHide} outlined={true} paddingX={"0 0.7vw"} />
                        </div>
                    </Form.Item>
                </div>
            </Form>
            {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} /> : null}
        </div>
    );
}

const styles = {
    textField: {
        width: "15vw",
        marginRight: "0.625vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    }
}

export default AddLetterType