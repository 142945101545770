import { EMPLOYEE_ATTENDANCE_DAILY, EMPLOYEE_ATTENDANCE_LOG,  EMPLOYEE_ATTENDANCE_LOG_CHART} from 'constants';
import { prefillUrl } from 'utils/StringUtils';

export const getDayWiseAttendance = async ({ pageNo, pageSize, query, search, sort, date }) => {
    const authtoken = sessionStorage.getItem("token")
    let url = EMPLOYEE_ATTENDANCE_DAILY;
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sort) {
        url = prefillUrl(url);
        url = `${url}&${sort}`;
    }

    if (date) {
        url = prefillUrl(url);
        url = `${url}&date=${date}`;
    }

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getDayWiseAttendanceByQuery = async (query) => {
    try {
        const authtoken = sessionStorage.getItem("token");
        const requestOptions = {
            method: "GET",
            headers: { Authorization: `token ${authtoken}` },
        };

        let url = EMPLOYEE_ATTENDANCE_DAILY;

        if (query) {
            url = prefillUrl(url);
            url = `${url}${query}`;
        }

        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
};

export const getAttendanceLog = async (month, year, pageNo, pageSize, query, search, sort) => {
    const authtoken = sessionStorage.getItem("token")
    let url = EMPLOYEE_ATTENDANCE_LOG;
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `token ${authtoken}` },
    };

    if (month) {
        url = prefillUrl(url);
        url = `${url}month=${month}`;
    }

    if (year) {
        url = prefillUrl(url);
        url = `${url}&year=${year}`;
    }

    if (pageNo) {
        url = prefillUrl(url);
        url = `${url}&page_no=${pageNo}`;
    }

    if (pageSize) {
        url = prefillUrl(url);
        url = `${url}&page_size=${pageSize}`;
    }

    if (query) {
        url = prefillUrl(url);
        url = `${url}&${query}`;
    }

    if (search) {
        url = prefillUrl(url);
        url = `${url}&search=${search}`;
    }

    if (sort) {
        url = prefillUrl(url);
        url = `${url}&${sort}`;
    }

    return await fetch(url, requestOptions).then((response) => response.json()).then(data => data);
}

export const getAttendanceLogchart = async ( id ) => {
    try {
        const authtoken = sessionStorage.getItem("token")
        let url = EMPLOYEE_ATTENDANCE_LOG_CHART;

        if (id) {
            url = prefillUrl(url);
            url = `${url}id=${id}`;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `token ${authtoken}` },
        };
        return await fetch(url, requestOptions)
            .then((response) => response.json())
            .then(data => data);
    } catch (error) {
        return { status: "fail", message: error.message };
    }
}