import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Select, Card, DatePicker, Tooltip } from 'antd'
import { ASSET_EMPLOYEE_AUDIT } from 'constants';
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US';

export const AddEditAssetEmployeeAudit = () => {
    const companyid = sessionStorage.getItem("userId")
    const [formData, setFormData] = useState({
        company_id: companyid,
        is_active: true
    });
    const [loadings, setLoadings] = useState(false);
    const prevPropsRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const { fromEdit, editDetails, filterDatas } = location.state || {};
    const { assetRegistryList, employeeList } = filterDatas;
    const [form] = Form.useForm();
    const hideCursorRef = useRef({});

    useEffect(() => {
        if (editDetails !== null &&
            Object.keys(editDetails).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
            JSON.stringify(formData) !== JSON.stringify(editDetails)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: editDetails.id,
                company_id: editDetails.company_id,
                employee: editDetails.employee?.id ? editDetails.employee.id : undefined,
                asset_registry: editDetails.asset_registry_id?.id ? editDetails.asset_registry_id.id : undefined,
                handing_over_to: editDetails.handing_over_to_id?.employee_id ? editDetails.handing_over_to_id.employee_id : undefined,
                handing_over_by: editDetails.hand_over_by?.employee_id ? editDetails.hand_over_by.employee_id : undefined,
                approved_by: editDetails.approved_by_id?.employee_id ? editDetails.approved_by_id.employee_id : undefined,
                remarks: editDetails.remarks ? editDetails.remarks : undefined,
                issued_date: editDetails.issued_date ? dayjs(editDetails.issued_date, "YYYY-MM-DD") : undefined,
                return_date: editDetails.return_date ? dayjs(editDetails.return_date, "YYYY-MM-DD") : undefined,
            }));
            form.setFieldsValue({
                employee: editDetails.employee?.id ? editDetails.employee.id : undefined,
                asset_registry: editDetails.asset_registry_id?.id ? editDetails.asset_registry_id.id : undefined,
                handing_over_by: editDetails.hand_over_by?.employee_id ? editDetails.hand_over_by.employee_id : undefined,
                handing_over_to: editDetails.handing_over_to_id?.employee_id ? editDetails.handing_over_to_id.employee_id : undefined,
                approved_by: editDetails.approved_by_id?.employee_id ? editDetails.approved_by_id.employee_id : undefined,
                remarks: editDetails.remarks ? editDetails.remarks : undefined,
                issued_date: editDetails.issued_date ? dayjs(editDetails.issued_date, "YYYY-MM-DD") : undefined,
                return_date: editDetails.return_date ? dayjs(editDetails.return_date, "YYYY-MM-DD") : undefined,
            });
            prevPropsRef.current = editDetails;
        }
    }, [editDetails, form, formData])

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        form.setFieldsValue({
            ...formData,
            [name]: value,
          });
    };

    const handleBackCancel = useCallback(() => {
        navigate('/assetmanagement/assetemployeeaudit');
    }, [navigate])


    const handleSubmit = useCallback(() => {
        let methods = fromEdit ? "PUT" : "POST";
        const authtoken = sessionStorage.getItem("token")
        try {
            setLoadings(true)
            let updatedFormData = {
                ...formData,
                issued_date: formData.issued_date ? dayjs(formData.issued_date).format('YYYY-MM-DD') : undefined,
                return_date: formData.return_date ? dayjs(formData.return_date).format('YYYY-MM-DD') : undefined
            };

            fetch(ASSET_EMPLOYEE_AUDIT, {
                method: methods,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `token ${authtoken}`
                },
                body: JSON.stringify(updatedFormData)
            })
                .then((response) => response.json())
                .then(async data => {
                    if (data.status === "success") {
                        ToastMsg("success", data.message);
                        handleBackCancel();
                        setLoadings(false);
                        setFormData({
                            employee: undefined,
                            asset_registry: undefined,
                            issued_date: undefined,
                            return_date: undefined,
                            handing_over_to: undefined,
                            approved_by: undefined,
                            remarks: undefined,
                            handing_over_by: undefined,
                            is_active: true,
                        })
                    }
                    else if (data.status === "fail") {
                        setLoadings(false);
                        ToastMsg("error", data.message);
                    }
                })
                .catch(error => {
                    setLoadings(false);
                    ToastMsg("error", error.message);
                });
        } catch (error) {
            ToastMsg("error", error.message);
        }
    }, [formData, fromEdit, companyid, handleBackCancel]);

    const renderInput = (label, statekey, maxLength = 150, testID = '', required = false) => {
        return (
            <div>
            <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span>: null}</div>
            <Input
                autoComplete='off'
                style={styles.textField}
                type={"text"}
                name={statekey}
                value={formData[statekey]}
                maxLength={maxLength}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                data-testid={testID}
            />
            </div>
        )
    }

    const disabledDateFrom = current => {
        let { issued_date } = formData || {};
        const startDate = dayjs(issued_date);
        if (startDate) {
            return current && current < startDate.startOf('day');
        }
        return current && current > dayjs().endOf('day');
    };

    const renderDatePicker = (label, statekey, disabled = false, testID = '', required = false) => {
        return (
            <div>
            <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span>: null}</div>
            <DatePicker
                style={styles.textField}
                name={statekey}
                value={formData[statekey] ? formData[statekey] : undefined}
                format={"DD-MM-YYYY"}
                onChange={(date) => handleChange(statekey, date)}
                allowClear={true}
                inputReadOnly={true}
                locale={locale}
                disabled={disabled}
                data-testid={testID}
                // disabledDate={statekey === "return_date" ? disabledDateFrom : statekey === "issued_date"? restrictIssuedDated:  null}
                placeholder = ""
                disabledDate={statekey === "return_date" ? disabledDate : statekey === "issued_date"? restrictIssuedDated:  null}
            />
            </div>
        )
    }

    
    const disabledDate = (currentDate) => {
        const today = dayjs().startOf('day');
        return !currentDate || !currentDate.isSame(today, 'day');
    };

    const restrictIssuedDated = current => {
        if (formData.asset_registry) {
            const item = assetRegistryList.find(item => item.id === parseInt(formData.asset_registry));
            if (item.purchase_date) {
                const startDate = dayjs(item.purchase_date);
                if (startDate) {
                    return current && current < startDate.startOf('day');
                }
            }
        }
        return null;
    }

    const renderSelect = (label, statekey, list = [], showKey = "text", valueKey = "value", disabled = false, testID = '', withEmpId = false,required = false) => {
        const filteredList = list.filter(option => {
            if (statekey === "asset_registry") {
                return (!option.is_assigned && option.is_active) || option.id === formData.asset_registry;
            } else {
                return valueKey !== "id" || option.is_active || option.id === formData[statekey];
            }
        });
        if (!hideCursorRef.current[statekey]) {
            hideCursorRef.current[statekey] = React.createRef();
        }
        return (
            <div>
            <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span>: null}</div>
            <Select
                style={styles.textField}
                value={formData[statekey] ? formData[statekey] : undefined}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                disabled={disabled}
                data-testid={testID}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                ref={hideCursorRef.current[statekey]}
                onSelect={() => {
                    hideCursorRef.current[statekey].current.blur();
                }} 
                >
                    {filteredList.map(option => (
                        <Select.Option key={option[valueKey]} value={option[valueKey]}>
                            {withEmpId ? option[showKey] + (option['employee_id'] ? " - " + option['employee_id'] : "")
                                : statekey === "asset_registry" ? (option[showKey] + " - " + option['asset_name'])
                                    : option[showKey]}
                        </Select.Option>
                    ))}
           </Select>
            </div>
        );
    };

    return (
        <Card className='h-full'>
            <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
                <div className='flex items-center'>
                    <Tooltip title={"Back"} placement="bottom">
                        <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                    </Tooltip>
                    <p className='common_addTltTxt'>{fromEdit ? "Edit Asset Employee Audit" : "Add Asset Employee Audit"}</p>
                </div>
            </div>
            <div className='h-5/6' style={{ margin: "3vw 0vw" }}>
                <Form
                    layout="inline"
                    form={form}
                    colon={false}
                    requiredMark={false}
                    className='w-full'
                    name="basicform"
                    initialValues={formData}
                    onFinish={handleSubmit}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid={'astadt_form'}
                >
                    <div className='w-full flex justify-evenly align-center m-1'>
                        <Form.Item style={styles.formItem} name="employee" rules={[{ required: true, message: "This field is required", }]}>
                            {renderSelect("Employee ID", "employee", employeeList, "first_name", "id", fromEdit ? true : false, 'astadt_in_empid', true, true)}
                        </Form.Item>
                        <Form.Item style={styles.formItem} name="asset_registry" rules={[{ required: true, message: "This field is required", }]}>
                            {renderSelect("Asset ID", "asset_registry", assetRegistryList, "asset_id", "id", fromEdit ? true : false, 'astadt_in_astid', false, true)}
                        </Form.Item>
                        <Form.Item style={styles.formItem} name="issued_date" rules={[{ required: true, message: "This field is required", }]}>
                            {renderDatePicker("Issued Date", "issued_date", fromEdit ? true : false, 'astadt_in_isd', true)}
                        </Form.Item>
                    </div>
                    <div className='w-full flex justify-evenly align-center m-1'>
                        <Form.Item name="return_date" style={styles.formItem} rules={[{ required: formData.handing_over_to ? true : false, message: "This field is required", }]}>
                            {renderDatePicker("Return Date", "return_date", false, 'astadt_in_rtnd', formData.handing_over_to ? true : false)}
                        </Form.Item>
                        <Form.Item name="handing_over_by" style={styles.formItem} rules={[{ required: true, message: "This field is required" }]}>
                            {renderSelect("Handover By", "handing_over_by", employeeList, "first_name", "id", fromEdit ? true : false, 'astadt_in_hob', true, true)}
                        </Form.Item>
                        <Form.Item name="handing_over_to" style={styles.formItem}>
                            {renderSelect("Handover To", "handing_over_to", employeeList, "first_name", "id", false, 'astadt_in_hot', true)}
                        </Form.Item>
                    </div>
                    <div className='w-full flex justify-evenly align-center m-1'>
                        <Form.Item name="approved_by" style={styles.formItem}>
                            {renderSelect("Approved By", "approved_by", employeeList, "first_name", "id", false, 'astadt_in_appby', true)}
                        </Form.Item>
                        <Form.Item style={styles.formItem} name="remarks">
                            {renderInput('Remarks', "remarks", 150, 'asdadt_in_remark')}
                        </Form.Item>
                        <Form.Item style={styles.emptyFormItem} />
                    </div>
                    <div className='w-full relative inset-y-10'>
                        <Form.Item >
                            <div className='flex justify-end items-end mr-2'>
                                <MyButton htmlType="button" label="Cancel" onClick={handleBackCancel} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='astadt_btn_cancel' />
                                <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} testID='astadt_btn_save' />
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Card>
    );
}
const styles = {
    textField: {
        width: "23vw"
    },
    formItem: {
        margin: "1vw"
    },
    emptyFormItem: {
        width: "23vw",
        margin: "1vw"
    }
}
export default AddEditAssetEmployeeAudit