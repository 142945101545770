import React, { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const EmployeesChart = ({ chartData }) => {
  useLayoutEffect(() => {
    let root = am5.Root.new(`chartdivemp`);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    // Define data
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: am5.GridLayout.new(root, {
          maxColumns: 4,
          fixedWidthGrid: false,
        }),
      })
    );
    
    const transformedData = chartData.map((employeeItem) => {
      const { employee_type, department } = employeeItem;
      const { "django developer": djangoDeveloper, ...rest } = employee_type;
      const djangoDevelopers = djangoDeveloper - 2; // Assuming the constant value "2" for "django developers"

      return {
        type: department,
        // "django developers": djangoDevelopers,
        ...rest,
      };
    });

    var data = [...transformedData];
    
    var xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 0.1,
      minGridDistance: 10,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "type",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    xRenderer.labels.template.setAll({
      oversizedBehavior: "truncate",
      maxWidth: 50,
    });

    xAxis.data.setAll(data);

    var yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "type",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{categoryX} - {name}:{valueY}",
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    const consolidatedData = transformedData.reduce((result, currentObj) => {
      Object.entries(currentObj).forEach(([key, value]) => {
        const existingItemIndex = result.findIndex(
          (item) => item.designation === key
        );
        if (existingItemIndex === -1) {
          if (key !== "type") {
            result.push({ designation: key, count: value });
          }
          // result.push({ designation: key, count: value });
        } else {
          result[existingItemIndex].count += value;
        }
      });
      return result;
    }, []);

    consolidatedData.map((row) => {
      makeSeries(row.designation, row.designation);
    });
    

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "300",
    });
    legend.valueLabels.template.set("forceHidden", true);

    var modal = am5.Modal.new(root, {
      content: "No data"
    });

    if (!data || data.length === 0){
      modal.open();
    }

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root._logo?.dispose();
      root.dispose();
    };
  }, []);

  return (
    <div id={`chartdivemp`} style={{ width: "100%", height: "250px" }} />
  );
}
export default EmployeesChart;
