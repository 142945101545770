import React, { useEffect, useState} from "react";
import { Card, Table } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import {getUserPermissionPagination, getUserPermissionTypeById} from "hooks/api/adminApi/userPermissionApi";
import { ADMIN_USER_PERMISSION } from "constants";
import { useNavigate } from 'react-router-dom';
import {checkUserPermissionFunc} from "components/common/CommonFuntion";

function UserPermission() {
  const authtoken = sessionStorage.getItem("token");
  const query = "";
  const pageSize = 10;

  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const tableContainerHeight = "72vh";

  const navigate = useNavigate();

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  const userPermission = checkUserPermissionFunc("administrator", "User Permission", "SubMenu");

  const [data, pageInfo, loading, _message, refetch] = useApiData(getUserPermissionPagination, {
    pageNo,
    pageSize,
    query,
    searchText,
    sorting,
  });

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg("error", _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const getUserAccessList = async (id) => {
    try {
      const apiData = await getUserPermissionTypeById(id);
      if (apiData && apiData.status === "success" && apiData.data ) {
        let dataList = (apiData.data.result && apiData.data.result?.length > 0)  ? {...apiData.data.result[0]} : {}
        navigate('/admin/userpermission/edit', { state: { editDetails: dataList } });
        setSelectedDetails(dataList);
      } else {
        ToastMsg("error", apiData.message)
        setSelectedDetails({});
      }
    } catch (error) {
      setSelectedDetails({});
    }
  }

  const handleEditClick = (id) => {
    if(id){
      setEditVisible(true);
      getUserAccessList(id);
    }
  };

  const handleSearch = () => {
    setEditVisible(false);
    setSelectedDetails({});
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend"
        ? "desc"
        : sorter.order === "ascend"
          ? "asc"
          : ""
      }&`;
    const sortQuery = sorter.order
      ? `${sortorder}column=${sorter.columnKey}`
      : "";
    refetch(pagination.current, pageSize, query, searchText, sortQuery);
    setSorting(sortQuery);
  };

  const columns = [
    {
      title: "User Group",
      dataIndex: "usergroup_name",
      key: "usergroup_name",
      sorter: true,
      fixed: "left",
      render: (_, record) => (<p style={{ color: "black" }}>{record?.usergroup_name ? record?.usergroup_name : "-"}</p>),
    },
  ];
  if (userPermission?.edit) {
    columns.push({
      title: "Action",
      key: "action",
      align: "center",
      width: "25%",
      fixed: "left",
      render: (_, record) => (
        <IconButton
          title="Edit"
          icon={<img src={ImagePaths.edit.default} alt="Edit" />}
          onClick={() => (loading ? "" : handleEditClick(record?.usergroup_id))}
        />
      ),
    })
  }

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search);
    setReorderedColumns(reorderedList);
  };

  const queryString ="?page_no=" + pageNo + "&page_size=" + pageSize + "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full overflow-hidden">
      <div className={"h-1/6"}>
        <CommonTblHeader
          pageName={"User Permission"}
          editVisible={editVisible}
          pageInfo={pageInfo}
          handleSearch={handleSearch}
          selectedDetails={selectedDetails}
          onStateChange={handleStateChanges}
          columns={columns}
          url={ADMIN_USER_PERMISSION}
          query={queryString}
          isAddRequired = {false}
          isDraftRequired = {false}
          isFltRequired = {false}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center mt-1">
      <div className={"mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </div>
      </div>
    </Card>
  );
}

export default UserPermission;
