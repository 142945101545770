import React from "react";
import { Button, Tooltip } from "antd";

const MyPresentButton = ({
  label = "",
  onClick = () => {},
  className = "",
  icon = "",
  type = "primary",
  htmlType="",
  loading="",
  value="",
  bgColor = "#28A745",
  tooltipLabel = ""
}) => (
  
  <Tooltip title = {tooltipLabel}>
    <Button style={{ background: bgColor, border: "none", width:"2.18vw", borderRadius:5, boxShadow:'0', fontFamily: "Urbanist" }} htmlType={htmlType} icon={icon} type={type} 
  className={className} onClick={onClick} loading={loading} value={value}>
    {label}
  </Button>
  </Tooltip>
);

export default MyPresentButton;
